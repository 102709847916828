import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileState } from 'src/app/core/store/reducers/s3-upload.reducer';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { S3Service } from './s3.service';
import { PollingErrorResponse } from 'src/app/modules/wizards/base-wizard.service';


@Injectable({
  providedIn: 'root'
})
export class FileValidatorService {

  constructor(
    protected http: HttpClient,
    protected s3Service: S3Service
  ) { }

  async validateCsvFileRules(fileState: FileState): Promise<any> {

    try {
      const signedGetUri = await this.s3Service.presignedS3GetUrl(fileState.s3PutUri);
      const payload = {
        "data": {
          "attributes": {
            "type": "async",
            "dry_run": true,
            "dir_path": "/ebs/ftpd/validation/sample",
            "sample_url":  signedGetUri,
            "prepend_headers": false
          }
        }
      };

      const uri = environment.openbridgeApiUris.service + '/service/rules/' + environment.openbridgeApiUris.partialIdentifier + '/v1/rules-dryrun';

      const response = await this.http.post(uri, payload, {observe: 'response'}).toPromise();

      const location = response.headers.get('location');

      if(location === null) {
        throw Error("Location not returned.")
      }

      let asyncPoll: boolean = true;
      const asyncStartEpoch = Math.floor((Date.now()/1000));
      const asyncForcePollEndEpoch = asyncStartEpoch + environment.csvUploadValidation.pollingTimeInSeconds;

      let finalResponse: HttpResponse<any> | HttpErrorResponse | PollingErrorResponse = null;

      while(asyncPoll) {
        let asyncResponse = await this.http.get(location, {observe: 'response'}).toPromise();

        const nowEpoch = Math.floor((Date.now()/1000));

        if(asyncResponse.status === 200) {
          finalResponse = asyncResponse;
          asyncPoll = false;
        }
        else if(nowEpoch > asyncForcePollEndEpoch) {
          finalResponse = new PollingErrorResponse();
          finalResponse.error = {
              errors: 'File processing exceeded polling limit'
          };
          asyncPoll = false;
        }
        else {
          const delay = ms => new Promise(res => setTimeout(res, ms));
          await delay(2000);
        }

      }

      return finalResponse;

    }
    catch(error) {
      return error;
    }
  }
}

