export const responseSnowflakeAccountMappingEncryption = {
  data: {
    attributes: {
      is_temp: true,
      path: '/ebs/ftpd/test-1641468440915-000001',
      region: 'us-east-1',
      s3_bucket: 'ob-zeroadmin-stage',
      snowflake_account: {
        encrypt: 'kms',
        value: 'encrypted data'
      },
      snowflake_database: 'OPENBRIDGE_DATABASE',
      snowflake_schema: 'OPENBRIDGE_SCHEMA',
      snowflake_password: {
        encrypt: 'kms',
        value: 'encrypted data'
      },
      snowflake_user: {
        encrypt: 'kms',
        value: 'encrypted data'
      },
      snowflake_warehouse: 'OPENBRIDGE_WAREHOUSE',
      storage: 'snowflake',
      templates: 'https://s3.amazonaws.com/ob_internal/templates/default.json'
    }
  }
};