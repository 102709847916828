import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { accountUpdate } from '../store/actions/account.actions';
import { authUpdateToken } from '../store/actions/auth.actions';
import { modalsOpen } from '../store/actions/modals.action';
import { AppState } from '../store/reducers';
import { take } from 'rxjs/operators';
import { errorVpnAuthBlock } from 'src/app/shared/constants/flash-notifications';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    private httpClient: HttpClient,
    private store$: Store<AppState>
  ) { }

  async hasDeltas(type: string = 'all', accountId: number, epochSeconds: number): Promise<boolean> {

    const requestUrl = environment.openbridgeApiUris.account + '/deltas/' + accountId + '?timestamp=' + epochSeconds + '&type=' + type;
    const response = await this.httpClient.get(requestUrl).toPromise();

    if (!response['data'].has_deltas) {
      console.info('Retaining cached account ' +  type + ', if they exist');
      return false;
    }
    console.info('Clearing cached account subscriptions and identities ' +  type + ', if they exist');
    return true;
  }

  async getAccountUserDetails(authProfile: any): Promise<any> {

    try {

      const accountResponse = await this.getAccount();
      const userResponse = await this.getUser();

      // Ignore email update if in proxy mode.
      if(localStorage.getItem('proxy') === null) {
        this.validateCorrectEmailAddress(authProfile.email, userResponse['data'][0]['attributes']['email_address'], userResponse['data'][0]['id']);
      }

      const payload = {
        account: accountResponse['data'][0]['id'],
        accountOwnerAuth0Id: accountResponse['data'][0]['attributes']['owner']['auth0_user_id'],
        organizationId: accountResponse['data'][0]['attributes']['organization_id'],
        organizationAllowed: accountResponse['data'][0]['attributes']['organization_allowed'],
      };

      this.store$.dispatch(accountUpdate(payload));

      // The format of the nodes are legacy auth0 code product.
      const accountUserInfo = {
        accountId: accountResponse['data'][0]['id'],
        userId: userResponse['data'][0]['id'],
        stripeCustomerId: accountResponse['data'][0]['attributes']['stripe_customer_id']
      };

      return accountUserInfo;
    }
    catch (error) {
      throw error;
    }
  }

  async getAccount(): Promise<any> {
    const accountRequestUrl = environment.openbridgeApiUris.account;
    return await this.httpClient.get(accountRequestUrl).toPromise();
  }

  async getUser(): Promise<any> {
    const userRequestUrl = environment.openbridgeApiUris.user;
    return await this.httpClient.get(userRequestUrl).toPromise();
  }

  async getAccountApiToken(): Promise<any> {
    try {

      const authenticateResponse = await this.httpClient.get(environment.openbridgeApiUris.authentication + '/auth/jwt').toPromise();

      return {
        token: authenticateResponse['data']['attributes']['token'],
        expiresAt: Math.floor(authenticateResponse['data']['attributes']['expires_at'])
      };
    }
    catch (errorResponse) {
      let errorMessage = '';
 
      const keys = Object.keys(errorResponse.error);

      if((keys.length == 1) && (keys[0] == 'isTrusted') && (errorResponse.error['isTrusted'] === true)) {
        errorMessage = errorVpnAuthBlock;
      }
      else {
        errorMessage = JSON.stringify(errorResponse.error);
      }

      this.store$.dispatch(modalsOpen({
        modalType: 'error',
        title: 'Account authentication error',
        message: errorMessage,
        progress: 0
      }));
    }
  }

  async updateAccountApiToken(): Promise<void> {
    const tokenData = await this.getAccountApiToken();
    this.store$.dispatch(authUpdateToken(tokenData));
  }

  async createOrganization(organizationName: string): Promise<any> {
    const payload = {
      data: {
        type: 'Service',
        attributes: {
          name: organizationName.replace(/ /g, '-').toLowerCase(),
          display_name: organizationName,
          enabled_connections: [
            {
              connection_id: 'con_2wpt6dNQGLBbIzNw',
              assign_membership_on_login: false
            }
          ]
        }
      }
    };
    return await this.httpClient.post(environment.openbridgeApiUris.service + '/service/org/manage', payload).toPromise();
  }

  async getOrganization(): Promise<any> {
    return await this.httpClient.get(environment.openbridgeApiUris.service + '/service/org/manage').toPromise();
  }

  async validateCorrectEmailAddress(auth0Email: string, openbridgeEmailAddress: string, openbridgeUserId: string): Promise<void> {
    try {
      if (openbridgeEmailAddress !== auth0Email) {
        console.info('Updating local user information');

        const payload = {
          data: {
            id: openbridgeUserId,
            type: 'User',
            attributes: {
              email_address: auth0Email
            }
          }
        };

        const userRequestUrl = environment.openbridgeApiUris.user;
        await this.httpClient.patch(userRequestUrl + '/' + openbridgeUserId, payload).toPromise();

        return;
      }
    }
    catch (error) {
      console.error(error);
    }
  }

  async updateAccountStripeEmail(stripeCustomerId: string, emailAddress: string) {
    const payload = {
      "data": {
        "type": "Service",
        "attributes": {
          "stripe_customer_id": stripeCustomerId,
          "email": emailAddress
        }
      } 
    };

    const serviceRequestUri = environment.openbridgeApiUris.service;
    await this.httpClient.patch(serviceRequestUri + '/service/stripe/customer_email_update', payload).toPromise();
  };  

  async getAccountId(): Promise<number> {
    let accountId = null;
    this.store$.select('account').pipe(take(1)).subscribe(response => {
      accountId = response.account;
    });
    return accountId;
  }
}
