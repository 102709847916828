import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { take, tap } from 'rxjs/operators';
import { ModalPipelineSaveComponent } from 'src/app/shared/components/modals/modal-pipeline-save/modal-pipeline-save.component';
import { PipelineSubscription } from 'src/app/shared/models/pipeline-subscription.model';
import { flashNotificationsFormValidationErrorWithDetails } from '../actions/flash-notifications.actions';
import { subscriptionAdd, subscriptionUpdate } from '../actions/subscriptions.actions';
import { wizardIntegrationInit, wizardIntegrationSave } from '../actions/wizard.actions';
import { AppState } from '../reducers';
import { PipelineService } from './../../services/pipeline.service';
import { modalNonUserClosable } from 'src/app/shared/constants/component-configs';
import { apiResponseCodesSubscription } from 'src/app/shared/constants/api-response-code';
import { subscriptionEventMetaData } from 'src/app/shared/constants/intercom-event-metadata-types';
import { IntegrationService } from 'src/app/shared/services/integration.service';
import { intercomUpdate } from '../actions/intercom.actions';
import { Integration } from 'src/app/shared/models/integration.model';
import { IntercomService } from 'src/app/shared/services/intercom.service';

@Injectable()
export class WizardEffects {
  bsModalRef: BsModalRef;

  savePipeline$ = createEffect(() => this.actions$.pipe(
    ofType(wizardIntegrationSave),
    tap(() => {

      this.store$.select('auth').pipe(take(1)).subscribe(async (authResponse) => {
        const accountId: number = authResponse.currentAccount.accountId;
        const userId: number = authResponse.currentAccount.userId;

        this.store$.select('wizard').pipe(take(1)).subscribe(
          async (response) => {

            try {

              this.openModalWithComponent();
              const pipelineSubscription: PipelineSubscription = await this.pipelineService.process(
                accountId, userId, response);
              this.closeModalWithComponent();

              if (pipelineSubscription) {
                if (response.config.wizardMode === 'create') {
                  this.store$.dispatch(subscriptionAdd({
                    pipelineSubscription: pipelineSubscription
                  }));
                }
                else if (response.config.wizardMode === 'edit') {
                  this.store$.dispatch(subscriptionUpdate({
                    pipelineSubscription: pipelineSubscription
                  }));
                }
              }

              this.store$.select('subscriptions').subscribe(response => {
                let tmpArray: any[] = [];

                if (typeof response.subscriptions[Symbol.iterator] === 'function') {
                  response.subscriptions.forEach((val) => {
                    if (val.status !== 'invalid') {
                      tmpArray = [
                        val,
                        ...tmpArray
                      ];
                    }
                  });
                }

                // update product ids in intercom
                const allSubscriptions = [...tmpArray];
                const activeSubscription = allSubscriptions.filter(data => data.status === 'active');
                const cancelledSubscription = allSubscriptions.filter(data => data.status === 'cancelled')

                const activeSubscriptionProductIdMap = activeSubscription.map(data => (data.productId));
                const activeSubscriptionUniqueProductIds = this.removeDuplicateProductIds(activeSubscriptionProductIdMap);

                const cancelledSubscriptionProductIdMap = cancelledSubscription.map(data => (data.productId));
                const cancelledSubscriptionUniqueProductIds = this.removeDuplicateProductIds(cancelledSubscriptionProductIdMap);

                this.store$.dispatch(intercomUpdate({
                  ActiveSubscribedProductIds: activeSubscriptionUniqueProductIds,
                  InActiveSubscribedProductIds: cancelledSubscriptionUniqueProductIds
                }));
              });

              // Intercom success subscription creation dispatch here.
              this.dispatchIntercomSubscriptionEvent(response, 'success');

              const routerLink = response.routerLink + '/complete';

              // re-initialize the wizard state
              this.store$.dispatch(wizardIntegrationInit({
                routerLink: null,
                stage: null,
                config: null
              }));

              // navigate to the thankyou page.
              this.router.navigateByUrl(response.routerLink + '/complete');
            }
            catch (error) {
              let parsedError = null;

              if (typeof error === 'object') {
                if (error.error) {
                  parsedError = apiResponseCodesSubscription[error.error.errors[0].code];
                } else {
                  parsedError = JSON.stringify(error);
                }
              } else {
                parsedError = error;
              }

              // Intercom error subscription creation dispatch here.
              this.dispatchIntercomSubscriptionEvent(response, 'failure', parsedError);

              // tslint:disable-next-line: max-line-length
              this.store$.dispatch(flashNotificationsFormValidationErrorWithDetails({ message: 'Unable to save pipeline', details: parsedError }));
              this.closeModalWithComponent();
            }
          }
        );
      });
    })
  ),
    { dispatch: false }
  );

  private openModalWithComponent(): void {
    this.bsModalRef = this.modalService.show(ModalPipelineSaveComponent, modalNonUserClosable);
  }

  private closeModalWithComponent(): void {
    if (this.bsModalRef !== null) {
      this.bsModalRef.hide();
    }
    this.bsModalRef = null;
  }

  private removeDuplicateProductIds(data): any {
    return data.filter((value, index) => data.indexOf(value) === index);
  }

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private pipelineService: PipelineService,
    private router: Router,
    private modalService: BsModalService,
    private integrationService: IntegrationService,
    private intercomService: IntercomService,
  ) { }



  private dispatchIntercomSubscriptionEvent(response: any, status: 'success' | 'failure', error?: any | null): void {
    
    const configKeys = Object.keys(response.config);
    let integration: Integration | null = null;

    if(configKeys.includes('subProductId')) {
      integration = this.integrationService.findIntegrationFromIdAndSubproductId(response.productId, response.config.subProductId)
    }
    else {
      integration = this.integrationService.findIntegrationFromId(response.productId)
    } 

    if(integration) { 
      const productName = integration.brand + ' ' + integration.name;
      const eventDescription = 'subscription ' + response.config.wizardMode + ' '+ status +' event';
      
      const metaData: subscriptionEventMetaData = {
        productType: integration.type,
        mode: response.config.wizardMode,
        eventDescription: eventDescription,
        isAbandon: false,
        productId: response.productId,
        subProductId: response.config.subProductId || "default",
        productName: productName,
        status: status,
        error: JSON.stringify(error),
      }
  
      this.intercomService.dispatchIntercomSubscriptionEvent(metaData);

    }
  }

}