import { Injectable } from '@angular/core';
import { sources as integrations } from '../constants/integrations/integrations';
import { Integration } from '../models/integration.model';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/reducers';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HistoryDefinition } from 'src/app/shared/interfaces/history.interfaces';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  constructor(
    private store$: Store<AppState>,
    private router: Router
  ) { }

  // findIntegrationFromId(productId: number): any {
  //   return this.findIntegrationFromIdAndSubproductId(productId, "default");
  // }

  findIntegrationFromId(productId: number): any {
    let result: any = null;

    result = integrations.filter((source) => {
      return (+source.productId === +productId && source.primary === true);
    });

    if (result.length === 1) {
      return result[0];
    }
    return false;
  }  

  findIntegrationFromIdAndSubproductId(productId: number, subProductId: string): any {
    let result: any = null;

    result = integrations.filter((source) => {

      return (+source.productId === +productId && source.primary === true && source.subProductId === subProductId);
    });

    if (result.length === 1) {
      return result[0];
    }
    return false;
  }

  findIntegrationFromPath(path: string): any {
    let result: any = null;

    result = integrations.filter((source) => {
      return source.routerLink === path;
    });

    if (result.length === 1) {
      return result[0];
    }
    return false;
  }

  getSources(): Integration[] {
    return this.getIntegrationsByType('source');
  }

  getDestinations(): Integration[] {
    return this.getIntegrationsByType('destination');
  }

  integrationHistoryParameters(integrationId: number): HistoryDefinition {

    let integrationsHistory = []
    let counter = 0;

    this.store$.select('history').pipe(take(1)).subscribe(response => {
      integrationsHistory = response.productHistoryParams;
      if(integrationsHistory.length === 0){
        this.router.navigate(['/pipelines']);
      }
    });

    let integrationHistory = integrationsHistory.find(o => o.productId === integrationId);

    if(!integrationHistory) return null;
    if(integrationHistory.endDateDeltaInDays === 0) return null;

    return integrationHistory;
  }

  canRequestHistory(integrationId: number): boolean {
    const parameters: HistoryDefinition = this.integrationHistoryParameters(integrationId);
    return (parameters === null) ? false : true;
  }

  integrationIsDestination(integrationId: number): boolean {
    const destinations = this.getDestinations();
    const destination = destinations.find(dest => dest.productId === integrationId);
    return (destination) ? true : false;
  }

  private getIntegrationsByType(type: 'source' | 'destination'): Integration[] {
    let integrationList = integrations.filter((source) => {
      if (source.enabled || source.comingSoon) {
        return source.type === type;
      }
    });
    integrationList = integrationList.sort((a, b) => b.sortOrder - b.sortOrder);
    return integrationList;
  }

}
