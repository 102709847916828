export const responseAmazonAdvertisingProfiles = {
  data: [
    {
      id: 586011285459526,
      type: 'AmazonAdvertisingProfile',
      attributes: {
        country_code: 'US',
        currency_code: 'USD',
        daily_budget: 21000000,
        timezone: 'America/Los_Angeles',
        account_info: {
          id: 'A59V0PY298IQ2',
          type: 'AmazonAdvertisingProfileAccountInfo',
          attributes: {
            marketplace_country: 'US',
            marketplace_string_id: 'ATVPDKIKX0DER',
            name: 'Luca + Danni',
            type: 'seller',
            subType: '',
            valid_payment_method: true
          }
        }
      }
    },
    {
      id: 2831667069998299,
      type: 'AmazonAdvertisingProfile',
      attributes: {
        country_code: 'US',
        currency_code: 'USD',
        daily_budget: '',
        timezone: 'America/Los_Angeles',
        account_info: {
          id: 'ENTITYX0AH3TP6MMWG',
          type: 'AmazonAdvertisingProfileAccountInfo',
          attributes: {
            marketplace_country: 'US',
            marketplace_string_id: 'ATVPDKIKX0DER',
            name: 'Openbridge, Inc',
            type: 'vendor',
            subType: '',
            valid_payment_method: false
          }
        }
      }
    }
  ]
};