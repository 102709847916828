export const responseGoogleAnalytics360Datasets = {
  data: [
    {
      id: 'data-transfers-241317:4558003',
      attributes: {
        datasetId: '4558003',
        projectId: 'data-transfers-241317',
        location: 'US'
      }
    }
  ]
};