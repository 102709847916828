import { createAction, props } from '@ngrx/store';

export const intercomBoot = createAction('[Intercom] Intercom Boot',
  props<IntercomBoot>()
);

export const intercomUpdate = createAction('[Intercom] Intercom Update',
  props<any>()
);

export const intercomEventPost = createAction('[Intercom] Intercom Event Post',
  props<IntercomEventPost>()
);

interface IntercomBoot {
  email: string;
  emailVerified?: boolean;
  name: string;
  userId?: string;
  accountId?: string;
  ActiveSubscribedProductIds?: any[];
  InActiveSubscribedProductIds?: any[];
}

interface IntercomEventPost {
  eventName: string;
  metaData: any;
}