export const responseDataLakeStorage = {
  data: {
    attributes: {
      accmapping_id: 953,
      account_id: 'test-1640084082099-000001',
      created_at: '2021-12-21 10:54:51.577877',
      error_code: null,
      message: '',
      modified_at: '2021-12-21 10:54:52.034958',
      path: '/ebs/ftpd/test-1640084082099-000001/storage_validation',
      status: 'ACTIVE',
      storage_type: 'azure_datalake'
    },
    id: 959
  }
};