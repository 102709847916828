import { IdentityType } from '../models/identity-type.type';
import { identityTypeIdGoogleBigquery, identityTypeIdShopify } from './identity-ids';
import { vendors } from './logos';

export const identityTypes: IdentityType[] = [
  {
    remoteIdentityTypeId: 1,
    name: 'Google',
    urlIdentifier: 'google-primary',
    logoPath: vendors.google,
    authButton: {
      imagePath: 'assets/img/vendor/btn_google_signin.png',
      imageClasses: 'google-signin-btn cursor-pointer lift'
    },
    validRegions: [
      { value: 'global', name: 'global' }
    ]
  },
  {
    remoteIdentityTypeId: 2,
    name: 'Facebook',
    urlIdentifier: 'facebook',
    logoPath: vendors.facebook,
    authButton: {
      imagePath: 'assets/img/vendor/btn_facebook_login.png',
      imageClasses: 'facebook-login-btn cursor-pointer lift'
    },
    validRegions: [
      { value: 'global', name: 'global' }
    ]
  },
  {
    remoteIdentityTypeId: 8,
    name: 'Google Adwords',
    urlIdentifier: 'google-adwords',
    logoPath: vendors.googleAds,
    authButton: {
      imagePath: 'assets/img/vendor/btn_google_signin.png',
      imageClasses: 'google-signin-btn cursor-pointer lift'
    },
    validRegions: [
      { value: 'global', name: 'global' }
    ]
  },
  {
    // Not really used anymore just here as a record, we'll remove it later.
    remoteIdentityTypeId: 12,
    name: 'Google BigQuery Service Account',
    logoPath: vendors.googleBigquery,
    authButton: null
  },
  {
    remoteIdentityTypeId: 13,
    name: 'Amazon MWS',
    logoPath: vendors.amazonAdvertising,
    authButton: null
  },
  {
    remoteIdentityTypeId: 14,
    name: 'Amazon Advertising',
    urlIdentifier: 'amazon-advertising',
    logoPath: vendors.amazonAdvertising,
    authButton: {
      imagePath: 'assets/img/vendor/btn_amazon_login_gold_195x46.png',
      imageClasses: 'amazon-login-btn cursor-pointer lift'
    },
    validRegions: [
      { value: 'na', name: 'North America'},
      { value: 'eu', name: 'Europe'},
      { value: 'fe', name: 'Far East'}
    ]
  },
  {
    remoteIdentityTypeId: 15,
    name: 'Google',
    logoPath: vendors.google,
    authButton: {
      imagePath: 'assets/img/vendor/btn_google_signin.png',
      imageClasses: 'google-signin-btn cursor-pointer lift'
    },
    validRegions: [
      { value: 'global', name: 'global' }
    ]
  },
  {
    remoteIdentityTypeId: identityTypeIdShopify,
    name: 'Shopify',
    urlIdentifier: 'shopify',
    logoPath: vendors.shopify,
    authButton: null,
    validRegions: [
      { value: 'global', name: 'global' }
    ]
  },
  {
    remoteIdentityTypeId: 17,
    name: 'Amazon Selling Partners',
    logoPath: vendors.amazonSellerCentral,
    urlIdentifier: 'amazon-selling-partners',
    providerAlias: 'Merchant Id',
    regionAlias: 'Marketplace',
    authButton: {
      imagePath: 'assets/img/vendor/btn_amazon_login_gold_195x46.png',
      imageClasses: 'amazon-login-btn cursor-pointer lift'
    },
    validRegions: [
      { value: 'AU', name: 'Australia'},
      { value: 'BR', name: 'Brazil'},
      { value: 'CA', name: 'Canada'},
      { value: 'EG', name: 'Egypt'},
      { value: 'FR', name: 'France'},
      { value: 'DE', name: 'Germany'},
      { value: 'IN', name: 'India'},
      { value: 'IT', name: 'Italy'},
      { value: 'JP', name: 'Japan'},
      { value: 'MX', name: 'Mexico'},
      { value: 'NL', name: 'Netherlands'},
      { value: 'PL', name: 'Poland'},
      { value: 'SA', name: 'Saudi Arabia'},
      { value: 'SG', name: 'Singapore'},
      // { value: 'ZA', name: 'South Africa'},
      { value: 'ES', name: 'Spain'},
      { value: 'SE', name: 'Sweden'},
      { value: 'TR', name: 'Turkey'},
      { value: 'UK', name: 'United Kingdom'},
      { value: 'AE', name: 'United Arab Emirates (U.A.E.)'},
      { value: 'US', name: 'United States'},
    ]
  },
  {
    remoteIdentityTypeId: 18,
    name: 'Amazon Vendor Central',
    logoPath: vendors.amazonSellerCentral,
    urlIdentifier: 'amazon-vendor-central',
    providerAlias: 'Merchant Id',
    regionAlias: 'Marketplace',
    authButton: {
      imagePath: 'assets/img/vendor/btn_amazon_login_gold_195x46.png',
      imageClasses: 'amazon-login-btn cursor-pointer lift'
    },
    validRegions: [
      { value: 'AU', name: 'Australia'},
      { value: 'BE', name: 'Belgium'},
      { value: 'BR', name: 'Brazil'},
      { value: 'CA', name: 'Canada'},
      { value: 'EG', name: 'Egypt'},
      { value: 'FR', name: 'France'},
      { value: 'DE', name: 'Germany'},
      { value: 'IN', name: 'India'},
      { value: 'IT', name: 'Italy'},
      { value: 'JP', name: 'Japan'},
      { value: 'MX', name: 'Mexico'},
      { value: 'NL', name: 'Netherlands'},
      { value: 'PL', name: 'Poland'},
      { value: 'SA', name: 'Saudi Arabia'},
      { value: 'SG', name: 'Singapore'},
      // { value: 'ZA', name: 'South Africa'},
      { value: 'ES', name: 'Spain'},
      { value: 'SE', name: 'Sweden'},
      { value: 'TR', name: 'Turkey'},
      { value: 'UK', name: 'United Kingdom'},
      { value: 'AE', name: 'United Arab Emirates (U.A.E.)'},
      { value: 'US', name: 'United States'},
    ]
  }
];