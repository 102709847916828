import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { flashNotificationsFormValidationErrorWithDetails } from 'src/app/core/store/actions/flash-notifications.actions';
import { AppState } from 'src/app/core/store/reducers';
import { errorWizardGeneralError } from 'src/app/shared/constants/flash-notifications';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JobsService {
  jobSubscription$: Subject<any> = new Subject<any>();
  jobRequestState$: Subject<{ state: string, payload: any }> = new Subject<{ state: string, payload: any }>();

  constructor(
    private http: HttpClient,
    private store$: Store<AppState>,
  ) { }

  async getJobs(
    subscriptionIds: number[],
    page: number,
    pageSize: number,
    jobType: string = 'all'   // 'all', 'primary' and 'history' are valid values.
  ): Promise<void> {

    try {
      this.jobRequestState$.next({ state: 'job-request-initialize', payload: null });
      
      const params = {
        subscription_ids: subscriptionIds.join(','),
        order_by: '-is_primary',
        page: page,
        page_size: pageSize,
      };

      if(jobType === 'primary') {
        params['is_primary'] = true;
      }

      if(jobType === 'history') {
        params['is_primary'] = false;
      }

      const httpParams: HttpParams = new HttpParams({ fromObject: params });
      
      const jobRequestUrl = environment.openbridgeApiUris.service +
        '/service/jobs/jobs?' + httpParams.toString();

      await this.getJobsFromUrl(jobRequestUrl);
      this.jobRequestState$.next({ state: 'job-request-complete', payload: null });
    }
    catch (error) {
      this.jobRequestState$.next({ state: 'job-request-complete', payload: null });
      this.store$.dispatch(flashNotificationsFormValidationErrorWithDetails({ message: errorWizardGeneralError, details: JSON.stringify(error) })); 
      throw error;
    }
  }

  async getJobsFromUrl(url: string): Promise<void> {
    const response = await this.http.get(url).toPromise();
    this.jobSubscription$.next(response);
  }

  async getActiveHistoryJobs(subscriptionId): Promise<any> {
      this.jobRequestState$.next({ state: 'job-request-initialize', payload: null });
      
      const params = {
        subscription_ids: subscriptionId,
        is_primary: false,
        page: 1,
        status: 'active'
      };

      const httpParams: HttpParams = new HttpParams({ fromObject: params });
      
      const jobRequestUrl = environment.openbridgeApiUris.service +
        '/service/jobs/jobs?' + httpParams.toString();
      const response = await this.http.get(jobRequestUrl).toPromise();
      return response;
  }
}
