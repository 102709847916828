export const responseAmazonDspSpm = {
  links: {
    first: 'https://subscriptions.api.dev.openbridge.io/spm?page=1&subscription=761',
    last: 'https://subscriptions.api.dev.openbridge.io/spm?page=1&subscription=761',
    next: '',
    prev: ''
  },
  data: [
    {
      type: 'SubscriptionProductMeta',
      id: '1687',
      attributes: {
        data_id: 0,
        data_key: 'remote_identity_id',
        data_value: '22',
        created_at: '2022-05-09T05:27:37.615085',
        modified_at: '2022-05-09T05:27:37.615106',
        product: {
          id: 54,
          active: 1,
          name: 'Amazon DSP',
          summary: '',
          name_slug: 'amazon-dsp',
          created_at: '2021-12-21T09:00:00',
          modified_at: '2021-12-21T09:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'amazon-dsp',
          allow_as_trial: 1,
          is_storage_product: 0,
          initialize_job: 1,
          premium_product: 1,
          worker_name: 'amzn_dsp',
          product_group: 1,
          remote_identity_type: 14,
          product_category: 1,
          parent_product: null
        },
        subscription: {
          id: 761,
          price: 0,
          status: 'active',
          date_start: '2022-05-09T05:27:33',
          date_end: '2022-05-09T05:27:33',
          auto_renew: 1,
          created_at: '2022-05-09T05:27:37.600805',
          modified_at: '2022-05-09T05:27:37.600821',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'amazon-dsp-test',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 54,
          product_plan: null,
          remote_identity: 22,
          storage_group: 110,
          user: 309
        }
      }
    },
    {
      type: 'SubscriptionProductMeta',
      id: '1688',
      attributes: {
        data_id: 0,
        data_key: 'profile_id',
        data_value: '693136736383016',
        created_at: '2022-05-09T05:27:37.629003',
        modified_at: '2022-05-09T05:27:37.629025',
        product: {
          id: 54,
          active: 1,
          name: 'Amazon DSP',
          summary: '',
          name_slug: 'amazon-dsp',
          created_at: '2021-12-21T09:00:00',
          modified_at: '2021-12-21T09:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'amazon-dsp',
          allow_as_trial: 1,
          is_storage_product: 0,
          initialize_job: 1,
          premium_product: 1,
          worker_name: 'amzn_dsp',
          product_group: 1,
          remote_identity_type: 14,
          product_category: 1,
          parent_product: null
        },
        subscription: {
          id: 761,
          price: 0,
          status: 'active',
          date_start: '2022-05-09T05:27:33',
          date_end: '2022-05-09T05:27:33',
          auto_renew: 1,
          created_at: '2022-05-09T05:27:37.600805',
          modified_at: '2022-05-09T05:27:37.600821',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'amazon-dsp-test',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 54,
          product_plan: null,
          remote_identity: 22,
          storage_group: 110,
          user: 309
        }
      }
    }
  ],
  meta: {
    pagination: {
      page: 1,
      pages: 1,
      count: 2
    }
  }
};