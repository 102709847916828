import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DateTimeService } from 'src/app/core/services/date-time.service';
import { intercomEventPost } from 'src/app/core/store/actions/intercom.actions';
import { AppState } from 'src/app/core/store/reducers';
import { environment } from 'src/environments/environment';
import { identityEventMetaData, refreshTokenEventMetaData, subscriptionEventMetaData } from '../constants/intercom-event-metadata-types';
import { identityEvent, refreshTokenEvent, subscriptionEvent } from '../constants/intercom-events';
import { take } from 'rxjs/operators';
import { Integration } from '../models/integration.model';
import { IntegrationService } from './integration.service';
import { NavigationStart } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class IntercomService {

  appId = environment.intercom.appId;

  constructor(
    private dateTimeService: DateTimeService,
    protected store$: Store<AppState>,
    private integrationService: IntegrationService,
  ) { }

  loadIntercom(): void {
    if (!this.isProxyMode()) {
      if (window['Intercom']) {
        return;
      }
      // w = window, d = document, id = appId, s = script element
      ((w, d, id, s) => {

        function i() {
          i.c(arguments);
        }
        i.q = [];
        i.c = function (args: any) {
          i.q.push(args);
        };

        w['Intercom'] = i;
        s = d.createElement('script');
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + id;
        d.head.appendChild(s);
      })(window, document, this.appId);
    }
  }

  bootIntercom(details: any): void {
    if (!this.isProxyMode()) {
      window['Intercom']('boot', {
        app_id: this.appId,
        ...details
      });
    }
  }

  updateIntercom(details: any): void {
    if (!this.isProxyMode()) {
      window['Intercom']('update', {
        app_id: this.appId,
        ...details
      });
    }
  }

  triggerIntercomEvent(eventName: string, metadata: any) {
    if (!this.isProxyMode()) {
      const createdAt = this.dateTimeService.utcDateTime()
      window['Intercom']('trackEvent', eventName, {
        created_at: createdAt,
        ...metadata
      });
    }
  }

  shutdownIntercom(): void {
    window['Intercom']('shutdown');
  }

  isProxyMode(): boolean {
    return (localStorage.getItem('proxy') !== null);
  }

  dispatchIntercomIdentityEvent(urlParams: any): void {
    const isReAuth = urlParams.reauth ? 'reauthorize' : 'create';
    const remoteIdentityId = urlParams.ri_id
    const eventDescription = 'identity ' + isReAuth + ' event';

    // find identity 
    const findIdentityFromIdentityId = (identities: any[], id: number) => {
      for (const element of identities) {
        if (element.id === +remoteIdentityId) {
          return element;
        }
      }
      return undefined; // Return undefined if element is not found
    }

    this.store$.select('identities').subscribe(response => {
      const responseKeys = Object.keys(response);
      if (responseKeys.includes('identities')) {
        if (response.identities.length > 0) {
          // identity
          const identity = findIdentityFromIdentityId(response.identities, remoteIdentityId);

          if (identity !== undefined) {
            // event meta data
            const metaData: identityEventMetaData = {
              mode: isReAuth,
              eventDescription: eventDescription,
              remoteIdentityId: identity.id,
              remoteIdentityTypeId: identity.remoteIdentityTypeId,
              remoteIdentityName: identity.name,
              identityEmail: identity.email,
              identityCreatedAt: identity.createdAt,
              identityModifiedAt: identity.modifiedAt,
              isIdentityExternal: identity.external,
              accountId: identity.accountId,
              userId: identity.userId,
              authorizationStateId: urlParams.state,
              status: 'success',
            }

            // intercom event
            this.store$.dispatch(intercomEventPost({
              eventName: identityEvent,
              metaData: metaData
            }));
          }
        }
      }
    });
  }

  dispatchIntercomRefreshTokenEvent(tokenName: string, requestMode: 'create' | 'revoke'): void {
    const metaData: refreshTokenEventMetaData = {
      mode: requestMode,
      tokenName: tokenName
    }

    this.store$.dispatch(intercomEventPost({
      eventName: refreshTokenEvent,
      metaData: metaData
    }));
  }

  dispatchIntercomSubscriptionEvent(metaData: subscriptionEventMetaData) {
    this.store$.dispatch(intercomEventPost({
      eventName: subscriptionEvent,
      metaData: metaData
    }));
  }

  shouldShowTabCloseNotification(URL: any): boolean {
    const [emptyString, moduleURL, componentURL, completeURL] = URL.split('/');
    if (moduleURL === 'wizards' && completeURL !== 'complete') {
      // Intercom tab close event for abandon product
      this.dispatchRouteOrTabClose('tab close');
      const delay = (ms: number) => (res => setTimeout(res, ms));
      delay(1000);
      return true;
    }
    return false;
  }

  detectRouteChange(routerURL: any, event: any) {
    if (event instanceof NavigationStart) {
      const previousPageRoute = routerURL;
      const currentPageRoute = event.url;
      let isAbandon = false;

      if (previousPageRoute.includes('wizards')
        && !previousPageRoute.includes('complete')
        && !currentPageRoute.includes('wizards')) {

        if (previousPageRoute.includes('wizards') && currentPageRoute.includes('identity')) {
          // do nothing because user still on wizard.
        }
        else {
          isAbandon = true;
        }
      }

      if (isAbandon) {
        // Intercom change route event for abandon product
        this.dispatchRouteOrTabClose('route change');
      }

    }

  }

  dispatchRouteOrTabClose(cause: 'route change' | 'tab close'): void {

    this.store$.select('wizard').pipe(take(1)).subscribe(state => {

      const configKeys = Object.keys(state.config);
      let integration: Integration | null = null;

      if (configKeys.includes('subProductId')) {
        integration = this.integrationService.findIntegrationFromIdAndSubproductId(state.productId, state.config.subProductId)
      }
      else {
        integration = this.integrationService.findIntegrationFromId(state.productId)
      }

      if (integration) {
        const productName = integration.brand + ' ' + integration.name;
        const eventDescription = 'subscription abandon on ' + cause + ' event';

        const metaData: subscriptionEventMetaData = {
          productType: integration.type,
          mode: state.config.wizardMode,
          eventDescription: eventDescription,
          isAbandon: true,
          abandonCause: cause,
          productId: state.productId,
          subProductId: state.config.subProductId || "default",
          productName: productName,
          currentStage: state.stage,
          currentStep: state.stages.findIndex(x => x === state.stage) + 1,
          totalSteps: state.stages.length - 1
        }

        this.dispatchIntercomSubscriptionEvent(metaData);
      }

    });
  }

}
