export const regionIdentifiers = {
  na: 'North America',
  eu: 'Europe',
  fe: 'Far East',
  us: 'United States',
  global: 'Global',
  AU: 'Austrailia',
  BE: 'Belgium',
  BR: 'Brazil',
  CA: 'Canada',
  EG: 'Egypt',
  FR: 'France',
  DE: 'Germany',
  IN: 'India',
  IT: 'Italy',
  JP: 'Japan',
  MX: 'Mexico',
  NL: 'Netherlands',
  PL: 'Poland',
  SA: 'Saudi Arablia',
  SG: 'Singapore',
  ES: 'Spain',
  SE: 'Sweden',
  TR: 'Turkey',
  GB: 'United Kingdom',
  UK: 'United Kingdom',
  AE: 'United Arab Emirates',
  US: 'United States'
};
 
export const regionIdentifiersFlags = [
  {
    name: 'na',
    flagURL: '/assets/img/avatars/regions/na.svg'
  },
  {
    name: 'eu',
    flagURL: '/assets/img/avatars/regions/eu.svg'
  },
  {
    name: 'fe',
    flagURL: '/assets/img/avatars/regions/fe.svg'
  },
  {
    name: 'global',
    flagURL: '/assets/img/avatars/regions/global.svg'
  },
  {
    name: 'us',
    flagURL: '/assets/img/avatars/countries/us.svg'
  },
  {
    name: 'AU',
    flagURL: '/assets/img/avatars/countries/au.svg'
  },
  {
    name: 'BE',
    flagURL: '/assets/img/avatars/countries/be.svg'
  },
  {
    name: 'BR',
    flagURL: '/assets/img/avatars/countries/br.svg'
  },
  {
    name: 'CA',
    flagURL: '/assets/img/avatars/countries/ca.svg'
  },
  {
    name: 'EG',
    flagURL: '/assets/img/avatars/countries/eg.svg'
  },
  {
    name: 'FR',
    flagURL: '/assets/img/avatars/countries/fr.svg'
  },
  {
    name: 'DE',
    flagURL: '/assets/img/avatars/countries/de.svg'
  },
  {
    name: 'IN',
    flagURL: '/assets/img/avatars/countries/in.svg'
  },
  {
    name: 'IT',
    flagURL: '/assets/img/avatars/countries/it.svg'
  },
  {
    name: 'JP',
    flagURL: '/assets/img/avatars/countries/jp.svg'
  },
  {
    name: 'MX',
    flagURL: '/assets/img/avatars/countries/mx.svg'
  },
  {
    name: 'NL',
    flagURL: '/assets/img/avatars/countries/nl.svg'
  },
  {
    name: 'PL',
    flagURL: '/assets/img/avatars/countries/pl.svg'
  },
  {
    name: 'SA',
    flagURL: '/assets/img/avatars/countries/sa.svg'
  },
  {
    name: 'SG',
    flagURL: '/assets/img/avatars/countries/sg.svg'
  },
  {
    name: 'ES',
    flagURL: '/assets/img/avatars/countries/es.svg'
  },
  {
    name: 'SE',
    flagURL: '/assets/img/avatars/countries/se.svg'
  },
  {
    name: 'TR',
    flagURL: '/assets/img/avatars/countries/tr.svg'
  },
  {
    name: 'GB',
    flagURL: '/assets/img/avatars/countries/gb.svg'
  },
  {
    name: 'UK',
    flagURL: '/assets/img/avatars/countries/gb.svg'
  },
  {
    name: 'AE',
    flagURL: '/assets/img/avatars/countries/ae.svg'
  },
  {
    name: 'US',
    flagURL: '/assets/img/avatars/countries/us.svg'
  }
];