import { Injectable } from '@angular/core';
import { take, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { historyParamsRequest, historyParamsUpdate } from '../actions/history.actions';
import { HistoryService } from '../../services/history.service';
import { productIdAmzSellingPartnersFinanceRt, productIdAmzSellingPartnersOrders, productIdAmzSellingPartnersOrdersPii, productIdAmzVendorsRetailProcurmentOrder, productIdFacebookPageInsights } from 'src/app/shared/constants/product-ids';
import { HistoryDefinition } from 'src/app/shared/interfaces/history.interfaces';

const dayByDayOnlyHistoryProducts: number[] = [
  productIdAmzSellingPartnersOrders,
  productIdAmzSellingPartnersFinanceRt,
  productIdAmzSellingPartnersOrdersPii
];

const adjustedHistoryStartDates = {};
adjustedHistoryStartDates[productIdFacebookPageInsights] = -5

@Injectable()
export class HistoryEffects {

  private getProductStartOffset(productId: number): number {
    const keys = Object.keys(adjustedHistoryStartDates);
    return (keys.includes(String(productId))) ? adjustedHistoryStartDates[productId] : -1;
  }

  private isSingleDayHistoryproduct(productId: number): boolean {
    return dayByDayOnlyHistoryProducts.includes(productId);
  }

  private calculateEdDateDeltaInDays(maxRequestTime: number): number {
    return (maxRequestTime !== 0) ? (maxRequestTime * -1) : 0;
  }

  historyParamsRequest$ = createEffect(() => this.actions$
    .pipe(
      ofType(historyParamsRequest),
      tap(async action => {

        const productHistoryResponse = await this.historyService.getProductHistoryInformation();
        let productHistoryParams: HistoryDefinition[] = [];

        for(const productHistory of productHistoryResponse.data) {
          const productId = +productHistory.id;

          productHistoryParams = [
            {
              productId: productId,
              startDateDeltaInDays: (productHistory.attributes['base_request_start'] === 0 || productHistory.attributes['base_request_start'] === 1) ? -1 : (productHistory.attributes['base_request_start'] * -1),
              endDateDeltaInDays: this.calculateEdDateDeltaInDays(productHistory.attributes['max_request_time']),
              singleDayHistoryOnly: (productHistory.attributes['max_days_per_request'] === 1) ? true : false
            },
            ...productHistoryParams
          ]
        };

        this.store$.dispatch(historyParamsUpdate({params: productHistoryParams}));
      })
    ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private historyService: HistoryService,
    private store$: Store<AppState>,
  ) { }

}