import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/subscriptions.actions';
import { setCache } from 'src/app/core/functions/cache';

// CHange "any" to whatever the user model will be in the future.
export interface SubscriptionsState {
  subscriptions: any[] | null;
  selectedPipelines: any[] | null;
  cacheKey: string;
}

//  Create the initial state for the authenticated user.
const initialSubscriptionsState: SubscriptionsState = {
  subscriptions: [],
  selectedPipelines: [],
  cacheKey: 'test'
};

export const reducer = createReducer(
  initialSubscriptionsState,
  on(actions.subscriptionsSetCacheKey, (state, payload) => {
    const stateData = {
      ...state,
      cacheKey: payload.cacheKey
    };
    return stateData;
  }),
  on(actions.subscriptionsLoad, (state, payload) => {
    const stateData = {
      ...state,
      subscriptions: payload.subscriptions,
    };
    setCache('local', stateData.cacheKey, stateData);
    return stateData;
  }),
  on(actions.subscriptionAdd, (state, payload) => {
    const pipelines = state.subscriptions;
    const selectedPipelines = state.selectedPipelines;

    const stateData = {
      ...state,
      subscriptions: [
        ...pipelines,
        payload.pipelineSubscription
      ],
      selectedPipelines: [
        ...selectedPipelines,
        { id: payload.pipelineSubscription.id, isSelected: false }
      ]
    };

    setCache('local', stateData.cacheKey, stateData);
    return stateData;
  }),
  on(actions.subscriptionDelete, (state, payload) => {
    // we don't delete subscriptions we change their state.
    return state;
  }),
  on(actions.subscriptionUpdate, (state, payload) => {
    const subscription = payload.pipelineSubscription;
    let stateData = null;
    if (subscription) {
      stateData = {
        ...state,
        subscriptions: state.subscriptions.map(obj => [subscription].find(o => o.id === obj.id) || obj)
      };
    } else {
      stateData = { ...state };
    }
    setCache('local', state.cacheKey, stateData);
    return stateData;
  }),
  on(actions.initializePipelineSelection, (state, payload) => {
    const stateData = {
      ...state,
      selectedPipelines: payload.selectedPipelines
    };
    return stateData;
  }),
  on(actions.updateAllSelectedPipeline, (state, payload) => {
    const previousPipelines = [...state.selectedPipelines];
    const nextPipelines = payload.selectedPipelines;

    nextPipelines.forEach(val => {
      const index = previousPipelines.findIndex((el) => el.id === val.id);
      previousPipelines[index] = val;
    });

    const stateData = {
      ...state,
      selectedPipelines: previousPipelines
    };
    return stateData;
  }),
  on(actions.updateSelectedPipeline, (state, payload) => {
    const pipelines = state.selectedPipelines;
    const pipeline = payload.selectedPipeline;

    let stateData = null;
    if (pipelines.length > 0) {
      const updatedPipelines = [...pipelines.filter((o) => o.id !== pipeline.id), { ...pipeline }];
      stateData = {
        ...state,
        selectedPipelines: [
          ...updatedPipelines
        ]
      };
    } else {
      stateData = {
        ...state,
        selectedPipelines: [
          ...pipelines,
          payload.selectedPipeline
        ]
      };
    }
    return stateData;
  }),
  on(actions.updateHistoryProcessing, (state, payload) => {
    let subscriptions = state.subscriptions;
    const subIndex = subscriptions.map(function(x) {return x.id; }).indexOf(+payload.subId);
    let subscription = subscriptions[subIndex];

    // Update the subscription we want updated.
    subscription = {
      ...subscription,
      history: {
        dateStart: payload.dateStart,
        dateEnd: payload.dateEnd,
        dateTimeRequested: payload.dateTimeRequested
      }
    };

    let newSubscriptions = [];
    for (const { index, value } of subscriptions.map((value, index) => ({ index, value }))) {
      if(index === subIndex) {
        newSubscriptions = [
          ...newSubscriptions,
          subscription
        ];
      }
      else {
        newSubscriptions = [
          ...newSubscriptions,
          value
        ];
      }
    }

    const stateData = {
      ...state,
      subscriptions: newSubscriptions
    };

    setCache('local', stateData.cacheKey, stateData);
    return stateData;
  }),
  on(actions.removeHistoryProcessing, (state, payload) => {
    let subscriptions = state.subscriptions;
    const subIndex = subscriptions.map(function(x) {return x.id; }).indexOf(+payload.subId);
    let subscription = subscriptions[subIndex];

    // Update the subscription we want updated.
    subscription = {
      ...subscription,
      history: false
    };

    let newSubscriptions = [];
    for (const { index, value } of subscriptions.map((value, index) => ({ index, value }))) {
      if(index === subIndex) {
        newSubscriptions = [
          ...newSubscriptions,
          subscription
        ];
      }
      else {
        newSubscriptions = [
          ...newSubscriptions,
          value
        ];
      }
    }

    const stateData = {
      ...state,
      subscriptions: newSubscriptions
    }

    setCache('local', stateData.cacheKey, stateData);
    return stateData;
  })

);
