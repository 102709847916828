const baseLogoImagePath = '/assets/img/products/airbyte/';

export const vendorsAirbyteHashed = {
  logoActivecampaignSvg: baseLogoImagePath +'e60eb008871933f43f62bb0638bee95a_activecampaign.svg',
  logoAdjustSvg: baseLogoImagePath +'a33ebf0f411704930745bb8ace9797d0_adjust.svg',
  logoAhaapiSvg: baseLogoImagePath +'b8f54d6c17183e89fd2eb080d3cecc07_aha-api.svg',
  logoAircallSvg: baseLogoImagePath +'20ad52daf634752dde355e0c36381e89_aircall.svg',
  logoAirtableSvg: baseLogoImagePath +'c17f31fc50149f6150fdd63a96eb894c_airtable.svg',
  logoAlloydbforpostgresqlSvg: baseLogoImagePath +'5a2380aa5a0fb182929e0dc5e8587062_alloydb-for-postgresql.svg',
  logoAlphavantageSvg: baseLogoImagePath +'8904b4aa23d1b0923502c27a8396f52b_alpha-vantage.svg',
  logoAmazonairbyteSvg: baseLogoImagePath +'d45431fcc9118e2cf621184582b01353_amazon-airbyte.svg',
  logoAmazonsqsSvg: baseLogoImagePath +'04c02d0d91d2688c3ba9ea3cd6c62938_amazon-sqs.svg',
  logoAmplitudeSvg: baseLogoImagePath +'d087c86d7121cdd73bf1850cb5f61646_amplitude.svg',
  logoApifySvg: baseLogoImagePath +'558497d98a3315f12c18576066be653c_apify.svg',
  logoAppfollowSvg: baseLogoImagePath +'2fef3730b6afa2ebfcbc299684745201_appfollow.svg',
  logoAppleadsSvg: baseLogoImagePath +'aad7ddd97ef96527f588eb0a414c7d59_apple-ads.svg',
  logoAppsflyerSvg: baseLogoImagePath +'37fe679b7848740697cdb7b2d355dc82_appsflyer.svg',
  logoAppstoreSvg: baseLogoImagePath +'8c0597123332b0293e6bc3c4f95ffd22_appstore.svg',
  logoAsanaSvg: baseLogoImagePath +'262c78bd366362c77870ac6ca76ae66f_asana.svg',
  logoAshbySvg: baseLogoImagePath +'72552a315ff1a796bdb16e0341a93ef2_ashby.svg',
  logoAuth0Svg: baseLogoImagePath +'d95059e998e8556168bc2df86d125a70_auth0.svg',
  logoAwscloudtrailSvg: baseLogoImagePath +'8e4ffa8aa672c9bab227e9184b2e7252_aws-cloudtrail.svg',
  logoAzureblobstorageSvg: baseLogoImagePath +'91fd934d72b50079116e1ea37a93b4db_azure-blob-storage.svg',
  logoAzuretablestorageSvg: baseLogoImagePath +'b54a66023bd8c31feb300562b0054c6b_azure-table-storage.svg',
  logoBabelforceSvg: baseLogoImagePath +'92a12a4c44ccd29e3546144a617185d8_babelforce.svg',
  logoBamboohrSvg: baseLogoImagePath +'ea05b1e9974b82805b082e49636411f1_bamboo-hr.svg',
  logoBatonSvg: baseLogoImagePath +'2c0b4a13a261e6b72d9c99d689e1c611_baton.svg',
  logoBigcommerceSvg: baseLogoImagePath +'c4b58fb5baa19ee4789c78e785e51fc0_bigcommerce.svg',
  logoBigquerySvg: baseLogoImagePath +'595f6dd4a1bac0f94b1d2ebfa47019ad_bigquery.svg',
  logoBingadsSvg: baseLogoImagePath +'38fe82c64afeac8fa2e0062f9dc07152_bing-ads.svg',
  logoBraintreeSvg: baseLogoImagePath +'d2e86831a73f3d41ad64525af8ac8c6f_braintree.svg',
  logoBrazeSvg: baseLogoImagePath +'330c145e927ed77a26fb7a340de26844_braze.svg',
  logoBreezometerSvg: baseLogoImagePath +'46db5ceb40c3afa5ae18734a0ba17112_breezometer.svg',
  logoCallrailSvg: baseLogoImagePath +'91a8b68e31f3870d6d8caeffc186035b_callrail.svg',
  logoCaptaindataSvg: baseLogoImagePath +'eb56354b91e566eaa1d5959fc8fcda89_captain-data.svg',
  logoCartCom: baseLogoImagePath +'43846a9be67f6538a696feb4f66fa889_cart.com.svg',
  logoChargebeeSvg: baseLogoImagePath +'c6a44cff04330a5d05e3fd9b6c5a5fcf_chargebee.svg',
  logoChargifySvg: baseLogoImagePath +'846f27eaf9913771873ed2789d7ade0d_chargify.svg',
  logoChartmogulSvg: baseLogoImagePath +'81aba1d9eb78d05aaa653b147ed0ba2c_chartmogul.svg',
  logoClickhouseSvg: baseLogoImagePath +'a31549afb21c2f1cb75535b07c857278_clickhouse.svg',
  logoClickupapiSvg: baseLogoImagePath +'b0d175ad06b2bb3de79ea5678e67839a_clickup-api.svg',
  logoClockifySvg: baseLogoImagePath +'e6f7598dfb076aa9c8509cb9579839e6_clockify.svg',
  logoCloseCom: baseLogoImagePath +'5be9d0a409716bc51f323692956e78a2_close.com.svg',
  logoCockroachdbSvg: baseLogoImagePath +'7862fff84d4248c306859d75f369ab12_cockroachdb.svg',
  logoCodaSvg: baseLogoImagePath +'321a1dbefabd0547cb7496b6ce2a4e2b_coda.svg',
  logoCoinapiSvg: baseLogoImagePath +'7633c174920fe48cb18e46c8d6546d07_coinapi.svg',
  logoCoingeckocoinsSvg: baseLogoImagePath +'f165b3a496fec1eecc6fb793803adc77_coingecko-coins.svg',
  logoCoinmarketcapapiSvg: baseLogoImagePath +'b4d3d462205b7a06ed5a0bd7be22b6c0_coinmarketcap-api.svg',
  logoCommcareSvg: baseLogoImagePath +'ae6bddfe08cf6c574240617da25eb621_commcare.svg',
  logoCommercetoolsSvg: baseLogoImagePath +'8d790d6a5bab5589f08b89910aff5f87_commercetools.svg',
  logoConfigcatapiSvg: baseLogoImagePath +'0c656367c1948c82ed488ae0508f4d17_configcat-api.svg',
  logoConfluenceSvg: baseLogoImagePath +'3b7c99d8b21e6b86ab8b64b02fe366b9_confluence.svg',
  logoConvertkitSvg: baseLogoImagePath +'aaf84a4574d739a5fb0635febe818298_convertkit.svg',
  logoConvexSvg: baseLogoImagePath +'bc5b92b165a94936ceca85af1008c079_convex.svg',
  logoCopperSvg: baseLogoImagePath +'a1001b344af9e2ed3443eff968e1ff99_copper.svg',
  logoCourierSvg: baseLogoImagePath +'e80a2cc982ce0dd64909d7e88199aef3_courier.svg',
  logoCustomerIo: baseLogoImagePath +'09ad56cb9bf5ac079d40b554d8463c1c_customer.io.svg',
  logoDatadogSvg: baseLogoImagePath +'f2a673d0ed8470c9f72ffa83f1a1f6de_datadog.svg',
  logoDatascopeSvg: baseLogoImagePath +'404509561b68b2e1e0c70d263c1fb8a6_datascope.svg',
  logoDb2Svg: baseLogoImagePath +'07ec6b9a94e2bc4fe697e66811ea9a28_db2.svg',
  logoDelightedSvg: baseLogoImagePath +'42ae00c00e09bea69d29d3083c8c82c1_delighted.svg',
  logoGoogledisplayvideo360Svg: baseLogoImagePath +'ded277ff286294dc87c53f45f98b8ac9_google-display-video-360.svg',
  logoDixaSvg: baseLogoImagePath +'213e9f69bab187e1526bcf99a7f65cce_dixa.svg',
  logoDockerhubSvg: baseLogoImagePath +'3719b3263d2e4676a6f85b0cfe6c079f_dockerhub.svg',
  logoDremioSvg: baseLogoImagePath +'7762846c47d66fb55ab370aa39c04333_dremio.svg',
  logoDriftSvg: baseLogoImagePath +'6de515b68149d8bbadffbe38bf77af17_drift.svg',
  logoDrupalSvg: baseLogoImagePath +'dce580dfa1d1c9be3afcb4ad479f31e8_drupal.svg',
  logoDynamodbSvg: baseLogoImagePath +'7b89209ad1a56a15350937f53fe31d5f_dynamodb.svg',
  logoElasticsearchSvg: baseLogoImagePath +'2bd59a3d8a9b0b8a6c397c5f4328fb04_elasticsearch.svg',
  logoEmailoctopusSvg: baseLogoImagePath +'d041d126090761cb4663a1696ebc4309_emailoctopus.svg',
  logoOpensourceSvg: baseLogoImagePath +'d1db11711720ec40d34344656d6fe092_opensource.svg',
  logoEverhourSvg: baseLogoImagePath +'de0d53992e564336ffae29dc6a55594c_everhour.svg',
  logoExchangeratesapiSvg: baseLogoImagePath +'4c4178570946adac136e75b8d6bf1faa_exchangeratesapi.svg',
  logoFacebookSvg: baseLogoImagePath +'c4e467e4e6e594ef9bdb7167499c2d8b_facebook.svg',
  logoFakerSvg: baseLogoImagePath +'9c79e328347daabf9686fa9248061438_faker.svg',
  logoFastbillSvg: baseLogoImagePath +'9eff47031ee470d65b43ce25de92b2bb_fastbill.svg',
  logoFaunaSvg: baseLogoImagePath +'05687fe1ccea9a25b52e874caa899796_fauna.svg',
  logoFileSvg: baseLogoImagePath +'3412efb27e8428003da9d67a23a997fa_file.svg',
  logoFirebaseSvg: baseLogoImagePath +'135c85548860e3e13ad9940d0c8fe7d4_firebase.svg',
  logoFireboltSvg: baseLogoImagePath +'670d7df00f59e6018cafb1a00b107da8_firebolt.svg',
  logoFlexportSvg: baseLogoImagePath +'eb835e72d07555f5ed3b36008c9b5b82_flexport.svg',
  logoFreshcallerSvg: baseLogoImagePath +'15be3580e7a954d524d2a61fdef0da03_freshcaller.svg',
  logoFreshdeskSvg: baseLogoImagePath +'033c48fbf78a4934e5e25c4bdb039727_freshdesk.svg',
  logoFreshsalesSvg: baseLogoImagePath +'9a255cc22ec2fb1845744ae33cbd1576_freshsales.svg',
  logoFreshserviceSvg: baseLogoImagePath +'b1a737256d27c170033a301feccf847d_freshservice.svg',
  logoFullstorySvg: baseLogoImagePath +'0bc7686cc6b30e8a5aac318cedc69eea_fullstory.svg',
  logoGainsightSvg: baseLogoImagePath +'8769ac5879b6d9b992970302b3505302_gainsight.svg',
  logoGcsSvg: baseLogoImagePath +'832220363438537affae17587f396551_gcs.svg',
  logoGenesysSvg: baseLogoImagePath +'4138fc469578d7994264b379cac3384e_genesys.svg',
  logoGetlagoSvg: baseLogoImagePath +'4f4057aac792b4fd9def3cbef4ca8f80_getlago.svg',
  logoGithubSvg: baseLogoImagePath +'ed03b4361d28749f3fd66db25a8f8222_github.svg',
  logoGitlabSvg: baseLogoImagePath +'b38d5445051c2cc4f120de638073f75a_gitlab.svg',
  logoGlassfrogSvg: baseLogoImagePath +'493cf95ecaa33428246a1a47db102c47_glassfrog.svg',
  logoGnewsSvg: baseLogoImagePath +'8ea1544304cdd2336fce1b2b3d20c982_gnews.svg',
  logoGocardlessSvg: baseLogoImagePath +'41925a97c629843f479c501c9e5354de_gocardless.svg',
  logoGongSvg: baseLogoImagePath +'62feef974ca3d23e73398f740ba505fb_gong.svg',
  logoGoogleadsSvg: baseLogoImagePath +'430aa25569d751c93998b27e4c3d0c7e_google-ads.svg',
  logoGoogleanalyticsSvg: baseLogoImagePath +'b8df567639553485549ad3eea3b93c17_google-analytics.svg',
  logoGoogleSvg: baseLogoImagePath +'f4ca0c8872f0843ce823ce318de893fa_google.svg',
  logoGooglepagespeedinsightsSvg: baseLogoImagePath +'f133f940fdc24a9281abb2729b6c1881_google-pagespeed-insights.svg',
  logoGooglesheetsSvg: baseLogoImagePath +'307c67e6e6be94f8ff612662f2d3bc8d_google-sheets.svg',
  logoGreenhouseSvg: baseLogoImagePath +'07d383341e206e6ff88fbc073adb24a0_greenhouse.svg',
  logoGridlySvg: baseLogoImagePath +'3673e3001e20355169c564ac7cec38cc_gridly.svg',
  logoHarnessSvg: baseLogoImagePath +'389ae755785556879f665bd9cec47f4a_harness.svg',
  logoHarvestSvg: baseLogoImagePath +'172d22972178db124bf8739c1f289b1b_harvest.svg',
  logoHttpapiSvg: baseLogoImagePath +'8f0b6e4ef4baa3c02b65857d20c286d0_http-api.svg',
  logoHubplannerSvg: baseLogoImagePath +'3dfb682bd0f82b7212d2b3ff6a2dfb8a_hubplanner.svg',
  logoHubspotSvg: baseLogoImagePath +'b7f5b07ecb2fdfd3680078774f30aaef_hubspot.svg',
  logoInsightlySvg: baseLogoImagePath +'7cfef5bfd50692a8b1175616ead8778b_insightly.svg',
  logoInstagramSvg: baseLogoImagePath +'dba6e5175e922e99c35e32842670c3a3_instagram.svg',
  logoInstatusSvg: baseLogoImagePath +'99f74204cb0e337c2962fdee30ad726a_instatus.svg',
  logoIntercomSvg: baseLogoImagePath +'d5c4543dc6353848cc0a654b51cb61d9_intercom.svg',
  logoIntruderSvg: baseLogoImagePath +'eceb44b2a769b745a199dfdfb1bd3571_intruder.svg',
  logoIp2whoisSvg: baseLogoImagePath +'00f765a38e4d3051751c73ce42acfc86_ip2whois.svg',
  logoIterableSvg: baseLogoImagePath +'bd3ebaa874a8ac6d8cef2c5ce3e0125c_iterable.svg',
  logoJenkinsSvg: baseLogoImagePath +'0c652ca6064f3e97d5e89b312515c3a8_jenkins.svg',
  logoJiraSvg: baseLogoImagePath +'50d750ccd387fd0266738c2797c67a14_jira.svg',
  logoK6cloudSvg: baseLogoImagePath +'2997dd30a23a4386ba241b0e873c0f57_k6cloud.svg',
  logoKafkaSvg: baseLogoImagePath +'bf7df597287dccdae85bc4dd14fec654_kafka.svg',
  logoKlarnaSvg: baseLogoImagePath +'d4d0ef7d506f10a3072101aac9dfba8f_klarna.svg',
  logoKlaviyoSvg: baseLogoImagePath +'56dfb58d582da59c5f2a24a9c57ede4e_klaviyo.svg',
  logoKustomerSvg: baseLogoImagePath +'01400b94b978ebb947b0661c2c826f14_kustomer.svg',
  logoKyribaSvg: baseLogoImagePath +'08f96529a3c932dbc028e90ff9742bb2_kyriba.svg',
  logoKyvesourceSvg: baseLogoImagePath +'fbb2eef5cf1d4e1cc35e11854015e243_kyve-source.svg',
  logoLaunchdarklySvg: baseLogoImagePath +'24661aa7e47c95b2d69eb64616ba9972_launchdarkly.svg',
  logoLemlistSvg: baseLogoImagePath +'efb538090b5a0ae3851d5772bfa87ebb_lemlist.svg',
  logoLeverhiringSvg: baseLogoImagePath +'772eab93434a2f9ad44caf5237a3e60d_leverhiring.svg',
  logoLinkedinSvg: baseLogoImagePath +'7e7c6c8b720c54fa7ab4472986e01294_linkedin.svg',
  logoLinnworksSvg: baseLogoImagePath +'b31d7766a656a318ad7d0e015a0c7ae4_linnworks.svg',
  logoLokaliseSvg: baseLogoImagePath +'e11f646c50f379dd1d9b05effc5b4d81_lokalise.svg',
  logoLookerSvg: baseLogoImagePath +'701b84c5d43e0da26346c8718e9ce357_looker.svg',
  logoMagentoSvg: baseLogoImagePath +'1dfc7dabc0db2d288fc14b6f9a55106d_magento.svg',
  logoMailchimpSvg: baseLogoImagePath +'1e4afdbd411ff77d71f08f4860c742cd_mailchimp.svg',
  logoMailerliteSvg: baseLogoImagePath +'a6d1ab7ed388be0f7fb5fe4cc3cafaa2_mailerlite.svg',
  logoMailersendSvg: baseLogoImagePath +'73ece69d5a2e6e5e17a9a22d58e2934f_mailersend.svg',
  logoMailgunSvg: baseLogoImagePath +'2c15418015326c4f4bda9b4eab6198d3_mailgun.svg',
  logoMailjetSvg: baseLogoImagePath +'e2ae8a8a257b3a3fd39906143fb291e2_mailjet.svg',
  logoMarketoSvg: baseLogoImagePath +'98e615b9265a8be2abe3557ef655e7e9_marketo.svg',
  logoMergeSvg: baseLogoImagePath +'e903206051ea9e2489a7c9c9e95d5537_merge.svg',
  logoMetabaseSvg: baseLogoImagePath +'4bd3673f60b5f52a195068463dd1c164_metabase.svg',
  logoMicrosoftdataverseSvg: baseLogoImagePath +'2c30f268806a365886f5d1350cfa2d64_microsoftdataverse.svg',
  logoMicrosoftdynamicsSvg: baseLogoImagePath +'ed19193ab9c5112ef7b3eb15d1bdc76f_microsoft-dynamics.svg',
  logoMicrosoftsqlserverSvg: baseLogoImagePath +'ca195809e27055e2fff6f0e177ab5869_microsoft-sql-server.svg',
  logoMicrosoftteamsSvg: baseLogoImagePath +'1be536ccc4c4a5b408c2e261dc5e7c13_microsoft-teams.svg',
  logoMixpanelSvg: baseLogoImagePath +'f3a627d58762a27af453adaaba541f54_mixpanel.svg',
  logoMondaySvg: baseLogoImagePath +'9d9d9cd2b1299b171ef53b41b8c3f2f0_monday.svg',
  logoMongdbSvg: baseLogoImagePath +'ba0a2cd16814bb978be6236ab4380165_mongdb.svg',
  logoMyhoursSvg: baseLogoImagePath +'f893945d027bb365ed0f2182acc26d23_my-hours.svg',
  logoMysqlSvg: baseLogoImagePath +'b42293e347e347cf6c5bf71504de5eec_mysql.svg',
  logoN8nSvg: baseLogoImagePath +'d01d0d8f7a3bc609bf68159000cec640_n8n.svg',
  logoNasaSvg: baseLogoImagePath +'fc46a15659cb88cb4092a082ed89de28_nasa.svg',
  logoNewyorktimesSvg: baseLogoImagePath +'31d5d4d0b02db005bb7caf680ff4dbb6_newyorktimes.svg',
  logoNewsapiSvg: baseLogoImagePath +'23ce6e41b60310de1cd6768ca1ffe43f_newsapi.svg',
  logoNewsdataSvg: baseLogoImagePath +'f7826b362a30bcdac53bdbf371c20739_newsdata.svg',
  logoNotionSvg: baseLogoImagePath +'cc39bb0adf440ad88d9ba4cb666b8abf_notion.svg',
  logoOktaSvg: baseLogoImagePath +'2b68665fe8bd703681b5dc1a43d8feba_okta.svg',
  logoOmnisendSvg: baseLogoImagePath +'91540a5e9046d7d498b875dc634fd197_omnisend.svg',
  logoOnesignalSvg: baseLogoImagePath +'1f6b63b16c44af09782b8ffd61fc9a52_onesignal.svg',
  logoOpenexchangeratesSvg: baseLogoImagePath +'0a9923bae7426afb4e42a12a77350dfc_open-exchange-rates.svg',
  logoOpenweatherSvg: baseLogoImagePath +'9b511edd60fd75073a39a7ca3c003567_openweather.svg',
  logoOpsgenieSvg: baseLogoImagePath +'51f05cf0ef788afdad748f82bbfbe972_opsgenie.svg',
  logoOracleSvg: baseLogoImagePath +'4541657d9bebb6ac5e7e070619528d4b_oracle.svg',
  logoNetsuiteSvg: baseLogoImagePath +'4930bcda5e8df0254f5f30694b65415f_netsuite.svg',
  logoOraclepeoplesoftSvg: baseLogoImagePath +'6df86e57bec89e663b9a0a4b5a253be8_oracle-peoplesoft.svg',
  logoOraclesiebelcrmSvg: baseLogoImagePath +'6df86e57bec89e663b9a0a4b5a253be8_oracle-siebel-crm.svg',
  logoOrbSvg: baseLogoImagePath +'d48be75d6858ba20a45617774ee50181_orb.svg',
  logoOrbitSvg: baseLogoImagePath +'a1263638bb23c3429585aad4773a94ea_orbit.svg',
  logoOuraSvg: baseLogoImagePath +'af1f5ac0416ad6222ee1968592919843_oura.svg',
  logoOutreachSvg: baseLogoImagePath +'efbd059c1d02b78660c4fb28a453c217_outreach.svg',
  logoPagerdutySvg: baseLogoImagePath +'fa6d7579909874ca31365d7c7a9a8866_pagerduty.svg',
  logoSalesforceSvg: baseLogoImagePath +'58915dbb66be2d29ada584594d92bbaa_salesforce.svg',
  logoPartnerstackSvg: baseLogoImagePath +'02a5919e8d26e03c9841beccc7015df2_partnerstack.svg',
  logoPaypalSvg: baseLogoImagePath +'6aa7babd9c372e6dee3f8cf4e04353cd_paypal.svg',
  logoPaystackSvg: baseLogoImagePath +'20828d905cd9b5d8421bfe376edeef9c_paystack.svg',
  logoPendoSvg: baseLogoImagePath +'236e9ffdbb839c31026e6b624f05a1b1_pendo.svg',
  logoPersistiqSvg: baseLogoImagePath +'09b7598cdf868f362670897f624e00d2_persistiq.svg',
  logoPexelsSvg: baseLogoImagePath +'c1f3e559d270dbdda4d78e2d001df9bf_pexels.svg',
  logoPinterestSvg: baseLogoImagePath +'f5fd300119b29dbbcb6bd352fb6e3a41_pinterest.svg',
  logoPipedriveSvg: baseLogoImagePath +'19b4fbc58f5ec50925b81c0d15339ed2_pipedrive.svg',
  logoPivotaltrackerSvg: baseLogoImagePath +'f015474c120c246833331fda1ba91e7c_pivotal-tracker.svg',
  logoPlaidSvg: baseLogoImagePath +'9d366ef5d327e60387924e1005d15a30_plaid.svg',
  logoPlausibleSvg: baseLogoImagePath +'d7377781c59d5a945da72f358f23b63a_plausible.svg',
  logoPocketSvg: baseLogoImagePath +'0ecbf5e52755d96bf648fcb969320ba5_pocket.svg',
  logoPokeapiSvg: baseLogoImagePath +'baf64d5818214e264457bf623f1395ba_pokeapi.svg',
  logoPolygonSvg: baseLogoImagePath +'dca2c522d339a29e5a5434781061dd38_polygon.svg',
  logoPostgresSvg: baseLogoImagePath +'7c7dd1dcc313271564d7c2b55fb6f4da_postgres.svg',
  logoPosthogSvg: baseLogoImagePath +'b7f402518757bd28b579bfabde828963_posthog.svg',
  logoPostmarkSvg: baseLogoImagePath +'e9f5132d8119d098f794626bb01a21f3_postmark.svg',
  logoPrestashopSvg: baseLogoImagePath +'98fb405958fa7f56413b38cbe7f77512_prestashop.svg',
  logoPrimetricSvg: baseLogoImagePath +'334d8101bfefe82b805a8cfefe20bee0_primetric.svg',
  logoPublicapiSvg: baseLogoImagePath +'7dfd93f370c1863f3c378623f324109b_publicapi.svg',
  logoPunkapiSvg: baseLogoImagePath +'de8248b13da4366282276d97e7bc22ac_punkapi.svg',
  logoPypiSvg: baseLogoImagePath +'ef8303018006b4c14b3b32298cb7a533_pypi.svg',
  logoQontoSvg: baseLogoImagePath +'bd1a0fced040794e5b1b2a11e4860382_qonto.svg',
  logoQualarooSvg: baseLogoImagePath +'6927d2cbe2314d5891d752e4e897cdd3_qualaroo.svg',
  logoQuickbooksSvg: baseLogoImagePath +'f2af5fb09226e9ace6b6f9de6ec9c857_quickbooks.svg',
  logoRailzSvg: baseLogoImagePath +'e1d4bca7ea6c1d99b77a1b4e688ecfaa_railz.svg',
  logoRdstationSvg: baseLogoImagePath +'f475667c324e10fce23c9b509cbf21cd_rdstation.svg',
  logoRechargeSvg: baseLogoImagePath +'b0214389e7f36727a33ca5b5e0cc6f49_recharge.svg',
  logoRecreationgovSvg: baseLogoImagePath +'78f8250a6f9a7f00e0a3a832395ca29a_recreationgov.svg',
  logoRecruiteeSvg: baseLogoImagePath +'4c629c52f3d66dac87577bde1c7e926c_recruitee.svg',
  logoRecurlySvg: baseLogoImagePath +'d448f728f26526db1de7a6322075d775_recurly.svg',
  logoRedshiftSvg: baseLogoImagePath +'b659e0d3d210956931915af2f728073f_redshift.svg',
  logoReplyioSvg: baseLogoImagePath +'5141569b0f5786d3fb8de0c202189d98_replyio.svg',
  logoRetentlySvg: baseLogoImagePath +'3d71bad10015014cf377f8d50b7f53e9_retently.svg',
  logoRingcentralSvg: baseLogoImagePath +'17962b3932bcb7e247c356ae2c70bf5d_ringcentral.svg',
  logoRocketchatSvg: baseLogoImagePath +'a63b3abe8db1682270982c993ae997ec_rocket-chat.svg',
  logoRssSvg: baseLogoImagePath +'9ea88311f12603a9919084c98261312c_rss.svg',
  logoS3Svg: baseLogoImagePath +'4f066cd90523722372be17631788d2ff_s3.svg',
  logoSalesloftSvg: baseLogoImagePath +'dcf9197d6517c70ccad509cca62fd9fc_salesloft.svg',
  logoSapbusinessoneSvg: baseLogoImagePath +'96975f922138c92420152f30a5f13e1d_sap-business-one.svg',
  logoSapfieldglassSvg: baseLogoImagePath +'56d6d9db1a62ab6ca858782bc1ba21c7_sapfieldglass.svg',
  logoSearchmetricsSvg: baseLogoImagePath +'91540e8ef550d84cab24faef8159bef0_searchmetrics.svg',
  logoSecodaSvg: baseLogoImagePath +'e908d66578da0aaa772c30a60a04b748_secoda.svg',
  logoSendgridSvg: baseLogoImagePath +'4ff57c48d454abaf6ec5adeb2d5ffbf4_sendgrid.svg',
  logoSendinblueSvg: baseLogoImagePath +'b7ec3b45c839e74285a46a0916d4d6f0_sendinblue.svg',
  logoSenseforceSvg: baseLogoImagePath +'ab4ddc735405429d6be671e727403488_senseforce.svg',
  logoSentrySvg: baseLogoImagePath +'7f789f20813718b0cdf5bbc115abfd06_sentry.svg',
  logoSftpSvg: baseLogoImagePath +'f4127f3ef344a84557065d1a30955ec6_sftp.svg',
  logoShopifySvg: baseLogoImagePath +'432cb77db41dd055ecb4546b9cb24ab8_shopify.svg',
  logoShortSvg: baseLogoImagePath +'4a96ae3261f435fb055d03858dbb8737_short.svg',
  logoSlackSvg: baseLogoImagePath +'800f14b57e6d3e6c65f15447cda64d2d_slack.svg',
  logoSmailySvg: baseLogoImagePath +'1e7ecddb676a3f7d995f94cafff47c0a_smaily.svg',
  logoSmartengageSvg: baseLogoImagePath +'2c5c178778ef195ce88314c4aa09d57f_smartengage.svg',
  logoSmartsheetsSvg: baseLogoImagePath +'987af46fb9f65de9dc1337841aa39c89_smartsheets.svg',
  logoSnapchatSvg: baseLogoImagePath +'0943c038dba1503a82c56b9c509454f9_snapchat.svg',
  logoSnowflakeSvg: baseLogoImagePath +'448bc8b7a0137205948cd7cb27e78466_snowflake.svg',
  logoSonarcloudSvg: baseLogoImagePath +'9be01e7e37a4e1f7b367fde6755a5712_sonarcloud.svg',
  logoSpacexSvg: baseLogoImagePath +'907a590a9825554487e67263bb2264f5_spacex.svg',
  logoSpreecommerceSvg: baseLogoImagePath +'db0dc7ca14425e464f050b3fdf23fe85_spree-commerce.svg',
  logoSquareSvg: baseLogoImagePath +'63be3079e0be72f0b90689519ae44b4d_square.svg',
  logoStatuspageSvg: baseLogoImagePath +'798d4230e68cd7dba5449f8f33ddf58b_statuspage.svg',
  logoStravaSvg: baseLogoImagePath +'36599ada88563c8f49202f3686b55658_strava.svg',
  logoStripeSvg: baseLogoImagePath +'aaaa6b61ff87a91c4c996a5fd1439387_stripe.svg',
  logoSugarcrmSvg: baseLogoImagePath +'1686fb66dd1e72b29cb0ad1e59e08712_sugar-crm.svg',
  logoSurveyctoSvg: baseLogoImagePath +'14d0d0f8386c95ff48bed4b03ceeabc1_surveycto.svg',
  logoSurveymonkeySvg: baseLogoImagePath +'4ed84b348f6b9c69f83dfaae47ef9ebe_surveymonkey.svg',
  logoSurveysparrowSvg: baseLogoImagePath +'f33114258cbeddea5826471a1a780cf5_surveysparrow.svg',
  logoTalkdeskexploreSvg: baseLogoImagePath +'b93fba616f27b8673a91dbb5de90fec8_talkdesk-explore.svg',
  logoTempoSvg: baseLogoImagePath +'a82be9d002f5bcf01c62996b8f270219_tempo.svg',
  logoTeradataSvg: baseLogoImagePath +'ee3f5a9991954e1920cd1d0ffdc346eb_teradata.svg',
  logoTheguardianSvg: baseLogoImagePath +'f1a9c80e6a425d3a136980830dc4f677_theguardian.svg',
  logoTidbSvg: baseLogoImagePath +'72a1239c215ddc959211af48a4af2fc8_tidb.svg',
  logoTiktokSvg: baseLogoImagePath +'8e3638e0d74428a02e10b46bd7c48155_tiktok.svg',
  logoTimelySvg: baseLogoImagePath +'130c4032fdc832ef28864287cb210426_timely.svg',
  logoTmdbSvg: baseLogoImagePath +'18ef67b2bddb1119e9d4220ef59f5de0_tmdb.svg',
  logoTodoistSvg: baseLogoImagePath +'c782c21fac8adf0f299a6b2eecb26380_todoist.svg',
  logoTogglSvg: baseLogoImagePath +'8e551c56a0669b95d7461d394e952a23_toggl.svg',
  logoTrelloSvg: baseLogoImagePath +'4af0a6537e59e9c14170872db3b2f58b_trello.svg',
  logoTrustpilotSvg: baseLogoImagePath +'6b3cd38597ab861ebde743f239c307c9_trustpilot.svg',
  logoTvmazescheduleSvg: baseLogoImagePath +'6f399e1657fe9d742d274e06fb91ce8a_tvmazeschedule.svg',
  logoTwilioSvg: baseLogoImagePath +'9686efc4e792e042f12cc04e1d8f5059_twilio.svg',
  logoTwitterSvg: baseLogoImagePath +'a5e4e2f97c8df7d510a0e49deaddf19e_twitter.svg',
  logoTyntecsmsSvg: baseLogoImagePath +'b32e585305eb39d688ab438cbbc59161_tyntec-sms.svg',
  logoTypeformSvg: baseLogoImagePath +'223a9373e91c63ae8eb0bbb8aa9dd02c_typeform.svg',
  logoUnleashSvg: baseLogoImagePath +'e53d9cac06384b9107e8328811f42f3e_unleash.svg',
  logoUscensusSvg: baseLogoImagePath +'29235094b2479848b2402235e45dcfe5_uscensus.svg',
  logoVantageSvg: baseLogoImagePath +'daafc728b9e562c3a5b447490baf2da8_vantage.svg',
  logoVictoropsSvg: baseLogoImagePath +'3051b95cfcd9dc7d1ae37d29cd0d4ad1_victorops.svg',
  logoVismaeconomicSvg: baseLogoImagePath +'e823347365a87e84cd49280ac80fcb7b_visma-e-conomic.svg',
  logoVittallySvg: baseLogoImagePath +'45f911a0ec7ff7264bfa89d34929e210_vittally.svg',
  logoWaiteraidSvg: baseLogoImagePath +'2531e9ae5b6faa92e214b13b9732bf0c_waiteraid.svg',
  logoWeatherstackSvg: baseLogoImagePath +'562bb233d75bf2efbc74383063cc8648_weatherstack.svg',
  logoWebflowSvg: baseLogoImagePath +'f825b0de3c697d120f16eb92585204dc_webflow.svg',
  logoWhiskyhunterSvg: baseLogoImagePath +'7afe8bcd91f2cc7df29bc75d087dda40_whiskyhunter.svg',
  logoWikipediapageviewsSvg: baseLogoImagePath +'16d09dc67cb1b97d535469a1408c6a27_wikipediapageviews.svg',
  logoWoocommerceSvg: baseLogoImagePath +'0bda92312a96637a0a525173440da0c1_woocommerce.svg',
  logoWordpressSvg: baseLogoImagePath +'09a04ec93c3c5e045886f65aeb9846cd_wordpress.svg',
  logoWorkableSvg: baseLogoImagePath +'58791a1431f609ef02124ef8a93378c8_workable.svg',
  logoWorkrampSvg: baseLogoImagePath +'db91b31ee2b9049015bc894d701d2229_workramp.svg',
  logoWrikeSvg: baseLogoImagePath +'0e684a2418afedc046cfcb9f7dfdb912_wrike.svg',
  logoXeroSvg: baseLogoImagePath +'378e63af9a9a59b638e08b0c6fea94d5_xero.svg',
  logoXkcdSvg: baseLogoImagePath +'98deded789bbe021663d364c45483368_xkcd.svg',
  logoYahooSvg: baseLogoImagePath +'2bb8ba6ca7c96458e812a5032bee843b_yahoo.svg',
  logoYandexmetricaSvg: baseLogoImagePath +'73c8bd168b69302093ab3f6401ceb7b3_yandex-metrica.svg',
  logoYotpoSvg: baseLogoImagePath +'b65757fc79132706ee03dfd41b2050f4_yotpo.svg',
  logoYouniumSvg: baseLogoImagePath +'99410270750adfbfd1966a87a4bcc8c0_younium.svg',
  logoYoutubeanalyticsSvg: baseLogoImagePath +'fd56f41a4f2cb8b6aa615c934f0f96a5_youtube-analytics.svg',
  logoZapierSvg: baseLogoImagePath +'3e78d9d7a2f34c29ee6079c73b3ba2ee_zapier.svg',
  logoZencartSvg: baseLogoImagePath +'295f8fce946e92ebb35449b73ea2c187_zencart.svg',
  logoZendeskchatSvg: baseLogoImagePath +'7fb203433783de0e8aa8566b6c4f5c0f_zendesk-chat.svg',
  logoZendeskSvg: baseLogoImagePath +'8fab413d2fb525ff0c032cc709e45139_zendesk.svg',
  logoZendesksunshineSvg: baseLogoImagePath +'39eb3da1485e0d141a006fe1d6f8d366_zendesk-sunshine.svg',
  logoZendesksupportSvg: baseLogoImagePath +'681310407251a5dc2c8643c8046f6787_zendesk-support.svg',
  logoZendesktalkSvg: baseLogoImagePath +'f10e88fa7b9da81eddf0cc0f06b39563_zendesk-talk.svg',
  logoZenefitsSvg: baseLogoImagePath +'622bf9d5dfbced90137b49ea209e108a_zenefits.svg',
  logoZenloopSvg: baseLogoImagePath +'4cd923a19c870fbdba818683e3da758f_zenloop.svg',
  logoZohocrmSvg: baseLogoImagePath +'c345c3cb16dde1f2072860357a683991_zohocrm.svg',
  logoZoomSvg: baseLogoImagePath +'87d5f7c0b9d83dee87f781e027361e99_zoom.svg',
  logoZuoraSvg: baseLogoImagePath +'b8413e1cf61ea617171560f2430b629b_zuora.svg'
}
