export const responseGoogleAdsSpm = {
  links: {
    first: 'https://subscriptions.api.dev.openbridge.io/spm?page=1&subscription=760',
    last: 'https://subscriptions.api.dev.openbridge.io/spm?page=1&subscription=760',
    next: '',
    prev: ''
  },
  data: [
    {
      type: 'SubscriptionProductMeta',
      id: '1684',
      attributes: {
        data_id: 0,
        data_key: 'remote_identity_id',
        data_value: '178',
        created_at: '2022-05-09T05:02:01.242650',
        modified_at: '2022-05-09T05:02:01.242671',
        product: {
          id: 63,
          active: 1,
          name: 'Google Ads',
          summary: '',
          name_slug: 'google-ads',
          created_at: '2022-01-31T06:24:29.962112',
          modified_at: '2022-01-31T06:24:29.962112',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'google-ads',
          allow_as_trial: 0,
          is_storage_product: 0,
          initialize_job: 1,
          premium_product: 0,
          worker_name: 'ads',
          product_group: 1,
          remote_identity_type: 8,
          product_category: 3,
          parent_product: null
        },
        subscription: {
          id: 760,
          price: 0,
          status: 'active',
          date_start: '2022-05-09T05:01:57',
          date_end: '2022-05-09T05:01:57',
          auto_renew: 1,
          created_at: '2022-05-09T05:02:01.220837',
          modified_at: '2022-05-09T05:02:01.220852',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'google-ads-test',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 63,
          product_plan: null,
          remote_identity: 178,
          storage_group: 110,
          user: 309
        }
      }
    },
    {
      type: 'SubscriptionProductMeta',
      id: '1685',
      attributes: {
        data_id: 0,
        data_key: 'manager_customer_id',
        data_value: '9018096832',
        created_at: '2022-05-09T05:02:01.255610',
        modified_at: '2022-05-09T05:02:01.255629',
        product: {
          id: 63,
          active: 1,
          name: 'Google Ads',
          summary: '',
          name_slug: 'google-ads',
          created_at: '2022-01-31T06:24:29.962112',
          modified_at: '2022-01-31T06:24:29.962112',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'google-ads',
          allow_as_trial: 0,
          is_storage_product: 0,
          initialize_job: 1,
          premium_product: 0,
          worker_name: 'ads',
          product_group: 1,
          remote_identity_type: 8,
          product_category: 3,
          parent_product: null
        },
        subscription: {
          id: 760,
          price: 0,
          status: 'active',
          date_start: '2022-05-09T05:01:57',
          date_end: '2022-05-09T05:01:57',
          auto_renew: 1,
          created_at: '2022-05-09T05:02:01.220837',
          modified_at: '2022-05-09T05:02:01.220852',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'google-ads-test',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 63,
          product_plan: null,
          remote_identity: 178,
          storage_group: 110,
          user: 309
        }
      }
    },
    {
      type: 'SubscriptionProductMeta',
      id: '1686',
      attributes: {
        data_id: 0,
        data_key: 'client_customer_id',
        data_value: '8593574706',
        created_at: '2022-05-09T05:02:01.264693',
        modified_at: '2022-05-09T05:02:01.264713',
        product: {
          id: 63,
          active: 1,
          name: 'Google Ads',
          summary: '',
          name_slug: 'google-ads',
          created_at: '2022-01-31T06:24:29.962112',
          modified_at: '2022-01-31T06:24:29.962112',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'google-ads',
          allow_as_trial: 0,
          is_storage_product: 0,
          initialize_job: 1,
          premium_product: 0,
          worker_name: 'ads',
          product_group: 1,
          remote_identity_type: 8,
          product_category: 3,
          parent_product: null
        },
        subscription: {
          id: 760,
          price: 0,
          status: 'active',
          date_start: '2022-05-09T05:01:57',
          date_end: '2022-05-09T05:01:57',
          auto_renew: 1,
          created_at: '2022-05-09T05:02:01.220837',
          modified_at: '2022-05-09T05:02:01.220852',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'google-ads-test',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 63,
          product_plan: null,
          remote_identity: 178,
          storage_group: 110,
          user: 309
        }
      }
    }
  ],
  meta: {
    pagination: {
      page: 1,
      pages: 1,
      count: 3
    }
  }
};