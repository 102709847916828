import { Injectable } from '@angular/core';
import { map, take, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { initializeS3FileUpload, updateUploadProgress, updateUploadProgressStatus, updateUploadStage, updateValidateS3FileUploadResponse, validateS3FileUpload } from '../actions/s3-upload.actions';
import { Store } from '@ngrx/store';
import { S3Service } from 'src/app/shared/services/s3.service';
import { environment } from 'src/environments/environment';
import { FileState } from '../reducers/s3-upload.reducer';
import { AppState } from '../reducers';
import { FileValidatorService } from 'src/app/shared/services/file-validator.service';
import { interval } from 'rxjs';

const delay = ms => new Promise(res => setTimeout(res, ms));

@Injectable()
export class S3UploadEffects {

  fileUploadSubscriptions$: any[] = [];
  counter$;

  initializeS3FileUpload$ = createEffect(() => this.actions$
    .pipe(
      ofType(initializeS3FileUpload),
      tap(async action => {

        const signedUrlInfo = await this.s3Service.presignedS3UploadUrl('csv_validator');
        let status: FileState['status'] = action.status;

        this.fileUploadSubscriptions$.push(this.s3Service.uploadPresignedS3File(
          environment.s3BucketUris.csvValidator,
          signedUrlInfo.data.fields,
          action.file
        ));

        this.fileUploadSubscriptions$[this.fileUploadSubscriptions$.length - 1].subscribe(async (response) => {
          let uploaded = false;

          const uploading = (
            response.type == 1 && (
              response.total > response.loaded
            ));

          const uploadedCheck = (response.type == 4 && status === 'uploading')

          if (uploadedCheck) {
            uploaded = true;
          }

          if (response.type === 1 && status === 'initialized') {
            status = 'uploading';

            // update progress
            this.counter$ = this.setProgressCount(1, 0, 50);
            this.counter$.subscribe(val => {
              this.store$.dispatch(updateUploadProgressStatus({ hashId: action.hashId, progress: val }));
            });

            this.store$.dispatch(updateUploadStage({ hashId: action.hashId, stage: status }));
          }

          if (uploading) {
            this.store$.dispatch(updateUploadProgress({ hashId: action.hashId, loaded: response.loaded, total: response.total }));
          }

          if (uploaded) {

            await this.s3Service.updateS3UriForHashId(action.hashId, response.headers.get('location'));
            status = 'verifying';

            // update progress
            this.counter$ = this.setProgressCount(1, 50, 25);
            this.counter$.subscribe(val => {
              this.store$.dispatch(updateUploadProgressStatus({ hashId: action.hashId, progress: val }));
            });

            this.store$.dispatch(updateUploadStage({ hashId: action.hashId, stage: status }));
            this.store$.dispatch(validateS3FileUpload({ hashId: action.hashId }));
          }

        });
      })
    ),
    { dispatch: false }
  );

  validateS3FileUpload$ = createEffect(() => this.actions$
    .pipe(
      ofType(validateS3FileUpload),
      tap(async action => {
        this.store$.select('s3Upload').pipe(take(1)).subscribe(async (response) => {
          const uploadStateIndex = response.uploadedFileStates.findIndex((obj => obj.hashId == action.hashId));
          const uploadState = response.uploadedFileStates[uploadStateIndex];
          if (uploadState.status === 'verifying') {
            let verifyResponse: any = null;

            switch (uploadState.uploadType) {
              case 'csvValidator':
                verifyResponse = await this.fileValidatorService.validateCsvFileRules(uploadState);
                break;
              default:
                // There is no default.
                break;
            }
            
            // update progress
            this.counter$ = this.setProgressCount(1, 75, 25);
            this.counter$.subscribe(val => {
             this.store$.dispatch(updateUploadProgressStatus({ hashId: action.hashId, progress: val }));
            });
            
            this.store$.dispatch(updateUploadStage({ hashId: action.hashId, stage: 'complete' }));
            this.store$.dispatch(updateValidateS3FileUploadResponse({ hashId: action.hashId, response: verifyResponse }));
          }
        });
      })
    ),
    { dispatch: false }
  );

 /**
 * @remarks
 * This method is part of the ProgressBar Status value
 * 
 * @param {number} duration - The interval size in milliseconds (by default) or the time unit determined by the scheduler's clock.
 * @param {number} startCountFrom - counter will start counting from this value
 * @param {number} iterations - counter increment the count till next `iterations`
 * @returns increment and return count value from `startCountFrom` to next `iterations` at every `duration` milliseconds as Observable
 * 
 * @example
 * setProgressCount(5, 25, 50);
 * increment and return count value from 25 to next 50 iteration at every 5 milliseconds 
 * so the final incremented count will be 75
 */

  setProgressCount(duration: number = 25, startCountFrom: number = 0, iterations: number = 0) {
    const source = interval(duration);
    const counter$ = source.pipe(
      take(iterations),
      map((value) => {
        return startCountFrom + value + 1;
      })
    );
    return counter$;
  }

  constructor(
    private actions$: Actions,
    private s3Service: S3Service,
    private store$: Store<AppState>,
    private fileValidatorService: FileValidatorService
  ) { }

}