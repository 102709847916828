import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '../reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { triggerToastNotification } from '../actions/flash-notifications.actions';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class FlashNotificationsEffects {

  triggerToastNotifications$ = createEffect(() => this.actions$
    .pipe(
      ofType(triggerToastNotification),
      tap(async action => {
        const validTypes = ['success','error','info','warning'];
        if(!validTypes.includes(action.toastType)) {
          console.error('Unable to submit toast notification, wrong type');
        }

        const type = action.toastType;
        const title = action.title;
        const message = action.message;

        if(title !== null) {
          if(type === 'success') {
            this.toastrService.success(message, title);
          }
          if(type === 'error') {      
            this.toastrService.error(message, title);
          }
          if(type === 'info') {      
            this.toastrService.info(message, title);
          }
          if(type === 'warning') {      
            this.toastrService.warning(message, title);
          }
        }
        else {
          if(type === 'success') {
            this.toastrService.success(message)
          }
          if(type === 'error') {      
            this.toastrService.error(message);
          }
          if(type === 'info') {      
            this.toastrService.info(message);
          }
          if(type === 'warning') {      
            this.toastrService.warning(message);
          }
        }
      })
    ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private toastrService: ToastrService,
    ) { }

}