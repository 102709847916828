export const responseBlobEditSubscription = {
  data: {
    type: 'Subscription',
    id: '162',
    attributes: {
      price: 0,
      status: 'active',
      date_start: '2021-12-09T16:13:22',
      date_end: '2021-12-09T16:13:22',
      auto_renew: 1,
      created_at: '2021-12-09T16:13:29.603127',
      modified_at: '2021-12-09T16:13:30.550700',
      quantity: 1,
      stripe_subscription_id: '',
      name: 'azure blob storage',
      rabbit_payload_successful: 0,
      primary_job_id: null,
      pipeline: null,
      invalid_subscription: 0,
      invalidated_at: null,
      notified_at: null,
      canonical_name: null,
      account_id: 342,
      product_id: 46,
      product_plan_id: null,
      remote_identity_id: null,
      storage_group_id: 107,
      user_id: 309,
      history_requested: 0,
      unique_hash: null
    },
    relationships: {
      account: {
        data: {
          type: 'Account',
          id: '342'
        }
      },
      product: {
        data: {
          type: 'Product',
          id: '46'
        }
      },
      product_plan: {
        data: null
      },
      remote_identity: {
        data: null
      },
      storage_group: {
        data: {
          type: 'StorageGroup',
          id: '107'
        }
      },
      user: {
        data: {
          type: 'User',
          id: '309'
        }
      }
    }
  },
  included: [
    {
      type: 'StorageGroup',
      id: '107',
      attributes: {
        product_id: 46,
        name: 'azure blob storage',
        key_name: '985317bf25546027-azure-blob-storage'
      }
    }
  ]
};