import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalGlobalErrorComponent } from 'src/app/shared/components/modals/modal-global-error/modal-global-error.component';
import { modalNonUserClosable } from 'src/app/shared/constants/component-configs';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorModalService {
  bsModalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
  ) { }

  generateGlobalError(errorValue: any): void {

    const responseError: GlobalErrorResponse = {
      message: ''
    };

    // let error = errorValue;
    let error = errorValue;

    // Check if it's a JSON object in a string format
    const isJsonString = this.isJsonString(errorValue);
    if (isJsonString) {
      error = JSON.parse(errorValue);
    }

    if (typeof error === 'object' && error.name === 'TypeError') {
      responseError.message = error.message;
      responseError.file = error.filename;
      responseError.line = error.lineNumber;
      responseError.column = error.columnNumber;
      responseError.stack = error.stack;

    }
    else if (typeof error === 'object') {
      responseError.message = JSON.stringify(error);
    }
    else {
      responseError.message = error;
    }

    const modalParams = {
      initialState: {
        error: responseError
      },
      ...modalNonUserClosable
    };

    this.bsModalRef = this.modalService.show(ModalGlobalErrorComponent, modalParams);

    // Throwing the response error here gives it to the global error handler
    // so we can send it out to log and see that a problem has happened.
    throw responseError;
  }

  private isJsonString(str): boolean {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

}

interface GlobalErrorResponse {
  message: string;
  file?: string;
  line?: number;
  column?: number;
  stack?: string;
}
