export const responseFacebookMarketingProfiles = {
  data: [
    {
      type: 'FacebookMarketing',
      id: '104597502978207',
      attributes: {
        name: 'Hasbro_Gaming_UK',
        account_id: '104597502978207',
        account_status: 101,
        business_name: 'OMD',
        business_city: 'london'
      }
    },
    {
      type: 'FacebookMarketing',
      id: '101441899995935',
      attributes: {
        name: '[AKL-OMD] Hasbro',
        account_id: '101441899995935',
        account_status: 1,
        business_name: '',
        business_city: ''
      }
    },
    {
      type: 'FacebookMarketing',
      id: '105585309607046',
      attributes: {
        name: 'Hasbro',
        account_id: '105585309607046',
        account_status: 1,
        business_name: '',
        business_city: ''
      }
    },
    {
      type: 'FacebookMarketing',
      id: '316335451798855',
      attributes: {
        name: 'Hasbro Analytics',
        account_id: '316335451798855',
        account_status: 1,
        business_name: '',
        business_city: ''
      }
    },
    {
      type: 'FacebookMarketing',
      id: '1375385749402831',
      attributes: {
        name: 'Hasbro Gaming',
        account_id: '1375385749402831',
        account_status: 1,
        business_name: '',
        business_city: '',
        end_advertiser_name: 'Hasbro Gaming'
      }
    },
    {
      type: 'FacebookMarketing',
      id: '1440044109582842',
      attributes: {
        name: 'Hasbro México',
        account_id: '1440044109582842',
        account_status: 1,
        business_name: '',
        business_city: ''
      }
    },
    {
      type: 'FacebookMarketing',
      id: '1479555592321550',
      attributes: {
        name: 'Hasbro Regional',
        account_id: '1479555592321550',
        account_status: 1,
        business_name: '',
        business_city: ''
      }
    },
    {
      type: 'FacebookMarketing',
      id: '1436023266660065',
      attributes: {
        name: 'Hasbro Colombia',
        account_id: '1436023266660065',
        account_status: 1,
        business_name: '',
        business_city: ''
      }
    },
    {
      type: 'FacebookMarketing',
      id: '1521900218056451',
      attributes: {
        name: 'Hasbro',
        account_id: '1521900218056451',
        account_status: 1,
        business_name: 'Mediacom Perú SAC',
        business_city: 'Lima',
        business_state: 'Miraflores',
        end_advertiser_name: 'Mediacom Perú'
      }
    },
    {
      type: 'FacebookMarketing',
      id: '754627104626616',
      attributes: {
        name: 'Hasbro Colombia Nueva',
        account_id: '754627104626616',
        account_status: 1,
        business_name: '',
        business_city: '',
        end_advertiser_name: ''
      }
    },
    {
      type: 'FacebookMarketing',
      id: '868490249893675',
      attributes: {
        name: 'Hasbro_Transformers_CA',
        account_id: '868490249893675',
        account_status: 1,
        business_name: '',
        business_city: '',
        end_advertiser_name: 'TRANSFORMERS'
      }
    },
    {
      type: 'FacebookMarketing',
      id: '868490929893607',
      attributes: {
        name: 'Hasbro_Nerf_CA',
        account_id: '868490929893607',
        account_status: 1,
        business_name: '',
        business_city: '',
        end_advertiser_name: 'Nerf'
      }
    },
    {
      type: 'FacebookMarketing',
      id: '868491039893596',
      attributes: {
        name: 'Hasbro_Playskool_CA',
        account_id: '868491039893596',
        account_status: 1,
        business_name: '',
        business_city: '',
        end_advertiser_name: 'Playskool'
      }
    },
    {
      type: 'FacebookMarketing',
      id: '932763520133014',
      attributes: {
        name: 'Hasbro_MyLittlePony_CA',
        account_id: '932763520133014',
        account_status: 1,
        business_name: '',
        business_city: '',
        end_advertiser_name: 'My Little Pony'
      }
    },
    {
      type: 'FacebookMarketing',
      id: '868491303226903',
      attributes: {
        name: 'Hasbro_PlayDoh_CA',
        account_id: '868491303226903',
        account_status: 1,
        business_name: '',
        business_city: '',
        end_advertiser_name: 'Play-Doh'
      }
    },
    {
      type: 'FacebookMarketing',
      id: '462794540575144',
      attributes: {
        name: 'Hasbro',
        account_id: '462794540575144',
        account_status: 1,
        business_name: '',
        business_city: '',
        end_advertiser_name: ''
      }
    },
    {
      type: 'FacebookMarketing',
      id: '10153690385388054',
      attributes: {
        name: 'Power Rangers Ads',
        account_id: '10153690385388054',
        account_status: 1,
        business_name: '',
        business_city: '',
        end_advertiser_name: 'Mambo'
      }
    },
    {
      type: 'FacebookMarketing',
      id: '1553725028000861',
      attributes: {
        name: 'Play-Doh TL',
        account_id: '1553725028000861',
        account_status: 1,
        business_name: '',
        business_city: '',
        end_advertiser_name: 'OMD Turkey'
      }
    },
    {
      type: 'FacebookMarketing',
      id: '1529009303805767',
      attributes: {
        name: 'Nerf TL',
        account_id: '1529009303805767',
        account_status: 1,
        business_name: '',
        business_city: '',
        end_advertiser_name: 'OMD Turkey'
      }
    },
    {
      type: 'FacebookMarketing',
      id: '1527218150636206',
      attributes: {
        name: 'Hasbro_HasbroGaming_IN',
        account_id: '1527218150636206',
        account_status: 1,
        business_name: '',
        business_city: '',
        end_advertiser_name: 'Omnicom Media Group India Private Limited'
      }
    },
    {
      type: 'FacebookMarketing',
      id: '1282952908460697',
      attributes: {
        name: 'HASBRO BRASIL',
        account_id: '1282952908460697',
        account_status: 1,
        business_name: 'HASBRO DO BRASIL IND E COM DE BRINQUEDOS E JOGOS LTDA',
        business_city: 'SAO PAULO',
        business_state: 'SP',
        end_advertiser_name: 'Mediacom Miami'
      }
    },
    {
      type: 'FacebookMarketing',
      id: '962327527203097',
      attributes: {
        name: 'Hasbro',
        account_id: '962327527203097',
        account_status: 2,
        business_name: '',
        business_city: '',
        end_advertiser_name: 'GroupM HUB'
      }
    },
    {
      type: 'FacebookMarketing',
      id: '1421234267914479',
      attributes: {
        name: 'Hasbro_Hanazuki_ES',
        account_id: '1421234267914479',
        account_status: 1,
        business_name: 'OMNICOM MEDIA GROUP, S.L.',
        business_city: 'Barcelona',
        business_state: 'Barcelona',
        end_advertiser_name: 'Omnicom Media Group - Spain'
      }
    },
    {
      type: 'FacebookMarketing',
      id: '1421872137850692',
      attributes: {
        name: 'Hasbro_Nerf_ES',
        account_id: '1421872137850692',
        account_status: 1,
        business_name: 'OMNICOM MEDIA GROUP, S.L.',
        business_city: 'Barcelona',
        business_state: 'Barcelona',
        end_advertiser_name: 'Omnicom Media Group - Spain'
      }
    },
    {
      type: 'FacebookMarketing',
      id: '1529010620472302',
      attributes: {
        name: 'Transformers TL',
        account_id: '1529010620472302',
        account_status: 1,
        business_name: '',
        business_city: '',
        end_advertiser_name: 'OMD Turkey'
      }
    }
  ],
  includes: {
    next: null
  }
};