import { Integration } from '../../models/integration.model';
import { vendors } from '../logos';
import {
  productIdAwsAthena, productIdAwsRedshift, productIdAwsRedshiftManaged, productIdAwsRedshiftSpectrum, productIdAzureBlobStorage,
  productIdAzureDataLake, productIdDataBricksExternal, productIdGoogleBigquery, productIdMultiStorage, productIdSnowflakeWarehouse
} from '../product-ids';

export const destinationAwsAthena: Integration = {
  productId: productIdAwsAthena,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'AWS Athena',
  badge: 'none',
  type: 'destination',
  class: 'Data Lake',
  sortOrder: 2100,
  tagLine: 'On-demand pricing, efficient, serverless SQL query engine for your data lake',
  routerLink: '/wizards/aws-athena',
  logoPath: vendors.awsAthena,
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com/en/'
  },
  vendor: {
    text: 'aws.amazon.com/athena',
    uri: 'https://docs.aws.amazon.com/athena/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'integration-name',
        'save'
      ]
    }
  },
  includeRegions: false
};

export const destinationRedshift: Integration = {
  productId: productIdAwsRedshift,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'AWS Redshift',
  badge: 'none',
  type: 'destination',
  class: 'Data Warehouse',
  sortOrder: 2200,
  tagLine: 'A fast, petabyte scale cloud first data warehouse for analytics and data science',
  routerLink: '/wizards/aws-redshift',
  logoPath: vendors.awsRedshift,
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com/en/'
  },
  vendor: {
    text: 'aws.amazon.com/redshift',
    uri: 'https://docs.aws.amazon.com/redshift/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'integration-name',
        'save'
      ]
    }
  },
  includeRegions: false
};

export const destinationRedshiftManaged: Integration = {
  productId: productIdAwsRedshiftManaged,
  subProductId: "default",
  enabled: false,
  primary: true,  
  comingSoon: false,
  isConfigurable: false,
  brand: 'Amazon',
  name: 'AWS Redshift Managed',
  badge: 'none',
  type: 'destination',
  class: 'Data Warehouse',
  sortOrder: 2200,
  tagLine: 'A fast, petabyte scale cloud first data warehouse for analytics and data science',
  routerLink: '/wizards/aws-redshift-managed',
  logoPath: vendors.awsRedshift,
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com/en/'
  },
  vendor: {
    text: 'aws.amazon.com/redshift',
    uri: 'https://docs.aws.amazon.com/redshift/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'integration-name',
        'save'
      ]
    }
  },
  includeRegions: false
};

export const destinationRedshiftSpectrum: Integration ={
  productId: productIdAwsRedshiftSpectrum,
  subProductId: "default",
  enabled: false,
  primary: true,  
  comingSoon: true,
  isConfigurable: false,
  brand: 'Amazon',
  name: 'AWS Redshift Spectrum',
  badge: 'none',
  type: 'destination',
  class: 'Data Lake',
  sortOrder: 2300,
  tagLine: 'Extend Redshift with an on-demand SQL query engine your Amazon data lake',
  routerLink: '/wizards/aws-spectrum',
  logoPath: vendors.awsSpectrum,
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com/en/'
  },
  vendor: {
    text: 'aws.amazon.com/spectrum',
    uri: 'https://docs.aws.amazon.com/redshift/latest/dg/c-getting-started-using-spectrum.html'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'integration-name',
        'save'
      ]
    }
  },
  includeRegions: false
};

export const destinationGoogleBigQuery: Integration = {
  productId: productIdGoogleBigquery,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Google',
  name: 'Google BigQuery',
  badge: 'none',
  type: 'destination',
  class: 'Data Warehouse',
  sortOrder: 2600,
  tagLine: 'Serverless, on-demand enterprise class data warehouse with pay-as-you-go pricing',
  routerLink: '/wizards/google-bigquery',
  logoPath: vendors.googleBigquery,
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  remoteIdentityTypeId: 12,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com/en/'
  },
  vendor: {
    text: 'google.com/bigquery',
    uri: 'https://cloud.google.com/bigquery/docs'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false
};

export const destinationAzureDataLake: Integration = {
  productId: productIdAzureDataLake,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Azure',
  name: 'Azure Data Lake',
  badge: 'none',
  type: 'destination',
  class: 'Data Lake',
  sortOrder: 2500,
  tagLine: 'No-limits, low-cost, Azure data lake offers cost-effective, easy access storage environment',
  routerLink: '/wizards/azure-data-lake',
  logoPath: vendors.azureDataLake,
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com/en/'
  },
  vendor: {
    text: 'docs.microsoft.com/adls-gen2',
    uri: 'https://docs.microsoft.com/en-us/azure/databricks/data/data-sources/azure/adls-gen2/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'integration-name',
        'save'
      ]
    }
  },
  includeRegions: false
};

export const destinationAzureBlobStorage: Integration = {
  productId: productIdAzureBlobStorage,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Azure',
  badge: 'none',
  name: 'Azure Blob Storage',
  type: 'destination',
  class: 'Data Lake',
  sortOrder: 2400,
  tagLine: 'Basic, durable, and cost-effective data storage for structured and unstructured data',
  routerLink: '/wizards/azure-blob-storage',
  logoPath: vendors.azureBlobStorage,
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com/en/'
  },
  vendor: {
    text: 'docs.microsoft.com/storage/blobs',
    uri: 'https://docs.microsoft.com/en-us/azure/storage/blobs/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'integration-name',
        'save'
      ]
    }
  },
  includeRegions: false
};

export const destinationSnowflakeWarehouse: Integration = {
  productId: productIdSnowflakeWarehouse,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Snowflake',
  name: 'Snowflake Warehouse',
  badge: 'none',
  type: 'destination',
  class: 'Data Warehouse',
  sortOrder: 2700,
  tagLine: 'Fast, reliable, and secure data warehouse with cost-effective pay-as-you-go pricing',
  routerLink: '/wizards/snowflake',
  logoPath: vendors.snowflake,
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com/en/'
  },
  vendor: {
    text: 'docs.snowflake.com',
    uri: 'https://docs.snowflake.com/en/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'integration-name',
        'save'
      ]
    }
  },
  includeRegions: false
};

export const destinationDatabricksExternal: Integration = {
  productId: productIdDataBricksExternal,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Databricks',
  name: 'Databricks Lakehouse',
  badge: 'none',
  type: 'destination',
  class: 'Data Warehouse',
  sortOrder: 2800,
  tagLine: 'Fast, reliable, and secure data warehouse with cost-effective pay-as-you-go pricing',
  routerLink: '/wizards/databricks-lakehouse',
  logoPath: vendors.dataBricks,
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com/en/'
  },
  vendor: {
    text: 'docs.databricks.com/lakehouse/',
    uri: 'https://docs.databricks.com/lakehouse/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'integration-name',
        'save'
      ]
    }
  },
  includeRegions: false
};

export const destinationMultiStorage: Integration = {
  productId: productIdMultiStorage,
  subProductId: "default",
  enabled: false,
  primary: true,  
  comingSoon: false,
  isConfigurable: false,
  brand: 'MultiStorage',
  name: 'Openbridge Multistorage',
  badge: 'none',
  type: 'destination',
  class: 'Data Warehouse',
  sortOrder: 2800,
  tagLine: 'Fast, reliable, and secure data warehouse with cost-effective pay-as-you-go pricing',
  routerLink: '/wizards/openbridge-multistorage',
  logoPath: vendors.openbridgeMultiStorage,
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com/en/'
  },
  vendor: {
    text: 'docs.databricks.com/lakehouse/',
    uri: 'https://docs.databricks.com/lakehouse/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'integration-name',
        'save'
      ]
    }
  },
  includeRegions: false
};

export const destinations = [
  destinationAwsAthena,
  destinationAzureBlobStorage,
  destinationAzureDataLake,
  destinationDatabricksExternal,
  destinationGoogleBigQuery,
  destinationMultiStorage,
  destinationRedshift,
  destinationRedshiftManaged,
  destinationRedshiftSpectrum,
  destinationSnowflakeWarehouse,
];