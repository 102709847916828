export const responseAthenaSpm = {
  links: {
    first: 'https://subscriptions.api.dev.openbridge.io/spm?page=1&subscription=165',
    last: 'https://subscriptions.api.dev.openbridge.io/spm?page=1&subscription=165',
    next: '',
    prev: ''
  },
  data: [
    {
      type: 'SubscriptionProductMeta',
      id: '779',
      attributes: {
        data_id: 0,
        data_key: 'athenaRegion',
        data_value: 'us-east-1',
        created_at: '2021-12-10T07:24:27.136555',
        modified_at: '2021-12-10T07:24:27.136568',
        product: {
          id: 31,
          active: 1,
          name: 'Amazon Athena',
          summary: '',
          name_slug: 'amazon-athena',
          created_at: '2017-11-28T05:00:00',
          modified_at: '2017-11-28T05:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'amazon-athena',
          allow_as_trial: 1,
          is_storage_product: 1,
          initialize_job: 0,
          premium_product: 0,
          worker_name: null,
          product_group: 4,
          remote_identity_type: null,
          product_category: 6,
          parent_product: null
        },
        subscription: {
          id: 165,
          price: 0,
          status: 'active',
          date_start: '2021-12-10T07:24:24',
          date_end: '2021-12-10T07:24:24',
          auto_renew: 1,
          created_at: '2021-12-10T07:24:27.132305',
          modified_at: '2021-12-10T08:20:55.649222',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'aws-athena-test',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 31,
          product_plan: null,
          remote_identity: null,
          storage_group: 110,
          user: 309
        }
      }
    },
    {
      type: 'SubscriptionProductMeta',
      id: '780',
      attributes: {
        data_id: 0,
        data_key: 'athenaBucket',
        data_value: 'netimp-openrbridge-athena',
        created_at: '2021-12-10T07:24:27.143191',
        modified_at: '2021-12-10T07:24:27.143205',
        product: {
          id: 31,
          active: 1,
          name: 'Amazon Athena',
          summary: '',
          name_slug: 'amazon-athena',
          created_at: '2017-11-28T05:00:00',
          modified_at: '2017-11-28T05:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'amazon-athena',
          allow_as_trial: 1,
          is_storage_product: 1,
          initialize_job: 0,
          premium_product: 0,
          worker_name: null,
          product_group: 4,
          remote_identity_type: null,
          product_category: 6,
          parent_product: null
        },
        subscription: {
          id: 165,
          price: 0,
          status: 'active',
          date_start: '2021-12-10T07:24:24',
          date_end: '2021-12-10T07:24:24',
          auto_renew: 1,
          created_at: '2021-12-10T07:24:27.132305',
          modified_at: '2021-12-10T08:20:55.649222',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'aws-athena-test',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 31,
          product_plan: null,
          remote_identity: null,
          storage_group: 110,
          user: 309
        }
      }
    },
    {
      type: 'SubscriptionProductMeta',
      id: '781',
      attributes: {
        data_id: 0,
        data_key: 'athenaDatabase',
        data_value: 'db001',
        created_at: '2021-12-10T07:24:27.145936',
        modified_at: '2021-12-10T07:24:27.145949',
        product: {
          id: 31,
          active: 1,
          name: 'Amazon Athena',
          summary: '',
          name_slug: 'amazon-athena',
          created_at: '2017-11-28T05:00:00',
          modified_at: '2017-11-28T05:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'amazon-athena',
          allow_as_trial: 1,
          is_storage_product: 1,
          initialize_job: 0,
          premium_product: 0,
          worker_name: null,
          product_group: 4,
          remote_identity_type: null,
          product_category: 6,
          parent_product: null
        },
        subscription: {
          id: 165,
          price: 0,
          status: 'active',
          date_start: '2021-12-10T07:24:24',
          date_end: '2021-12-10T07:24:24',
          auto_renew: 1,
          created_at: '2021-12-10T07:24:27.132305',
          modified_at: '2021-12-10T08:20:55.649222',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'aws-athena-test',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 31,
          product_plan: null,
          remote_identity: null,
          storage_group: 110,
          user: 309
        }
      }
    },
    {
      type: 'SubscriptionProductMeta',
      id: '782',
      attributes: {
        data_id: 0,
        data_key: 'athenaAccessKeyId',
        data_value: 'AKIARYAEKOUBUBKJQCVH',
        created_at: '2021-12-10T07:24:27.148511',
        modified_at: '2021-12-10T07:24:27.148523',
        product: {
          id: 31,
          active: 1,
          name: 'Amazon Athena',
          summary: '',
          name_slug: 'amazon-athena',
          created_at: '2017-11-28T05:00:00',
          modified_at: '2017-11-28T05:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'amazon-athena',
          allow_as_trial: 1,
          is_storage_product: 1,
          initialize_job: 0,
          premium_product: 0,
          worker_name: null,
          product_group: 4,
          remote_identity_type: null,
          product_category: 6,
          parent_product: null
        },
        subscription: {
          id: 165,
          price: 0,
          status: 'active',
          date_start: '2021-12-10T07:24:24',
          date_end: '2021-12-10T07:24:24',
          auto_renew: 1,
          created_at: '2021-12-10T07:24:27.132305',
          modified_at: '2021-12-10T08:20:55.649222',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'aws-athena-test',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 31,
          product_plan: null,
          remote_identity: null,
          storage_group: 110,
          user: 309
        }
      }
    },
    {
      type: 'SubscriptionProductMeta',
      id: '783',
      attributes: {
        data_id: 0,
        data_key: 'athenaSecretAccessKey',
        data_value: 'awsAthenaSecretKey',
        created_at: '2021-12-10T07:24:27.151845',
        modified_at: '2021-12-10T07:24:27.151856',
        product: {
          id: 31,
          active: 1,
          name: 'Amazon Athena',
          summary: '',
          name_slug: 'amazon-athena',
          created_at: '2017-11-28T05:00:00',
          modified_at: '2017-11-28T05:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'amazon-athena',
          allow_as_trial: 1,
          is_storage_product: 1,
          initialize_job: 0,
          premium_product: 0,
          worker_name: null,
          product_group: 4,
          remote_identity_type: null,
          product_category: 6,
          parent_product: null
        },
        subscription: {
          id: 165,
          price: 0,
          status: 'active',
          date_start: '2021-12-10T07:24:24',
          date_end: '2021-12-10T07:24:24',
          auto_renew: 1,
          created_at: '2021-12-10T07:24:27.132305',
          modified_at: '2021-12-10T08:20:55.649222',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'aws-athena-test',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 31,
          product_plan: null,
          remote_identity: null,
          storage_group: 110,
          user: 309
        }
      }
    }
  ],
  meta: {
    pagination: {
      page: 1,
      pages: 1,
      count: 5
    }
  }
};