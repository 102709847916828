import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  loadingModalManager$: Subject<string> = new Subject<string>();

  constructor() { }

  openLoadingModal(): void {
    this.loadingModalManager$.next('open');
  }

  closeLoadingModal(): void {
    this.loadingModalManager$.next('close');
  }

}
