export const responseAmazonDspEditSubscription = {
  data: {
    type: 'Subscription',
    id: '761',
    attributes: {
      price: 0,
      status: 'active',
      date_start: '2022-05-09T05:35:55',
      date_end: '2022-05-09T05:35:55',
      auto_renew: 1,
      created_at: '2022-05-09T05:27:37.600805',
      modified_at: '2022-05-09T05:36:00.064909',
      quantity: 1,
      stripe_subscription_id: '',
      name: 'amazon-dsp-test',
      rabbit_payload_successful: 0,
      primary_job_id: null,
      pipeline: null,
      invalid_subscription: 0,
      invalidated_at: null,
      notified_at: null,
      canonical_name: null,
      account_id: 342,
      product_id: 54,
      product_plan_id: null,
      remote_identity_id: 22,
      storage_group_id: 110,
      user_id: 309,
      history_requested: 0,
      unique_hash: null
    },
    relationships: {
      account: {
        data: {
          type: 'Account',
          id: '342'
        }
      },
      product: {
        data: {
          type: 'Product',
          id: '54'
        }
      },
      product_plan: {
        data: null
      },
      remote_identity: {
        data: {
          type: 'RemoteIdentity',
          id: '22'
        }
      },
      storage_group: {
        data: {
          type: 'StorageGroup',
          id: '110'
        }
      },
      user: {
        data: {
          type: 'User',
          id: '309'
        }
      }
    }
  },
  included: [
    {
      type: 'StorageGroup',
      id: '110',
      attributes: {
        product_id: 31,
        name: 'aws-athena-test',
        key_name: '85e4f28214fd8834-aws-athena-test'
      }
    }
  ]
};