import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from 'src/app/core/store/reducers/auth.reducer';

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const authenticated = createSelector(
    selectAuthState,
    auth => (auth.profile != null)
);

export const apiUserRole = createSelector(
    selectAuthState,
    auth => {
        if (auth.profile != null && auth.profile['https://openbridge.com/roles'].includes('api-user')) {
            return true;
        }
        return false;
    }
);

export const openbridgeSupportUserRole = createSelector(
    selectAuthState,
    auth => {
        if (auth.profile != null && auth.profile['https://openbridge.com/roles'].includes('openbridge-support')) {
            return true;
        }
        return false;
    }
);

export const authTokenExpired = createSelector(
    selectAuthState,
    auth => {
        const now = new Date().getTime();
        return auth.expiresAt;
    }
);
