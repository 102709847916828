import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from '../store/reducers';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TemplateProcessorService {

  destinationSubscription: Subscription[]
  sftpHost = environment.sftp.host;

  constructor(
    private httpClient: HttpClient,
    private store$: Store<AppState>
  ) {}


  async generateBatchInstructions(input: any): Promise<string> {
    try {
      this.store$.select('subscriptions').pipe(take(1)).subscribe(response => {
        this.destinationSubscription = response.subscriptions.filter(subscription => {
          if(subscription.isStorage === true && parseInt(input.destination, 10) === subscription.storageGroupId) {
            return subscription;
          }
          return null; 
        });
      });      

      const response = await this.httpClient.get(window.origin + '/assets/text/template-batch-instructions.txt', { responseType: 'text' }).toPromise();

      const tableName = (input.tableName + '_' + input.tableUUID);
      const tablePath = input.storageGroupKeyName + '/' + input.username + '/' + tableName;

      let template = response;
      template = template.replace(new RegExp('{{ host }}', 'g'), this.sftpHost)
      template = template.replace(new RegExp('{{ username }}', 'g'), input.username)
      template = template.replace(new RegExp('{{ password }}', 'g'), input.password)
      template = template.replace(new RegExp('{{ table }}', 'g'), tableName);
      template = template.replace(new RegExp('{{ table_path }}', 'g'), tablePath);
      template = template.replace(new RegExp('{{ destination_name }}','g'), this.destinationSubscription[0]['name']);
      return template;
    }
    catch(error) {
      console.error(error);
    }
  }

}
