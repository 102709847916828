import { createAction, props } from '@ngrx/store';

export const oauthUpdate = createAction('[Account] Update Account',
  props<any>()
);

export const oauthPreviousPage = createAction('[OauthWizard] Previous Page');

export const oauthNextPage = createAction('[OauthWizard] Next Page',
  props<PropStage>()
);

export const oauthAppIdUpdate = createAction('[OauthWizard] App Credentials',
  props<PayloadPropCredentials>()
);

export const oauthUpdateExtraParams = createAction('[OauthWizard] Extra Params',
  props<PropExtraParams>()
);

export interface PropCredentials  {
  remoteIdentityAppId: number;
}

export interface PayloadPropCredentials {
  config: PropCredentials;
}

export interface PropStage {
  stageName: string;
}

export interface PropExtraParams  {
  extraParams: object;
}
