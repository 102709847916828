import { createAction, props } from '@ngrx/store';

export const flashNotificationsFormValidationError = createAction('[Flash Notifications] Flash Error',
  props<{message: string }>()
);

export const flashNotificationsFormValidationErrorWithDetails = createAction('[Flash Notifications] Flash Error with Details',
  props<{message: string, details: string }>()
);


export const flashNotificationsFormValidationSuccess = createAction('[Flash Notifications] Form Validation Success',
  props<{message: string }>()
);

export const flashNotificationsClear = createAction('[Flash Notifications] Clear Notifications');

export const triggerToastNotification = createAction('[Flash Notifications] Trigger Toast Notification',
props<{toastType: string, message: string, title: string | null }>()
);
