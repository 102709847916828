import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppService } from './app.service';
import { modalNonUserClosable } from './shared/constants/component-configs';
import { LastUpdatedCheckService } from './core/services/last-updated-check.service';
import { ModalSiteUpdateRefreshComponent } from './shared/components/modals/modal-site-update-refresh/modal-site-update-refresh.component';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from './core/store/reducers';
import { AccountService } from './core/services/account.service';
import { authUserLogout } from './core/store/actions/auth.actions';
import { GlobalErrorModalService } from './core/services/global-error-modal.service';
import { IntercomService } from './shared/services/intercom.service';
import { NavigationStart, Router } from '@angular/router';
import { ReportingService } from './modules/reporting/reporting.service';
import { wizardIntegrationInit } from 'src/app/core/store/actions/wizard.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: WindowEventHandlers) {
    const intercomShouldShowTabCloseNotification = this.intercomService.shouldShowTabCloseNotification(this.router.url);
    const reportingShouldShowTabCloseNotification = this.reportingService.shouldShowTabCloseNotification(this.router);

    // If either are true then return false
    if(intercomShouldShowTabCloseNotification || reportingShouldShowTabCloseNotification) {
      return false;
    }

    return true;
  }

  maintenanceModeEnabled: boolean = environment.maintenanceMode;
  bsModalRef: BsModalRef = null;
  loading: false;
  title = 'console-app';
  tokenCheckInterval: any = null;
  versionCheckInterval: any = null;
  lastUpdated = null;
  tokenExpiresAt: number = null;

  /**
   * IMPORTANT NOTE.  The AuthService below even though not used by code in this file is required
   * for the site to load correctly.  Removing it will break the site.
   */
  constructor(
    private accountService: AccountService,
    protected appService: AppService,
    public auth: AuthService,
    protected lastUpdatedService: LastUpdatedCheckService,
    protected modalService: BsModalService,
    protected httpClient: HttpClient,
    protected store$: Store<AppState>,
    // Subscribe to Auth0 Errors
    private globalErrorService: GlobalErrorModalService,
    private intercomService: IntercomService,
    private reportingService: ReportingService,
    private router: Router

  ) { }

  async ngOnInit(): Promise<void> {

    this.detectRoute();

    this.auth.error$.subscribe(error => {
      this.globalErrorService.generateGlobalError(error);
    });

    // load intercom
    this.intercomService.loadIntercom();

    this.startVersionCheck();
    this.startTokenValidityCheck();

    this.store$.select('reporting').subscribe(async response => {
      for (let i = 0; i < response.reportStates.length ; i++) {
        const reportingState = response.reportStates[i];
        if(reportingState.stage === 'initialized') {
          await this.reportingService.initializeReport(i, reportingState);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.intercomService.shutdownIntercom();
  }

  logout(): void {
    localStorage.removeItem('proxy');
    this.auth.logout();
  }

  private startVersionCheck(): void {

    this.lastUpdatedService.setLastUpdated();

    const intervalTiming = (environment.siteUpdate.versionCheckIntervalLengthInMinutes * 1000 * 60);
    this.versionCheckInterval = setInterval(async () => {
      if (await this.lastUpdatedService.serverVersionIsDifferent()) {
        this.bsModalRef = this.modalService.show(ModalSiteUpdateRefreshComponent, modalNonUserClosable);
        clearInterval(this.versionCheckInterval);
        this.versionCheckInterval = null;
      }
    }, intervalTiming);
  }

  private startTokenValidityCheck(): void {

    if (environment.enableMock !== true) {
      this.store$.select('auth').subscribe(response => {
        if (response.expiresAt != null) {
          this.tokenExpiresAt = response.expiresAt;
        }
      });

      this.tokenCheckInterval = setInterval(async () => {
        if (this.tokenExpiresAt != null) {
          const now = Math.floor(new Date().getTime() / 1000);
          const difference = this.tokenExpiresAt - now;

          if (difference <= 10) {
            this.store$.dispatch(authUserLogout());
            this.auth.logout();
          }
          else if (difference < 100) {
            this.accountService.updateAccountApiToken();
          }
        }
      }, 20000);
    }
  }

  private detectRoute() {
    this.router.events.subscribe((event: any) => {
      this.intercomService.detectRouteChange(this.router.url, event);
    
      // This is similar code as for intercom.
      // However this is used to clear the wizard ngrx data
      // when they leave a wizard
      // I will leave it here for now, not sure where it should live.
      // Maybe put it in an NGRX effect?  maybe?
      if (event instanceof NavigationStart) {
        const previousPageRoute = this.router.url;
        const currentPageRoute = event.url;
        let isAbandon = false;
  
        if (previousPageRoute.includes('wizards')
          && !previousPageRoute.includes('complete')
          && !currentPageRoute.includes('wizards')) {
          if (!(previousPageRoute.includes('wizards') && currentPageRoute.includes('identity'))) {
            isAbandon = true;
          }
        }
  
        if (isAbandon) {
          this.store$.dispatch(wizardIntegrationInit({
            routerLink: null,
            stage: null,
            config: null
          }));
        }
      }
    
    
    
    
    
    
    
    
    });
  }

}