export const responseDataLakeAccountMappingEncryption = {
  data: {
    attributes: {
      is_temp: true,
      container: 'test',
      connection_string: {
        encrypt: 'kms',
        value: 'AQICAHjIV9pasxFCwcl96XQ9T7SI448IVp1d2JwsMZkDhJARcwFvmCyPt6fXBKlpghbXlr41AAABKDCCASQGCSqGSIb3DQEHBqCCARUwggERAgEAMIIBCgYJKoZIhvcNAQcBMB4GCWCGSAFlAwQBLjARBAxcYMenv4I022dVZqMCARCAgdzVwrUk2p1NVXmqfy5lfdOlQxVQd7eSukO/nle3x+6PWtT/oxLiq3xQNwdaS09qFAkvLXEkyg7j87ymq6tDHiBtDFlYloDNqjlyt7YlhZdgfcLbfU4/CFgr+k0pDEZK66IYTrRFeXqMfcttPNqCQyV+MlWrRSasiIbuIExLhwJN6ISoJPIylhx6s79Hv94LelI1aixDJdb1TvVhqkJ4a9AWSjy6clOAAmcHBg/+EnAA7kuwQBsmLvuoloDBDeOdeYXvOBzdgK7Eyk1WQDhKJ4qKZZbZTm3o5o0vLlm1'
      },
      path: '/ebs/ftpd/test-1640084082099-000001',
      region: 'us-east-1',
      s3_bucket: 'ob-zeroadmin-stage',
      storage: 'azure_datalake'
    }
  }
};