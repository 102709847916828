
// For production
export const productionTrialPlan = 'price_1KsRRcDJfvhvVHhndDbOhkuT';
export const productionPlanStandard = 'price_1KKNQNDJfvhvVHhnZmhCtgdF';
export const productionPlanPremium = 'price_1KKNReDJfvhvVHhndSzVR832';
export const productionPlanBusiness = 'price_1KKNUEDJfvhvVHhnYFroRzMY';

// For dev and staging
export const testTrialPlan = 'price_1KsRTADJfvhvVHhnyqPMMCcL';
export const testPlanStandard = 'price_1KUANbDJfvhvVHhnsvsfst5u';
export const testPlanPremium = 'price_1KUAPzDJfvhvVHhndb5EX4Fv';
export const testPlanBusiness = 'price_1KUAU5DJfvhvVHhn9dUoOYqd';