import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { authAccountToggle, authUserLogin, authUserLogout } from '../actions/auth.actions';
import { StripeService } from 'src/app/shared/services/stripe.service';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { AccountService } from 'src/app/core/services/account.service';
import { CacheService } from 'src/app/core/services/cache.service';
import { stripeCustomerSet } from '../actions/stripe.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { subscriptionsSetCacheKey } from '../actions/subscriptions.actions';
import { historyParamsRequest } from '../actions/history.actions';
import { identitiesGet, identitySetCacheKey } from '../actions/identities.actions';
import { dailyPipelinesGet } from '../actions/account.actions';
import { GlobalErrorModalService } from 'src/app/core/services/global-error-modal.service';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';
import { modalsOpen, modalsUpdate } from '../actions/modals.action';
import { intercomBoot } from '../actions/intercom.actions';
import { delay } from '../../functions/utility';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private stripeService: StripeService,
    private store$: Store<AppState>,
    private cacheService: CacheService,
    private accountService: AccountService,
    private globalErrorModalService: GlobalErrorModalService,
    private appService: AppService,
    private activatedRoute: ActivatedRoute
  ) { }


  login$ = createEffect(() => this.actions$
    .pipe(
      ofType(authUserLogin),
      tap(async action => {

        await delay(500);

        this.store$.dispatch(modalsOpen({
          modalType: 'progress',
          title: 'Account',
          message: 'Validating authentication',
          progress: 25
        }));
        const currentAccount = await this.accountService.getAccountUserDetails(action.profile);

        const condition = (action.profile['given_name'] && action.profile['family_name']);
        const givenAndFamilyName = action.profile['given_name'] + ' ' + action.profile['family_name'];
        const nickName = action.profile['nickname'];
        const filteredName = condition ? givenAndFamilyName : nickName;

        // Initially boot intercom
        this.store$.dispatch(intercomBoot({
          email: action.profile['email'],
          emailVerified: action.profile['email_verified'],
          name: filteredName,
          userId: currentAccount.userId,
          accountId: currentAccount.accountId,
        }));
        this.store$.dispatch(authAccountToggle({ currentAccount: currentAccount }));
      })
    ),
    { dispatch: false }
  );

  logout$ = createEffect(() => this.actions$
    .pipe(
      ofType(authUserLogout),
      tap(async action => {
        this.router.navigateByUrl('/');
      })
    ),
    { dispatch: false }
  );

  accountToggle$ = createEffect(() => this.actions$
    .pipe(
      ofType(authAccountToggle),
      tap(async action => {
        const currentAccount = action.currentAccount;
        try {

          const stripeCustomerId = currentAccount.stripeCustomerId;
          const identityCacheKey = stripeCustomerId + environment.cache.identitiesKey;
          const subscriptionCacheKey = stripeCustomerId + environment.cache.subscriptionsKey;
          const lastLoginAtCacheKey = stripeCustomerId + environment.cache.lastLoginKey;
          const historyRequestCacheKey = stripeCustomerId + environment.cache.lastLoginKey;

          this.store$.dispatch(identitySetCacheKey({ cacheKey: identityCacheKey }));
          this.store$.dispatch(subscriptionsSetCacheKey({ cacheKey: subscriptionCacheKey }));

          await delay(500);

          this.store$.dispatch(modalsUpdate({
            modalType: 'progress',
            title: 'Account',
            message: 'Retrieving Account information',
            progress: 50
          }));

          const stripeCustomer = await this.stripeService.retrieveStripeCustomer(stripeCustomerId);
          this.store$.dispatch(stripeCustomerSet({ stripeCustomer: stripeCustomer['data'] }));

          const subscriptionCachedEpoch = this.cacheService.getLastModifiedEpochForKey(subscriptionCacheKey);
          const identityCachedEpoch = this.cacheService.getLastModifiedEpochForKey(identityCacheKey);

          // See if there are deltas from the API
          // tslint:disable-next-line: max-line-length
          const hasSubscriptionDeltas = await this.accountService.hasDeltas('subscription', currentAccount.accountId, subscriptionCachedEpoch);
          const hasIdentityDeltas = await this.accountService.hasDeltas('identity', currentAccount.accountId, identityCachedEpoch);

          const forceCacheDeletion = this.cacheService.forceCacheDeletion(lastLoginAtCacheKey);

          console.info('Identity Delta Cache Deletion: ', ((hasIdentityDeltas) ? 'YES' : 'NO'));
          console.info('Subscription Delta Cache Deletion: ', ((hasSubscriptionDeltas) ? 'YES' : 'NO'));
          console.info('Force Cache Deletion: ', ((forceCacheDeletion) ? 'YES' : 'NO'));

          // Delete old unused cache keys if they exist.
          this.cacheService.removeCache('local', 'list-identities');
          this.cacheService.removeCache('local', 'list-subscriptions');

          if (hasIdentityDeltas || forceCacheDeletion) {
            console.info('Deleting Identity List Cache');
            this.cacheService.removeCache('local', identityCacheKey);
          }
          else if (this.shouldClearIdentitiesCache()) {
            this.cacheService.removeCache('local', identityCacheKey);
          }

          if (hasSubscriptionDeltas || forceCacheDeletion) {
            console.info('Deleting Subscription List Cache');
            this.cacheService.removeCache('local', subscriptionCacheKey);
          }

          // Needs to set after all subscription and identify caching has happened.
          const currentEpoch = Math.floor((new Date().getTime()) / 1000);
          this.cacheService.removeCache('local', lastLoginAtCacheKey);
          this.cacheService.setCache('local', lastLoginAtCacheKey, currentEpoch);

          if (hasSubscriptionDeltas || hasIdentityDeltas || forceCacheDeletion) {
            this.store$.dispatch(modalsUpdate({
              modalType: 'progress',
              title: 'Account',
              message: 'Deltas found, updating cached information.',
              progress: 20
            }));
          }

          this.store$.dispatch(identitiesGet({ identityCacheKey: identityCacheKey, subscriptionCacheKey: subscriptionCacheKey }));
          this.store$.dispatch(historyParamsRequest());
          this.store$.dispatch(dailyPipelinesGet());
        }
        catch (error) {
          this.globalErrorModalService.generateGlobalError(error);
        }
      })
    ),
    { dispatch: false }
  );

  private shouldClearIdentitiesCache(): boolean {

    const reauth = this.activatedRoute.snapshot.queryParamMap.get('reauth') || '';
    const state = this.activatedRoute.snapshot.queryParamMap.get('reauth') || '';
    if (state !== '' && reauth !== '') {
      return true;
    }
    return false;
  }
}

export interface CurrentAccount {
  accountId: number | null;
  stripeCustomerId: string;
  userId: number | null;
}