export const responseBlobAccountMappingEncryption = {
  data: {
    attributes: {
      is_temp: true,
      container: 'test',
      connection_string: {
        encrypt: 'kms',
        value: 'AQICAHjIV9pasxFCwcl96XQ9T7SI448IVp1d2JwsMZkDhJARcwEnfNHFisVVEmGO0WK/h/7oAAABJDCCASAGCSqGSIb3DQEHBqCCAREwggENAgEAMIIBBgYJKoZIhvcNAQcBMB4GCWCGSAFlAwQBLjARBAyT+4kiTH60aLDuVCUCARCAgdhSFpCK8mKwF9WCkLBIset/WRkebxd9TGVPGPcEZD+bWl7CLRu/wVdQQeP5ZxNmzlwXmvQwsy0mx8jbx4bfjfKSox+FLxwBO7/8qk+rn5i5fSkAbTTdnWjaAHRh+nTOFsNZOwVJqwzDtRaq8bDp8xWYGE4n8zrsb4/fqy2hMYj/s/wbTMYBKHqt8lNfMWGCjHRkOyffXih9DziifefgTAviSM/aggCVQBHSo3gdbndrQq2QhTARS0nW9vtFSOnG366hVZ3J+mOtA4yenZsRRsIzNDZsPPyYZ8o='
      },
      path: '/ebs/ftpd/test-1640068794020-000001',
      region: 'us-east-1',
      s3_bucket: 'ob-zeroadmin-stage',
      storage: 'azure_blob'
    }
  }
};