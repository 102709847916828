export const responseRedshiftStorage = {
  data: {
    attributes: {
      accmapping_id: 1010,
      account_id: 'test-1641559713622-000001',
      created_at: '2022-01-07 12:48:41.612020',
      error_code: null,
      message: '',
      modified_at: '2022-01-07 12:48:41.825544',
      path: '/ebs/ftpd/test-1641559713622-000001/storage_validation',
      status: 'ACTIVE',
      storage_type: 'redshift'
    },
    id: 1016
  }
};