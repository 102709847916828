export const responseAmazonAdvertizingSpm = {
  links: {
    first: 'https://subscriptions.api.dev.openbridge.io/spm?page=1&subscription=628',
    last: 'https://subscriptions.api.dev.openbridge.io/spm?page=1&subscription=628',
    next: '',
    prev: ''
  },
  data: [
    {
      type: 'SubscriptionProductMeta',
      id: '1519',
      attributes: {
        data_id: 0,
        data_key: 'remote_identity_id',
        data_value: '22',
        created_at: '2022-05-03T10:17:35.298824',
        modified_at: '2022-05-03T10:17:35.298844',
        product: {
          id: 40,
          active: 1,
          name: 'Amazon Advertising Insights',
          summary: '',
          name_slug: 'seller-advertising-insights',
          created_at: '2019-03-29T04:00:00',
          modified_at: '2019-03-29T04:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'seller-advertising-insights',
          allow_as_trial: 1,
          is_storage_product: 0,
          initialize_job: 1,
          premium_product: 0,
          worker_name: 'amzadvertising',
          product_group: 1,
          remote_identity_type: 14,
          product_category: 1,
          parent_product: null
        },
        subscription: {
          id: 628,
          price: 0,
          status: 'active',
          date_start: '2022-05-03T10:17:31',
          date_end: '2022-05-03T10:17:31',
          auto_renew: 1,
          created_at: '2022-05-03T10:17:35.279010',
          modified_at: '2022-05-03T10:17:35.279026',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'amazon-adv-test',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 40,
          product_plan: null,
          remote_identity: 22,
          storage_group: 110,
          user: 309
        }
      }
    },
    {
      type: 'SubscriptionProductMeta',
      id: '1520',
      attributes: {
        data_id: 0,
        data_key: 'profile_id',
        data_value: '586011285459526',
        created_at: '2022-05-03T10:17:35.310761',
        modified_at: '2022-05-03T10:17:35.310782',
        product: {
          id: 40,
          active: 1,
          name: 'Amazon Advertising Insights',
          summary: '',
          name_slug: 'seller-advertising-insights',
          created_at: '2019-03-29T04:00:00',
          modified_at: '2019-03-29T04:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'seller-advertising-insights',
          allow_as_trial: 1,
          is_storage_product: 0,
          initialize_job: 1,
          premium_product: 0,
          worker_name: 'amzadvertising',
          product_group: 1,
          remote_identity_type: 14,
          product_category: 1,
          parent_product: null
        },
        subscription: {
          id: 628,
          price: 0,
          status: 'active',
          date_start: '2022-05-03T10:17:31',
          date_end: '2022-05-03T10:17:31',
          auto_renew: 1,
          created_at: '2022-05-03T10:17:35.279010',
          modified_at: '2022-05-03T10:17:35.279026',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'amazon-adv-test',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 40,
          product_plan: null,
          remote_identity: 22,
          storage_group: 110,
          user: 309
        }
      }
    },
    {
      type: 'SubscriptionProductMeta',
      id: '1521',
      attributes: {
        data_id: 0,
        data_key: 'marketplace',
        data_value: 'US',
        created_at: '2022-05-03T10:17:35.316166',
        modified_at: '2022-05-03T10:17:35.316185',
        product: {
          id: 40,
          active: 1,
          name: 'Amazon Advertising Insights',
          summary: '',
          name_slug: 'seller-advertising-insights',
          created_at: '2019-03-29T04:00:00',
          modified_at: '2019-03-29T04:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'seller-advertising-insights',
          allow_as_trial: 1,
          is_storage_product: 0,
          initialize_job: 1,
          premium_product: 0,
          worker_name: 'amzadvertising',
          product_group: 1,
          remote_identity_type: 14,
          product_category: 1,
          parent_product: null
        },
        subscription: {
          id: 628,
          price: 0,
          status: 'active',
          date_start: '2022-05-03T10:17:31',
          date_end: '2022-05-03T10:17:31',
          auto_renew: 1,
          created_at: '2022-05-03T10:17:35.279010',
          modified_at: '2022-05-03T10:17:35.279026',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'amazon-adv-test',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 40,
          product_plan: null,
          remote_identity: 22,
          storage_group: 110,
          user: 309
        }
      }
    },
    {
      type: 'SubscriptionProductMeta',
      id: '1522',
      attributes: {
        data_id: 0,
        data_key: 'type',
        data_value: 'seller',
        created_at: '2022-05-03T10:17:35.321461',
        modified_at: '2022-05-03T10:17:35.321478',
        product: {
          id: 40,
          active: 1,
          name: 'Amazon Advertising Insights',
          summary: '',
          name_slug: 'seller-advertising-insights',
          created_at: '2019-03-29T04:00:00',
          modified_at: '2019-03-29T04:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'seller-advertising-insights',
          allow_as_trial: 1,
          is_storage_product: 0,
          initialize_job: 1,
          premium_product: 0,
          worker_name: 'amzadvertising',
          product_group: 1,
          remote_identity_type: 14,
          product_category: 1,
          parent_product: null
        },
        subscription: {
          id: 628,
          price: 0,
          status: 'active',
          date_start: '2022-05-03T10:17:31',
          date_end: '2022-05-03T10:17:31',
          auto_renew: 1,
          created_at: '2022-05-03T10:17:35.279010',
          modified_at: '2022-05-03T10:17:35.279026',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'amazon-adv-test',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 40,
          product_plan: null,
          remote_identity: 22,
          storage_group: 110,
          user: 309
        }
      }
    },
    {
      type: 'SubscriptionProductMeta',
      id: '1523',
      attributes: {
        data_id: 0,
        data_key: 'kdp_state',
        data_value: 'default',
        created_at: '2022-05-03T10:17:35.326713',
        modified_at: '2022-05-03T10:17:35.326729',
        product: {
          id: 40,
          active: 1,
          name: 'Amazon Advertising Insights',
          summary: '',
          name_slug: 'seller-advertising-insights',
          created_at: '2019-03-29T04:00:00',
          modified_at: '2019-03-29T04:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'seller-advertising-insights',
          allow_as_trial: 1,
          is_storage_product: 0,
          initialize_job: 1,
          premium_product: 0,
          worker_name: 'amzadvertising',
          product_group: 1,
          remote_identity_type: 14,
          product_category: 1,
          parent_product: null
        },
        subscription: {
          id: 628,
          price: 0,
          status: 'active',
          date_start: '2022-05-03T10:17:31',
          date_end: '2022-05-03T10:17:31',
          auto_renew: 1,
          created_at: '2022-05-03T10:17:35.279010',
          modified_at: '2022-05-03T10:17:35.279026',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'amazon-adv-test',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 40,
          product_plan: null,
          remote_identity: 22,
          storage_group: 110,
          user: 309
        }
      }
    }
  ],
  meta: {
    pagination: {
      page: 1,
      pages: 1,
      count: 5
    }
  }
};