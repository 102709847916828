export const responseGoogleAdsCustomerListManaged = {
  data: {
    manager: {
      id: '4156813945',
      name: 'Hasbro Global'
    },
    attributes: [
      {
        id: 9665639356,
        descriptive_name: 'Hasbro_Monopoly_GCC',
        currency_code: 'AED',
        time_zone: 'Asia/Dubai',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/9665639356'
      },
      {
        id: 9864594888,
        descriptive_name: 'Hasbro_Nerf_GCC',
        currency_code: 'AED',
        time_zone: 'Asia/Dubai',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/9864594888'
      },
      {
        id: 6479506295,
        descriptive_name: 'Hasbro_ClassicGames_AU',
        currency_code: 'AUD',
        time_zone: 'Australia/Sydney',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/6479506295'
      },
      {
        id: 9572639621,
        descriptive_name: 'Hasbro_Hasbro Gaming_AU_SEM',
        currency_code: 'AUD',
        time_zone: 'Australia/Sydney',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/9572639621'
      },
      {
        id: 8502380486,
        descriptive_name: 'Hasbro_Nerf_AU',
        currency_code: 'AUD',
        time_zone: 'Australia/Sydney',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/8502380486'
      },
      {
        id: 3354825742,
        descriptive_name: 'Hasbro_Transformers_AU',
        currency_code: 'AUD',
        time_zone: 'Australia/Sydney',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/3354825742'
      },
      {
        id: 1353561699,
        descriptive_name: 'Hasbro_Gaming Family_BG',
        currency_code: 'BGN',
        time_zone: 'Europe/Sofia',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/1353561699'
      },
      {
        id: 6644544182,
        descriptive_name: 'Hasbro_Monopoly_BG',
        currency_code: 'BGN',
        time_zone: 'Europe/Sofia',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/6644544182'
      },
      {
        id: 3353844043,
        descriptive_name: 'Hasbro_My Little Pony_BG',
        currency_code: 'BGN',
        time_zone: 'Europe/Sofia',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/3353844043'
      },
      {
        id: 1127458894,
        descriptive_name: 'Hasbro_Nerf_BG',
        currency_code: 'BGN',
        time_zone: 'Europe/Sofia',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/1127458894'
      },
      {
        id: 7188759831,
        descriptive_name: 'Hasbro_PlayDoh_BG',
        currency_code: 'BGN',
        time_zone: 'Europe/Sofia',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/7188759831'
      },
      {
        id: 1166559827,
        descriptive_name: 'Hasbro_Transformers_BG',
        currency_code: 'BGN',
        time_zone: 'Europe/Sofia',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/1166559827'
      },
      {
        id: 2947958045,
        descriptive_name: 'Blinks_Baby Alive',
        currency_code: 'BRL',
        time_zone: 'America/Sao_Paulo',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/2947958045'
      },
      {
        id: 3727402509,
        descriptive_name: 'Blinks_Beyblade',
        currency_code: 'BRL',
        time_zone: 'America/Sao_Paulo',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/3727402509'
      },
      {
        id: 9559263264,
        descriptive_name: 'Blinks_Disney Princess',
        currency_code: 'BRL',
        time_zone: 'America/Sao_Paulo',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/9559263264'
      },
      {
        id: 1940724592,
        descriptive_name: 'Blinks_Frozen',
        currency_code: 'BRL',
        time_zone: 'America/Sao_Paulo',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/1940724592'
      },
      {
        id: 6268429762,
        descriptive_name: 'Blinks_Hasbro_Institucional',
        currency_code: 'BRL',
        time_zone: 'America/Sao_Paulo',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/6268429762'
      },
      {
        id: 7125300637,
        descriptive_name: 'Blinks_Marvel',
        currency_code: 'BRL',
        time_zone: 'America/Sao_Paulo',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/7125300637'
      },
      {
        id: 2384593852,
        descriptive_name: 'Blinks_Nerf',
        currency_code: 'BRL',
        time_zone: 'America/Sao_Paulo',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/2384593852'
      },
      {
        id: 8615526980,
        descriptive_name: 'Blinks_Play-Doh',
        currency_code: 'BRL',
        time_zone: 'America/Sao_Paulo',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/8615526980'
      },
      {
        id: 5496817609,
        descriptive_name: 'Blinks_Power Rangers',
        currency_code: 'BRL',
        time_zone: 'America/Sao_Paulo',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/5496817609'
      },
      {
        id: 2467794691,
        descriptive_name: 'Blinks_Transformers',
        currency_code: 'BRL',
        time_zone: 'America/Sao_Paulo',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/2467794691'
      },
      {
        id: 4345586123,
        descriptive_name: 'Hasbro Gaming',
        currency_code: 'BRL',
        time_zone: 'America/Sao_Paulo',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/4345586123'
      },
      {
        id: 5383990854,
        descriptive_name: 'Hasbro_ Gaming_BR',
        currency_code: 'BRL',
        time_zone: 'America/Sao_Paulo',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/5383990854'
      },
      {
        id: 6449580648,
        descriptive_name: 'Hasbro_Baby Alive_BR',
        currency_code: 'BRL',
        time_zone: 'America/Sao_Paulo',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/6449580648'
      },
      {
        id: 5739357637,
        descriptive_name: 'Hasbro_Marvel_BR',
        currency_code: 'BRL',
        time_zone: 'America/Sao_Paulo',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/5739357637'
      },
      {
        id: 2228314996,
        descriptive_name: 'Hasbro_My Little Pony_BR',
        currency_code: 'BRL',
        time_zone: 'America/Sao_Paulo',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/2228314996'
      },
      {
        id: 6365877601,
        descriptive_name: 'Hasbro_Nerf_BR',
        currency_code: 'BRL',
        time_zone: 'America/Sao_Paulo',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/6365877601'
      },
      {
        id: 7747228323,
        descriptive_name: 'Hasbro_Play-Doh_BR',
        currency_code: 'BRL',
        time_zone: 'America/Sao_Paulo',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/7747228323'
      },
      {
        id: 8153320435,
        descriptive_name: 'Hasbro_Power Rangers_BR',
        currency_code: 'BRL',
        time_zone: 'America/Sao_Paulo',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/8153320435'
      },
      {
        id: 4766590084,
        descriptive_name: 'Hasbro_Power Rangers_MX',
        currency_code: 'BRL',
        time_zone: 'America/Sao_Paulo',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/4766590084'
      },
      {
        id: 3595616170,
        descriptive_name: 'Hasbro_Transformers_BR',
        currency_code: 'BRL',
        time_zone: 'America/Sao_Paulo',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/3595616170'
      },
      {
        id: 3347594864,
        descriptive_name: 'Hasbro_Transformers_MX',
        currency_code: 'BRL',
        time_zone: 'America/Mexico_City',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/3347594864'
      },
      {
        id: 5411801025,
        descriptive_name: 'Spider Man',
        currency_code: 'BRL',
        time_zone: 'America/Sao_Paulo',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/5411801025'
      },
      {
        id: 6405741705,
        descriptive_name: 'Ugly Doll',
        currency_code: 'BRL',
        time_zone: 'America/Sao_Paulo',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/6405741705'
      },
      {
        id: 4405405390,
        descriptive_name: 'zzz_Hasbro_PrimeTime Games_CA',
        currency_code: 'CAD',
        time_zone: 'America/Toronto',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/4405405390'
      },
      {
        id: 8908427494,
        descriptive_name: 'Hasbro | SEM',
        currency_code: 'CLP',
        time_zone: 'America/Santiago',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/8908427494'
      },
      {
        id: 5659361237,
        descriptive_name: 'Hasbro | Video',
        currency_code: 'CLP',
        time_zone: 'America/Santiago',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/5659361237'
      },
      {
        id: 4774632311,
        descriptive_name: 'Hasbro_FurReal_CZ',
        currency_code: 'CZK',
        time_zone: 'Europe/Prague',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/4774632311'
      },
      {
        id: 4405247374,
        descriptive_name: 'Hasbro_Gaming_CZ',
        currency_code: 'CZK',
        time_zone: 'Europe/Prague',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/4405247374'
      },
      {
        id: 6194025684,
        descriptive_name: 'Hasbro_Marvel_CZ',
        currency_code: 'CZK',
        time_zone: 'Europe/Prague',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/6194025684'
      },
      {
        id: 5679677156,
        descriptive_name: 'Hasbro_Monopoly_CZ',
        currency_code: 'CZK',
        time_zone: 'Europe/Prague',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/5679677156'
      },
      {
        id: 2102586745,
        descriptive_name: 'Hasbro_My little pony_CZ',
        currency_code: 'CZK',
        time_zone: 'Europe/Prague',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/2102586745'
      },
      {
        id: 6799339366,
        descriptive_name: 'Hasbro_Nerf_CZ',
        currency_code: 'CZK',
        time_zone: 'Europe/Prague',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/6799339366'
      },
      {
        id: 4077560258,
        descriptive_name: 'Hasbro_PlayDoh_CZ',
        currency_code: 'CZK',
        time_zone: 'Europe/Prague',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/4077560258'
      },
      {
        id: 8917472041,
        descriptive_name: 'Hasbro_Gaming_DK',
        currency_code: 'DKK',
        time_zone: 'Europe/Copenhagen',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/8917472041'
      },
      {
        id: 3506934098,
        descriptive_name: 'Hasbro_Gaming_DK 1',
        currency_code: 'DKK',
        time_zone: 'Europe/Copenhagen',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/3506934098'
      },
      {
        id: 3816746531,
        descriptive_name: 'Hasbro_Gaming_FI',
        currency_code: 'DKK',
        time_zone: 'Europe/Copenhagen',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/3816746531'
      },
      {
        id: 8020749366,
        descriptive_name: 'Hasbro_Gaming_NO',
        currency_code: 'DKK',
        time_zone: 'Europe/Copenhagen',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/8020749366'
      },
      {
        id: 7569911239,
        descriptive_name: 'Hasbro_Gaming_SE',
        currency_code: 'DKK',
        time_zone: 'Europe/Copenhagen',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/7569911239'
      },
      {
        id: 2276038693,
        descriptive_name: 'Hasbro_Nerf_FI',
        currency_code: 'DKK',
        time_zone: 'Europe/Copenhagen',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/2276038693'
      },
      {
        id: 4077538574,
        descriptive_name: 'Hasbro_Nerf_NO',
        currency_code: 'DKK',
        time_zone: 'Europe/Copenhagen',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/4077538574'
      },
      {
        id: 5921205349,
        descriptive_name: 'Hasbro_Nerf_SE',
        currency_code: 'DKK',
        time_zone: 'Europe/Copenhagen',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/5921205349'
      },
      {
        id: 5359396901,
        descriptive_name: 'HASBRO_BEYBLADE_FR',
        currency_code: 'EUR',
        time_zone: 'Europe/Paris',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/5359396901'
      },
      {
        id: 8786396125,
        descriptive_name: 'HASBRO_CLASSIC GAMES_FR',
        currency_code: 'EUR',
        time_zone: 'Europe/Paris',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/8786396125'
      },
      {
        id: 1503173535,
        descriptive_name: 'HASBRO_MARVEL_FR',
        currency_code: 'EUR',
        time_zone: 'Europe/Paris',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/1503173535'
      },
      {
        id: 3611583492,
        descriptive_name: 'HASBRO_NERF_FR',
        currency_code: 'EUR',
        time_zone: 'Europe/Paris',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/3611583492'
      },
      {
        id: 9438101925,
        descriptive_name: 'Hasbro_Beyblade_BENELUX',
        currency_code: 'EUR',
        time_zone: 'Europe/Amsterdam',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/9438101925'
      },
      {
        id: 8886243988,
        descriptive_name: 'Hasbro_Beyblade_GR',
        currency_code: 'EUR',
        time_zone: 'Europe/Athens',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/8886243988'
      },
      {
        id: 2053975041,
        descriptive_name: 'Hasbro_Classic Games_IT',
        currency_code: 'EUR',
        time_zone: 'Europe/Rome',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/2053975041'
      },
      {
        id: 4890155384,
        descriptive_name: 'Hasbro_Cluedo_BENELUX',
        currency_code: 'EUR',
        time_zone: 'Europe/Amsterdam',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/4890155384'
      },
      {
        id: 3184190168,
        descriptive_name: 'Hasbro_Disney Frozen_BENELUX',
        currency_code: 'EUR',
        time_zone: 'Europe/Amsterdam',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/3184190168'
      },
      {
        id: 5652337031,
        descriptive_name: 'Hasbro_Disney Princess_BENELUX',
        currency_code: 'EUR',
        time_zone: 'Europe/Amsterdam',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/5652337031'
      },
      {
        id: 2360999490,
        descriptive_name: 'Hasbro_FurReal_BENELUX',
        currency_code: 'EUR',
        time_zone: 'Europe/Amsterdam',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/2360999490'
      },
      {
        id: 6154856745,
        descriptive_name: 'Hasbro_Gaming Kids_BENELUX',
        currency_code: 'EUR',
        time_zone: 'Europe/Amsterdam',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/6154856745'
      },
      {
        id: 6965234994,
        descriptive_name: 'Hasbro_Gaming Preschool_IT',
        currency_code: 'EUR',
        time_zone: 'Europe/Rome',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/6965234994'
      },
      {
        id: 9982999822,
        descriptive_name: 'Hasbro_Hasbro Gaming_GR',
        currency_code: 'EUR',
        time_zone: 'Europe/Athens',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/9982999822'
      },
      {
        id: 9826730929,
        descriptive_name: 'Hasbro_Marvel_BENELUX',
        currency_code: 'EUR',
        time_zone: 'Europe/Amsterdam',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/9826730929'
      },
      {
        id: 1728955688,
        descriptive_name: 'Hasbro_Marvel_IT',
        currency_code: 'EUR',
        time_zone: 'Europe/Rome',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/1728955688'
      },
      {
        id: 3938684133,
        descriptive_name: 'Hasbro_Memories Included_IT',
        currency_code: 'EUR',
        time_zone: 'Europe/Rome',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/3938684133'
      },
      {
        id: 7992568444,
        descriptive_name: 'Hasbro_Monopoly_BENELUX',
        currency_code: 'EUR',
        time_zone: 'Europe/Amsterdam',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/7992568444'
      },
      {
        id: 8332902329,
        descriptive_name: 'Hasbro_Monopoly_GR',
        currency_code: 'EUR',
        time_zone: 'Europe/Athens',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/8332902329'
      },
      {
        id: 5765433411,
        descriptive_name: 'Hasbro_Monopoly_IT',
        currency_code: 'EUR',
        time_zone: 'Europe/Rome',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/5765433411'
      },
      {
        id: 7162090198,
        descriptive_name: 'Hasbro_Nerf_BENELUX',
        currency_code: 'EUR',
        time_zone: 'Europe/Amsterdam',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/7162090198'
      },
      {
        id: 6274726241,
        descriptive_name: 'Hasbro_Nerf_IT',
        currency_code: 'EUR',
        time_zone: 'Europe/Rome',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/6274726241'
      },
      {
        id: 3064824796,
        descriptive_name: 'Hasbro_PlayDoh_BENELUX',
        currency_code: 'EUR',
        time_zone: 'Europe/Amsterdam',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/3064824796'
      },
      {
        id: 8012110466,
        descriptive_name: 'Hasbro_PlayDoh_IT',
        currency_code: 'EUR',
        time_zone: 'Europe/Rome',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/8012110466'
      },
      {
        id: 7479585498,
        descriptive_name: 'Hasbro_Power Rangers_BENELUX',
        currency_code: 'EUR',
        time_zone: 'Europe/Amsterdam',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/7479585498'
      },
      {
        id: 8761141135,
        descriptive_name: 'Hasbro_Transformers_BENELUX',
        currency_code: 'EUR',
        time_zone: 'Europe/Amsterdam',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/8761141135'
      },
      {
        id: 3577785005,
        descriptive_name: 'Hasbro_Transformers_IT',
        currency_code: 'EUR',
        time_zone: 'Europe/Rome',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/3577785005'
      },
      {
        id: 6013578545,
        descriptive_name: 'Hasbro_Trolls_BENELUX',
        currency_code: 'EUR',
        time_zone: 'Europe/Amsterdam',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/6013578545'
      },
      {
        id: 7089986301,
        descriptive_name: 'Hasbro_Gaming_UK',
        currency_code: 'GBP',
        time_zone: 'Europe/London',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/7089986301'
      },
      {
        id: 1457974758,
        descriptive_name: 'Hasbro_Monopoly_UK',
        currency_code: 'GBP',
        time_zone: 'Europe/London',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/1457974758'
      },
      {
        id: 8030748170,
        descriptive_name: 'Hasbro_Nerf_UK',
        currency_code: 'GBP',
        time_zone: 'Europe/London',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/8030748170'
      },
      {
        id: 7716554503,
        descriptive_name: 'Hasbro_Play Doh_UK',
        currency_code: 'GBP',
        time_zone: 'Europe/London',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/7716554503'
      },
      {
        id: 3860438944,
        descriptive_name: 'Hasbro_Transformers_UK',
        currency_code: 'GBP',
        time_zone: 'Europe/London',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/3860438944'
      },
      {
        id: 9950624335,
        descriptive_name: 'Hasbro_Hasbro Gaming_HU',
        currency_code: 'HUF',
        time_zone: 'Europe/Budapest',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/9950624335'
      },
      {
        id: 2577213009,
        descriptive_name: 'Hasbro_Monopoly_HU',
        currency_code: 'HUF',
        time_zone: 'Europe/Budapest',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/2577213009'
      },
      {
        id: 3564707801,
        descriptive_name: 'Hasbro_My Little Pony_HU',
        currency_code: 'HUF',
        time_zone: 'Europe/Budapest',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/3564707801'
      },
      {
        id: 8688733480,
        descriptive_name: 'Hasbro_Nerf_HU',
        currency_code: 'HUF',
        time_zone: 'Europe/Budapest',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/8688733480'
      },
      {
        id: 1475411397,
        descriptive_name: 'Hasbro_PlayDoh_HU',
        currency_code: 'HUF',
        time_zone: 'Europe/Budapest',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/1475411397'
      },
      {
        id: 3320233933,
        descriptive_name: 'Hasbro Japan G.K. (GDN)',
        currency_code: 'JPY',
        time_zone: 'Asia/Tokyo',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/3320233933'
      },
      {
        id: 1061965437,
        descriptive_name: 'Hasbro Japan G.K. (YouTube)',
        currency_code: 'JPY',
        time_zone: 'Asia/Tokyo',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/1061965437'
      },
      {
        id: 9226144909,
        descriptive_name: 'Hasbro_DisneyFrozen_KR',
        currency_code: 'KRW',
        time_zone: 'Asia/Seoul',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/9226144909'
      },
      {
        id: 3305497044,
        descriptive_name: 'Hasbro_Gaming_KR',
        currency_code: 'KRW',
        time_zone: 'Asia/Seoul',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/3305497044'
      },
      {
        id: 7007058664,
        descriptive_name: 'Hasbro_Marvel_KR',
        currency_code: 'KRW',
        time_zone: 'Asia/Seoul',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/7007058664'
      },
      {
        id: 8229473998,
        descriptive_name: 'Hasbro_Monopoly_KR',
        currency_code: 'KRW',
        time_zone: 'Asia/Seoul',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/8229473998'
      },
      {
        id: 3540956641,
        descriptive_name: 'Hasbro_Nerf_KR',
        currency_code: 'KRW',
        time_zone: 'Asia/Seoul',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/3540956641'
      },
      {
        id: 8282032256,
        descriptive_name: 'Hasbro_PlayDoh_KR',
        currency_code: 'KRW',
        time_zone: 'Asia/Seoul',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/8282032256'
      },
      {
        id: 5886785686,
        descriptive_name: 'Hasbro_Baby Alive_MX_GDN',
        currency_code: 'MXN',
        time_zone: 'America/Mexico_City',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/5886785686'
      },
      {
        id: 2224286231,
        descriptive_name: 'Hasbro_Baby Alive_MX_SEM',
        currency_code: 'MXN',
        time_zone: 'America/Mexico_City',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/2224286231'
      },
      {
        id: 2869212364,
        descriptive_name: 'Hasbro_Baby Alive_MX_YT',
        currency_code: 'MXN',
        time_zone: 'America/Mexico_City',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/2869212364'
      },
      {
        id: 7611687609,
        descriptive_name: 'Hasbro_Beyblade_MX_GDN',
        currency_code: 'MXN',
        time_zone: 'America/Mexico_City',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/7611687609'
      },
      {
        id: 9785646905,
        descriptive_name: 'Hasbro_Beyblade_MX_SEM',
        currency_code: 'MXN',
        time_zone: 'America/Mexico_City',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/9785646905'
      },
      {
        id: 7950211507,
        descriptive_name: 'Hasbro_Disney_MX_GDN',
        currency_code: 'MXN',
        time_zone: 'America/Mexico_City',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/7950211507'
      },
      {
        id: 3420113170,
        descriptive_name: 'Hasbro_Furreal Friends_MX_GDN',
        currency_code: 'MXN',
        time_zone: 'America/Mexico_City',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/3420113170'
      },
      {
        id: 3915098488,
        descriptive_name: 'Hasbro_Furreal Friends_MX_SEM',
        currency_code: 'MXN',
        time_zone: 'America/Mexico_City',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/3915098488'
      },
      {
        id: 5919943837,
        descriptive_name: 'Hasbro_Gaming_MX_GDN',
        currency_code: 'MXN',
        time_zone: 'America/Mexico_City',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/5919943837'
      },
      {
        id: 8673905035,
        descriptive_name: 'Hasbro_Gaming_MX_SEM',
        currency_code: 'MXN',
        time_zone: 'America/Mexico_City',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/8673905035'
      },
      {
        id: 3036734697,
        descriptive_name: 'Hasbro_Gaming_MX_YT',
        currency_code: 'MXN',
        time_zone: 'America/Mexico_City',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/3036734697'
      },
      {
        id: 4579292323,
        descriptive_name: 'Hasbro_HasbroFan_MX_GDN',
        currency_code: 'MXN',
        time_zone: 'America/Mexico_City',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/4579292323'
      },
      {
        id: 8864338854,
        descriptive_name: 'Hasbro_HasbroFan_MX_SEM',
        currency_code: 'MXN',
        time_zone: 'America/Mexico_City',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/8864338854'
      },
      {
        id: 4016187252,
        descriptive_name: 'Hasbro_Marvel_MX_GDN',
        currency_code: 'MXN',
        time_zone: 'America/Mexico_City',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/4016187252'
      },
      {
        id: 7555017452,
        descriptive_name: 'Hasbro_Marvel_MX_SEM',
        currency_code: 'MXN',
        time_zone: 'America/Mexico_City',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/7555017452'
      },
      {
        id: 5810730560,
        descriptive_name: 'Hasbro_Marvel_MX_YT',
        currency_code: 'MXN',
        time_zone: 'America/Mexico_City',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/5810730560'
      },
      {
        id: 6779311342,
        descriptive_name: 'Hasbro_Nerf_MX_GDN',
        currency_code: 'MXN',
        time_zone: 'America/Mexico_City',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/6779311342'
      },
      {
        id: 5084217899,
        descriptive_name: 'Hasbro_Nerf_MX_SEM',
        currency_code: 'MXN',
        time_zone: 'America/Mexico_City',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/5084217899'
      },
      {
        id: 9100770600,
        descriptive_name: 'Hasbro_Nerf_MX_YT',
        currency_code: 'MXN',
        time_zone: 'America/Mexico_City',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/9100770600'
      },
      {
        id: 2122736911,
        descriptive_name: 'Hasbro_Play Doh_MX_SEM',
        currency_code: 'MXN',
        time_zone: 'America/Mexico_City',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/2122736911'
      },
      {
        id: 3147987850,
        descriptive_name: 'Hasbro_Play-Doh_MX_GDN',
        currency_code: 'MXN',
        time_zone: 'America/Mexico_City',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/3147987850'
      },
      {
        id: 4385451145,
        descriptive_name: 'Hasbro_Play-Doh_MX_YT',
        currency_code: 'MXN',
        time_zone: 'America/Mexico_City',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/4385451145'
      },
      {
        id: 4113196264,
        descriptive_name: 'Hasbro_Star Wars_MX_GDN',
        currency_code: 'MXN',
        time_zone: 'America/Mexico_City',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/4113196264'
      },
      {
        id: 6070061498,
        descriptive_name: 'Hasbro_Star Wars_MX_YT',
        currency_code: 'MXN',
        time_zone: 'America/Mexico_City',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/6070061498'
      },
      {
        id: 2660844233,
        descriptive_name: 'GDN | Hasbro_Hasbro Gaming_PL // Guess who; Monopoly ; Disney Princess',
        currency_code: 'PLN',
        time_zone: 'Europe/Warsaw',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/2660844233'
      },
      {
        id: 6760469160,
        descriptive_name: 'GDN | Hasbro_Marvel_PL // Nerf; Gaming',
        currency_code: 'PLN',
        time_zone: 'Europe/Warsaw',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/6760469160'
      },
      {
        id: 5216328427,
        descriptive_name: 'Hasbro_Monopoly_PL // Disney Frozen_PL,Memories_Included',
        currency_code: 'PLN',
        time_zone: 'Europe/Warsaw',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/5216328427'
      },
      {
        id: 1056945633,
        descriptive_name: 'Hasbro_Nerf_PL (FurReal_PL)',
        currency_code: 'PLN',
        time_zone: 'Europe/Warsaw',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/1056945633'
      },
      {
        id: 3015982200,
        descriptive_name: 'YT | Hasbro_PlayDoh_PL',
        currency_code: 'PLN',
        time_zone: 'Europe/Warsaw',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/3015982200'
      },
      {
        id: 2977476084,
        descriptive_name: 'Hasbro_ Memories Included_RO',
        currency_code: 'RON',
        time_zone: 'Europe/Bucharest',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/2977476084'
      },
      {
        id: 8449224447,
        descriptive_name: 'Hasbro_Gaming Family_RO',
        currency_code: 'RON',
        time_zone: 'Europe/Bucharest',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/8449224447'
      },
      {
        id: 2791707399,
        descriptive_name: 'Hasbro_Gaming Preschool_RO',
        currency_code: 'RON',
        time_zone: 'Europe/Bucharest',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/2791707399'
      },
      {
        id: 2865121156,
        descriptive_name: 'Hasbro_Monopoly_RO',
        currency_code: 'RON',
        time_zone: 'Europe/Bucharest',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/2865121156'
      },
      {
        id: 1036156239,
        descriptive_name: 'Hasbro_My Little Pony_RO',
        currency_code: 'RON',
        time_zone: 'Europe/Bucharest',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/1036156239'
      },
      {
        id: 6071974572,
        descriptive_name: 'Hasbro_Nerf_RO',
        currency_code: 'RON',
        time_zone: 'Europe/Bucharest',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/6071974572'
      },
      {
        id: 3983701429,
        descriptive_name: 'Hasbro_PlayDoh_RO',
        currency_code: 'RON',
        time_zone: 'Europe/Bucharest',
        test_account: false,
        level: 4,
        resource_name: 'customers/4156813945/customerClients/3983701429'
      },
      {
        id: 9634573156,
        descriptive_name: 'Hasbro_Beyblade_RU',
        currency_code: 'RUB',
        time_zone: 'Europe/Moscow',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/9634573156'
      },
      {
        id: 4571603793,
        descriptive_name: 'Hasbro_ClassicGames_RU',
        currency_code: 'RUB',
        time_zone: 'Europe/Moscow',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/4571603793'
      },
      {
        id: 4847549743,
        descriptive_name: 'Hasbro_DisneyFrozen_RU',
        currency_code: 'RUB',
        time_zone: 'Europe/Moscow',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/4847549743'
      },
      {
        id: 9192272813,
        descriptive_name: 'Hasbro_DisneyPrincess_RU',
        currency_code: 'RUB',
        time_zone: 'Europe/Moscow',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/9192272813'
      },
      {
        id: 8168566203,
        descriptive_name: 'Hasbro_FurReal_RU',
        currency_code: 'RUB',
        time_zone: 'Europe/Moscow',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/8168566203'
      },
      {
        id: 7447478080,
        descriptive_name: 'Hasbro_Marvel_RU',
        currency_code: 'RUB',
        time_zone: 'Europe/Moscow',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/7447478080'
      },
      {
        id: 7503617699,
        descriptive_name: 'Hasbro_Monopoly_RU',
        currency_code: 'RUB',
        time_zone: 'Europe/Moscow',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/7503617699'
      },
      {
        id: 5268507517,
        descriptive_name: 'Hasbro_MyLittlePony_RU',
        currency_code: 'RUB',
        time_zone: 'Europe/Moscow',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/5268507517'
      },
      {
        id: 5070633872,
        descriptive_name: 'Hasbro_Nerf_RU',
        currency_code: 'RUB',
        time_zone: 'Europe/Moscow',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/5070633872'
      },
      {
        id: 5736690737,
        descriptive_name: 'Hasbro_PlayDoh_RU',
        currency_code: 'RUB',
        time_zone: 'Europe/Moscow',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/5736690737'
      },
      {
        id: 5187346554,
        descriptive_name: 'Hasbro_Transformers_RU',
        currency_code: 'RUB',
        time_zone: 'Europe/Moscow',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/5187346554'
      },
      {
        id: 2628159037,
        descriptive_name: 'Hasbro_Baby Alive_US',
        currency_code: 'USD',
        time_zone: 'America/New_York',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/2628159037'
      },
      {
        id: 5593575113,
        descriptive_name: 'Hasbro_Beyblade_US',
        currency_code: 'USD',
        time_zone: 'America/New_York',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/5593575113'
      },
      {
        id: 2816847093,
        descriptive_name: 'Hasbro_Disney Frozen_US',
        currency_code: 'USD',
        time_zone: 'America/New_York',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/2816847093'
      },
      {
        id: 8426144807,
        descriptive_name: 'Hasbro_Disney Princess_US',
        currency_code: 'USD',
        time_zone: 'America/New_York',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/8426144807'
      },
      {
        id: 2912329778,
        descriptive_name: 'Hasbro_FurReal_US',
        currency_code: 'USD',
        time_zone: 'America/New_York',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/2912329778'
      },
      {
        id: 4113984866,
        descriptive_name: 'Hasbro_GI Joe_US',
        currency_code: 'USD',
        time_zone: 'America/New_York',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/4113984866'
      },
      {
        id: 2039843482,
        descriptive_name: 'Hasbro_Ghostbusters_US',
        currency_code: 'USD',
        time_zone: 'America/New_York',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/2039843482'
      },
      {
        id: 1445184168,
        descriptive_name: 'Hasbro_Marvel_US',
        currency_code: 'USD',
        time_zone: 'America/New_York',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/1445184168'
      },
      {
        id: 9705819437,
        descriptive_name: 'Hasbro_Monopoly_US',
        currency_code: 'USD',
        time_zone: 'America/New_York',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/9705819437'
      },
      {
        id: 7534002929,
        descriptive_name: 'Hasbro_My Little Pony_US',
        currency_code: 'USD',
        time_zone: 'America/New_York',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/7534002929'
      },
      {
        id: 7100596123,
        descriptive_name: 'Hasbro_Nerf_US',
        currency_code: 'USD',
        time_zone: 'America/New_York',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/7100596123'
      },
      {
        id: 7748505941,
        descriptive_name: 'Hasbro_Nerf_US_2',
        currency_code: 'USD',
        time_zone: 'America/New_York',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/7748505941'
      },
      {
        id: 3511305064,
        descriptive_name: 'Hasbro_PJ Masks_US',
        currency_code: 'USD',
        time_zone: 'America/New_York',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/3511305064'
      },
      {
        id: 9275616325,
        descriptive_name: 'Hasbro_Peppa Pig_US',
        currency_code: 'USD',
        time_zone: 'America/New_York',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/9275616325'
      },
      {
        id: 4391396846,
        descriptive_name: 'Hasbro_PlayDoh_US',
        currency_code: 'USD',
        time_zone: 'America/New_York',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/4391396846'
      },
      {
        id: 5037681845,
        descriptive_name: 'Hasbro_Playdoh_GCC',
        currency_code: 'USD',
        time_zone: 'Asia/Dubai',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/5037681845'
      },
      {
        id: 4074014058,
        descriptive_name: 'Hasbro_PowerRangers_US',
        currency_code: 'USD',
        time_zone: 'America/New_York',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/4074014058'
      },
      {
        id: 8144916174,
        descriptive_name: 'Hasbro_PrimeTimeGames_US',
        currency_code: 'USD',
        time_zone: 'America/New_York',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/8144916174'
      },
      {
        id: 1230914947,
        descriptive_name: 'Hasbro_Star Wars_US',
        currency_code: 'USD',
        time_zone: 'America/New_York',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/1230914947'
      },
      {
        id: 2760931605,
        descriptive_name: 'Hasbro_Transformers_US',
        currency_code: 'USD',
        time_zone: 'America/New_York',
        test_account: false,
        level: 3,
        resource_name: 'customers/4156813945/customerClients/2760931605'
      }
    ]
  }
};