// Security page for password reset.
export const successGeneralProfileUpdate = 'Success! Your profile has been updated.';
export const errorGeneralProfileUpdate = 'Unable to update your profile. Please try again in a few minutes.';

// Security page for password reset.
export const successSecurityResetPasswordRequest = 'Password reset request successful.  Check your email for a password reset link.';
export const errorSecurityResetPasswordRequestFailure = 'Unable to submit request for password reset. Please try again in a few minutes.';

// Final response in storage testing for all storages.
export const successWizardGeneralStorageTest = 'Destination test completed successfully. Continue to the next step.';
export const errorWizardGeneralStorageTestFailed = 'We had difficulty connecting to your destination. Please check your settings.';

// API Management errors.
export const errorRefreshTokenCreation = 'There was an issue generating your refresh token';

export const errorIdentityUndeletable = 'Identities associated to subscriptions can not be deleted until those associated subscriptions are deleted.'

// General wizard form errors.
export const errorWizardGeneralChecklist = 'Please check everything so we know you are ready to go.';
export const errorWizardGeneralDestinationSelection = 'Please choose a destination so we can store your data.';
export const errorWizardGeneralDestinationName = 'Please give your destination a unique name.';
export const errorWizardGeneralSourceName = 'Please give your subscription a unique name.';
export const errorWizardGeneralRemoteIdentitySelection = 'We need an identity to authorize our access to this service. Please select one below.';
export const errorWizardRedirectLink = 'Redirect link is a required field.';
export const errorWizardByoaOauthCredentials = 'App credentials are required fields.';
export const errorWizardGoogleBigqueryDatasetidSelection = 'Please choose an dataset.';
export const errorWizardGenericProfileSelection = 'Please choose a profile.';
export const errorWizardGeneralDetailsIncomplete = 'Looks like some information is missing. Please enter all the required details.';
export const errorWizardGeneralError = 'Something went wrong !!';
export const errorWizardProjectSelection = 'Please choose a project.';
export const errorWizardDatasetSelection = 'Please choose a dataset.';
export const errorWizardReportSelection = 'Please choose a report.';
export const errorWizardIdentityExists = 'This identity already exists';
export const errorWizardIdentityGeneralError = 'An unexpected error happened.';
export const errorIntegrationDoesNotSupportHistory = 'Sorry, but this product does not support history runs';
export const errorWizardAmazonAttributionTestFailure = 'Amazon reports that this profile can not access the attribution metrics API';
export const errorWizardYoutubeMultiChannelExists = 'Channel already exists in the selected list';
export const errorWizardYoutubeMultiChannelMax = 'You have reached the maximum allowed channels per subscription';
export const errorHistoryFailure = 'Unable to process history';
export const errorWizardInvalidAmzAsins = "You have provided a list with ASINs that are invalid.  View details to see the list of invalid ASINs";
export const errorVpnAuthBlock = 'Authentication failed. The most common cause of this is that you are connected to a corporate VPN that is blocking our API calls to our authentication service. Please disconnect from the VPN and log in again.';

// flash-notification icon list
export const FlashNotificationIcons = {
  primaryIcon: 'fa-check-square',
  secondaryIcon: 'fa-check-double',
  successIcon: 'fa-check-circle',
  dangerIcon: 'fa-octagon-exclamation',
  warningIcon: 'fa-question-circle',
  infoIcon: 'fa-info-circle',
  lightIcon: 'fa-lightbulb',
  darkIcon: 'fas fa-moon',
  defaultIcon: ''
};

// flash-notification alert list
export const FlashNotificationAlerts = {
  alertPrimary: 'alert-primary',
  alertSecondary: 'alert-secondary',
  alertSuccess: 'alert-success',
  alertDanger: 'alert-danger',
  alertWarning: 'alert-warning',
  alertInfo: 'alert-info',
  alertLight: 'alert-light',
  alertDark: 'alert-dark',
};
