import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { ApiUserGuard } from './core/guards/api-user.guard';

const routes: Routes = [
  {
    path: 'account',
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'identities',
    loadChildren: () => import('./modules/identities/identities.module').then(m => m.IdentitiesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pipelines',
    loadChildren: () => import('./modules/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'destinations',
    loadChildren: () => import('./modules/destinations/destinations.module').then(m => m.DestinationsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'sources',
    loadChildren: () => import('./modules/sources/sources.module').then(m => m.SourcesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'wizards',
    loadChildren: () => import('./modules/wizards/wizards.module').then(m => m.WizardsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'plans',
    loadChildren: () => import('./modules/pricing/pricing.module').then(m => m.PricingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'history',
    loadChildren: () => import('./modules/history/history.module').then(m => m.HistoryModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'healthchecks',
    loadChildren: () => import('./modules/healthchecks/healthchecks.module').then(m => m.HealthchecksModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'signup',
    loadChildren: () => import('./modules/signup/signup.module').then(m => m.SignupModule)
  },
  {
    path: 'identity',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/oauth-identity/oauth-identity.module').then(m => m.OauthIdentityModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'api-management',
    loadChildren: () => import('./modules/api-management/api-management.module').then(m => m.ApiManagementModule),
    canActivate: [AuthGuard, ApiUserGuard]
  },
  {
    path: 'testing',
    loadChildren: () => import('./testing/testing.module').then(m => m.TestingModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'jobs', 
    loadChildren: () => import('./modules/jobs/jobs.module').then(m => m.JobsModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'identity-invite', 
    loadChildren: () => import('./modules/third-pary-identity/third-pary-identity.module').then(m => m.ThirdParyIdentityModule) 
  },
  {
    path: 'reporting',
    loadChildren: () => import('./modules/reporting/reporting.module').then(m => m.ReportingModule),
    canActivate: [AuthGuard]

  },
  { 
    path: 'amazon-reauth-landing', loadChildren: () => import('./modules/amazon-reauth-landing/external-reauth.module').then(m => m.ExternalReauthModule) },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled', // scrolls to the anchor element when the URL has a fragment
    scrollOffset: [0, 64],  // scroll offset when scrolling to an element (optional)
    scrollPositionRestoration: 'enabled', // restores the previous scroll position on backward navigation
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
