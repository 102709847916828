import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { modalsOpen, modalsUpdate, modalsClose } from '../actions/modals.action';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalGeneralProgressComponent } from 'src/app/shared/components/modals/modal-general-progress/modal-general-progress.component';
import { modalNonUserClosable } from 'src/app/shared/constants/component-configs';
import { ModalGeneralErrorComponent } from 'src/app/shared/components/modals/modal-general-error/modal-general-error.component';

@Injectable()
export class ModalsEffects {
  bsModalRef = BsModalRef;

  constructor(
    private actions$: Actions,
    private modalService: BsModalService,
  ) { }

  openModal$ = createEffect(() => this.actions$
    .pipe(
      ofType(modalsOpen),
      tap(async action => {
        const delay = ms => new Promise(res => setTimeout(res, ms));
        delay(2000);
        if (action.modalType === 'error') {
          globalThis.bsModalRef = this.modalService.show(ModalGeneralErrorComponent, modalNonUserClosable);
        } else {
          globalThis.bsModalRef = this.modalService.show(ModalGeneralProgressComponent, modalNonUserClosable);
        }
      })
    ),
    { dispatch: false }
  );

  updateModal$ = createEffect(() => this.actions$
    .pipe(
      ofType(modalsUpdate),
      tap(async action => {
        // const currentAccount = await this.accountService.getAccountUserDetails();
        // this.store$.dispatch(authAccountToggle({ currentAccount: currentAccount }));
      })
    ),
    { dispatch: false }
  );

  closeModal$ = createEffect(() => this.actions$
    .pipe(
      ofType(modalsClose),
      tap(async action => {
        const delay = ms => new Promise(res => setTimeout(res, ms));
        delay(2000);
        if (globalThis.bsModalRef) {
          globalThis.bsModalRef.hide();
        }
      })
    ),
    { dispatch: false }
  );
}