import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../store/reducers';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseWizardService } from 'src/app/modules/wizards/base-wizard.service';
import { IntegrationService } from 'src/app/shared/services/integration.service';
import { Integration } from 'src/app/shared/models/integration.model';
import { IdentityService } from './identity.service';
import { updateReportStage } from '../store/actions/reporting.actions';
import { triggerToastNotification } from '../store/actions/flash-notifications.actions';

const delay = ms => new Promise(res => setTimeout(res, ms));

@Injectable({
  providedIn: 'root'
})
export class PipelineSpmReportService {

  reportData: any[] = [];
  spmExcludes = {
    2: ['fb_user_id', 'fb_user_token', 'account_name', 'account_token', 'limit_metrics', 'quantity'],
    4: ['dfa_report_name', 'sort_headers', 'quantity'],
    7: ['nickname'],
    11: ['quantity','remote_identity_name', 'ad_account_name'],
    15: ['quantity', 'channel_title_1', 'channel_id_1', 'channel_title_2', 'channel_id_2', 'channel_title_3', 'channel_id_3', 'channel_title_4', 'channel_id_4', 'channel_title_5', 'channel_id_5', 'channel_title_6', 'channel_id_6', 'channel_title_7', 'channel_id_7'],
    17: ['remote_identity_name', 'channel_title'],
    18: ['quantity', 'channel_title_1', 'channel_id_1', 'channel_title_2', 'channel_id_2', 'channel_title_3', 'channel_id_3', 'channel_title_4', 'channel_id_4', 'channel_title_5', 'channel_id_5', 'channel_title_6', 'channel_id_6', 'channel_title_7', 'channel_id_7'],
    26: ['remote_identity_name', 'channel_title', 'quantity'],
    32: ['remote_identity_name', 'channel_title', 'quantity'],
    34: ['remote_identity_name', 'account_name', 'quantity'],
    38: ['remote_identity_name', 'account_name', 'quantity'],
  }  

  constructor(
    protected httpClient: HttpClient,
    protected integrationService: IntegrationService,
    protected identityService: IdentityService,
    protected store$: Store<AppState>,
    protected wizardService: BaseWizardService
  ) { }

  async generateReport(index: number, subscriptions: any[]): Promise<any[]> {

    this.reportData = [];
    let percentageComplete = 0;

    const activeOrCancelledSubscriptions = subscriptions.filter(sub => {
      if(sub.status !== 'invalid') {
        return sub
      }
    });

    if(activeOrCancelledSubscriptions.length > 1) {
      const percentSegment = 100 / activeOrCancelledSubscriptions.length;

      for(const subscription of activeOrCancelledSubscriptions) {

        const report = {};
        // Only 'active' and 'cancelled' statuses
        if(subscription['status'] !== 'invalid') {

          // Get integration for subscription
          const integration: Integration = this.integrationService.findIntegrationFromIdAndSubproductId(subscription['productId'], subscription['subProductId'])

          // If not a destination process the subscription
          if(integration.type !== 'destination') {

            // Get the identity for the subscription
            const identity = this.identityService.getIdentityById(subscription['remoteIdentityId']);
            const spm = await this.wizardService.getSubscriptionProductMeta(subscription['id']);
            let spmInfo = this.wizardService.buildObjectFromSpmDataArray(spm.data);

            // Remove the remote identity id from the spm since it's in the subscription.
            const spmInfoKeys = Object.keys(spmInfo);
            if(spmInfoKeys.includes('remote_identity_id')) {
              delete spmInfo['remote_identity_id'];
            }

            if(spmInfoKeys.includes('passwd')) {
              delete spmInfo['passwd'];
            }

            if(spmInfoKeys.includes('password')) {
              delete spmInfo['password'];
            }

            // Remove old unnecessary data to keep from confusing customers when
            // it's not consistantly found in all of the records.
            const excludeProductIds = Object.keys(this.spmExcludes);
            const productId = String(subscription.productId);
            if(excludeProductIds.includes(productId)) {
              for(const exclude of this.spmExcludes[subscription['productId']]) {
                if(spmInfoKeys.includes(exclude)) {
                  delete spmInfo[exclude];
                }                
              }
            }

            spmInfo = (Object.keys(spmInfo).length === 0) ? null : spmInfo;

            // Start assembling the report JSON.
            report['subscriptionId'] = subscription['id'];
            report['name'] = subscription['name'];
            report['product'] = `${integration.brand} ${integration.name}` ;
            report['status'] = (subscription['status'] === 'active') ? 'active' : 'paused';
            report['created_at'] = subscription['createdAt'];
            report['modified_at']= subscription['modifiedAt'];

            // If we have an identity then pull out the information.
            if(identity !== null) {

              report['identity'] = {};
              report['identity']['id'] = identity['id'];
              report['identity']['name'] = identity['name'];
              report['identity']['created_at'] = identity['createdAt'];
              report['identity']['modified_at'] = identity['modifiedAt'];
              // report['identity']['meta'] = {}; // Figure out later.
            }
            else {
              report['identity'] = null;
            }

            report['meta'] = spmInfo;

            this.reportData.push(report);
            // await delay(50);
          }
        }
        percentageComplete += percentSegment;
        const payload = {index: index, percentage: Math.floor(percentageComplete)};
        this.store$.dispatch(updateReportStage(payload));

      }
    }
    else {
      return [];
    }

    percentageComplete = 100;
    this.store$.dispatch(updateReportStage({index: index, stage: 'complete', percentage: Math.floor(percentageComplete), reportData: JSON.stringify(this.reportData)}));
    this.store$.dispatch(triggerToastNotification({toastType: 'success', message: '<a href="/reporting">Report</a> Ready', title: null}));

    return this.reportData;

  }

  async getSubscriptionSpm(): Promise<any> {
    this.httpClient.get(environment.openbridgeApiUris.subscription)
  }
}
