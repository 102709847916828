import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SubscriptionsState } from '../reducers/subscriptions.reducer';

export const selectSubscriptionState = createFeatureSelector<SubscriptionsState>('subscriptions');

export const selectSubscriptions = createSelector(
  selectSubscriptionState,
  (subscriptions: SubscriptionsState) => subscriptions.subscriptions
);

// selector with param
export const selectSubscriptionById = (subscriptionId: number) =>
  createSelector(
    selectSubscriptions,
    (subscriptions: any[]) => subscriptions.find(s => +s.id === +subscriptionId)
  );

