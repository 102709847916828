export const responseAmazonAdvertisingBrands = {
  data: [
    {
      id: 'A2L3NO0M25UUPX',
      type: 'AmazonAdvertisingProfileBrand',
      attributes: {
        profile_id: '586011285459526',
        brand_entity_id: 'ENTITY1ODR7J41HTKL9',
        brand_registry_name: 'Luca + Danni'
      }
    }
  ]
};