import { Integration } from '../../models/integration.model';
import { vendors } from '../logos';
import { productIdBatchSftpProcessing, productIdOpenbridgeAirbyte, productIdShopifyInsights, productIdAwsS3Source, productIdStubProduct, productIdAmzPricingApi, productIdAmzCatalogApi } from '../product-ids';

export const integrationAirbyte: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'open-source',
  name: 'Airbridge',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'amazon'
  ],
  tagLine: 'Harness of the power of open source Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte',
  logoPath: vendors.airbyte,
  frequency: 'Daily',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: true,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'aws.amazon.com/redshift',
    uri: 'https://aws.amazon.com/redshift'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'schedule',
        'config',
        'aws',
        'integrationName',
        'save'
      ],
      edit: [
        'checklist',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false
};

export const integrationAwsS3Source: Integration = {
  productId: productIdAwsS3Source,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'AWS',
  name: 'S3 Source',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'amazon'
  ],
  tagLine: 'S3 file processing',
  routerLink: '/wizards/aws-s3-source',
  logoPath: vendors.amazon,
  frequency: 'Daily',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'aws.amazon.com/redshift',
    uri: 'https://aws.amazon.com/redshift'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'destination',
        'integrationName',
        'save'
      ],
    }
  },
  includeRegions: false
};

export const integrationCsvBatchLoader: Integration = {
  productId: productIdBatchSftpProcessing,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'CSV',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'amazon'
  ],
  tagLine: 'CSV file processing',
  routerLink: '/wizards/csv-batch-loader',
  logoPath: vendors.batch,
  frequency: 'Daily',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'aws.amazon.com/redshift',
    uri: 'https://aws.amazon.com/redshift'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'tester',
        'details',
        'authorization',
        'destination',
        'integrationName',
        'save'
      ],
      edit: [
        'checklist',
        'authorization',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false
};

export const integrationShopifyInsights: Integration = {
  productId: productIdShopifyInsights,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Shopify',
  name: 'Insights',
  badge: 'none',
  type: 'source',
  sortOrder: 1600,
  tagLine: 'Operations insights for merchant orders, returns, customers, shipments, and many more',
  routerLink: '/wizards/shopify',
  logoPath: vendors.shopify,
  frequency: 'Daily',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  remoteIdentityTypeId: 16,
  tableWeight: 0.3,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'shopify.dev/api',
    uri: 'https://shopify.dev/docs/admin-api'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: true
  },
  manageMenu: {
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false
};

export const integrationAmzPricingApi: Integration = {
  productId: productIdAmzPricingApi,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Pricing API Tracker',
  badge: 'none',
  type: 'source',
  sortOrder: 1200,
  tagLine: 'Track ASIN prices, sales rank, offers, feedback ratings, and more.',
  routerLink: '/wizards/amazon-pricing-api-tracker',
  logoPath: vendors.amazonSellerCentral,
  frequency: 'Hourly',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  remoteIdentityTypeId: 17,
  tableWeight: 0.4,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'amazon.com/selling-partner',
    uri: 'https://developer-docs.amazon.com/sp-api/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: {
    upstreamSource: "Amazon Seller Central",
    upstreamSourceProduct: "Amazon Seller Central",
    workerName: "sp_products_pricing",
    pathPrefix: "selling-partner/pricing",
    queryKey: "amazon-seller-central"
  }
};

export const integrationAmzCatalogApiKeyword: Integration = {
  productId: productIdAmzCatalogApi,
  subProductId: 'keywords',
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Keyword Tracker',
  badge: 'none',
  type: 'source',
  sortOrder: 1200,
  tagLine: 'Get keyword level product sales rank, descriptions, images, and more.',
  routerLink: '/wizards/amazon-catalog-keyword-tracker',
  logoPath: vendors.amazonSellerCentral,
  frequency: 'Daily',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  remoteIdentityTypeId: null,
  tableWeight: 0.4,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'amazon.com/selling-partner',
    uri: 'https://developer-docs.amazon.com/sp-api/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity-type-select',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false
};

export const integrationAmzCatalogApiAsin: Integration = {
  productId: productIdAmzCatalogApi,
  subProductId: 'identifiers',
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Catalog API',
  badge: 'none',
  type: 'source',
  sortOrder: 1200,
  tagLine: 'Get summaries, item details, sales rankings, images, and  more',
  routerLink: '/wizards/amazon-catalog-api',
  logoPath: vendors.amazonSellerCentral,
  frequency: 'Daily',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  remoteIdentityTypeId: null,
  tableWeight: 0.4,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'amazon.com/selling-partner',
    uri: 'https://developer-docs.amazon.com/sp-api/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity-type-select',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: {
    "upstreamSource": "Amazon Seller Central",
    "upstreamSourceProduct": "Amazon Seller Central",
    "workerName": "sp_catalog_items",
    "pathPrefix": "selling-partner/catalog",
    "queryKey": "amazon-seller-central"
  }    
};

