import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { identitiesLoad } from '../store/actions/identities.actions';
import { AppState } from '../store/reducers';

import { identities } from 'src/app/shared/mock/identities';
import { subscriptions } from 'src/app/shared/mock/subscriptions';
import { subscriptionsLoad } from '../store/actions/subscriptions.actions';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {

  constructor(
    protected store$: Store<AppState>
  ) { }
  loadedCachedData(): boolean {
    // this.store$.dispatch(identitiesLoad({identities: identities}));
    // this.store$.dispatch(subscriptionsLoad({subscriptions: subscriptions}));
    return true;
  }
}
