import { Integration } from '../../models/integration.model';
import { vendorsAirbyteHashed } from '../logos-hashed-airbyte';
import { productIdOpenbridgeAirbyte } from '../product-ids';


export const integrationAirbridgeAircall: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Aircall',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-aircall',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Aircall Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-aircall',
  logoPath: vendorsAirbyteHashed.logoAircallSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/aircall',
    uri: 'https://docs.airbyte.com/integrations/sources/aircall'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-aircall'
  },
  includeRegions: false
};

export const integrationAirbridgeAirtable: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Airtable',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-airtable',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Airtable Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-airtable',
  logoPath: vendorsAirbyteHashed.logoAirtableSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/airtable',
    uri: 'https://docs.airbyte.com/integrations/sources/airtable'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-airtable'
  },
  includeRegions: false
};

export const integrationAirbridgeAlloyDBforPostgreSQL: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'AlloyDB for PostgreSQL',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-alloydb-for-postgresql',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source AlloyDB for PostgreSQL Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-alloydb-for-postgresql',
  logoPath: vendorsAirbyteHashed.logoAlloydbforpostgresqlSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/alloydb',
    uri: 'https://docs.airbyte.com/integrations/sources/alloydb'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-alloydb'
  },
  includeRegions: false
};

export const integrationAirbridgeAlphaVantage: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Alpha Vantage',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-alpha-vantage',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Alpha Vantage Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-alpha-vantage',
  logoPath: vendorsAirbyteHashed.logoAlphavantageSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/alpha-vantage',
    uri: 'https://docs.airbyte.com/integrations/sources/alpha-vantage'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-alpha-vantage'
  },
  includeRegions: false
};

export const integrationAirbridgeAmazonAds: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Amazon Ads',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-amazon-ads',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Amazon Ads Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-amazon-ads',
  logoPath: vendorsAirbyteHashed.logoAmazonairbyteSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/amazon-ads',
    uri: 'https://docs.airbyte.com/integrations/sources/amazon-ads'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-amazon-ads'
  },
  includeRegions: false
};

export const integrationAirbridgeAmazonSellerCentral: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Amazon Seller Central',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-amazon-seller-partner',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Amazon Seller Central Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-amazon-seller-partner',
  logoPath: vendorsAirbyteHashed.logoAmazonairbyteSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/amazon-seller-partner',
    uri: 'https://docs.airbyte.com/integrations/sources/amazon-seller-partner'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-amazon-seller-partner'
  },
  includeRegions: false
};

export const integrationAirbridgeAmazonSQS: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Amazon SQS',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-amazon-sqs',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Amazon SQS Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-amazon-sqs',
  logoPath: vendorsAirbyteHashed.logoAmazonsqsSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/amazon-sqs',
    uri: 'https://docs.airbyte.com/integrations/sources/amazon-sqs'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-amazon-sqs'
  },
  includeRegions: false
};

export const integrationAirbridgeActiveCampaign: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'ActiveCampaign',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-activecampaign',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source ActiveCampaign Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-activecampaign',
  logoPath: vendorsAirbyteHashed.logoActivecampaignSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/activecampaign',
    uri: 'https://docs.airbyte.com/integrations/sources/activecampaign'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-activecampaign'
  },
  includeRegions: false
};

export const integrationAirbridgeAmplitude: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Amplitude',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-amplitude',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Amplitude Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-amplitude',
  logoPath: vendorsAirbyteHashed.logoAmplitudeSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/amplitude',
    uri: 'https://docs.airbyte.com/integrations/sources/amplitude'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-amplitude'
  },
  includeRegions: false
};

export const integrationAirbridgeAdjust: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Adjust',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-adjust',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Adjust Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-adjust',
  logoPath: vendorsAirbyteHashed.logoAdjustSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/adjust',
    uri: 'https://docs.airbyte.com/integrations/sources/adjust'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-adjust'
  },
  includeRegions: false
};

export const integrationAirbridgeApifyDataset: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Apify Dataset',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-apify-dataset',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Apify Dataset Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-apify-dataset',
  logoPath: vendorsAirbyteHashed.logoApifySvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/apify-dataset',
    uri: 'https://docs.airbyte.com/integrations/sources/apify-dataset'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-apify-dataset'
  },
  includeRegions: false
};

export const integrationAirbridgeAppleSearchAds: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Apple Search Ads',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-apple-search-ads',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Apple Search Ads Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-apple-search-ads',
  logoPath: vendorsAirbyteHashed.logoAppleadsSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/apple-search-ads',
    uri: 'https://docs.airbyte.com/integrations/sources/apple-search-ads'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-apple-search-ads'
  },
  includeRegions: false
};

export const integrationAirbridgeAppsFlyer: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'AppsFlyer',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-appsflyer',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source AppsFlyer Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-appsflyer',
  logoPath: vendorsAirbyteHashed.logoAppsflyerSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/appsflyer',
    uri: 'https://docs.airbyte.com/integrations/sources/appsflyer'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-appsflyer'
  },
  includeRegions: false
};

export const integrationAirbridgeAppstore: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Appstore',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-appstore',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Appstore Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-appstore',
  logoPath: vendorsAirbyteHashed.logoAppstoreSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/appstore-singer',
    uri: 'https://docs.airbyte.com/integrations/sources/appstore-singer'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-appstore-singer'
  },
  includeRegions: false
};

export const integrationAirbridgeAsana: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Asana',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-asana',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Asana Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-asana',
  logoPath: vendorsAirbyteHashed.logoAsanaSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/asana',
    uri: 'https://docs.airbyte.com/integrations/sources/asana'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-asana'
  },
  includeRegions: false
};

export const integrationAirbridgeAuth0: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Auth0',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-auth0',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Auth0 Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-auth0',
  logoPath: vendorsAirbyteHashed.logoAuth0Svg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/auth0',
    uri: 'https://docs.airbyte.com/integrations/sources/auth0'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-auth0'
  },
  includeRegions: false
};

export const integrationAirbridgeAWSCloudTrail: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'AWS CloudTrail',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-aws-cloudtrail',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source AWS CloudTrail Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-aws-cloudtrail',
  logoPath: vendorsAirbyteHashed.logoAwscloudtrailSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/aws-cloudtrail',
    uri: 'https://docs.airbyte.com/integrations/sources/aws-cloudtrail'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-aws-cloudtrail'
  },
  includeRegions: false
};

export const integrationAirbridgeAshby: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Ashby',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-ashby',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Ashby Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-ashby',
  logoPath: vendorsAirbyteHashed.logoAshbySvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/ashby',
    uri: 'https://docs.airbyte.com/integrations/sources/ashby'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-ashby'
  },
  includeRegions: false
};

export const integrationAirbridgeAzureTableStorage: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Azure Table Storage',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-azure-table-storage',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Azure Table Storage Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-azure-table-storage',
  logoPath: vendorsAirbyteHashed.logoAzuretablestorageSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/azure-table',
    uri: 'https://docs.airbyte.com/integrations/sources/azure-table'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-azure-table'
  },
  includeRegions: false
};

export const integrationAirbridgeBabelforce: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Babelforce',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-babelforce',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Babelforce Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-babelforce',
  logoPath: vendorsAirbyteHashed.logoBabelforceSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/babelforce',
    uri: 'https://docs.airbyte.com/integrations/sources/babelforce'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-babelforce'
  },
  includeRegions: false
};

export const integrationAirbridgeBambooHR: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Bamboo HR',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-bamboo-hr',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Bamboo HR Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-bamboo-hr',
  logoPath: vendorsAirbyteHashed.logoBamboohrSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/bamboo-hr',
    uri: 'https://docs.airbyte.com/integrations/sources/bamboo-hr'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-bamboo-hr'
  },
  includeRegions: false
};

export const integrationAirbridgeBigCommerce: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'BigCommerce',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-bigcommerce',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source BigCommerce Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-bigcommerce',
  logoPath: vendorsAirbyteHashed.logoBigcommerceSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/bigcommerce',
    uri: 'https://docs.airbyte.com/integrations/sources/bigcommerce'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-bigcommerce'
  },
  includeRegions: false
};

export const integrationAirbridgeBigQuery: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'BigQuery',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-bigquery',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source BigQuery Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-bigquery',
  logoPath: vendorsAirbyteHashed.logoBigquerySvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/bigquery',
    uri: 'https://docs.airbyte.com/integrations/sources/bigquery'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-bigquery'
  },
  includeRegions: false
};

export const integrationAirbridgeBingAds: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Bing Ads',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-bing-ads',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Bing Ads Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-bing-ads',
  logoPath: vendorsAirbyteHashed.logoBingadsSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/bing-ads',
    uri: 'https://docs.airbyte.com/integrations/sources/bing-ads'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-bing-ads'
  },
  includeRegions: false
};

export const integrationAirbridgeBraintree: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Braintree',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-braintree',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Braintree Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-braintree',
  logoPath: vendorsAirbyteHashed.logoBraintreeSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/braintree',
    uri: 'https://docs.airbyte.com/integrations/sources/braintree'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-braintree'
  },
  includeRegions: false
};

export const integrationAirbridgeBraze: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Braze',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-braze',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Braze Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-braze',
  logoPath: vendorsAirbyteHashed.logoBrazeSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/braze',
    uri: 'https://docs.airbyte.com/integrations/sources/braze'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-braze'
  },
  includeRegions: false
};

export const integrationAirbridgeAzureBlobStorage: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Azure Blob Storage',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-azure-blob-storage',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Azure Blob Storage Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-azure-blob-storage',
  logoPath: vendorsAirbyteHashed.logoAzureblobstorageSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/azure-blob-storage',
    uri: 'https://docs.airbyte.com/integrations/sources/azure-blob-storage'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-azure-blob-storage'
  },
  includeRegions: false
};

export const integrationAirbridgeBreezometer: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Breezometer',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-breezometer',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Breezometer Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-breezometer',
  logoPath: vendorsAirbyteHashed.logoBreezometerSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/breezometer',
    uri: 'https://docs.airbyte.com/integrations/sources/breezometer'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-breezometer'
  },
  includeRegions: false
};

export const integrationAirbridgeCallRail: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'CallRail',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-callrail',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source CallRail Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-callrail',
  logoPath: vendorsAirbyteHashed.logoCallrailSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/callrail',
    uri: 'https://docs.airbyte.com/integrations/sources/callrail'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-callrail'
  },
  includeRegions: false
};

export const integrationAirbridgeCaptainData: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Captain Data',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-captain-data',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Captain Data Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-captain-data',
  logoPath: vendorsAirbyteHashed.logoCaptaindataSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/captain-data',
    uri: 'https://docs.airbyte.com/integrations/sources/captain-data'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-captain-data'
  },
  includeRegions: false
};

export const integrationAirbridgeCartcom: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Cart.com',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-cart.com',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Cart.com Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-cart.com',
  logoPath: vendorsAirbyteHashed.logoCartCom,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/cart',
    uri: 'https://docs.airbyte.com/integrations/sources/cart'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-cart'
  },
  includeRegions: false
};

export const integrationAirbridgeAppfollow: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Appfollow',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-appfollow',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Appfollow Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-appfollow',
  logoPath: vendorsAirbyteHashed.logoAppfollowSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/appfollow',
    uri: 'https://docs.airbyte.com/integrations/sources/appfollow'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-appfollow'
  },
  includeRegions: false
};

export const integrationAirbridgeChargebee: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Chargebee',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-chargebee',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Chargebee Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-chargebee',
  logoPath: vendorsAirbyteHashed.logoChargebeeSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/chargebee',
    uri: 'https://docs.airbyte.com/integrations/sources/chargebee'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-chargebee'
  },
  includeRegions: false
};

export const integrationAirbridgeClickUpAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'ClickUp API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-clickup-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source ClickUp API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-clickup-api',
  logoPath: vendorsAirbyteHashed.logoClickupapiSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/clickup-api',
    uri: 'https://docs.airbyte.com/integrations/sources/clickup-api'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-clickup-api'
  },
  includeRegions: false
};

export const integrationAirbridgeClickHouse: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'ClickHouse',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-clickhouse',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source ClickHouse Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-clickhouse',
  logoPath: vendorsAirbyteHashed.logoClickhouseSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/clickhouse',
    uri: 'https://docs.airbyte.com/integrations/sources/clickhouse'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-clickhouse'
  },
  includeRegions: false
};

export const integrationAirbridgeClockify: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Clockify',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-clockify',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Clockify Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-clockify',
  logoPath: vendorsAirbyteHashed.logoClockifySvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/clockify',
    uri: 'https://docs.airbyte.com/integrations/sources/clockify'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-clockify'
  },
  includeRegions: false
};

export const integrationAirbridgeClosecom: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Close.com',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-close.com',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Close.com Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-close.com',
  logoPath: vendorsAirbyteHashed.logoCloseCom,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/close-com',
    uri: 'https://docs.airbyte.com/integrations/sources/close-com'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-close-com'
  },
  includeRegions: false
};

export const integrationAirbridgeCockroachDB: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'CockroachDB',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-cockroachdb',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source CockroachDB Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-cockroachdb',
  logoPath: vendorsAirbyteHashed.logoCockroachdbSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/cockroachdb',
    uri: 'https://docs.airbyte.com/integrations/sources/cockroachdb'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-cockroachdb'
  },
  includeRegions: false
};

export const integrationAirbridgeCoda: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Coda',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-coda',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Coda Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-coda',
  logoPath: vendorsAirbyteHashed.logoCodaSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/coda',
    uri: 'https://docs.airbyte.com/integrations/sources/coda'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-coda'
  },
  includeRegions: false
};

export const integrationAirbridgeCoinAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'CoinAPI',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-coinapi',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source CoinAPI Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-coinapi',
  logoPath: vendorsAirbyteHashed.logoCoinapiSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/coin-api',
    uri: 'https://docs.airbyte.com/integrations/sources/coin-api'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-coin-api'
  },
  includeRegions: false
};

export const integrationAirbridgeCoinGeckoCoins: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'CoinGecko Coins',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-coingecko-coins',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source CoinGecko Coins Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-coingecko-coins',
  logoPath: vendorsAirbyteHashed.logoCoingeckocoinsSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/coingecko-coins',
    uri: 'https://docs.airbyte.com/integrations/sources/coingecko-coins'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-coingecko-coins'
  },
  includeRegions: false
};

export const integrationAirbridgeCoinmarketcapAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Coinmarketcap API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-coinmarketcap-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Coinmarketcap API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-coinmarketcap-api',
  logoPath: vendorsAirbyteHashed.logoCoinmarketcapapiSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/coinmarketcap',
    uri: 'https://docs.airbyte.com/integrations/sources/coinmarketcap'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-coinmarketcap'
  },
  includeRegions: false
};

export const integrationAirbridgeCommcare: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Commcare',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-commcare',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Commcare Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-commcare',
  logoPath: vendorsAirbyteHashed.logoCommcareSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/commcare',
    uri: 'https://docs.airbyte.com/integrations/sources/commcare'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-commcare'
  },
  includeRegions: false
};

export const integrationAirbridgeCommercetools: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Commercetools',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-commercetools',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Commercetools Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-commercetools',
  logoPath: vendorsAirbyteHashed.logoCommercetoolsSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/commercetools',
    uri: 'https://docs.airbyte.com/integrations/sources/commercetools'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-commercetools'
  },
  includeRegions: false
};

export const integrationAirbridgeConfigcatAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Configcat API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-configcat-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Configcat API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-configcat-api',
  logoPath: vendorsAirbyteHashed.logoConfigcatapiSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/configcat',
    uri: 'https://docs.airbyte.com/integrations/sources/configcat'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-configcat'
  },
  includeRegions: false
};

export const integrationAirbridgeConfluence: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Confluence',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-confluence',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Confluence Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-confluence',
  logoPath: vendorsAirbyteHashed.logoConfluenceSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/confluence',
    uri: 'https://docs.airbyte.com/integrations/sources/confluence'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-confluence'
  },
  includeRegions: false
};

export const integrationAirbridgeConvertKit: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'ConvertKit',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-convertkit',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source ConvertKit Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-convertkit',
  logoPath: vendorsAirbyteHashed.logoConvertkitSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/convertkit',
    uri: 'https://docs.airbyte.com/integrations/sources/convertkit'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-convertkit'
  },
  includeRegions: false
};

export const integrationAirbridgeConvex: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Convex',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-convex',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Convex Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-convex',
  logoPath: vendorsAirbyteHashed.logoConvexSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/convex',
    uri: 'https://docs.airbyte.com/integrations/sources/convex'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-convex'
  },
  includeRegions: false
};

export const integrationAirbridgeCopper: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Copper',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-copper',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Copper Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-copper',
  logoPath: vendorsAirbyteHashed.logoCopperSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/copper',
    uri: 'https://docs.airbyte.com/integrations/sources/copper'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-copper'
  },
  includeRegions: false
};

export const integrationAirbridgeCustomerio: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Customer.io',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-customer.io',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Customer.io Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-customer.io',
  logoPath: vendorsAirbyteHashed.logoCustomerIo,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/customer-io',
    uri: 'https://docs.airbyte.com/integrations/sources/customer-io'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'farosai/airbyte-customer-io-source'
  },
  includeRegions: false
};

export const integrationAirbridgeChartmogul: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Chartmogul',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-chartmogul',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Chartmogul Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-chartmogul',
  logoPath: vendorsAirbyteHashed.logoChartmogulSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/chartmogul',
    uri: 'https://docs.airbyte.com/integrations/sources/chartmogul'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-chartmogul'
  },
  includeRegions: false
};

export const integrationAirbridgeDataScope: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'DataScope',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-datascope',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source DataScope Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-datascope',
  logoPath: vendorsAirbyteHashed.logoDatascopeSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/datascope',
    uri: 'https://docs.airbyte.com/integrations/sources/datascope'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-datascope'
  },
  includeRegions: false
};

export const integrationAirbridgeDb2: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Db2',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-db2',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Db2 Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-db2',
  logoPath: vendorsAirbyteHashed.logoDb2Svg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/db2',
    uri: 'https://docs.airbyte.com/integrations/sources/db2'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-db2'
  },
  includeRegions: false
};

export const integrationAirbridgeDelighted: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Delighted',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-delighted',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Delighted Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-delighted',
  logoPath: vendorsAirbyteHashed.logoDelightedSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/delighted',
    uri: 'https://docs.airbyte.com/integrations/sources/delighted'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-delighted'
  },
  includeRegions: false
};

export const integrationAirbridgeCourier: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Courier',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-courier',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Courier Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-courier',
  logoPath: vendorsAirbyteHashed.logoCourierSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/courier',
    uri: 'https://docs.airbyte.com/integrations/sources/courier'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-courier'
  },
  includeRegions: false
};

export const integrationAirbridgeDixa: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Dixa',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-dixa',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Dixa Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-dixa',
  logoPath: vendorsAirbyteHashed.logoDixaSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/dixa',
    uri: 'https://docs.airbyte.com/integrations/sources/dixa'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-dixa'
  },
  includeRegions: false
};

export const integrationAirbridgeChargify: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Chargify',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-chargify',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Chargify Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-chargify',
  logoPath: vendorsAirbyteHashed.logoChargifySvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/chargify',
    uri: 'https://docs.airbyte.com/integrations/sources/chargify'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-chargify'
  },
  includeRegions: false
};

export const integrationAirbridgeDremio: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Dremio',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-dremio',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Dremio Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-dremio',
  logoPath: vendorsAirbyteHashed.logoDremioSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/dremio',
    uri: 'https://docs.airbyte.com/integrations/sources/dremio'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-dremio'
  },
  includeRegions: false
};

export const integrationAirbridgeDatadog: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Datadog',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-datadog',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Datadog Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-datadog',
  logoPath: vendorsAirbyteHashed.logoDatadogSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/datadog',
    uri: 'https://docs.airbyte.com/integrations/sources/datadog'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-datadog'
  },
  includeRegions: false
};

export const integrationAirbridgeDockerhub: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Dockerhub',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-dockerhub',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Dockerhub Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-dockerhub',
  logoPath: vendorsAirbyteHashed.logoDockerhubSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/dockerhub',
    uri: 'https://docs.airbyte.com/integrations/sources/dockerhub'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-dockerhub'
  },
  includeRegions: false
};

export const integrationAirbridgeElasticsearch: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Elasticsearch',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-elasticsearch',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Elasticsearch Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-elasticsearch',
  logoPath: vendorsAirbyteHashed.logoElasticsearchSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/elasticsearch',
    uri: 'https://docs.airbyte.com/integrations/sources/elasticsearch'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-elasticsearch'
  },
  includeRegions: false
};

export const integrationAirbridgeEmailOctopus: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'EmailOctopus',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-emailoctopus',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source EmailOctopus Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-emailoctopus',
  logoPath: vendorsAirbyteHashed.logoEmailoctopusSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/emailoctopus',
    uri: 'https://docs.airbyte.com/integrations/sources/emailoctopus'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-emailoctopus'
  },
  includeRegions: false
};

export const integrationAirbridgeDrift: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Drift',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-drift',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Drift Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-drift',
  logoPath: vendorsAirbyteHashed.logoDriftSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/drift',
    uri: 'https://docs.airbyte.com/integrations/sources/drift'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-drift'
  },
  includeRegions: false
};

export const integrationAirbridgeEndtoEndTestingSource: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'End-to-End Testing Source',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-end-to-end-testing-source',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source End-to-End Testing Source Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-end-to-end-testing-source',
  logoPath: vendorsAirbyteHashed.logoOpensourceSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/e2e-test',
    uri: 'https://docs.airbyte.com/integrations/sources/e2e-test'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-e2e-test'
  },
  includeRegions: false
};

export const integrationAirbridgeDynamodb: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Dynamodb',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-dynamodb',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Dynamodb Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-dynamodb',
  logoPath: vendorsAirbyteHashed.logoDynamodbSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/dynamodb',
    uri: 'https://docs.airbyte.com/integrations/sources/dynamodb'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-dynamodb'
  },
  includeRegions: false
};

export const integrationAirbridgeEverhour: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Everhour',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-everhour',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Everhour Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-everhour',
  logoPath: vendorsAirbyteHashed.logoEverhourSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/everhour',
    uri: 'https://docs.airbyte.com/integrations/sources/everhour'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-everhour'
  },
  includeRegions: false
};

export const integrationAirbridgeExchangeRatesAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Exchange Rates API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-exchange-rates-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Exchange Rates API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-exchange-rates-api',
  logoPath: vendorsAirbyteHashed.logoExchangeratesapiSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/exchange-rates',
    uri: 'https://docs.airbyte.com/integrations/sources/exchange-rates'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-exchange-rates'
  },
  includeRegions: false
};

export const integrationAirbridgeFacebookMarketing: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Facebook Marketing',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-facebook-marketing',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Facebook Marketing Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-facebook-marketing',
  logoPath: vendorsAirbyteHashed.logoFacebookSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/facebook-marketing',
    uri: 'https://docs.airbyte.com/integrations/sources/facebook-marketing'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-facebook-marketing'
  },
  includeRegions: false
};

export const integrationAirbridgeFacebookPagePostReactions: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Facebook Page Post Reactions',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'facebook-post-reactions',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Facebook Page Post Reactions Airbyte connectors.',
  routerLink: '/wizards/facebook-post-reactions',
  logoPath: vendorsAirbyteHashed.logoFacebookSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/facebook-pages',
    uri: 'https://docs.airbyte.com/integrations/sources/facebook-pages'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: ''
  },
  includeRegions: false
};

export const integrationAirbridgeFaker: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Faker',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-faker',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Faker Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-faker',
  logoPath: vendorsAirbyteHashed.logoFakerSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/faker',
    uri: 'https://docs.airbyte.com/integrations/sources/faker'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-faker'
  },
  includeRegions: false
};

export const integrationAirbridgeFastbill: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Fastbill',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-fastbill',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Fastbill Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-fastbill',
  logoPath: vendorsAirbyteHashed.logoFastbillSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/fastbill',
    uri: 'https://docs.airbyte.com/integrations/sources/fastbill'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-fastbill'
  },
  includeRegions: false
};

export const integrationAirbridgeFauna: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Fauna',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-fauna',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Fauna Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-fauna',
  logoPath: vendorsAirbyteHashed.logoFaunaSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/fauna',
    uri: 'https://docs.airbyte.com/integrations/sources/fauna'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-fauna'
  },
  includeRegions: false
};

export const integrationAirbridgeFiles: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Files',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-files-csv-json-excel-feather-parquet',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Files Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-files-csv-json-excel-feather-parquet',
  logoPath: vendorsAirbyteHashed.logoFileSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/file',
    uri: 'https://docs.airbyte.com/integrations/sources/file'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-file'
  },
  includeRegions: false
};

export const integrationAirbridgeFirebaseRealtimeDatabase: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Firebase Realtime Database',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-firebase-realtime-database',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Firebase Realtime Database Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-firebase-realtime-database',
  logoPath: vendorsAirbyteHashed.logoFirebaseSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/firebase-realtime-database',
    uri: 'https://docs.airbyte.com/integrations/sources/firebase-realtime-database'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-firebase-realtime-database'
  },
  includeRegions: false
};

export const integrationAirbridgeFirebolt: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Firebolt',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-firebolt',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Firebolt Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-firebolt',
  logoPath: vendorsAirbyteHashed.logoFireboltSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/firebolt',
    uri: 'https://docs.airbyte.com/integrations/sources/firebolt'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-firebolt'
  },
  includeRegions: false
};

export const integrationAirbridgeFlexport: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Flexport',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-flexport',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Flexport Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-flexport',
  logoPath: vendorsAirbyteHashed.logoFlexportSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/flexport',
    uri: 'https://docs.airbyte.com/integrations/sources/flexport'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-flexport'
  },
  includeRegions: false
};

export const integrationAirbridgeFreshcaller: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Freshcaller',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-freshcaller',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Freshcaller Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-freshcaller',
  logoPath: vendorsAirbyteHashed.logoFreshcallerSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/freshcaller',
    uri: 'https://docs.airbyte.com/integrations/sources/freshcaller'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-freshcaller'
  },
  includeRegions: false
};

export const integrationAirbridgeFreshdesk: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Freshdesk',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-freshdesk',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Freshdesk Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-freshdesk',
  logoPath: vendorsAirbyteHashed.logoFreshdeskSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/freshdesk',
    uri: 'https://docs.airbyte.com/integrations/sources/freshdesk'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-freshdesk'
  },
  includeRegions: false
};

export const integrationAirbridgeDisplayVideo360: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Display & Video 360',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-display-&-video-360',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Display & Video 360 Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-display-&-video-360',
  logoPath: vendorsAirbyteHashed.logoGoogledisplayvideo360Svg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/dv-360',
    uri: 'https://docs.airbyte.com/integrations/sources/dv-360'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-dv-360'
  },
  includeRegions: false
};

export const integrationAirbridgeFreshsales: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Freshsales',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-freshsales',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Freshsales Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-freshsales',
  logoPath: vendorsAirbyteHashed.logoFreshsalesSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/freshsales',
    uri: 'https://docs.airbyte.com/integrations/sources/freshsales'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-freshsales'
  },
  includeRegions: false
};

export const integrationAirbridgeFullStory: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'FullStory',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-fullstory',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source FullStory Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-fullstory',
  logoPath: vendorsAirbyteHashed.logoFullstorySvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/fullstory',
    uri: 'https://docs.airbyte.com/integrations/sources/fullstory'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-fullstory'
  },
  includeRegions: false
};

export const integrationAirbridgeGainsightAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Gainsight-API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-gainsight-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Gainsight-API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-gainsight-api',
  logoPath: vendorsAirbyteHashed.logoGainsightSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/gainsight-px',
    uri: 'https://docs.airbyte.com/integrations/sources/gainsight-px'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-gainsight-px'
  },
  includeRegions: false
};

export const integrationAirbridgeGenesys: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Genesys',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-genesys',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Genesys Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-genesys',
  logoPath: vendorsAirbyteHashed.logoGenesysSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/genesys',
    uri: 'https://docs.airbyte.com/integrations/sources/genesys'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-genesys'
  },
  includeRegions: false
};

export const integrationAirbridgeFreshservice: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Freshservice',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-freshservice',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Freshservice Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-freshservice',
  logoPath: vendorsAirbyteHashed.logoFreshserviceSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/freshservice',
    uri: 'https://docs.airbyte.com/integrations/sources/freshservice'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-freshservice'
  },
  includeRegions: false
};

export const integrationAirbridgeGitHub: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'GitHub',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-github',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source GitHub Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-github',
  logoPath: vendorsAirbyteHashed.logoGithubSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/github',
    uri: 'https://docs.airbyte.com/integrations/sources/github'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-github'
  },
  includeRegions: false
};

export const integrationAirbridgeGlassfrog: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Glassfrog',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-glassfrog',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Glassfrog Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-glassfrog',
  logoPath: vendorsAirbyteHashed.logoGlassfrogSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/glassfrog',
    uri: 'https://docs.airbyte.com/integrations/sources/glassfrog'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-glassfrog'
  },
  includeRegions: false
};

export const integrationAirbridgeGitLab: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'GitLab',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-gitlab',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source GitLab Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-gitlab',
  logoPath: vendorsAirbyteHashed.logoGitlabSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/gitlab',
    uri: 'https://docs.airbyte.com/integrations/sources/gitlab'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-gitlab'
  },
  includeRegions: false
};

export const integrationAirbridgeGNews: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'GNews',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-gnews',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source GNews Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-gnews',
  logoPath: vendorsAirbyteHashed.logoGnewsSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/gnews',
    uri: 'https://docs.airbyte.com/integrations/sources/gnews'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-gnews'
  },
  includeRegions: false
};

export const integrationAirbridgeGoCardless: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'GoCardless',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-gocardless',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source GoCardless Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-gocardless',
  logoPath: vendorsAirbyteHashed.logoGocardlessSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/gocardless',
    uri: 'https://docs.airbyte.com/integrations/sources/gocardless'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-gocardless'
  },
  includeRegions: false
};

export const integrationAirbridgeGCS: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'GCS',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-gcs',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source GCS Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-gcs',
  logoPath: vendorsAirbyteHashed.logoGcsSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/gcs',
    uri: 'https://docs.airbyte.com/integrations/sources/gcs'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-gcs'
  },
  includeRegions: false
};

export const integrationAirbridgeGong: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Gong',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-gong',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Gong Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-gong',
  logoPath: vendorsAirbyteHashed.logoGongSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/gong',
    uri: 'https://docs.airbyte.com/integrations/sources/gong'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-gong'
  },
  includeRegions: false
};

export const integrationAirbridgeGoogleAds: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Google Ads',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-google-ads',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Google Ads Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-google-ads',
  logoPath: vendorsAirbyteHashed.logoGoogleadsSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/google-ads',
    uri: 'https://docs.airbyte.com/integrations/sources/google-ads'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-google-ads'
  },
  includeRegions: false
};

export const integrationAirbridgeGoogleAnalyticsUniversalAnalytics: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Google Analytics Universal Analytics',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-google-analytics-universal-analytics',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Google Analytics Universal Analytics Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-google-analytics-universal-analytics',
  logoPath: vendorsAirbyteHashed.logoGoogleanalyticsSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/google-analytics-v4',
    uri: 'https://docs.airbyte.com/integrations/sources/google-analytics-v4'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-google-analytics-v4'
  },
  includeRegions: false
};

export const integrationAirbridgeGoogleAnalytics4: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Google Analytics 4',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-google-analytics-4-ga4',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Google Analytics 4 Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-google-analytics-4-ga4',
  logoPath: vendorsAirbyteHashed.logoGoogleanalyticsSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/google-analytics-data-api',
    uri: 'https://docs.airbyte.com/integrations/sources/google-analytics-data-api'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-google-analytics-data-api'
  },
  includeRegions: false
};

export const integrationAirbridgeGoogleDirectory: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Google Directory',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-google-directory',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Google Directory Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-google-directory',
  logoPath: vendorsAirbyteHashed.logoGoogleSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/google-directory',
    uri: 'https://docs.airbyte.com/integrations/sources/google-directory'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-google-directory'
  },
  includeRegions: false
};

export const integrationAirbridgegetLagoAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'getLago API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-getlago-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source getLago API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-getlago-api',
  logoPath: vendorsAirbyteHashed.logoGetlagoSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/getlago',
    uri: 'https://docs.airbyte.com/integrations/sources/getlago'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-getlago'
  },
  includeRegions: false
};

export const integrationAirbridgeGooglePageSpeedInsights: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Google PageSpeed Insights',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-google-pagespeed-insights',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Google PageSpeed Insights Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-google-pagespeed-insights',
  logoPath: vendorsAirbyteHashed.logoGooglepagespeedinsightsSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/google-pagespeed-insights',
    uri: 'https://docs.airbyte.com/integrations/sources/google-pagespeed-insights'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-google-pagespeed-insights'
  },
  includeRegions: false
};

export const integrationAirbridgeGoogleSearchConsole: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Google Search Console',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-google-search-console',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Google Search Console Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-google-search-console',
  logoPath: vendorsAirbyteHashed.logoGoogleSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/google-search-console',
    uri: 'https://docs.airbyte.com/integrations/sources/google-search-console'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-google-search-console'
  },
  includeRegions: false
};

export const integrationAirbridgeGreenhouse: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Greenhouse',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-greenhouse',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Greenhouse Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-greenhouse',
  logoPath: vendorsAirbyteHashed.logoGreenhouseSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/greenhouse',
    uri: 'https://docs.airbyte.com/integrations/sources/greenhouse'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-greenhouse'
  },
  includeRegions: false
};

export const integrationAirbridgeGooglewebfonts: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Google-webfonts',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-google-webfonts',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Google-webfonts Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-google-webfonts',
  logoPath: vendorsAirbyteHashed.logoGoogleSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/google-webfonts',
    uri: 'https://docs.airbyte.com/integrations/sources/google-webfonts'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-google-webfonts'
  },
  includeRegions: false
};

export const integrationAirbridgeGoogleSheets: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Google Sheets',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-google-sheets',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Google Sheets Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-google-sheets',
  logoPath: vendorsAirbyteHashed.logoGooglesheetsSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/google-sheets',
    uri: 'https://docs.airbyte.com/integrations/sources/google-sheets'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-google-sheets'
  },
  includeRegions: false
};

export const integrationAirbridgeGoogleWorkspaceAdminReports: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Google Workspace Admin Reports',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-google-workspace-admin-reports',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Google Workspace Admin Reports Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-google-workspace-admin-reports',
  logoPath: vendorsAirbyteHashed.logoGoogleSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/google-workspace-admin-reports',
    uri: 'https://docs.airbyte.com/integrations/sources/google-workspace-admin-reports'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-google-workspace-admin-reports'
  },
  includeRegions: false
};

export const integrationAirbridgeHubplanner: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Hubplanner',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-hubplanner',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Hubplanner Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-hubplanner',
  logoPath: vendorsAirbyteHashed.logoHubplannerSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/hubplanner',
    uri: 'https://docs.airbyte.com/integrations/sources/hubplanner'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-hubplanner'
  },
  includeRegions: false
};

export const integrationAirbridgeHarness: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Harness',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-harness',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Harness Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-harness',
  logoPath: vendorsAirbyteHashed.logoHarnessSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/harness',
    uri: 'https://docs.airbyte.com/integrations/sources/harness'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'farosai/airbyte-harness-source'
  },
  includeRegions: false
};

export const integrationAirbridgeGridly: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Gridly',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-gridly',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Gridly Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-gridly',
  logoPath: vendorsAirbyteHashed.logoGridlySvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/gridly',
    uri: 'https://docs.airbyte.com/integrations/sources/gridly'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-gridly'
  },
  includeRegions: false
};

export const integrationAirbridgeInstagram: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Instagram',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-instagram',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Instagram Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-instagram',
  logoPath: vendorsAirbyteHashed.logoInstagramSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/instagram',
    uri: 'https://docs.airbyte.com/integrations/sources/instagram'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-instagram'
  },
  includeRegions: false
};

export const integrationAirbridgeInstatus: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Instatus',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-instatus',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Instatus Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-instatus',
  logoPath: vendorsAirbyteHashed.logoInstatusSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/instatus',
    uri: 'https://docs.airbyte.com/integrations/sources/instatus'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-instatus'
  },
  includeRegions: false
};

export const integrationAirbridgeHubSpot: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'HubSpot',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-hubspot',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source HubSpot Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-hubspot',
  logoPath: vendorsAirbyteHashed.logoHubspotSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/hubspot',
    uri: 'https://docs.airbyte.com/integrations/sources/hubspot'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-hubspot'
  },
  includeRegions: false
};

export const integrationAirbridgeInsightly: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Insightly',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-insightly',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Insightly Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-insightly',
  logoPath: vendorsAirbyteHashed.logoInsightlySvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/insightly',
    uri: 'https://docs.airbyte.com/integrations/sources/insightly'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-insightly'
  },
  includeRegions: false
};

export const integrationAirbridgeIntercom: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Intercom',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-intercom',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Intercom Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-intercom',
  logoPath: vendorsAirbyteHashed.logoIntercomSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/intercom',
    uri: 'https://docs.airbyte.com/integrations/sources/intercom'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-intercom'
  },
  includeRegions: false
};

export const integrationAirbridgeIp2whoisAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Ip2whois API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-ip2whois-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Ip2whois API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-ip2whois-api',
  logoPath: vendorsAirbyteHashed.logoIp2whoisSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/ip2whois',
    uri: 'https://docs.airbyte.com/integrations/sources/ip2whois'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-ip2whois'
  },
  includeRegions: false
};

export const integrationAirbridgeIterable: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Iterable',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-iterable',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Iterable Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-iterable',
  logoPath: vendorsAirbyteHashed.logoIterableSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/iterable',
    uri: 'https://docs.airbyte.com/integrations/sources/iterable'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-iterable'
  },
  includeRegions: false
};

export const integrationAirbridgeIntruderioAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Intruder.io API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-intruder.io-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Intruder.io API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-intruder.io-api',
  logoPath: vendorsAirbyteHashed.logoIntruderSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/intruder',
    uri: 'https://docs.airbyte.com/integrations/sources/intruder'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-intruder'
  },
  includeRegions: false
};

export const integrationAirbridgeHarvest: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Harvest',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-harvest',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Harvest Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-harvest',
  logoPath: vendorsAirbyteHashed.logoHarvestSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/harvest',
    uri: 'https://docs.airbyte.com/integrations/sources/harvest'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-harvest'
  },
  includeRegions: false
};

export const integrationAirbridgeJenkins: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Jenkins',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-jenkins',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Jenkins Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-jenkins',
  logoPath: vendorsAirbyteHashed.logoJenkinsSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/jenkins',
    uri: 'https://docs.airbyte.com/integrations/sources/jenkins'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'farosai/airbyte-jenkins-source'
  },
  includeRegions: false
};

export const integrationAirbridgeK6CloudAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'K6 Cloud API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-k6-cloud-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source K6 Cloud API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-k6-cloud-api',
  logoPath: vendorsAirbyteHashed.logoK6cloudSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/k6-cloud',
    uri: 'https://docs.airbyte.com/integrations/sources/k6-cloud'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-k6-cloud'
  },
  includeRegions: false
};

export const integrationAirbridgeKafka: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Kafka',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-kafka',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Kafka Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-kafka',
  logoPath: vendorsAirbyteHashed.logoKafkaSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/kafka',
    uri: 'https://docs.airbyte.com/integrations/sources/kafka'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-kafka'
  },
  includeRegions: false
};

export const integrationAirbridgeJira: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Jira',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-jira',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Jira Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-jira',
  logoPath: vendorsAirbyteHashed.logoJiraSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/jira',
    uri: 'https://docs.airbyte.com/integrations/sources/jira'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-jira'
  },
  includeRegions: false
};

export const integrationAirbridgeKlarna: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Klarna',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-klarna',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Klarna Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-klarna',
  logoPath: vendorsAirbyteHashed.logoKlarnaSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/klarna',
    uri: 'https://docs.airbyte.com/integrations/sources/klarna'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-klarna'
  },
  includeRegions: false
};

export const integrationAirbridgeGutendex: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Gutendex',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-gutendex',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Gutendex Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-gutendex',
  logoPath: vendorsAirbyteHashed.logoOpensourceSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/gutendex',
    uri: 'https://docs.airbyte.com/integrations/sources/gutendex'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-gutendex'
  },
  includeRegions: false
};

export const integrationAirbridgeKyriba: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Kyriba',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-kyriba',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Kyriba Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-kyriba',
  logoPath: vendorsAirbyteHashed.logoKyribaSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/kyriba',
    uri: 'https://docs.airbyte.com/integrations/sources/kyriba'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-kyriba'
  },
  includeRegions: false
};

export const integrationAirbridgeKlaviyo: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Klaviyo',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-klaviyo',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Klaviyo Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-klaviyo',
  logoPath: vendorsAirbyteHashed.logoKlaviyoSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/klaviyo',
    uri: 'https://docs.airbyte.com/integrations/sources/klaviyo'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-klaviyo'
  },
  includeRegions: false
};

export const integrationAirbridgeKustomer: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Kustomer',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-kustomer',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Kustomer Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-kustomer',
  logoPath: vendorsAirbyteHashed.logoKustomerSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/kustomer-singer',
    uri: 'https://docs.airbyte.com/integrations/sources/kustomer-singer'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-kustomer-singer'
  },
  includeRegions: false
};

export const integrationAirbridgeLaunchdarklyAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Launchdarkly API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-launchdarkly-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Launchdarkly API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-launchdarkly-api',
  logoPath: vendorsAirbyteHashed.logoLaunchdarklySvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/launchdarkly',
    uri: 'https://docs.airbyte.com/integrations/sources/launchdarkly'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-launchdarkly'
  },
  includeRegions: false
};

export const integrationAirbridgeLemlist: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Lemlist',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-lemlist',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Lemlist Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-lemlist',
  logoPath: vendorsAirbyteHashed.logoLemlistSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/lemlist',
    uri: 'https://docs.airbyte.com/integrations/sources/lemlist'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-lemlist'
  },
  includeRegions: false
};

export const integrationAirbridgeLeverHiring: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Lever Hiring',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-lever-hiring',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Lever Hiring Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-lever-hiring',
  logoPath: vendorsAirbyteHashed.logoLeverhiringSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/lever-hiring',
    uri: 'https://docs.airbyte.com/integrations/sources/lever-hiring'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-lever-hiring'
  },
  includeRegions: false
};

export const integrationAirbridgeLinkedInAds: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'LinkedIn Ads',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-linkedin-ads',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source LinkedIn Ads Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-linkedin-ads',
  logoPath: vendorsAirbyteHashed.logoLinkedinSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/linkedin-ads',
    uri: 'https://docs.airbyte.com/integrations/sources/linkedin-ads'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-linkedin-ads'
  },
  includeRegions: false
};

export const integrationAirbridgeLinkedInPages: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'LinkedIn Pages',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-linkedin-pages',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source LinkedIn Pages Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-linkedin-pages',
  logoPath: vendorsAirbyteHashed.logoLinkedinSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/linkedin-pages',
    uri: 'https://docs.airbyte.com/integrations/sources/linkedin-pages'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-linkedin-pages'
  },
  includeRegions: false
};

export const integrationAirbridgeLokalise: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Lokalise',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-lokalise',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Lokalise Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-lokalise',
  logoPath: vendorsAirbyteHashed.logoLokaliseSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/lokalise',
    uri: 'https://docs.airbyte.com/integrations/sources/lokalise'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-lokalise'
  },
  includeRegions: false
};

export const integrationAirbridgeLinnworks: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Linnworks',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-linnworks',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Linnworks Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-linnworks',
  logoPath: vendorsAirbyteHashed.logoLinnworksSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/linnworks',
    uri: 'https://docs.airbyte.com/integrations/sources/linnworks'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-linnworks'
  },
  includeRegions: false
};

export const integrationAirbridgeLooker: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Looker',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-looker',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Looker Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-looker',
  logoPath: vendorsAirbyteHashed.logoLookerSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/looker',
    uri: 'https://docs.airbyte.com/integrations/sources/looker'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-looker'
  },
  includeRegions: false
};

export const integrationAirbridgeMailchimp: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Mailchimp',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-mailchimp',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Mailchimp Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-mailchimp',
  logoPath: vendorsAirbyteHashed.logoMailchimpSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/mailchimp',
    uri: 'https://docs.airbyte.com/integrations/sources/mailchimp'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-mailchimp'
  },
  includeRegions: false
};

export const integrationAirbridgeMailerLite: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'MailerLite',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-mailerlite',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source MailerLite Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-mailerlite',
  logoPath: vendorsAirbyteHashed.logoMailerliteSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/mailerlite',
    uri: 'https://docs.airbyte.com/integrations/sources/mailerlite'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-mailerlite'
  },
  includeRegions: false
};

export const integrationAirbridgeMailersend: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Mailersend',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-mailersend',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Mailersend Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-mailersend',
  logoPath: vendorsAirbyteHashed.logoMailersendSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/mailersend',
    uri: 'https://docs.airbyte.com/integrations/sources/mailersend'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-mailersend'
  },
  includeRegions: false
};

export const integrationAirbridgeKyveSource: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Kyve Source',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-kyve-source',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Kyve Source Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-kyve-source',
  logoPath: vendorsAirbyteHashed.logoKyvesourceSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/kyve',
    uri: 'https://docs.airbyte.com/integrations/sources/kyve'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-kyve'
  },
  includeRegions: false
};

export const integrationAirbridgeMailGun: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'MailGun',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-mailgun',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source MailGun Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-mailgun',
  logoPath: vendorsAirbyteHashed.logoMailgunSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/mailgun',
    uri: 'https://docs.airbyte.com/integrations/sources/mailgun'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-mailgun'
  },
  includeRegions: false
};

export const integrationAirbridgeMailjetMailAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Mailjet Mail API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-mailjet-mail-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Mailjet Mail API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-mailjet-mail-api',
  logoPath: vendorsAirbyteHashed.logoMailjetSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/mailjet-mail',
    uri: 'https://docs.airbyte.com/integrations/sources/mailjet-mail'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-mailjet-mail'
  },
  includeRegions: false
};

export const integrationAirbridgeMailjetSMSAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Mailjet SMS API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-mailjet-sms-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Mailjet SMS API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-mailjet-sms-api',
  logoPath: vendorsAirbyteHashed.logoMailjetSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/mailjet-sms',
    uri: 'https://docs.airbyte.com/integrations/sources/mailjet-sms'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-mailjet-sms'
  },
  includeRegions: false
};

export const integrationAirbridgeMarketo: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Marketo',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-marketo',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Marketo Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-marketo',
  logoPath: vendorsAirbyteHashed.logoMarketoSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/marketo',
    uri: 'https://docs.airbyte.com/integrations/sources/marketo'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-marketo'
  },
  includeRegions: false
};

export const integrationAirbridgeMerge: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Merge',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-merge',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Merge Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-merge',
  logoPath: vendorsAirbyteHashed.logoMergeSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/merge',
    uri: 'https://docs.airbyte.com/integrations/sources/merge'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-merge'
  },
  includeRegions: false
};

export const integrationAirbridgeMetabase: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Metabase',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-metabase',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Metabase Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-metabase',
  logoPath: vendorsAirbyteHashed.logoMetabaseSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/metabase',
    uri: 'https://docs.airbyte.com/integrations/sources/metabase'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-metabase'
  },
  includeRegions: false
};

export const integrationAirbridgeMicrosoftTeams: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Microsoft Teams',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-microsoft-teams',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Microsoft Teams Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-microsoft-teams',
  logoPath: vendorsAirbyteHashed.logoMicrosoftteamsSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/microsoft-teams',
    uri: 'https://docs.airbyte.com/integrations/sources/microsoft-teams'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-microsoft-teams'
  },
  includeRegions: false
};

export const integrationAirbridgeMixpanel: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Mixpanel',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-mixpanel',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Mixpanel Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-mixpanel',
  logoPath: vendorsAirbyteHashed.logoMixpanelSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/mixpanel',
    uri: 'https://docs.airbyte.com/integrations/sources/mixpanel'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-mixpanel'
  },
  includeRegions: false
};

export const integrationAirbridgeMonday: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Monday',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-monday',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Monday Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-monday',
  logoPath: vendorsAirbyteHashed.logoMondaySvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/monday',
    uri: 'https://docs.airbyte.com/integrations/sources/monday'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-monday'
  },
  includeRegions: false
};

export const integrationAirbridgeMongoDB: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Mongo DB',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-mongo-db',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Mongo DB Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-mongo-db',
  logoPath: vendorsAirbyteHashed.logoMongdbSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/mongodb-v2',
    uri: 'https://docs.airbyte.com/integrations/sources/mongodb-v2'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-mongodb-v2'
  },
  includeRegions: false
};

export const integrationAirbridgeMyHours: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'My Hours',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-my-hours',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source My Hours Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-my-hours',
  logoPath: vendorsAirbyteHashed.logoMyhoursSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/my-hours',
    uri: 'https://docs.airbyte.com/integrations/sources/my-hours'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-my-hours'
  },
  includeRegions: false
};

export const integrationAirbridgeN8n: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'N8n',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-n8n',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source N8n Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-n8n',
  logoPath: vendorsAirbyteHashed.logoN8nSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/n8n',
    uri: 'https://docs.airbyte.com/integrations/sources/n8n'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-n8n'
  },
  includeRegions: false
};

export const integrationAirbridgeNASA: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'NASA',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-nasa',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source NASA Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-nasa',
  logoPath: vendorsAirbyteHashed.logoNasaSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/nasa',
    uri: 'https://docs.airbyte.com/integrations/sources/nasa'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-nasa'
  },
  includeRegions: false
};

export const integrationAirbridgeMySQL: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'MySQL',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-mysql',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source MySQL Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-mysql',
  logoPath: vendorsAirbyteHashed.logoMysqlSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/mysql',
    uri: 'https://docs.airbyte.com/integrations/sources/mysql'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-mysql'
  },
  includeRegions: false
};

export const integrationAirbridgeNewYorkTimes: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'New York Times',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-new-york-times',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source New York Times Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-new-york-times',
  logoPath: vendorsAirbyteHashed.logoNewyorktimesSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/nytimes',
    uri: 'https://docs.airbyte.com/integrations/sources/nytimes'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-nytimes'
  },
  includeRegions: false
};

export const integrationAirbridgeNewsAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'News API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-news-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source News API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-news-api',
  logoPath: vendorsAirbyteHashed.logoNewsapiSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/news-api',
    uri: 'https://docs.airbyte.com/integrations/sources/news-api'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-news-api'
  },
  includeRegions: false
};

export const integrationAirbridgeNewsdataAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Newsdata API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-newsdata-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Newsdata API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-newsdata-api',
  logoPath: vendorsAirbyteHashed.logoNewsdataSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/newsdata',
    uri: 'https://docs.airbyte.com/integrations/sources/newsdata'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-newsdata'
  },
  includeRegions: false
};

export const integrationAirbridgeNotion: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Notion',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-notion',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Notion Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-notion',
  logoPath: vendorsAirbyteHashed.logoNotionSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/notion',
    uri: 'https://docs.airbyte.com/integrations/sources/notion'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-notion'
  },
  includeRegions: false
};

export const integrationAirbridgeMicrosoftDataverse: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Microsoft Dataverse',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-microsoft-dataverse',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Microsoft Dataverse Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-microsoft-dataverse',
  logoPath: vendorsAirbyteHashed.logoMicrosoftdataverseSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/microsoft-dataverse',
    uri: 'https://docs.airbyte.com/integrations/sources/microsoft-dataverse'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-microsoft-dataverse'
  },
  includeRegions: false
};

export const integrationAirbridgeOmnisend: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Omnisend',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-omnisend',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Omnisend Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-omnisend',
  logoPath: vendorsAirbyteHashed.logoOmnisendSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/omnisend',
    uri: 'https://docs.airbyte.com/integrations/sources/omnisend'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-omnisend'
  },
  includeRegions: false
};

export const integrationAirbridgeMicrosoftSQLServer: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Microsoft SQL Server (MSSQL)',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-microsoft-sql-server-mssql',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Microsoft SQL Server (MSSQL) Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-microsoft-sql-server-mssql',
  logoPath: vendorsAirbyteHashed.logoMicrosoftsqlserverSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/mssql',
    uri: 'https://docs.airbyte.com/integrations/sources/mssql'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-mssql'
  },
  includeRegions: false
};

export const integrationAirbridgeOneSignal: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'OneSignal',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-onesignal',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source OneSignal Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-onesignal',
  logoPath: vendorsAirbyteHashed.logoOnesignalSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/onesignal',
    uri: 'https://docs.airbyte.com/integrations/sources/onesignal'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-onesignal'
  },
  includeRegions: false
};

export const integrationAirbridgeOpenWeather: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'OpenWeather',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-openweather',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source OpenWeather Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-openweather',
  logoPath: vendorsAirbyteHashed.logoOpenweatherSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/openweather',
    uri: 'https://docs.airbyte.com/integrations/sources/openweather'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-openweather'
  },
  includeRegions: false
};

export const integrationAirbridgeOkta: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Okta',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-okta',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Okta Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-okta',
  logoPath: vendorsAirbyteHashed.logoOktaSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/okta',
    uri: 'https://docs.airbyte.com/integrations/sources/okta'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-okta'
  },
  includeRegions: false
};

export const integrationAirbridgeOpenExchangeRates: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Open Exchange Rates',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-open-exchange-rates',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Open Exchange Rates Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-open-exchange-rates',
  logoPath: vendorsAirbyteHashed.logoOpenexchangeratesSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/open-exchange-rates',
    uri: 'https://docs.airbyte.com/integrations/sources/open-exchange-rates'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-open-exchange-rates'
  },
  includeRegions: false
};

export const integrationAirbridgeOpsgenie: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Opsgenie',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-opsgenie',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Opsgenie Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-opsgenie',
  logoPath: vendorsAirbyteHashed.logoOpsgenieSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/opsgenie',
    uri: 'https://docs.airbyte.com/integrations/sources/opsgenie'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-opsgenie'
  },
  includeRegions: false
};

export const integrationAirbridgeOracleDB: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Oracle DB',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-oracle-db',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Oracle DB Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-oracle-db',
  logoPath: vendorsAirbyteHashed.logoOracleSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/oracle',
    uri: 'https://docs.airbyte.com/integrations/sources/oracle'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-oracle'
  },
  includeRegions: false
};

export const integrationAirbridgeOrb: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Orb',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-orb',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Orb Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-orb',
  logoPath: vendorsAirbyteHashed.logoOrbSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/orb',
    uri: 'https://docs.airbyte.com/integrations/sources/orb'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-orb'
  },
  includeRegions: false
};

export const integrationAirbridgeOura: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Oura',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-oura',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Oura Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-oura',
  logoPath: vendorsAirbyteHashed.logoOuraSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/oura',
    uri: 'https://docs.airbyte.com/integrations/sources/oura'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-oura'
  },
  includeRegions: false
};

export const integrationAirbridgeOutreach: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Outreach',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-outreach',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Outreach Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-outreach',
  logoPath: vendorsAirbyteHashed.logoOutreachSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/outreach',
    uri: 'https://docs.airbyte.com/integrations/sources/outreach'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-outreach'
  },
  includeRegions: false
};

export const integrationAirbridgePagerDuty: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'PagerDuty',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-pagerduty',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source PagerDuty Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-pagerduty',
  logoPath: vendorsAirbyteHashed.logoPagerdutySvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/pagerduty',
    uri: 'https://docs.airbyte.com/integrations/sources/pagerduty'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'farosai/airbyte-pagerduty-source'
  },
  includeRegions: false
};

export const integrationAirbridgePardot: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Pardot',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-pardot',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Pardot Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-pardot',
  logoPath: vendorsAirbyteHashed.logoSalesforceSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/pardot',
    uri: 'https://docs.airbyte.com/integrations/sources/pardot'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-pardot'
  },
  includeRegions: false
};

export const integrationAirbridgePartnerstack: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Partnerstack',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-partnerstack',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Partnerstack Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-partnerstack',
  logoPath: vendorsAirbyteHashed.logoPartnerstackSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/partnerstack',
    uri: 'https://docs.airbyte.com/integrations/sources/partnerstack'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-partnerstack'
  },
  includeRegions: false
};

export const integrationAirbridgePaypalTransaction: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Paypal Transaction',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-paypal-transaction',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Paypal Transaction Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-paypal-transaction',
  logoPath: vendorsAirbyteHashed.logoPaypalSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/paypal-transaction',
    uri: 'https://docs.airbyte.com/integrations/sources/paypal-transaction'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-paypal-transaction'
  },
  includeRegions: false
};

export const integrationAirbridgePaystack: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Paystack',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-paystack',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Paystack Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-paystack',
  logoPath: vendorsAirbyteHashed.logoPaystackSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/paystack',
    uri: 'https://docs.airbyte.com/integrations/sources/paystack'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-paystack'
  },
  includeRegions: false
};

export const integrationAirbridgePendo: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Pendo',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-pendo',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Pendo Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-pendo',
  logoPath: vendorsAirbyteHashed.logoPendoSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/pendo',
    uri: 'https://docs.airbyte.com/integrations/sources/pendo'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-pendo'
  },
  includeRegions: false
};

export const integrationAirbridgePersistIq: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'PersistIq',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-persistiq',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source PersistIq Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-persistiq',
  logoPath: vendorsAirbyteHashed.logoPersistiqSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/persistiq',
    uri: 'https://docs.airbyte.com/integrations/sources/persistiq'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-persistiq'
  },
  includeRegions: false
};

export const integrationAirbridgePexelsAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Pexels-API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-pexels-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Pexels-API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-pexels-api',
  logoPath: vendorsAirbyteHashed.logoPexelsSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/pexels-api',
    uri: 'https://docs.airbyte.com/integrations/sources/pexels-api'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-pexels-api'
  },
  includeRegions: false
};

export const integrationAirbridgePipedrive: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Pipedrive',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-pipedrive',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Pipedrive Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-pipedrive',
  logoPath: vendorsAirbyteHashed.logoPipedriveSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/pipedrive',
    uri: 'https://docs.airbyte.com/integrations/sources/pipedrive'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-pipedrive'
  },
  includeRegions: false
};

export const integrationAirbridgePivotalTracker: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Pivotal Tracker',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-pivotal-tracker',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Pivotal Tracker Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-pivotal-tracker',
  logoPath: vendorsAirbyteHashed.logoPivotaltrackerSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/pivotal-tracker',
    uri: 'https://docs.airbyte.com/integrations/sources/pivotal-tracker'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-pivotal-tracker'
  },
  includeRegions: false
};

export const integrationAirbridgePinterest: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Pinterest',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-pinterest',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Pinterest Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-pinterest',
  logoPath: vendorsAirbyteHashed.logoPinterestSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/pinterest',
    uri: 'https://docs.airbyte.com/integrations/sources/pinterest'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-pinterest'
  },
  includeRegions: false
};

export const integrationAirbridgePlaid: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Plaid',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-plaid',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Plaid Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-plaid',
  logoPath: vendorsAirbyteHashed.logoPlaidSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/plaid',
    uri: 'https://docs.airbyte.com/integrations/sources/plaid'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-plaid'
  },
  includeRegions: false
};

export const integrationAirbridgePlausible: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Plausible',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-plausible',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Plausible Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-plausible',
  logoPath: vendorsAirbyteHashed.logoPlausibleSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/plausible',
    uri: 'https://docs.airbyte.com/integrations/sources/plausible'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-plausible'
  },
  includeRegions: false
};

export const integrationAirbridgePocket: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Pocket',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-pocket',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Pocket Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-pocket',
  logoPath: vendorsAirbyteHashed.logoPocketSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/pocket',
    uri: 'https://docs.airbyte.com/integrations/sources/pocket'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-pocket'
  },
  includeRegions: false
};

export const integrationAirbridgePokéAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'PokéAPI',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-pokéapi',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source PokéAPI Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-pokéapi',
  logoPath: vendorsAirbyteHashed.logoPokeapiSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/pokeapi',
    uri: 'https://docs.airbyte.com/integrations/sources/pokeapi'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-pokeapi'
  },
  includeRegions: false
};

export const integrationAirbridgePolygonStockAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Polygon Stock API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-polygon-stock-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Polygon Stock API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-polygon-stock-api',
  logoPath: vendorsAirbyteHashed.logoPolygonSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/polygon-stock-api',
    uri: 'https://docs.airbyte.com/integrations/sources/polygon-stock-api'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-polygon-stock-api'
  },
  includeRegions: false
};

export const integrationAirbridgePostgres: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Postgres',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-postgres',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Postgres Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-postgres',
  logoPath: vendorsAirbyteHashed.logoPostgresSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/postgres',
    uri: 'https://docs.airbyte.com/integrations/sources/postgres'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-postgres'
  },
  includeRegions: false
};

export const integrationAirbridgePostHog: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'PostHog',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-posthog',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source PostHog Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-posthog',
  logoPath: vendorsAirbyteHashed.logoPosthogSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/posthog',
    uri: 'https://docs.airbyte.com/integrations/sources/posthog'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-posthog'
  },
  includeRegions: false
};

export const integrationAirbridgePostmarkapp: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Postmarkapp',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-postmarkapp',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Postmarkapp Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-postmarkapp',
  logoPath: vendorsAirbyteHashed.logoPostmarkSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/postmarkapp',
    uri: 'https://docs.airbyte.com/integrations/sources/postmarkapp'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-postmarkapp'
  },
  includeRegions: false
};

export const integrationAirbridgePrestaShop: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'PrestaShop',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-prestashop',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source PrestaShop Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-prestashop',
  logoPath: vendorsAirbyteHashed.logoPrestashopSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/prestashop',
    uri: 'https://docs.airbyte.com/integrations/sources/prestashop'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-prestashop'
  },
  includeRegions: false
};

export const integrationAirbridgePrimetric: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Primetric',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-primetric',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Primetric Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-primetric',
  logoPath: vendorsAirbyteHashed.logoPrimetricSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/primetric',
    uri: 'https://docs.airbyte.com/integrations/sources/primetric'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-primetric'
  },
  includeRegions: false
};

export const integrationAirbridgePublicAPIs: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Public APIs',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-public-apis',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Public APIs Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-public-apis',
  logoPath: vendorsAirbyteHashed.logoPublicapiSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/public-apis',
    uri: 'https://docs.airbyte.com/integrations/sources/public-apis'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-public-apis'
  },
  includeRegions: false
};

export const integrationAirbridgePunkAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Punk-API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-punk-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Punk-API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-punk-api',
  logoPath: vendorsAirbyteHashed.logoPunkapiSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/punk-api',
    uri: 'https://docs.airbyte.com/integrations/sources/punk-api'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-punk-api'
  },
  includeRegions: false
};

export const integrationAirbridgePyPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'PyPI',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-pypi',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source PyPI Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-pypi',
  logoPath: vendorsAirbyteHashed.logoPypiSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/pypi',
    uri: 'https://docs.airbyte.com/integrations/sources/pypi'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-pypi'
  },
  includeRegions: false
};

export const integrationAirbridgeQonto: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Qonto',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-qonto',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Qonto Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-qonto',
  logoPath: vendorsAirbyteHashed.logoQontoSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/qonto',
    uri: 'https://docs.airbyte.com/integrations/sources/qonto'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-qonto'
  },
  includeRegions: false
};

export const integrationAirbridgeQualaroo: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Qualaroo',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-qualaroo',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Qualaroo Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-qualaroo',
  logoPath: vendorsAirbyteHashed.logoQualarooSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/qualaroo',
    uri: 'https://docs.airbyte.com/integrations/sources/qualaroo'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-qualaroo'
  },
  includeRegions: false
};

export const integrationAirbridgeQuickBooks: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'QuickBooks',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-quickbooks',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source QuickBooks Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-quickbooks',
  logoPath: vendorsAirbyteHashed.logoQuickbooksSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/quickbooks',
    uri: 'https://docs.airbyte.com/integrations/sources/quickbooks'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-quickbooks'
  },
  includeRegions: false
};

export const integrationAirbridgeRailz: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Railz',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-railz',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Railz Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-railz',
  logoPath: vendorsAirbyteHashed.logoRailzSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/railz',
    uri: 'https://docs.airbyte.com/integrations/sources/railz'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-railz'
  },
  includeRegions: false
};

export const integrationAirbridgeRDStationMarketing: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'RD Station Marketing',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-rd-station-marketing',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source RD Station Marketing Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-rd-station-marketing',
  logoPath: vendorsAirbyteHashed.logoRdstationSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/rd-station-marketing',
    uri: 'https://docs.airbyte.com/integrations/sources/rd-station-marketing'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-rd-station-marketing'
  },
  includeRegions: false
};

export const integrationAirbridgeRecharge: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Recharge',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-recharge',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Recharge Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-recharge',
  logoPath: vendorsAirbyteHashed.logoRechargeSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/recharge',
    uri: 'https://docs.airbyte.com/integrations/sources/recharge'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-recharge'
  },
  includeRegions: false
};

export const integrationAirbridgeRecruitee: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Recruitee',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-recruitee',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Recruitee Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-recruitee',
  logoPath: vendorsAirbyteHashed.logoRecruiteeSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/recruitee',
    uri: 'https://docs.airbyte.com/integrations/sources/recruitee'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-recruitee'
  },
  includeRegions: false
};

export const integrationAirbridgeRecreationgovAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Recreation.gov API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-recreation.gov-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Recreation.gov API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-recreation.gov-api',
  logoPath: vendorsAirbyteHashed.logoRecreationgovSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/recreation',
    uri: 'https://docs.airbyte.com/integrations/sources/recreation'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-recreation'
  },
  includeRegions: false
};

export const integrationAirbridgeRecurly: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Recurly',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-recurly',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Recurly Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-recurly',
  logoPath: vendorsAirbyteHashed.logoRecurlySvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/recurly',
    uri: 'https://docs.airbyte.com/integrations/sources/recurly'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-recurly'
  },
  includeRegions: false
};

export const integrationAirbridgeRedshift: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Redshift',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-redshift',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Redshift Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-redshift',
  logoPath: vendorsAirbyteHashed.logoRedshiftSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/redshift',
    uri: 'https://docs.airbyte.com/integrations/sources/redshift'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-redshift'
  },
  includeRegions: false
};

export const integrationAirbridgeReplyio: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Reply.io',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-reply.io',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Reply.io Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-reply.io',
  logoPath: vendorsAirbyteHashed.logoReplyioSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/reply-io',
    uri: 'https://docs.airbyte.com/integrations/sources/reply-io'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-reply-io'
  },
  includeRegions: false
};

export const integrationAirbridgeRetently: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Retently',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-retently',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Retently Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-retently',
  logoPath: vendorsAirbyteHashed.logoRetentlySvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/retently',
    uri: 'https://docs.airbyte.com/integrations/sources/retently'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-retently'
  },
  includeRegions: false
};

export const integrationAirbridgeRingCentral: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'RingCentral',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-ringcentral',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source RingCentral Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-ringcentral',
  logoPath: vendorsAirbyteHashed.logoRingcentralSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/ringcentral',
    uri: 'https://docs.airbyte.com/integrations/sources/ringcentral'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-ringcentral'
  },
  includeRegions: false
};

export const integrationAirbridgeOrbit: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Orbit',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-orbit',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Orbit Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-orbit',
  logoPath: vendorsAirbyteHashed.logoOrbitSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/orbit',
    uri: 'https://docs.airbyte.com/integrations/sources/orbit'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-orbit'
  },
  includeRegions: false
};

export const integrationAirbridgeRobertKochInstituteCovid: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Robert Koch-Institute Covid',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-robert-koch-institute-covid',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Robert Koch-Institute Covid Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-robert-koch-institute-covid',
  logoPath: vendorsAirbyteHashed.logoOpensourceSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/rki-covid',
    uri: 'https://docs.airbyte.com/integrations/sources/rki-covid'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-rki-covid'
  },
  includeRegions: false
};

export const integrationAirbridgeS3: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'S3',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-s3',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source S3 Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-s3',
  logoPath: vendorsAirbyteHashed.logoS3Svg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/s3',
    uri: 'https://docs.airbyte.com/integrations/sources/s3'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-s3'
  },
  includeRegions: false
};

export const integrationAirbridgeSalesforce: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Salesforce',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-salesforce',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Salesforce Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-salesforce',
  logoPath: vendorsAirbyteHashed.logoSalesforceSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/salesforce',
    uri: 'https://docs.airbyte.com/integrations/sources/salesforce'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-salesforce'
  },
  includeRegions: false
};

export const integrationAirbridgeSalesloft: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Salesloft',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-salesloft',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Salesloft Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-salesloft',
  logoPath: vendorsAirbyteHashed.logoSalesloftSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/salesloft',
    uri: 'https://docs.airbyte.com/integrations/sources/salesloft'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-salesloft'
  },
  includeRegions: false
};

export const integrationAirbridgeSecodaAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Secoda API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-secoda-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Secoda API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-secoda-api',
  logoPath: vendorsAirbyteHashed.logoSecodaSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/secoda',
    uri: 'https://docs.airbyte.com/integrations/sources/secoda'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-secoda'
  },
  includeRegions: false
};

export const integrationAirbridgesapfieldglass: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'sap-fieldglass',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-sap-fieldglass',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source sap-fieldglass Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-sap-fieldglass',
  logoPath: vendorsAirbyteHashed.logoSapfieldglassSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/sap-fieldglass',
    uri: 'https://docs.airbyte.com/integrations/sources/sap-fieldglass'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-sap-fieldglass'
  },
  includeRegions: false
};

export const integrationAirbridgeSearchMetrics: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'SearchMetrics',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-searchmetrics',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source SearchMetrics Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-searchmetrics',
  logoPath: vendorsAirbyteHashed.logoSearchmetricsSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/search-metrics',
    uri: 'https://docs.airbyte.com/integrations/sources/search-metrics'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-search-metrics'
  },
  includeRegions: false
};

export const integrationAirbridgeSendinblueAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Sendinblue API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-sendinblue-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Sendinblue API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-sendinblue-api',
  logoPath: vendorsAirbyteHashed.logoSendinblueSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/sendinblue',
    uri: 'https://docs.airbyte.com/integrations/sources/sendinblue'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-sendinblue'
  },
  includeRegions: false
};

export const integrationAirbridgeSendgrid: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Sendgrid',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-sendgrid',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Sendgrid Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-sendgrid',
  logoPath: vendorsAirbyteHashed.logoSendgridSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/sendgrid',
    uri: 'https://docs.airbyte.com/integrations/sources/sendgrid'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-sendgrid'
  },
  includeRegions: false
};

export const integrationAirbridgeSenseforce: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Senseforce',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-senseforce',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Senseforce Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-senseforce',
  logoPath: vendorsAirbyteHashed.logoSenseforceSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/senseforce',
    uri: 'https://docs.airbyte.com/integrations/sources/senseforce'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-senseforce'
  },
  includeRegions: false
};

export const integrationAirbridgeSentry: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Sentry',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-sentry',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Sentry Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-sentry',
  logoPath: vendorsAirbyteHashed.logoSentrySvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/sentry',
    uri: 'https://docs.airbyte.com/integrations/sources/sentry'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-sentry'
  },
  includeRegions: false
};

export const integrationAirbridgeSFTP: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'SFTP',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-sftp',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source SFTP Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-sftp',
  logoPath: vendorsAirbyteHashed.logoSftpSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/sftp',
    uri: 'https://docs.airbyte.com/integrations/sources/sftp'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-sftp'
  },
  includeRegions: false
};

export const integrationAirbridgeSFTPBulk: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'SFTP Bulk',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-sftp-bulk',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source SFTP Bulk Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-sftp-bulk',
  logoPath: vendorsAirbyteHashed.logoSftpSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/sftp-bulk',
    uri: 'https://docs.airbyte.com/integrations/sources/sftp-bulk'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-sftp-bulk'
  },
  includeRegions: false
};

export const integrationAirbridgeShopify: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Shopify',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-shopify',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Shopify Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-shopify',
  logoPath: vendorsAirbyteHashed.logoShopifySvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/shopify',
    uri: 'https://docs.airbyte.com/integrations/sources/shopify'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-shopify'
  },
  includeRegions: false
};

export const integrationAirbridgeShortio: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Shortio',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-shortio',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Shortio Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-shortio',
  logoPath: vendorsAirbyteHashed.logoShortSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/shortio',
    uri: 'https://docs.airbyte.com/integrations/sources/shortio'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-shortio'
  },
  includeRegions: false
};

export const integrationAirbridgeSlack: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Slack',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-slack',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Slack Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-slack',
  logoPath: vendorsAirbyteHashed.logoSlackSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/slack',
    uri: 'https://docs.airbyte.com/integrations/sources/slack'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-slack'
  },
  includeRegions: false
};

export const integrationAirbridgeSmartEngage: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'SmartEngage',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-smartengage',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source SmartEngage Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-smartengage',
  logoPath: vendorsAirbyteHashed.logoSmartengageSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/smartengage',
    uri: 'https://docs.airbyte.com/integrations/sources/smartengage'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-smartengage'
  },
  includeRegions: false
};

export const integrationAirbridgeRSS: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'RSS',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-rss',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source RSS Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-rss',
  logoPath: vendorsAirbyteHashed.logoRssSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/rss',
    uri: 'https://docs.airbyte.com/integrations/sources/rss'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-rss'
  },
  includeRegions: false
};

export const integrationAirbridgeSnapchatMarketing: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Snapchat Marketing',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-snapchat-marketing',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Snapchat Marketing Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-snapchat-marketing',
  logoPath: vendorsAirbyteHashed.logoSnapchatSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/snapchat-marketing',
    uri: 'https://docs.airbyte.com/integrations/sources/snapchat-marketing'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-snapchat-marketing'
  },
  includeRegions: false
};

export const integrationAirbridgeSnowflake: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Snowflake',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-snowflake',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Snowflake Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-snowflake',
  logoPath: vendorsAirbyteHashed.logoSnowflakeSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/snowflake',
    uri: 'https://docs.airbyte.com/integrations/sources/snowflake'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-snowflake'
  },
  includeRegions: false
};

export const integrationAirbridgeSonarCloudAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Sonar Cloud API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-sonar-cloud-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Sonar Cloud API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-sonar-cloud-api',
  logoPath: vendorsAirbyteHashed.logoSonarcloudSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/sonar-cloud',
    uri: 'https://docs.airbyte.com/integrations/sources/sonar-cloud'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-sonar-cloud'
  },
  includeRegions: false
};

export const integrationAirbridgeSpaceXAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'SpaceX-API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-spacex-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source SpaceX-API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-spacex-api',
  logoPath: vendorsAirbyteHashed.logoSpacexSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/spacex-api',
    uri: 'https://docs.airbyte.com/integrations/sources/spacex-api'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-spacex-api'
  },
  includeRegions: false
};

export const integrationAirbridgeRocketchatAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Rocket.chat API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-rocket.chat-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Rocket.chat API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-rocket.chat-api',
  logoPath: vendorsAirbyteHashed.logoRocketchatSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/rocket-chat',
    uri: 'https://docs.airbyte.com/integrations/sources/rocket-chat'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-rocket-chat'
  },
  includeRegions: false
};

export const integrationAirbridgeSmaily: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Smaily',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-smaily',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Smaily Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-smaily',
  logoPath: vendorsAirbyteHashed.logoSmailySvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/smaily',
    uri: 'https://docs.airbyte.com/integrations/sources/smaily'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-smaily'
  },
  includeRegions: false
};

export const integrationAirbridgeStatuspageioAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Statuspage.io API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-statuspage.io-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Statuspage.io API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-statuspage.io-api',
  logoPath: vendorsAirbyteHashed.logoStatuspageSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/statuspage',
    uri: 'https://docs.airbyte.com/integrations/sources/statuspage'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-statuspage'
  },
  includeRegions: false
};

export const integrationAirbridgeStrava: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Strava',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-strava',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Strava Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-strava',
  logoPath: vendorsAirbyteHashed.logoStravaSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/strava',
    uri: 'https://docs.airbyte.com/integrations/sources/strava'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-strava'
  },
  includeRegions: false
};

export const integrationAirbridgeSmartsheets: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Smartsheets',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-smartsheets',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Smartsheets Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-smartsheets',
  logoPath: vendorsAirbyteHashed.logoSmartsheetsSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/smartsheets',
    uri: 'https://docs.airbyte.com/integrations/sources/smartsheets'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-smartsheets'
  },
  includeRegions: false
};

export const integrationAirbridgeStripe: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Stripe',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-stripe',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Stripe Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-stripe',
  logoPath: vendorsAirbyteHashed.logoStripeSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/stripe',
    uri: 'https://docs.airbyte.com/integrations/sources/stripe'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-stripe'
  },
  includeRegions: false
};

export const integrationAirbridgeSurveyMonkey: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'SurveyMonkey',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-surveymonkey',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source SurveyMonkey Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-surveymonkey',
  logoPath: vendorsAirbyteHashed.logoSurveymonkeySvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/surveymonkey',
    uri: 'https://docs.airbyte.com/integrations/sources/surveymonkey'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-surveymonkey'
  },
  includeRegions: false
};

export const integrationAirbridgeSurveySparrow: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'SurveySparrow',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-surveysparrow',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source SurveySparrow Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-surveysparrow',
  logoPath: vendorsAirbyteHashed.logoSurveysparrowSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/survey-sparrow',
    uri: 'https://docs.airbyte.com/integrations/sources/survey-sparrow'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-survey-sparrow'
  },
  includeRegions: false
};

export const integrationAirbridgeTempo: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Tempo',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-tempo',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Tempo Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-tempo',
  logoPath: vendorsAirbyteHashed.logoTempoSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/tempo',
    uri: 'https://docs.airbyte.com/integrations/sources/tempo'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-tempo'
  },
  includeRegions: false
};

export const integrationAirbridgeTalkdeskExplore: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Talkdesk Explore',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-talkdesk-explore',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Talkdesk Explore Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-talkdesk-explore',
  logoPath: vendorsAirbyteHashed.logoTalkdeskexploreSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/talkdesk-explore',
    uri: 'https://docs.airbyte.com/integrations/sources/talkdesk-explore'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-talkdesk-explore'
  },
  includeRegions: false
};

export const integrationAirbridgeTeradata: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Teradata',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-teradata',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Teradata Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-teradata',
  logoPath: vendorsAirbyteHashed.logoTeradataSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/teradata',
    uri: 'https://docs.airbyte.com/integrations/sources/teradata'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-teradata'
  },
  includeRegions: false
};

export const integrationAirbridgeTheGuardianAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'The Guardian API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-the-guardian-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source The Guardian API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-the-guardian-api',
  logoPath: vendorsAirbyteHashed.logoTheguardianSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/the-guardian-api',
    uri: 'https://docs.airbyte.com/integrations/sources/the-guardian-api'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-the-guardian-api'
  },
  includeRegions: false
};

export const integrationAirbridgeTiDB: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'TiDB',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-tidb',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source TiDB Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-tidb',
  logoPath: vendorsAirbyteHashed.logoTidbSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/tidb',
    uri: 'https://docs.airbyte.com/integrations/sources/tidb'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-tidb'
  },
  includeRegions: false
};

export const integrationAirbridgeTikTokMarketing: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'TikTok Marketing',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-tiktok-marketing',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source TikTok Marketing Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-tiktok-marketing',
  logoPath: vendorsAirbyteHashed.logoTiktokSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/tiktok-marketing',
    uri: 'https://docs.airbyte.com/integrations/sources/tiktok-marketing'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-tiktok-marketing'
  },
  includeRegions: false
};

export const integrationAirbridgeTimely: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Timely',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-timely',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Timely Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-timely',
  logoPath: vendorsAirbyteHashed.logoTimelySvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/timely',
    uri: 'https://docs.airbyte.com/integrations/sources/timely'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-timely'
  },
  includeRegions: false
};

export const integrationAirbridgeTMDb: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'TMDb',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-tmdb',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source TMDb Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-tmdb',
  logoPath: vendorsAirbyteHashed.logoTmdbSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/tmdb',
    uri: 'https://docs.airbyte.com/integrations/sources/tmdb'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-tmdb'
  },
  includeRegions: false
};

export const integrationAirbridgeTodoist: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Todoist',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-todoist',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Todoist Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-todoist',
  logoPath: vendorsAirbyteHashed.logoTodoistSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/todoist',
    uri: 'https://docs.airbyte.com/integrations/sources/todoist'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-todoist'
  },
  includeRegions: false
};

export const integrationAirbridgeTogglAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Toggl API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-toggl-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Toggl API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-toggl-api',
  logoPath: vendorsAirbyteHashed.logoTogglSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/toggl',
    uri: 'https://docs.airbyte.com/integrations/sources/toggl'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-toggl'
  },
  includeRegions: false
};

export const integrationAirbridgeSquare: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Square',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-square',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Square Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-square',
  logoPath: vendorsAirbyteHashed.logoSquareSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/square',
    uri: 'https://docs.airbyte.com/integrations/sources/square'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-square'
  },
  includeRegions: false
};

export const integrationAirbridgeTPL3PLCentral: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'TPL-3PL Central',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-tpl-3pl-central',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source TPL-3PL Central Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-tpl-3pl-central',
  logoPath: vendorsAirbyteHashed.logoOpensourceSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/tplcentral',
    uri: 'https://docs.airbyte.com/integrations/sources/tplcentral'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-tplcentral'
  },
  includeRegions: false
};

export const integrationAirbridgeTrello: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Trello',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-trello',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Trello Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-trello',
  logoPath: vendorsAirbyteHashed.logoTrelloSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/trello',
    uri: 'https://docs.airbyte.com/integrations/sources/trello'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-trello'
  },
  includeRegions: false
};

export const integrationAirbridgeTrustPilot: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'TrustPilot',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-trustpilot',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source TrustPilot Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-trustpilot',
  logoPath: vendorsAirbyteHashed.logoTrustpilotSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/trustpilot',
    uri: 'https://docs.airbyte.com/integrations/sources/trustpilot'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-trustpilot'
  },
  includeRegions: false
};

export const integrationAirbridgeSurveyCTO: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'SurveyCTO',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-surveycto',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source SurveyCTO Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-surveycto',
  logoPath: vendorsAirbyteHashed.logoSurveyctoSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/surveycto',
    uri: 'https://docs.airbyte.com/integrations/sources/surveycto'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-surveycto'
  },
  includeRegions: false
};

export const integrationAirbridgeTwilio: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Twilio',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-twilio',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Twilio Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-twilio',
  logoPath: vendorsAirbyteHashed.logoTwilioSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/twilio',
    uri: 'https://docs.airbyte.com/integrations/sources/twilio'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-twilio'
  },
  includeRegions: false
};

export const integrationAirbridgeTwitter: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Twitter',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-twitter',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Twitter Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-twitter',
  logoPath: vendorsAirbyteHashed.logoTwitterSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/twitter',
    uri: 'https://docs.airbyte.com/integrations/sources/twitter'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-twitter'
  },
  includeRegions: false
};

export const integrationAirbridgeTwilioTaskrouter: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Twilio Taskrouter',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-twilio-taskrouter',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Twilio Taskrouter Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-twilio-taskrouter',
  logoPath: vendorsAirbyteHashed.logoTwilioSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/twilio-taskrouter',
    uri: 'https://docs.airbyte.com/integrations/sources/twilio-taskrouter'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-twilio-taskrouter'
  },
  includeRegions: false
};

export const integrationAirbridgeTyntecSMS: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Tyntec SMS',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-tyntec-sms',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Tyntec SMS Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-tyntec-sms',
  logoPath: vendorsAirbyteHashed.logoTyntecsmsSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/tyntec-sms',
    uri: 'https://docs.airbyte.com/integrations/sources/tyntec-sms'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-tyntec-sms'
  },
  includeRegions: false
};

export const integrationAirbridgeTypeform: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Typeform',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-typeform',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Typeform Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-typeform',
  logoPath: vendorsAirbyteHashed.logoTypeformSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/typeform',
    uri: 'https://docs.airbyte.com/integrations/sources/typeform'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-typeform'
  },
  includeRegions: false
};

export const integrationAirbridgeUnleash: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Unleash',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-unleash',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Unleash Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-unleash',
  logoPath: vendorsAirbyteHashed.logoUnleashSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/unleash',
    uri: 'https://docs.airbyte.com/integrations/sources/unleash'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-unleash'
  },
  includeRegions: false
};

export const integrationAirbridgeUSCensusAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'US Census API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-us-census-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source US Census API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-us-census-api',
  logoPath: vendorsAirbyteHashed.logoUscensusSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/us-census',
    uri: 'https://docs.airbyte.com/integrations/sources/us-census'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-us-census'
  },
  includeRegions: false
};

export const integrationAirbridgeVantageAPI: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Vantage API',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-vantage-api',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Vantage API Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-vantage-api',
  logoPath: vendorsAirbyteHashed.logoVantageSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/vantage',
    uri: 'https://docs.airbyte.com/integrations/sources/vantage'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-vantage'
  },
  includeRegions: false
};

export const integrationAirbridgeVictorOps: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'VictorOps',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-victorops',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source VictorOps Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-victorops',
  logoPath: vendorsAirbyteHashed.logoVictoropsSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/victorops',
    uri: 'https://docs.airbyte.com/integrations/sources/victorops'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'farosai/airbyte-victorops-source'
  },
  includeRegions: false
};

export const integrationAirbridgeTVMazeSchedule: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'TVMaze Schedule',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-tvmaze-schedule',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source TVMaze Schedule Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-tvmaze-schedule',
  logoPath: vendorsAirbyteHashed.logoTvmazescheduleSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/tvmaze-schedule',
    uri: 'https://docs.airbyte.com/integrations/sources/tvmaze-schedule'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-tvmaze-schedule'
  },
  includeRegions: false
};

export const integrationAirbridgeWaiteraid: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Waiteraid',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-waiteraid',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Waiteraid Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-waiteraid',
  logoPath: vendorsAirbyteHashed.logoWaiteraidSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/waiteraid',
    uri: 'https://docs.airbyte.com/integrations/sources/waiteraid'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-waiteraid'
  },
  includeRegions: false
};

export const integrationAirbridgeWeatherstack: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Weatherstack',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-weatherstack',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Weatherstack Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-weatherstack',
  logoPath: vendorsAirbyteHashed.logoWeatherstackSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/weatherstack',
    uri: 'https://docs.airbyte.com/integrations/sources/weatherstack'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-weatherstack'
  },
  includeRegions: false
};

export const integrationAirbridgeVittally: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Vittally',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-vittally',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Vittally Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-vittally',
  logoPath: vendorsAirbyteHashed.logoVittallySvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/vitally',
    uri: 'https://docs.airbyte.com/integrations/sources/vitally'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-vitally'
  },
  includeRegions: false
};

export const integrationAirbridgeWebflow: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Webflow',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-webflow',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Webflow Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-webflow',
  logoPath: vendorsAirbyteHashed.logoWebflowSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/webflow',
    uri: 'https://docs.airbyte.com/integrations/sources/webflow'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-webflow'
  },
  includeRegions: false
};

export const integrationAirbridgeWhiskyHunter: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Whisky Hunter',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-whisky-hunter',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Whisky Hunter Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-whisky-hunter',
  logoPath: vendorsAirbyteHashed.logoWhiskyhunterSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/whisky-hunter',
    uri: 'https://docs.airbyte.com/integrations/sources/whisky-hunter'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-whisky-hunter'
  },
  includeRegions: false
};

export const integrationAirbridgeWooCommerce: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'WooCommerce',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-woocommerce',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source WooCommerce Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-woocommerce',
  logoPath: vendorsAirbyteHashed.logoWoocommerceSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/woocommerce',
    uri: 'https://docs.airbyte.com/integrations/sources/woocommerce'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-woocommerce'
  },
  includeRegions: false
};

export const integrationAirbridgeWikipediaPageviews: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Wikipedia Pageviews',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-wikipedia-pageviews',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Wikipedia Pageviews Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-wikipedia-pageviews',
  logoPath: vendorsAirbyteHashed.logoWikipediapageviewsSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/wikipedia-pageviews',
    uri: 'https://docs.airbyte.com/integrations/sources/wikipedia-pageviews'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-wikipedia-pageviews'
  },
  includeRegions: false
};

export const integrationAirbridgeWrike: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Wrike',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-wrike',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Wrike Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-wrike',
  logoPath: vendorsAirbyteHashed.logoWrikeSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/wrike',
    uri: 'https://docs.airbyte.com/integrations/sources/wrike'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-wrike'
  },
  includeRegions: false
};

export const integrationAirbridgeWorkramp: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Workramp',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-workramp',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Workramp Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-workramp',
  logoPath: vendorsAirbyteHashed.logoWorkrampSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/workramp',
    uri: 'https://docs.airbyte.com/integrations/sources/workramp'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-workramp'
  },
  includeRegions: false
};

export const integrationAirbridgeWorkable: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Workable',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-workable',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Workable Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-workable',
  logoPath: vendorsAirbyteHashed.logoWorkableSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/workable',
    uri: 'https://docs.airbyte.com/integrations/sources/workable'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-workable'
  },
  includeRegions: false
};

export const integrationAirbridgeVismaeconomic: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Visma e-conomic',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-visma-e-conomic',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Visma e-conomic Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-visma-e-conomic',
  logoPath: vendorsAirbyteHashed.logoVismaeconomicSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/visma-economic',
    uri: 'https://docs.airbyte.com/integrations/sources/visma-economic'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-visma-economic'
  },
  includeRegions: false
};

export const integrationAirbridgeYotpo: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Yotpo',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-yotpo',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Yotpo Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-yotpo',
  logoPath: vendorsAirbyteHashed.logoYotpoSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/yotpo',
    uri: 'https://docs.airbyte.com/integrations/sources/yotpo'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-yotpo'
  },
  includeRegions: false
};

export const integrationAirbridgeYounium: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Younium',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-younium',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Younium Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-younium',
  logoPath: vendorsAirbyteHashed.logoYouniumSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/younium',
    uri: 'https://docs.airbyte.com/integrations/sources/younium'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-younium'
  },
  includeRegions: false
};

export const integrationAirbridgeYandexMetrica: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Yandex Metrica',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-yandex-metrica',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Yandex Metrica Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-yandex-metrica',
  logoPath: vendorsAirbyteHashed.logoYandexmetricaSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/yandex-metrica',
    uri: 'https://docs.airbyte.com/integrations/sources/yandex-metrica'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-yandex-metrica'
  },
  includeRegions: false
};

export const integrationAirbridgeZapierSupportedStorage: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Zapier Supported Storage',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-zapier-supported-storage',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Zapier Supported Storage Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-zapier-supported-storage',
  logoPath: vendorsAirbyteHashed.logoZapierSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/zapier-supported-storage',
    uri: 'https://docs.airbyte.com/integrations/sources/zapier-supported-storage'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-zapier-supported-storage'
  },
  includeRegions: false
};

export const integrationAirbridgeYouTubeAnalytics: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'YouTube Analytics',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-youtube-analytics',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source YouTube Analytics Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-youtube-analytics',
  logoPath: vendorsAirbyteHashed.logoYoutubeanalyticsSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/youtube-analytics',
    uri: 'https://docs.airbyte.com/integrations/sources/youtube-analytics'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-youtube-analytics'
  },
  includeRegions: false
};

export const integrationAirbridgeZendeskChat: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Zendesk Chat',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-zendesk-chat',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Zendesk Chat Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-zendesk-chat',
  logoPath: vendorsAirbyteHashed.logoZendeskchatSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/zendesk-chat',
    uri: 'https://docs.airbyte.com/integrations/sources/zendesk-chat'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-zendesk-chat'
  },
  includeRegions: false
};

export const integrationAirbridgeZendeskSell: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Zendesk Sell',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-zendesk-sell',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Zendesk Sell Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-zendesk-sell',
  logoPath: vendorsAirbyteHashed.logoZendeskSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/zendesk-sell',
    uri: 'https://docs.airbyte.com/integrations/sources/zendesk-sell'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-zendesk-sell'
  },
  includeRegions: false
};

export const integrationAirbridgeZendeskSunshine: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Zendesk Sunshine',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-zendesk-sunshine',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Zendesk Sunshine Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-zendesk-sunshine',
  logoPath: vendorsAirbyteHashed.logoZendesksunshineSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/zendesk-sunshine',
    uri: 'https://docs.airbyte.com/integrations/sources/zendesk-sunshine'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-zendesk-sunshine'
  },
  includeRegions: false
};

export const integrationAirbridgeZendeskSupport: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Zendesk Support',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-zendesk-support',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Zendesk Support Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-zendesk-support',
  logoPath: vendorsAirbyteHashed.logoZendesksupportSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/zendesk-support',
    uri: 'https://docs.airbyte.com/integrations/sources/zendesk-support'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-zendesk-support'
  },
  includeRegions: false
};

export const integrationAirbridgeZendeskTalk: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Zendesk Talk',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-zendesk-talk',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Zendesk Talk Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-zendesk-talk',
  logoPath: vendorsAirbyteHashed.logoZendesktalkSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/zendesk-talk',
    uri: 'https://docs.airbyte.com/integrations/sources/zendesk-talk'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-zendesk-talk'
  },
  includeRegions: false
};

export const integrationAirbridgeZenefits: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Zenefits',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-zenefits',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Zenefits Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-zenefits',
  logoPath: vendorsAirbyteHashed.logoZenefitsSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/zenefits',
    uri: 'https://docs.airbyte.com/integrations/sources/zenefits'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-zenefits'
  },
  includeRegions: false
};

export const integrationAirbridgeZenloop: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Zenloop',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-zenloop',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Zenloop Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-zenloop',
  logoPath: vendorsAirbyteHashed.logoZenloopSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/zenloop',
    uri: 'https://docs.airbyte.com/integrations/sources/zenloop'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-zenloop'
  },
  includeRegions: false
};

export const integrationAirbridgeZohoCRM: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Zoho CRM',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-zoho-crm',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Zoho CRM Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-zoho-crm',
  logoPath: vendorsAirbyteHashed.logoZohocrmSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/zoho-crm',
    uri: 'https://docs.airbyte.com/integrations/sources/zoho-crm'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-zoho-crm'
  },
  includeRegions: false
};

export const integrationAirbridgeXKCD: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'XKCD',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-xkcd',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source XKCD Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-xkcd',
  logoPath: vendorsAirbyteHashed.logoXkcdSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/xkcd',
    uri: 'https://docs.airbyte.com/integrations/sources/xkcd'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-xkcd'
  },
  includeRegions: false
};

export const integrationAirbridgeZoom: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Zoom',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-zoom',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Zoom Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-zoom',
  logoPath: vendorsAirbyteHashed.logoZoomSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/zoom',
    uri: 'https://docs.airbyte.com/integrations/sources/zoom'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-zoom'
  },
  includeRegions: false
};

export const integrationAirbridgeXero: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Xero',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-xero',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Xero Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-xero',
  logoPath: vendorsAirbyteHashed.logoXeroSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/xero',
    uri: 'https://docs.airbyte.com/integrations/sources/xero'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-xero'
  },
  includeRegions: false
};

export const integrationAirbridgeZuora: Integration = {
  productId: productIdOpenbridgeAirbyte,
  subProductId: "default",
  enabled: true,
  primary: false,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Airbyte',
  name: 'Zuora',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'airbyte-zuora',
    'airbyte'
  ],
  tagLine: 'Harness of the power of open source Zuora Airbyte connectors via Airbridge.',
  routerLink: '/wizards/airbyte-zuora',
  logoPath: vendorsAirbyteHashed.logoZuoraSvg,
  frequency: 'Daily',
  opensourceIntegration: true,
  standardIntegration: true,
  premiumIntegration: false,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'docs.airbyte.com/integrations/sources/zuora',
    uri: 'https://docs.airbyte.com/integrations/sources/zuora'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: false,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  manageMenu: {
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'details',
        'config',
        'schedule',
        'aws',
        'integrationName',
        'save'
      ]
    },
    airbyteDockerImage: 'airbyte/source-zuora'
  },
  includeRegions: false
};

