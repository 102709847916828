export const responseUserDetailsUpdate = {
    data: {
        created_at: '2021-10-15T14:25:13.200Z',
        email: 'thomas+2021101501a@openbridge.com',
        email_verified: false,
        identities: [
            {
                connection: 'Username-Password-Authentication',
                provider: 'auth0',
                user_id: '61698f49ff86f6006a397d15',
                isSocial: false
            }
        ],
        name: 'thomas+2021101501@openbridge.com',
        nickname: 'thomas+2021101501',
        picture: 'https://s.gravatar.com/avatar/3ca1178cd0e3f4dc79f206d3d9d716cd?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fth.png',
        updated_at: '2022-02-22T09:08:00.266Z',
        user_id: 'auth0|61698f49ff86f6006a397d15',
        user_metadata: {
            stripe_customer_id: 'cus_KPgiun9YhOBtyE',
            openbridge_defaults: {
                accountId: '342',
                userId: '309'
            },
            ob_auth_token: {
                account_uuid: '67cbabf913f545deb581d8358f8fed23',
                expires_at: 1650704867.925788,
                token: 'fd176bc550a38ecea0a442aee5b2a4be'
            }
        },
        family_name: 'Spicer',
        given_name: 'John',
        last_password_reset: '2021-12-01T11:48:24.936Z',
        last_ip: '43.241.193.118',
        last_login: '2022-02-22T09:07:46.584Z',
        logins_count: 181
    }
};