export const responseSubscriptionExistError = {
  errors: [
    {
      detail: 'SPM unique value already exists.',
      status: '400',
      source: {
        pointer: '/data'
      },
      code: 'CONSOLE_EXCEPTION_CREATE_SUBSCRIPTION_SPM_ALREADY_EXISTS'
    }
  ]
};