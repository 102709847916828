
import { subtractDateFromToday } from '../function';

export const responsePipelineCount = {
  links: {
    first: 'https://pipeline-count.api.dev.openbridge.io/count?date=2022-05-02&page=1',
    last: 'https://pipeline-count.api.dev.openbridge.io/count?date=2022-05-02&page=1',
    next: '',
    prev: ''
  },
  data: [
    {
      type: 'PipelineCount',
      id: '1915',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 59,
        date: subtractDateFromToday(30),
        created_at: '2022-05-02T11:47:23.408286',
        modified_at: '2022-05-02T11:47:23.408286'
      }
    },
    {
      type: 'PipelineCount',
      id: '1948',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 62,
        date: subtractDateFromToday(29),
        created_at: '2022-05-03T11:45:45.408545',
        modified_at: '2022-05-03T11:45:45.408545'
      }
    },
    {
      type: 'PipelineCount',
      id: '1981',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 67,
        date: subtractDateFromToday(28),
        created_at: '2022-05-04T11:45:45.014747',
        modified_at: '2022-05-04T11:45:45.014747'
      }
    },
    {
      type: 'PipelineCount',
      id: '2014',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 67,
        date: subtractDateFromToday(27),
        created_at: '2022-05-05T11:45:44.978790',
        modified_at: '2022-05-05T11:45:44.978790'
      }
    },
    {
      type: 'PipelineCount',
      id: '2047',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 65,
        date: subtractDateFromToday(26),
        created_at: '2022-05-06T11:45:45.124996',
        modified_at: '2022-05-06T11:45:45.124996'
      }
    },
    {
      type: 'PipelineCount',
      id: '2080',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 67,
        date: subtractDateFromToday(25),
        created_at: '2022-05-07T11:47:34.859339',
        modified_at: '2022-05-07T11:47:34.859339'
      }
    },
    {
      type: 'PipelineCount',
      id: '2113',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 67,
        date: subtractDateFromToday(24),
        created_at: '2022-05-08T11:47:29.779507',
        modified_at: '2022-05-08T11:47:29.779507'
      }
    },
    {
      type: 'PipelineCount',
      id: '2146',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 70,
        date: subtractDateFromToday(23),
        created_at: '2022-05-09T11:45:45.290024',
        modified_at: '2022-05-09T11:45:45.290024'
      }
    },
    {
      type: 'PipelineCount',
      id: '2179',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 70,
        date: subtractDateFromToday(22),
        created_at: '2022-05-10T11:45:45.239970',
        modified_at: '2022-05-10T11:45:45.239970'
      }
    },
    {
      type: 'PipelineCount',
      id: '2212',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 72,
        date: subtractDateFromToday(21),
        created_at: '2022-05-11T11:45:45.077474',
        modified_at: '2022-05-11T11:45:45.077474'
      }
    },
    {
      type: 'PipelineCount',
      id: '2245',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 80,
        date: subtractDateFromToday(20),
        created_at: '2022-05-12T11:45:45.360060',
        modified_at: '2022-05-12T11:45:45.360060'
      }
    },
    {
      type: 'PipelineCount',
      id: '2278',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 75,
        date: subtractDateFromToday(19),
        created_at: '2022-05-13T11:45:45.047761',
        modified_at: '2022-05-13T11:45:45.047761'
      }
    },
    {
      type: 'PipelineCount',
      id: '2311',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 75,
        date: subtractDateFromToday(18),
        created_at: '2022-05-14T11:47:27.809919',
        modified_at: '2022-05-14T11:47:27.809919'
      }
    },
    {
      type: 'PipelineCount',
      id: '2344',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 75,
        date: subtractDateFromToday(17),
        created_at: '2022-05-15T11:47:24.847429',
        modified_at: '2022-05-15T11:47:24.847429'
      }
    },
    {
      type: 'PipelineCount',
      id: '2377',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 75,
        date: subtractDateFromToday(16),
        created_at: '2022-05-16T11:45:44.825529',
        modified_at: '2022-05-16T11:45:44.825529'
      }
    },
    {
      type: 'PipelineCount',
      id: '2410',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 75,
        date: subtractDateFromToday(15),
        created_at: '2022-05-17T11:45:45.303639',
        modified_at: '2022-05-17T11:45:45.303639'
      }
    },
    {
      type: 'PipelineCount',
      id: '2443',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 75,
        date: subtractDateFromToday(14),
        created_at: '2022-05-18T11:47:34.970828',
        modified_at: '2022-05-18T11:47:34.970828'
      }
    },
    {
      type: 'PipelineCount',
      id: '2476',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 75,
        date: subtractDateFromToday(13),
        created_at: '2022-05-19T11:45:44.925140',
        modified_at: '2022-05-19T11:45:44.925140'
      }
    },
    {
      type: 'PipelineCount',
      id: '2509',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 75,
        date: subtractDateFromToday(12),
        created_at: '2022-05-20T11:45:45.222731',
        modified_at: '2022-05-20T11:45:45.222731'
      }
    },
    {
      type: 'PipelineCount',
      id: '2542',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 75,
        date: subtractDateFromToday(11),
        created_at: '2022-05-21T11:47:36.521867',
        modified_at: '2022-05-21T11:47:36.521867'
      }
    },
    {
      type: 'PipelineCount',
      id: '2575',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 75,
        date: subtractDateFromToday(10),
        created_at: '2022-05-22T11:47:28.041474',
        modified_at: '2022-05-22T11:47:28.041474'
      }
    },
    {
      type: 'PipelineCount',
      id: '2608',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 75,
        date: subtractDateFromToday(9),
        created_at: '2022-05-23T11:47:26.839757',
        modified_at: '2022-05-23T11:47:26.839757'
      }
    },
    {
      type: 'PipelineCount',
      id: '2641',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 75,
        date: subtractDateFromToday(8),
        created_at: '2022-05-24T11:45:45.188940',
        modified_at: '2022-05-24T11:45:45.188940'
      }
    },
    {
      type: 'PipelineCount',
      id: '2674',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 75,
        date: subtractDateFromToday(7),
        created_at: '2022-05-25T11:45:44.810817',
        modified_at: '2022-05-25T11:45:44.810817'
      }
    },
    {
      type: 'PipelineCount',
      id: '2707',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 75,
        date: subtractDateFromToday(6),
        created_at: '2022-05-26T11:47:36.104581',
        modified_at: '2022-05-26T11:47:36.104581'
      }
    },
    {
      type: 'PipelineCount',
      id: '2740',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 76,
        date: subtractDateFromToday(5),
        created_at: '2022-05-27T11:45:45.442576',
        modified_at: '2022-05-27T11:45:45.442576'
      }
    },
    {
      type: 'PipelineCount',
      id: '2773',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 75,
        date: subtractDateFromToday(4),
        created_at: '2022-05-28T11:47:23.720757',
        modified_at: '2022-05-28T11:47:23.720757'
      }
    },
    {
      type: 'PipelineCount',
      id: '2806',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 75,
        date: subtractDateFromToday(3),
        created_at: '2022-05-29T11:47:34.927486',
        modified_at: '2022-05-29T11:47:34.927486'
      }
    },
    {
      type: 'PipelineCount',
      id: '2839',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 75,
        date: subtractDateFromToday(2),
        created_at: '2022-05-30T11:47:25.983331',
        modified_at: '2022-05-30T11:47:25.983331'
      }
    },
    {
      type: 'PipelineCount',
      id: '2872',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 75,
        date: subtractDateFromToday(1),
        created_at: '2022-05-31T11:47:24.085466',
        modified_at: '2022-05-31T11:47:24.085466'
      }
    }
  ],
  meta: {
    pagination: {
      page: 1,
      pages: 1,
      count: 30
    }
  }
};