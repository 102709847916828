
import { Integration } from '../../models/integration.model';
import {
  integrationAirbridgeActiveCampaign,
  integrationAirbridgeAircall,
  integrationAirbridgeAirtable,
  integrationAirbridgeAlloyDBforPostgreSQL,
  integrationAirbridgeAlphaVantage,
  integrationAirbridgeAdjust,
  // integrationAirbridgeAmazonSellerCentral,
  integrationAirbridgeAmplitude,
  integrationAirbridgeApifyDataset,
  integrationAirbridgeAppfollow,
  integrationAirbridgeAppleSearchAds,
  integrationAirbridgeAppsFlyer,
  integrationAirbridgeAppstore,
  integrationAirbridgeAsana,
  integrationAirbridgeAmazonSQS,
  integrationAirbridgeAshby,
  integrationAirbridgeAuth0,
  integrationAirbridgeAWSCloudTrail,
  integrationAirbridgeAzureBlobStorage,
  integrationAirbridgeAzureTableStorage,
  integrationAirbridgeBabelforce,
  integrationAirbridgeBambooHR,
  integrationAirbridgeBigCommerce,
  integrationAirbridgeBigQuery,
  integrationAirbridgeBingAds,
  integrationAirbridgeBraintree,
  integrationAirbridgeBreezometer,
  integrationAirbridgeCallRail,
  integrationAirbridgeCaptainData,
  integrationAirbridgeBraze,
  integrationAirbridgeCartcom,
  integrationAirbridgeChargebee,
  integrationAirbridgeChargify,
  // integrationAirbridgeAmazonAds,
  integrationAirbridgeChartmogul,
  integrationAirbridgeClickHouse,
  integrationAirbridgeClockify,
  integrationAirbridgeClosecom,
  integrationAirbridgeCockroachDB,
  integrationAirbridgeCoda,
  integrationAirbridgeCoinAPI,
  integrationAirbridgeClickUpAPI,
  integrationAirbridgeCoinmarketcapAPI,
  integrationAirbridgeCommcare,
  integrationAirbridgeCommercetools,
  integrationAirbridgeConfigcatAPI,
  integrationAirbridgeCoinGeckoCoins,
  integrationAirbridgeConvertKit,
  integrationAirbridgeConvex,
  integrationAirbridgeCopper,
  integrationAirbridgeConfluence,
  integrationAirbridgeCourier,
  integrationAirbridgeCustomerio,
  integrationAirbridgeDatadog,
  integrationAirbridgeDataScope,
  integrationAirbridgeDb2,
  integrationAirbridgeDelighted,
  integrationAirbridgeDisplayVideo360,
  integrationAirbridgeDixa,
  integrationAirbridgeDockerhub,
  integrationAirbridgeDremio,
  integrationAirbridgeDrift,
  integrationAirbridgeDynamodb,
  integrationAirbridgeElasticsearch,
  integrationAirbridgeEverhour,
  integrationAirbridgeExchangeRatesAPI,
  integrationAirbridgeFacebookMarketing,
  integrationAirbridgeFacebookPagePostReactions,
  integrationAirbridgeFaker,
  integrationAirbridgeFastbill,
  integrationAirbridgeFiles,
  integrationAirbridgeFauna,
  integrationAirbridgeFirebaseRealtimeDatabase,
  integrationAirbridgeFirebolt,
  integrationAirbridgeFlexport,
  integrationAirbridgeFreshcaller,
  integrationAirbridgeFreshsales,
  integrationAirbridgeFreshservice,
  integrationAirbridgeEndtoEndTestingSource,
  integrationAirbridgeFullStory,
  integrationAirbridgeGainsightAPI,
  integrationAirbridgeFreshdesk,
  integrationAirbridgeGCS,
  integrationAirbridgeGenesys,
  integrationAirbridgegetLagoAPI,
  integrationAirbridgeGitHub,
  integrationAirbridgeEmailOctopus,
  integrationAirbridgeGlassfrog,
  integrationAirbridgeGNews,
  integrationAirbridgeGong,
  integrationAirbridgeGoogleAds,
  integrationAirbridgeGoogleAnalyticsUniversalAnalytics,
  integrationAirbridgeGoogleAnalytics4,
  integrationAirbridgeGoogleDirectory,
  integrationAirbridgeGitLab,
  integrationAirbridgeGoCardless,
  integrationAirbridgeGooglePageSpeedInsights,
  integrationAirbridgeGoogleWorkspaceAdminReports,
  integrationAirbridgeGooglewebfonts,
  integrationAirbridgeGoogleSearchConsole,
  integrationAirbridgeGoogleSheets,
  integrationAirbridgeGreenhouse,
  integrationAirbridgeGutendex,
  integrationAirbridgeGridly,
  integrationAirbridgeHarness,
  integrationAirbridgeHubplanner,
  integrationAirbridgeHarvest,
  integrationAirbridgeInsightly,
  integrationAirbridgeInstagram,
  integrationAirbridgeInstatus,
  integrationAirbridgeIntruderioAPI,
  integrationAirbridgeIp2whoisAPI,
  integrationAirbridgeIntercom,
  integrationAirbridgeJira,
  integrationAirbridgeK6CloudAPI,
  integrationAirbridgeJenkins,
  integrationAirbridgeHubSpot,
  integrationAirbridgeKlaviyo,
  integrationAirbridgeIterable,
  integrationAirbridgeKustomer,
  integrationAirbridgeKyriba,
  integrationAirbridgeKyveSource,
  integrationAirbridgeLaunchdarklyAPI,
  integrationAirbridgeLemlist,
  integrationAirbridgeLeverHiring,
  integrationAirbridgeLinkedInAds,
  integrationAirbridgeLinkedInPages,
  integrationAirbridgeLinnworks,
  integrationAirbridgeLokalise,
  integrationAirbridgeLooker,
  integrationAirbridgeMailchimp,
  integrationAirbridgeMailerLite,
  integrationAirbridgeMailGun,
  integrationAirbridgeKafka,
  integrationAirbridgeMailjetSMSAPI,
  integrationAirbridgeMarketo,
  integrationAirbridgeMerge,
  integrationAirbridgeMetabase,
  integrationAirbridgeMailjetMailAPI,
  integrationAirbridgeMicrosoftDataverse,
  integrationAirbridgeMailersend,
  integrationAirbridgeMicrosoftSQLServer,
  integrationAirbridgeMicrosoftTeams,
  integrationAirbridgeMixpanel,
  integrationAirbridgeKlarna,
  integrationAirbridgeMonday,
  integrationAirbridgeMyHours,
  integrationAirbridgeMySQL,
  integrationAirbridgeN8n,
  integrationAirbridgeNASA,
  integrationAirbridgeNewYorkTimes,
  integrationAirbridgeNewsAPI,
  integrationAirbridgeNewsdataAPI,
  integrationAirbridgeNotion,
  integrationAirbridgeOkta,
  integrationAirbridgeMongoDB,
  integrationAirbridgeOpenExchangeRates,
  integrationAirbridgeOpsgenie,
  integrationAirbridgeOracleDB,
  integrationAirbridgeOrb,
  integrationAirbridgeOneSignal,
  integrationAirbridgeOrbit,
  integrationAirbridgeOura,
  integrationAirbridgeOutreach,
  integrationAirbridgePagerDuty,
  integrationAirbridgeOmnisend,
  integrationAirbridgePaypalTransaction,
  integrationAirbridgePaystack,
  integrationAirbridgePartnerstack,
  integrationAirbridgePendo,
  integrationAirbridgePersistIq,
  integrationAirbridgePexelsAPI,
  integrationAirbridgePinterest,
  integrationAirbridgePipedrive,
  integrationAirbridgePivotalTracker,
  integrationAirbridgePlaid,
  integrationAirbridgePlausible,
  integrationAirbridgePocket,
  integrationAirbridgePolygonStockAPI,
  integrationAirbridgePokéAPI,
  integrationAirbridgePostgres,
  integrationAirbridgePostmarkapp,
  integrationAirbridgePostHog,
  integrationAirbridgePrestaShop,
  integrationAirbridgePrimetric,
  integrationAirbridgePublicAPIs,
  integrationAirbridgePunkAPI,
  integrationAirbridgePardot,
  integrationAirbridgeQonto,
  integrationAirbridgeQualaroo,
  integrationAirbridgeQuickBooks,
  integrationAirbridgeRailz,
  integrationAirbridgeRDStationMarketing,
  integrationAirbridgeRecharge,
  integrationAirbridgeRecreationgovAPI,
  integrationAirbridgeRecruitee,
  integrationAirbridgeRecurly,
  integrationAirbridgeRedshift,
  integrationAirbridgeReplyio,
  integrationAirbridgeRetently,
  integrationAirbridgeRingCentral,
  integrationAirbridgeRobertKochInstituteCovid,
  integrationAirbridgeRocketchatAPI,
  integrationAirbridgeOpenWeather,
  integrationAirbridgeRSS,
  integrationAirbridgeS3,
  integrationAirbridgeSalesforce,
  integrationAirbridgeSalesloft,
  integrationAirbridgePyPI,
  integrationAirbridgesapfieldglass,
  integrationAirbridgeSecodaAPI,
  integrationAirbridgeSendinblueAPI,
  integrationAirbridgeSendgrid,
  integrationAirbridgeSenseforce,
  integrationAirbridgeSentry,
  integrationAirbridgeSFTP,
  integrationAirbridgeSFTPBulk,
  integrationAirbridgeShopify,
  integrationAirbridgeShortio,
  integrationAirbridgeSlack,
  integrationAirbridgeSearchMetrics,
  integrationAirbridgeSmartEngage,
  integrationAirbridgeSmaily,
  integrationAirbridgeSmartsheets,
  integrationAirbridgeSnapchatMarketing,
  integrationAirbridgeSnowflake,
  integrationAirbridgeSonarCloudAPI,
  integrationAirbridgeSquare,
  integrationAirbridgeStatuspageioAPI,
  integrationAirbridgeStripe,
  integrationAirbridgeStrava,
  integrationAirbridgeSurveyCTO,
  integrationAirbridgeSurveySparrow,
  integrationAirbridgeSurveyMonkey,
  integrationAirbridgeTalkdeskExplore,
  integrationAirbridgeTempo,
  integrationAirbridgeTeradata,
  integrationAirbridgeTheGuardianAPI,
  integrationAirbridgeTiDB,
  integrationAirbridgeTikTokMarketing,
  integrationAirbridgeTimely,
  integrationAirbridgeTMDb,
  integrationAirbridgeTodoist,
  integrationAirbridgeTogglAPI,
  integrationAirbridgeTrello,
  integrationAirbridgeTrustPilot,
  integrationAirbridgeTPL3PLCentral,
  integrationAirbridgeTVMazeSchedule,
  integrationAirbridgeTwilioTaskrouter,
  integrationAirbridgeTwitter,
  integrationAirbridgeTyntecSMS,
  integrationAirbridgeTypeform,
  integrationAirbridgeUnleash,
  integrationAirbridgeUSCensusAPI,
  integrationAirbridgeVantageAPI,
  integrationAirbridgeVictorOps,
  integrationAirbridgeVismaeconomic,
  integrationAirbridgeWaiteraid,
  integrationAirbridgeWeatherstack,
  integrationAirbridgeVittally,
  integrationAirbridgeSpaceXAPI,
  integrationAirbridgeWhiskyHunter,
  integrationAirbridgeWebflow,
  integrationAirbridgeWikipediaPageviews,
  integrationAirbridgeWooCommerce,
  integrationAirbridgeWorkable,
  integrationAirbridgeWorkramp,
  integrationAirbridgeWrike,
  integrationAirbridgeTwilio,
  integrationAirbridgeXero,
  integrationAirbridgeYandexMetrica,
  integrationAirbridgeXKCD,
  integrationAirbridgeYotpo,
  integrationAirbridgeYounium,
  integrationAirbridgeYouTubeAnalytics,
  integrationAirbridgeZendeskChat,
  integrationAirbridgeZendeskSell,
  integrationAirbridgeZendeskSunshine,
  integrationAirbridgeZendeskSupport,
  integrationAirbridgeZendeskTalk,
  integrationAirbridgeZenefits,
  integrationAirbridgeZenloop,
  integrationAirbridgeZapierSupportedStorage,
  integrationAirbridgeZoom,
  integrationAirbridgeZohoCRM,
  integrationAirbridgeZuora
} from './airbyte-opensource';


export const opensource: Integration[] = [
  integrationAirbridgeActiveCampaign,
  integrationAirbridgeAircall,
  integrationAirbridgeAirtable,
  integrationAirbridgeAlloyDBforPostgreSQL,
  integrationAirbridgeAlphaVantage,
  integrationAirbridgeAdjust,
  // integrationAirbridgeAmazonSellerCentral,
  integrationAirbridgeAmplitude,
  integrationAirbridgeApifyDataset,
  integrationAirbridgeAppfollow,
  integrationAirbridgeAppleSearchAds,
  integrationAirbridgeAppsFlyer,
  integrationAirbridgeAppstore,
  integrationAirbridgeAsana,
  integrationAirbridgeAmazonSQS,
  integrationAirbridgeAshby,
  integrationAirbridgeAuth0,
  integrationAirbridgeAWSCloudTrail,
  integrationAirbridgeAzureBlobStorage,
  integrationAirbridgeAzureTableStorage,
  integrationAirbridgeBabelforce,
  integrationAirbridgeBambooHR,
  integrationAirbridgeBigCommerce,
  integrationAirbridgeBigQuery,
  integrationAirbridgeBingAds,
  integrationAirbridgeBraintree,
  integrationAirbridgeBreezometer,
  integrationAirbridgeCallRail,
  integrationAirbridgeCaptainData,
  integrationAirbridgeBraze,
  integrationAirbridgeCartcom,
  integrationAirbridgeChargebee,
  integrationAirbridgeChargify,
  // integrationAirbridgeAmazonAds,
  integrationAirbridgeChartmogul,
  integrationAirbridgeClickHouse,
  integrationAirbridgeClockify,
  integrationAirbridgeClosecom,
  integrationAirbridgeCockroachDB,
  integrationAirbridgeCoda,
  integrationAirbridgeCoinAPI,
  integrationAirbridgeClickUpAPI,
  integrationAirbridgeCoinmarketcapAPI,
  integrationAirbridgeCommcare,
  integrationAirbridgeCommercetools,
  integrationAirbridgeConfigcatAPI,
  integrationAirbridgeCoinGeckoCoins,
  integrationAirbridgeConvertKit,
  integrationAirbridgeConvex,
  integrationAirbridgeCopper,
  integrationAirbridgeConfluence,
  integrationAirbridgeCourier,
  integrationAirbridgeCustomerio,
  integrationAirbridgeDatadog,
  integrationAirbridgeDataScope,
  integrationAirbridgeDb2,
  integrationAirbridgeDelighted,
  integrationAirbridgeDisplayVideo360,
  integrationAirbridgeDixa,
  integrationAirbridgeDockerhub,
  integrationAirbridgeDremio,
  integrationAirbridgeDrift,
  integrationAirbridgeDynamodb,
  integrationAirbridgeElasticsearch,
  integrationAirbridgeEverhour,
  integrationAirbridgeExchangeRatesAPI,
  integrationAirbridgeFacebookMarketing,
  integrationAirbridgeFacebookPagePostReactions,
  integrationAirbridgeFaker,
  integrationAirbridgeFastbill,
  integrationAirbridgeFiles,
  integrationAirbridgeFauna,
  integrationAirbridgeFirebaseRealtimeDatabase,
  integrationAirbridgeFirebolt,
  integrationAirbridgeFlexport,
  integrationAirbridgeFreshcaller,
  integrationAirbridgeFreshsales,
  integrationAirbridgeFreshservice,
  integrationAirbridgeEndtoEndTestingSource,
  integrationAirbridgeFullStory,
  integrationAirbridgeGainsightAPI,
  integrationAirbridgeFreshdesk,
  integrationAirbridgeGCS,
  integrationAirbridgeGenesys,
  integrationAirbridgegetLagoAPI,
  integrationAirbridgeGitHub,
  integrationAirbridgeEmailOctopus,
  integrationAirbridgeGlassfrog,
  integrationAirbridgeGNews,
  integrationAirbridgeGong,
  integrationAirbridgeGoogleAds,
  integrationAirbridgeGoogleAnalyticsUniversalAnalytics,
  integrationAirbridgeGoogleAnalytics4,
  integrationAirbridgeGoogleDirectory,
  integrationAirbridgeGitLab,
  integrationAirbridgeGoCardless,
  integrationAirbridgeGooglePageSpeedInsights,
  integrationAirbridgeGoogleWorkspaceAdminReports,
  integrationAirbridgeGooglewebfonts,
  integrationAirbridgeGoogleSearchConsole,
  integrationAirbridgeGoogleSheets,
  integrationAirbridgeGreenhouse,
  integrationAirbridgeGutendex,
  integrationAirbridgeGridly,
  integrationAirbridgeHarness,
  integrationAirbridgeHubplanner,
  integrationAirbridgeHarvest,
  integrationAirbridgeInsightly,
  integrationAirbridgeInstagram,
  integrationAirbridgeInstatus,
  integrationAirbridgeIntruderioAPI,
  integrationAirbridgeIp2whoisAPI,
  integrationAirbridgeIntercom,
  integrationAirbridgeJira,
  integrationAirbridgeK6CloudAPI,
  integrationAirbridgeJenkins,
  integrationAirbridgeHubSpot,
  integrationAirbridgeKlaviyo,
  integrationAirbridgeIterable,
  integrationAirbridgeKustomer,
  integrationAirbridgeKyriba,
  integrationAirbridgeKyveSource,
  integrationAirbridgeLaunchdarklyAPI,
  integrationAirbridgeLemlist,
  integrationAirbridgeLeverHiring,
  integrationAirbridgeLinkedInAds,
  integrationAirbridgeLinkedInPages,
  integrationAirbridgeLinnworks,
  integrationAirbridgeLokalise,
  integrationAirbridgeLooker,
  integrationAirbridgeMailchimp,
  integrationAirbridgeMailerLite,
  integrationAirbridgeMailGun,
  integrationAirbridgeKafka,
  integrationAirbridgeMailjetSMSAPI,
  integrationAirbridgeMarketo,
  integrationAirbridgeMerge,
  integrationAirbridgeMetabase,
  integrationAirbridgeMailjetMailAPI,
  integrationAirbridgeMicrosoftDataverse,
  integrationAirbridgeMailersend,
  integrationAirbridgeMicrosoftSQLServer,
  integrationAirbridgeMicrosoftTeams,
  integrationAirbridgeMixpanel,
  integrationAirbridgeKlarna,
  integrationAirbridgeMonday,
  integrationAirbridgeMyHours,
  integrationAirbridgeMySQL,
  integrationAirbridgeN8n,
  integrationAirbridgeNASA,
  integrationAirbridgeNewYorkTimes,
  integrationAirbridgeNewsAPI,
  integrationAirbridgeNewsdataAPI,
  integrationAirbridgeNotion,
  integrationAirbridgeOkta,
  integrationAirbridgeMongoDB,
  integrationAirbridgeOpenExchangeRates,
  integrationAirbridgeOpsgenie,
  integrationAirbridgeOracleDB,
  integrationAirbridgeOrb,
  integrationAirbridgeOneSignal,
  integrationAirbridgeOrbit,
  integrationAirbridgeOura,
  integrationAirbridgeOutreach,
  integrationAirbridgePagerDuty,
  integrationAirbridgeOmnisend,
  integrationAirbridgePaypalTransaction,
  integrationAirbridgePaystack,
  integrationAirbridgePartnerstack,
  integrationAirbridgePendo,
  integrationAirbridgePersistIq,
  integrationAirbridgePexelsAPI,
  integrationAirbridgePinterest,
  integrationAirbridgePipedrive,
  integrationAirbridgePivotalTracker,
  integrationAirbridgePlaid,
  integrationAirbridgePlausible,
  integrationAirbridgePocket,
  integrationAirbridgePolygonStockAPI,
  integrationAirbridgePokéAPI,
  integrationAirbridgePostgres,
  integrationAirbridgePostmarkapp,
  integrationAirbridgePostHog,
  integrationAirbridgePrestaShop,
  integrationAirbridgePrimetric,
  integrationAirbridgePublicAPIs,
  integrationAirbridgePunkAPI,
  integrationAirbridgePardot,
  integrationAirbridgeQonto,
  integrationAirbridgeQualaroo,
  integrationAirbridgeQuickBooks,
  integrationAirbridgeRailz,
  integrationAirbridgeRDStationMarketing,
  integrationAirbridgeRecharge,
  integrationAirbridgeRecreationgovAPI,
  integrationAirbridgeRecruitee,
  integrationAirbridgeRecurly,
  integrationAirbridgeRedshift,
  integrationAirbridgeReplyio,
  integrationAirbridgeRetently,
  integrationAirbridgeRingCentral,
  integrationAirbridgeRobertKochInstituteCovid,
  integrationAirbridgeRocketchatAPI,
  integrationAirbridgeOpenWeather,
  integrationAirbridgeRSS,
  integrationAirbridgeS3,
  integrationAirbridgeSalesforce,
  integrationAirbridgeSalesloft,
  integrationAirbridgePyPI,
  integrationAirbridgesapfieldglass,
  integrationAirbridgeSecodaAPI,
  integrationAirbridgeSendinblueAPI,
  integrationAirbridgeSendgrid,
  integrationAirbridgeSenseforce,
  integrationAirbridgeSentry,
  integrationAirbridgeSFTP,
  integrationAirbridgeSFTPBulk,
  integrationAirbridgeShopify,
  integrationAirbridgeShortio,
  integrationAirbridgeSlack,
  integrationAirbridgeSearchMetrics,
  integrationAirbridgeSmartEngage,
  integrationAirbridgeSmaily,
  integrationAirbridgeSmartsheets,
  integrationAirbridgeSnapchatMarketing,
  integrationAirbridgeSnowflake,
  integrationAirbridgeSonarCloudAPI,
  integrationAirbridgeSquare,
  integrationAirbridgeStatuspageioAPI,
  integrationAirbridgeStripe,
  integrationAirbridgeStrava,
  integrationAirbridgeSurveyCTO,
  integrationAirbridgeSurveySparrow,
  integrationAirbridgeSurveyMonkey,
  integrationAirbridgeTalkdeskExplore,
  integrationAirbridgeTempo,
  integrationAirbridgeTeradata,
  integrationAirbridgeTheGuardianAPI,
  integrationAirbridgeTiDB,
  integrationAirbridgeTikTokMarketing,
  integrationAirbridgeTimely,
  integrationAirbridgeTMDb,
  integrationAirbridgeTodoist,
  integrationAirbridgeTogglAPI,
  integrationAirbridgeTrello,
  integrationAirbridgeTrustPilot,
  integrationAirbridgeTPL3PLCentral,
  integrationAirbridgeTVMazeSchedule,
  integrationAirbridgeTwilioTaskrouter,
  integrationAirbridgeTwitter,
  integrationAirbridgeTyntecSMS,
  integrationAirbridgeTypeform,
  integrationAirbridgeUnleash,
  integrationAirbridgeUSCensusAPI,
  integrationAirbridgeVantageAPI,
  integrationAirbridgeVictorOps,
  integrationAirbridgeVismaeconomic,
  integrationAirbridgeWaiteraid,
  integrationAirbridgeWeatherstack,
  integrationAirbridgeVittally,
  integrationAirbridgeSpaceXAPI,
  integrationAirbridgeWhiskyHunter,
  integrationAirbridgeWebflow,
  integrationAirbridgeWikipediaPageviews,
  integrationAirbridgeWooCommerce,
  integrationAirbridgeWorkable,
  integrationAirbridgeWorkramp,
  integrationAirbridgeWrike,
  integrationAirbridgeTwilio,
  integrationAirbridgeXero,
  integrationAirbridgeYandexMetrica,
  integrationAirbridgeXKCD,
  integrationAirbridgeYotpo,
  integrationAirbridgeYounium,
  integrationAirbridgeYouTubeAnalytics,
  integrationAirbridgeZendeskChat,
  integrationAirbridgeZendeskSell,
  integrationAirbridgeZendeskSunshine,
  integrationAirbridgeZendeskSupport,
  integrationAirbridgeZendeskTalk,
  integrationAirbridgeZenefits,
  integrationAirbridgeZenloop,
  integrationAirbridgeZapierSupportedStorage,
  integrationAirbridgeZoom,
  integrationAirbridgeZohoCRM,
  integrationAirbridgeZuora  
];