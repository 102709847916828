import { createAction, props } from '@ngrx/store';

export const startReportGeneration = createAction('[Reporting] Generate Report Start',
  props<any>()
);

export const startReportProcessing = createAction('[Reporting] Processing Report Start',
  props<any>()
);

export const initializeReportGengeration = createAction('[Reporting] Report Initialize',
  props<any>()
);

export const updateReportStage = createAction('[Reporting] Report State Change',
  props<any>()
);
