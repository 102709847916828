export const responseUser = {
  links: {
    first: 'https://user.api.dev.openbridge.io/user?page=1',
    last: 'https://user.api.dev.openbridge.io/user?page=1',
    next: '',
    prev: ''
  },
  data: [
    {
      type: 'User',
      id: '309',
      attributes: {
        account_id: 342,
        first_name: null,
        last_name: null,
        email_address: 'thomas+2021101501a@openbridge.com',
        password: 'a5117b41870943809b70f72e0dc20343',
        password_request_token: '',
        password_request_token_expire: '2021-10-15T14:25:12.905414',
        created_at: '2021-10-15T14:25:12.905429',
        modified_at: '2022-12-20T11:58:29.203221',
        uniqueness: '67cbabf913f545deb581d8358f8fed23',
        is_admin: 0,
        auth0_user_id: 'auth0|61698f49ff86f6006a397d15',
        admin_permissions: 0,
        email_address_normalized: 'thomas@openbridge.com'
      }
    }
  ],
  meta: {
    pagination: {
      page: 1,
      pages: 1,
      count: 1
    }
  }
};