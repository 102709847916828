export const responseGoogleAdsCustomerList = {
  data: [
    {
      id: 9018096832,
      descriptive_name: 'HASBRO - Search & Display MCC',
      currency_code: 'CAD',
      time_zone: 'America/Toronto',
      auto_tagging_enabled: false,
      has_partners_badge: false,
      manager: true,
      test_account: false
    },
    {
      id: 4156813945,
      descriptive_name: 'Hasbro Global',
      currency_code: 'USD',
      time_zone: 'America/New_York',
      auto_tagging_enabled: false,
      has_partners_badge: false,
      manager: true,
      test_account: false
    },
    {
      id: 9119996770,
      descriptive_name: 'Brazil Global MCC',
      currency_code: 'BRL',
      time_zone: 'America/Sao_Paulo',
      auto_tagging_enabled: false,
      has_partners_badge: false,
      manager: true,
      test_account: false
    },
    {
      id: 4629706750,
      descriptive_name: 'Canada Global MCC',
      currency_code: 'CAD',
      time_zone: 'America/Toronto',
      auto_tagging_enabled: false,
      has_partners_badge: false,
      manager: true,
      test_account: false
    },
    {
      id: 2905956686,
      descriptive_name: 'Ogilvy MCC',
      currency_code: 'BRL',
      time_zone: 'America/Sao_Paulo',
      auto_tagging_enabled: false,
      has_partners_badge: false,
      manager: true,
      test_account: false
    },
    {
      id: 6188599435,
      descriptive_name: 'Russia Global MCC',
      currency_code: 'RUB',
      time_zone: 'Europe/Moscow',
      auto_tagging_enabled: false,
      has_partners_badge: false,
      manager: true,
      test_account: false
    },
    {
      id: 2674364031,
      descriptive_name: 'Italy Global MCC',
      currency_code: 'EUR',
      time_zone: 'Europe/Rome',
      auto_tagging_enabled: false,
      has_partners_badge: false,
      manager: true,
      test_account: false
    },
    {
      id: 8443183443,
      descriptive_name: 'GSA Global MCC',
      currency_code: 'EUR',
      time_zone: 'Europe/Berlin',
      auto_tagging_enabled: false,
      has_partners_badge: false,
      manager: true,
      test_account: false
    },
    {
      id: 2505290713,
      descriptive_name: 'Hasbro_Beyblade_CA',
      currency_code: 'CAD',
      time_zone: 'America/Toronto',
      auto_tagging_enabled: false,
      has_partners_badge: false,
      manager: false,
      test_account: false
    },
    {
      id: 4823151576,
      descriptive_name: 'Hasbro_FurReal_CA',
      currency_code: 'CAD',
      time_zone: 'America/Toronto',
      auto_tagging_enabled: false,
      has_partners_badge: false,
      manager: false,
      test_account: false
    },
    {
      id: 9216253842,
      descriptive_name: 'Hasbro_Nerf_CA',
      currency_code: 'CAD',
      time_zone: 'America/Toronto',
      auto_tagging_enabled: false,
      has_partners_badge: false,
      manager: false,
      test_account: false
    },
    {
      id: 2963983813,
      descriptive_name: 'Hasbro_Nerf_CA_World',
      currency_code: 'CAD',
      time_zone: 'America/Toronto',
      auto_tagging_enabled: false,
      has_partners_badge: false,
      manager: false,
      test_account: false
    },
    {
      id: 3129668642,
      descriptive_name: 'Hasbro_Core Games_CA',
      currency_code: 'CAD',
      time_zone: 'America/Toronto',
      auto_tagging_enabled: false,
      has_partners_badge: false,
      manager: false,
      test_account: false
    },
    {
      id: 3282004896,
      descriptive_name: 'Hasbro_Monopoly_CA',
      currency_code: 'CAD',
      time_zone: 'America/Toronto',
      auto_tagging_enabled: false,
      has_partners_badge: false,
      manager: false,
      test_account: false
    },
    {
      id: 1373025207,
      descriptive_name: 'Hasbro_PrimeTime Games_CA',
      currency_code: 'CAD',
      time_zone: 'America/Toronto',
      auto_tagging_enabled: false,
      has_partners_badge: false,
      manager: false,
      test_account: false
    },
    {
      id: 3108669342,
      descriptive_name: 'Hasbro_PlayDoh_CA',
      currency_code: 'CAD',
      time_zone: 'America/Toronto',
      auto_tagging_enabled: false,
      has_partners_badge: false,
      manager: false,
      test_account: false
    },
    {
      id: 3805431211,
      descriptive_name: 'Hasbro_Disney Princess_CA',
      currency_code: 'CAD',
      time_zone: 'America/Toronto',
      auto_tagging_enabled: false,
      has_partners_badge: false,
      manager: false,
      test_account: false
    }
  ]
};