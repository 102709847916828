export const responseAmazonDSPProfiles = {
  data: [
    {
      id: 693136736383016,
      type: 'AmazonAdvertisingProfile',
      attributes: {
        country_code: 'US',
        currency_code: 'USD',
        daily_budget: '',
        timezone: 'America/Los_Angeles',
        account_info: {
          id: 'ENTITY1KYDY8TQRNLPG',
          type: 'AmazonAdvertisingProfileAccountInfo',
          attributes: {
            marketplace_country: 'US',
            marketplace_string_id: 'ATVPDKIKX0DER',
            name: 'PLP Perpetua - Influxion Point: US',
            type: 'agency',
            subType: '',
            valid_payment_method: ''
          }
        }
      }
    }
  ]
};