import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { response } from 'msw';
import { take } from 'rxjs/operators';
import { PipelineSpmReportService } from 'src/app/core/services/pipeline-spm-report.service';
import { updateReportStage } from 'src/app/core/store/actions/reporting.actions';
import { AppState } from 'src/app/core/store/reducers';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  constructor(
    private store$: Store<AppState>,
    private pipelineSpmReportService: PipelineSpmReportService
  ) { }

  async initializeReport(index: number, reportState: any): Promise<void> {
    this.store$.dispatch(updateReportStage({index: index, stage: 'processing'}));
    await this.pipelineSpmReportService.generateReport(index, reportState['subscriptions']);


  }

  shouldShowTabCloseNotification(router: Router): boolean {
    let reportStateResponse: any = null;

    this.store$.select('reporting').pipe(take(1)).subscribe(response => {
      reportStateResponse = response;   
    });

    if(reportStateResponse?.reportStates && reportStateResponse?.reportStates.length > 0) {
      return true;
    }

    return false;
  }  
}
