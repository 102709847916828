import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { changedStateEpochUpdate } from '../actions/account.actions';
import { CacheService } from 'src/app/core/services/cache.service';
import { secondSinceEpoch } from 'src/app/core/functions/time';


@Injectable()
export class AccountEffects {

  updateChangedStateEpoch$ = createEffect(() => this.actions$
    .pipe(
      ofType(changedStateEpochUpdate),
      tap(async action => {
        const epochSeconds = secondSinceEpoch() + 30;
        console.info('New delta epoch set to: ' + epochSeconds);
        this.cacheService.setCache('local', 'cachedDataModifiedEpoch', epochSeconds);
      })
    ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private cacheService: CacheService
  ) { }

}