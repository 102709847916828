import { createAction, props } from '@ngrx/store';

export const subscriptionsSetCacheKey = createAction('[Subscriptions] Set Cache Key',
  props<any>()
);
export const subscriptionsLoad = createAction('[Subscriptions] Load Subscriptions',
  props<any>()
);
export const subscriptionsGet = createAction('[Subscriptions] Get Subscriptions',
  props<any>()
);
export const subscriptionGet = createAction('[Subscriptions] Get Subscription');
export const subscriptionAdd = createAction('[Subscriptions] Add Subscription',
  props<any>()
);
export const subscriptionUpdate = createAction('[Subscriptions] Update Subscription',
  props<any>()
);
export const subscriptionDelete = createAction('[Subscriptions] Delete Subscription',
  props<any>()
);

export const initializePipelineSelection = createAction('[Selected Pipeline] Initialize Pipeline selection',
  props<any>()
);

export const updateAllSelectedPipeline = createAction('[Selected Pipeline] Update All Selected Pipeline',
  props<any>()
);

export const updateSelectedPipeline = createAction('[Selected Pipeline] Update Pipeline',
  props<any>()
);

export const updateHistoryProcessing = createAction('[Selected Pipeline] History Processing Create',
  props<any>()
);

export const removeHistoryProcessing = createAction('[Selected Pipeline] History Processing Remove',
  props<any>()
);

