<div class="modal-header">
  <h4 class="modal-title pull-left">Create External Identity.</h4>
  <button type="button" class="btn-close pull-right" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!-- <form class="tab-content"> -->
  <p>
    This is external identity link. Please use it to create external identity.
    <!-- See our help docs on <a href="https://docs.openbridge.com/en/articles/8075889-using-trusted-external-identities"
      target="_blank" rel="noopener" class="light-link">"External Identities"</a>. -->
      See our help docs on <a href="https://docs.openbridge.com/en/articles/8075889-using-trusted-external-identities"
      target="_blank" rel="noopener" class="light-link">"External Identities"</a> for guides, tips, and troubleshooting.
  </p>
  <form class="tab-content" novalidate [formGroup]="formGroup" (ngSubmit)="onSubmit($event)">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="externalIdentityLink" class="mb-2">External Identity Link</label>
          <input type="text" class="form-control" id="externalIdentityLink" formControlName="externalIdentityLink"
            aria-describedby="externalIdentityLinkHelp" formControlName="externalIdentityLink" [readonly]="'true'">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <button type="submit" class="btn btn-primary">
            <span *ngIf="!isCopied">
              Copy <i class="ms-2 fa-duotone fa-copy"></i>
            </span>
            <span *ngIf="isCopied" [@fadeInOut]> Copied !</span>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>