import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/reducers';

@Component({
  selector: 'app-modal-general-progress',
  templateUrl: './modal-general-progress.component.html'
})
export class ModalGeneralProgressComponent implements OnInit {
  modalState: any;
  modalStateSubscription: any;
  widthStyle = 'width: 0%';

  constructor(
    private store$: Store<AppState>,
  ) { }

  ngOnInit(): void {
    this.modalStateSubscription = this.store$.select('modals').subscribe(response => {
      this.modalState = response;
      this.updateWidthStyle(this.modalState.progress);
    });
  }

  private updateWidthStyle(percent: string): void {
    this.widthStyle = 'width: ' + percent + '%';
  }

}
