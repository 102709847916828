export const responseDatabricksAccountMappingEncryption = {
  data: {
    attributes: {
      is_temp: true,
      path: '/ebs/ftpd/test-1641468440915-000001',
      region: 'us-east-1',
      s3_bucket: 'ob-zeroadmin-stage',
      databricks_server_hostname: 'hostname',
      databricks_http_path: 'httppath',
      databricks_schema: 'schema',
      databricks_catalog: 'catalog',
      databricks_access_token: {
        encrypt: 'kms',
        value: 'encrypted data'
      },
      storage: 'databricks',
    }
  }
};