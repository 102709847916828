import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { updateKeyS3FileUpload } from 'src/app/core/store/actions/s3-upload.actions';
import { AppState } from 'src/app/core/store/reducers';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class S3Service {

  uploadSubscriptions$: any[];

  constructor(
    private httpClient: HttpClient,
    private store$: Store<AppState>
  ) { }

  async presignedS3UploadUrl(param: string): Promise<any> {
    return this.httpClient.get(environment.openbridgeApiUris.service + '/service/s3/presigned_url/' + param).toPromise();
  }

  async presignedS3GetUrl(uri: string): Promise<any> {
    const encodedUri = encodeURIComponent(uri);
    const response = await this.httpClient.get(environment.openbridgeApiUris.service + '/service/s3/presigned_url/generate_url?url=' + encodedUri).toPromise();
    return response['data'].attributes.url;
  }

  async readFileContents(file: File): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = e => {
        return resolve((e.target as FileReader).result);
      };
  
      reader.onerror = e => {
        console.error(`FileReader failed on file ${file.name}.`);
        return reject(null);
      };
  
      if (!file) {
        console.error('No file to read.');
        return reject(null);
      }
  
      reader.readAsText(file);
    });
  }

  uploadPresignedS3File(url: string, fields: string[], file: File, reportProgress: boolean = true): any {

    const fieldKeys = Object.keys(fields);
    const formData = new FormData();

    for(const fieldKey of fieldKeys){
      formData.append(fieldKey, fields[fieldKey]);
    }

    formData.append('file', file);

    let options = {};
    if(reportProgress === true) {
      options['observe'] = 'events';
      options['reportProgress'] = true;
    }

    return this.httpClient.post(url, formData, options);
  }

  async updateS3UriForHashId(hashId: string, uri: string): Promise<void> {
    this.store$.dispatch(updateKeyS3FileUpload({hashId, uri}));
  }

}

export interface FileUploadProgressReport {
  type: number,
  loaded: number,
  total: number
}
