<ng-container *ngIf="maintenanceModeEnabled; else siteActiveDisplay">
  <div class="container">
    <div class="row mt-6 justify-content-center">
      <div class="col-md-6 card">
        <div class="text-center">

          <!-- Heading -->
          <h1 class="mb-4 mt-4" id="modalTitle">
            Undergoing Maintenance
          </h1>
          <!-- Subheading -->
          <p class="text-muted mb-4">
            Sorry for the inconvenience, we are undergoing maintenance at the moment, and be back as soon as possible.
          </p>

        </div>

      </div>
    </div>
  </div>
</ng-container>

<ng-template #siteActiveDisplay>
  <ng-container *ngIf="!loading">
    <router-outlet></router-outlet>
  </ng-container>
</ng-template>