import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { AppState } from 'src/app/core/store/reducers';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripeService implements OnInit {
  stripeCustomerUpdated$: Subject<boolean> = new Subject();
  stripeSession$: Subject<string> = new Subject();

  constructor(
    protected store$: Store<AppState>,
    protected http: HttpClient
  ) {
    this.stripeCustomerUpdated$ = new Subject<boolean>();
  }

  ngOnInit(): void {
  }

  createStripeCheckoutSession(data: any): void {
    const hostname = window.location.host;

    const payload = {
      data: {
        type: 'Service',
        attributes: {
          allow_promotion_codes: true,
          customer: data.customerId,
          mode: 'subscription',
          payment_method_types: ['card'],
          subscription: (data.subscriptionId !== null) ? data.subscriptionId : null,
          line_items: [
            {
              price: data.selectedPlan
            }
          ],
          success_url: 'https://' + hostname + '/account/billing',
          cancel_url: window.location.href + '?checkout-cancelled=true'
        }
      }
    };

    this.http.post(environment.openbridgeApiUris.service + '/service/stripe/checkout-session', payload).subscribe(response => {
      this.stripeSession$.next(response['data']['id']);
    });
  }

  processStripeCustomer(stripeCustomer: any): any {
    if (stripeCustomer !== null) {

      const subscriptionDetails = this.processSubscriptionDetails(stripeCustomer);

      const details = {
        customerId: stripeCustomer.id,
        subscriptionId: subscriptionDetails.id,
        planId: subscriptionDetails.planId,
        planName: subscriptionDetails.planName,
        planCurrentPeriodEnds: subscriptionDetails.planCurrentPeriodEnd
      };

      return details;
    }
    return {
      customerId: null,
      subscriptionId: null,
      planId: null,
      planName: null
    };
  }

  private processSubscriptionDetails(stripeCustomer: any): any {
    const subscriptions = stripeCustomer.subscriptions;
    let stripePlanName: string | null =  null

    if (subscriptions.total_count > 0) {

      // This is a hack because stripe stopped giving us the plan names in the API at some point.
      // We should figure out a better way to do this.
      switch(subscriptions.data[0].plan.id) {
        case environment.stripe.prices.trialPlanId:
          stripePlanName = environment.stripe.name.trial;
          break;
        case environment.stripe.prices.standardPriceId:
          stripePlanName = environment.stripe.name.standard ;
          break;
        case environment.stripe.prices.premiumPriceId:
          stripePlanName = environment.stripe.name.premium ;
          break;
        case environment.stripe.prices.businessPriceId:
          stripePlanName = environment.stripe.name.business ;
          break;
        default:
          stripePlanName ='Enterprise Plan'
      }

      return {
        id: subscriptions.data[0].id,
        planId: subscriptions.data[0].plan.id,
        planCurrentPeriodEnd: subscriptions.data[0].current_period_end,
        planName: stripePlanName,
        cancelAtPeriodEnd: subscriptions.data[0].cancel_at_period_end
      };
    }
    else {
      return {
        id: null,
        planId: null,
        planCurrentPeriodEnd: null,
        planName: 'Expired',
        cancelAtPeriodEnd: null,
      };
    }
  }

  async retrieveStripeCustomer(stripeCustomerId: string): Promise<any> {
    return await this.http.get(environment.openbridgeApiUris.service + '/service/stripe/customer/' + stripeCustomerId).toPromise();
  }

  stripePlanIsLegacyPlan(stripePlanId: string): boolean {
    const priceIds = Object.values(environment.stripe.prices);
    return (!priceIds.includes(stripePlanId)) ? true : false;
  }

  stripePlanCanHavePremiumProducts(stripePlanId: string): boolean {
    switch (stripePlanId) {
      case environment.stripe.prices.standardPriceId:
        return false;
      default:
        return true;
    }
  }

}
