export const responseDatabricksEditSubscription = {
  data: {
    type: 'Subscription',
    id: '277',
    attributes: {
      price: 0,
      status: 'active',
      date_start: '2022-01-24T13:33:28',
      date_end: '2022-01-24T13:33:28',
      auto_renew: 1,
      created_at: '2022-01-24T13:33:31.316183',
      modified_at: '2022-01-24T13:33:31.981685',
      quantity: 1,
      stripe_subscription_id: '',
      name: 'databricks-development',
      rabbit_payload_successful: 0,
      primary_job_id: null,
      pipeline: null,
      invalid_subscription: 0,
      invalidated_at: null,
      notified_at: null,
      canonical_name: null,
      account_id: 342,
      product_id: 68,
      product_plan_id: null,
      remote_identity_id: null,
      storage_group_id: 171,
      user_id: 309,
      history_requested: 0,
      unique_hash: null
    },
    relationships: {
      account: {
        data: {
          type: 'Account',
          id: '342'
        }
      },
      product: {
        data: {
          type: 'Product',
          id: '68'
        }
      },
      product_plan: {
        data: null
      },
      remote_identity: {
        data: null
      },
      storage_group: {
        data: {
          type: 'StorageGroup',
          id: '171'
        }
      },
      user: {
        data: {
          type: 'User',
          id: '309'
        }
      }
    }
  },
  included: [
    {
      type: 'StorageGroup',
      id: '171',
      attributes: {
        product_id: 68,
        name: 'databricks-development',
        key_name: 'bce6bd86207a5549-databricks-development'
      }
    }
  ]
};