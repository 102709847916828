import { Integration } from '../../models/integration.model';
import { vendors } from '../logos';
import {
  productIdGoogleAds,
  productIdGoogleAnalytics360,
  productIdGoogleCampaignManager,
  productIdGoogleSearchAds360
} from '../product-ids';


export const integrationGoogleAds : Integration = {
  productId: productIdGoogleAds,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Google',
  name: 'Ads',
  badge: 'none',
  type: 'source',
  sortOrder: 600,
  tagLine: 'Obtain Ads performance data for campaigns, keywords, creative and geography',
  routerLink: '/wizards/google-ads',
  logoPath: vendors.googleAds,
  frequency: 'Daily',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  remoteIdentityTypeId: 8,
  tableWeight: 0.74,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'google.com/google-ads',
    uri: 'https://developers.google.com/adwords/api/docs/guides/reporting'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'customer',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ],
      managed: [
        'checklist',
        'identity',
        'customer',
        'managed-customer',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false
};

export const integrationGoogleAnalytics360 : Integration = {
  productId: productIdGoogleAnalytics360,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Google',
  name: 'Analytics 360',
  badge: 'none',
  type: 'source',
  sortOrder: 700,
  tagLine: 'Enterprise, unsampled event level data for custom reporting, insights and attribution',
  routerLink: '/wizards/google-analytics-360',
  logoPath: vendors.googleAnalytics360,
  frequency: 'Daily',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  remoteIdentityTypeId: 1,
  tableWeight: 0.1,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'google.com/analytics',
    uri: 'https://support.google.com/analytics/answer/3437434?hl=en'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  manageMenu: {
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'projectId',
        'datasetId',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false
};

export const integrationGoogleCampaignManager : Integration = {
  productId: productIdGoogleCampaignManager,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Google',
  name: 'Campaign Manager',
  badge: 'none',
  type: 'source',
  sortOrder: 800,
  tagLine: 'Reporting insights, benchmarks, and media performance across digital campaigns',
  routerLink: '/wizards/google-campaign-manager',
  logoPath: vendors.googleCampaignManager,
  frequency: 'Daily',
  standardIntegration: false,
  premiumIntegration: true,
  opensourceIntegration: false,
  remoteIdentityTypeId: 1,
  tableWeight: 0.1,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'google.com/campaignmanager',
    uri: 'https://developers.google.com/doubleclick-advertisers'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  manageMenu: {
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'profile',
        'report',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false
};

export const integrationGoogleSearchAds360 : Integration = {
  productId: productIdGoogleSearchAds360,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Google',
  name: 'Search Ads 360',
  badge: 'none',
  type: 'source',
  sortOrder: 900,
  tagLine: 'Customer journey search data for campaigns, insights and attribution',
  routerLink: '/wizards/google-search-ads-360',
  logoPath: vendors.googleSearch360,
  frequency: 'Daily',
  standardIntegration: false,
  premiumIntegration: true,
  opensourceIntegration: false,
  remoteIdentityTypeId: 1,
  tableWeight: 0.1,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'google.com/search-ads',
    uri: 'https://developers.google.com/search-ads'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  manageMenu: {
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false
};