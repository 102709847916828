export const responseAmazonAttributionProfiles = {
  data: [
    {
      id: 2401846814837028,
      type: 'AmazonAdvertisingProfile',
      attributes: {
        country_code: 'CA',
        currency_code: 'CAD',
        daily_budget: 999999999,
        timezone: 'America/Los_Angeles',
        account_info: {
          id: 'AE696WJXMQ5SG',
          type: 'AmazonAdvertisingProfileAccountInfo',
          attributes: {
            marketplace_country: 'CA',
            marketplace_string_id: 'A2EUQ1WTGCTBG2',
            name: 'Fullstar Houseware',
            type: 'seller',
            subType: '',
            valid_payment_method: true
          }
        }
      }
    },
    {
      id: 3612007981467236,
      type: 'AmazonAdvertisingProfile',
      attributes: {
        country_code: 'MX',
        currency_code: 'MXN',
        daily_budget: 999999999,
        timezone: 'America/Los_Angeles',
        account_info: {
          id: 'AE696WJXMQ5SG',
          type: 'AmazonAdvertisingProfileAccountInfo',
          attributes: {
            marketplace_country: 'MX',
            marketplace_string_id: 'A1AM78C64UM0Y8',
            name: 'Fullstar Houseware',
            type: 'seller',
            subType: '',
            valid_payment_method: true
          }
        }
      }
    },
    {
      id: 2116350962252689,
      type: 'AmazonAdvertisingProfile',
      attributes: {
        country_code: 'US',
        currency_code: 'USD',
        daily_budget: '',
        timezone: 'America/Los_Angeles',
        account_info: {
          id: 'ENTITY27U3P80PXY90D',
          type: 'AmazonAdvertisingProfileAccountInfo',
          attributes: {
            marketplace_country: 'US',
            marketplace_string_id: 'ATVPDKIKX0DER',
            name: 'Attribution: Fullstar Houseware (US)',
            type: 'agency',
            subType: 'AMAZON_ATTRIBUTION',
            valid_payment_method: ''
          }
        }
      }
    },
    {
      id: 801268442976258,
      type: 'AmazonAdvertisingProfile',
      attributes: {
        country_code: 'US',
        currency_code: 'USD',
        daily_budget: 999999999,
        timezone: 'America/Los_Angeles',
        account_info: {
          id: 'AE696WJXMQ5SG',
          type: 'AmazonAdvertisingProfileAccountInfo',
          attributes: {
            marketplace_country: 'US',
            marketplace_string_id: 'ATVPDKIKX0DER',
            name: 'Fullstar Houseware',
            type: 'seller',
            subType: '',
            valid_payment_method: true
          }
        }
      }
    }
  ]
};