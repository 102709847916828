import { createAction, props } from "@ngrx/store";
import { FileState } from "../reducers/s3-upload.reducer";

export const initializeS3FileUpload = createAction('[S3Upload] Initialize CSV File Upload',
  props<FileState>()
);

export const rejectedS3FileUpload = createAction('[S3Upload] Adding Rejected Files',
  props<FileState>()
);

export const updateUploadProgress = createAction('[S3Upload] Updating Upload progress',
  props<{hashId: string, loaded: number, total: number}>()
);

export const updateUploadProgressStatus = createAction('[S3Upload] Updating Upload progress based on stage',
  props<{hashId: string, progress: number}>()
);


export const updateUploadStage = createAction('[S3Upload] Updating Upload Stage',
  props<{hashId: string, stage: string}>()
);

export const updateKeyS3FileUpload = createAction('[S3Upload] Update the s3 key',
  props<{hashId: string, uri: string}>()
);

export const validateS3FileUpload = createAction('[S3Upload] Validating Uploaded file',
  props<{hashId: string}>()
);

export const updateValidateS3FileUploadResponse = createAction('[S3Upload] Update validation response',
  props<{hashId: string, response: any}>()
);

export const removeS3FileUploadFromStore = createAction('[S3Upload] Remove S3 File reference',
  props<{hashId: string}>()
);