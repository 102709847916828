<!-- <p>modal-global-error works!</p> -->

<!-- <div class="modal-header">
  <h4 class="modal-title pull-left">Unrecoverable error</h4>
</div> -->
<div class="modal-body">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-12 col-xl-12 my-5">

        <div class="text-center">

          <!-- Heading -->
          <h1 class="mb-4" id="modalTitle">
            {{ modalState.title }}
          </h1>

          <div class="alert alert-danger" role="alert">
            <blockquote class="my-2">
              <i class="fe fe-info me-1"></i>
              <!-- <span *ngIf="!(error.length <= 40)">Use the copy details button to copy the error text to your
                clipboard and send it with your support request.</span> -->
              <span id="modalMessage"> {{ modalState.message }} </span>
              <!-- <br><a href class="btn btn-sm btn-secondary mt-3" (click)="copyMessage(error);">Copy Details</a> -->
            </blockquote>
          </div>

          <!-- Subheading -->
          <p class="text-muted mb-4">
            Please sign out and sign back in. If the problem persists, send the above message to <a
              href="mailto:support@openbridge.com">Openbridge Support</a> </p>

          <!-- Button -->
          <a routerLink="/auth/logout" class="btn btn-lg btn-primary">Sign out</a>

        </div>

      </div>
    </div> <!-- / .row -->
  </div>
</div>