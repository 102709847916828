export const responseBigQuerySpm = {
  links: {
    first: 'https://subscriptions.api.dev.openbridge.io/spm?page=1&subscription=138',
    last: 'https://subscriptions.api.dev.openbridge.io/spm?page=1&subscription=138',
    next: '',
    prev: ''
  },
  data: [
    {
      type: 'SubscriptionProductMeta',
      id: '680',
      attributes: {
        data_id: 0,
        data_key: 'remote_identity_id',
        data_value: '77',
        created_at: '2021-11-01T08:07:20.522318',
        modified_at: '2021-11-01T08:07:20.522335',
        product: {
          id: 37,
          active: 1,
          name: 'Google BigQuery',
          summary: '',
          name_slug: 'google-big-query',
          created_at: '2018-04-16T04:00:00',
          modified_at: '2018-04-16T04:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'google-big-query',
          allow_as_trial: 1,
          is_storage_product: 1,
          initialize_job: 0,
          premium_product: 0,
          worker_name: null,
          product_group: 4,
          remote_identity_type: 12,
          product_category: 6,
          parent_product: null
        },
        subscription: {
          id: 138,
          price: 0,
          status: 'active',
          date_start: '2021-11-01T08:07:17',
          date_end: '2021-11-01T08:07:17',
          auto_renew: 1,
          created_at: '2021-11-01T08:07:20.518985',
          modified_at: '2021-11-10T08:02:46.433260',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'bigquery',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 37,
          product_plan: null,
          remote_identity: 77,
          storage_group: 102,
          user: 309
        }
      }
    },
    {
      type: 'SubscriptionProductMeta',
      id: '681',
      attributes: {
        data_id: 0,
        data_key: 'project_id',
        data_value: 'solid-skill-161102',
        created_at: '2021-11-01T08:07:20.528908',
        modified_at: '2021-11-01T08:07:20.528928',
        product: {
          id: 37,
          active: 1,
          name: 'Google BigQuery',
          summary: '',
          name_slug: 'google-big-query',
          created_at: '2018-04-16T04:00:00',
          modified_at: '2018-04-16T04:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'google-big-query',
          allow_as_trial: 1,
          is_storage_product: 1,
          initialize_job: 0,
          premium_product: 0,
          worker_name: null,
          product_group: 4,
          remote_identity_type: 12,
          product_category: 6,
          parent_product: null
        },
        subscription: {
          id: 138,
          price: 0,
          status: 'active',
          date_start: '2021-11-01T08:07:17',
          date_end: '2021-11-01T08:07:17',
          auto_renew: 1,
          created_at: '2021-11-01T08:07:20.518985',
          modified_at: '2021-11-10T08:02:46.433260',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'bigquery',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 37,
          product_plan: null,
          remote_identity: 77,
          storage_group: 102,
          user: 309
        }
      }
    },
    {
      type: 'SubscriptionProductMeta',
      id: '682',
      attributes: {
        data_id: 0,
        data_key: 'dataset_id',
        data_value: 'razorgroup_test',
        created_at: '2021-11-01T08:07:20.531970',
        modified_at: '2021-11-01T08:07:20.532061',
        product: {
          id: 37,
          active: 1,
          name: 'Google BigQuery',
          summary: '',
          name_slug: 'google-big-query',
          created_at: '2018-04-16T04:00:00',
          modified_at: '2018-04-16T04:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'google-big-query',
          allow_as_trial: 1,
          is_storage_product: 1,
          initialize_job: 0,
          premium_product: 0,
          worker_name: null,
          product_group: 4,
          remote_identity_type: 12,
          product_category: 6,
          parent_product: null
        },
        subscription: {
          id: 138,
          price: 0,
          status: 'active',
          date_start: '2021-11-01T08:07:17',
          date_end: '2021-11-01T08:07:17',
          auto_renew: 1,
          created_at: '2021-11-01T08:07:20.518985',
          modified_at: '2021-11-10T08:02:46.433260',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'bigquery',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 37,
          product_plan: null,
          remote_identity: 77,
          storage_group: 102,
          user: 309
        }
      }
    }
  ],
  meta: {
    pagination: {
      page: 1,
      pages: 1,
      count: 3
    }
  }
};