export const responseRedshiftSubscription = {
  data: {
    type: 'Subscription',
    id: '202',
    attributes: {
      price: 0,
      status: 'active',
      subscription_product_meta_attributes: [
        {
          data_id: 0,
          data_key: 'hostname',
          data_value: 'awsRedshiftDatabaseHost',
          product: 29
        },
        {
          data_id: 0,
          data_key: 'port',
          data_value: '5439',
          product: 29
        },
        {
          data_id: 0,
          data_key: 'databaseName',
          data_value: 'awsRedshiftDatabaseName',
          product: 29
        },
        {
          data_id: 0,
          data_key: 'username',
          data_value: 'awsRedshiftUsername',
          product: 29
        },
        {
          data_id: 0,
          data_key: 'password',
          data_value: 'awsRedshiftPassword',
          product: 29
        }
      ],
      date_start: '2021-12-15T14:18:04',
      date_end: '2021-12-15T14:18:04',
      auto_renew: 1,
      created_at: '2021-12-15T14:18:07.497426',
      modified_at: '2021-12-15T14:18:08.074282',
      quantity: 1,
      stripe_subscription_id: '',
      name: 'aws-redshift-success',
      rabbit_payload_successful: 0,
      primary_job_id: null,
      pipeline: null,
      invalid_subscription: 0,
      invalidated_at: null,
      notified_at: null,
      canonical_name: null,
      account_id: 414,
      product_id: 29,
      product_plan_id: null,
      remote_identity_id: null,
      storage_group_id: 136,
      user_id: 382,
      history_requested: 0,
      unique_hash: null
    },
    relationships: {
      account: {
        data: {
          type: 'Account',
          id: '414'
        }
      },
      product: {
        data: {
          type: 'Product',
          id: '29'
        }
      },
      product_plan: {
        data: null
      },
      remote_identity: {
        data: null
      },
      storage_group: {
        data: {
          type: 'StorageGroup',
          id: '136'
        }
      },
      user: {
        data: {
          type: 'User',
          id: '382'
        }
      }
    }
  },
  included: [
    {
      type: 'StorageGroup',
      id: '136',
      attributes: {
        product_id: 29,
        name: 'aws-redshift-success',
        key_name: '99c0882a3c72c772-aws-redshift-success'
      }
    }
  ]
};