import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { buildSpecificModules } from './build-specifics/index';

import { APP_INITIALIZER } from '@angular/core';
import { ResourceService } from 'src/app/core/services/resource.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// ngx-bootstrap imports for bootstrap objects
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { SharedModule } from './shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { reducers } from './core/store/reducers';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { AccountEffects } from './core/store/effects/account.effects';
import { AuthEffects } from './core/store/effects/auth.effects';
import { IdentitiesEffects } from './core/store/effects/identities.effects';
import { HistoryEffects } from './core/store/effects/history.effects';
import { FlashNotificationsEffects } from './core/store/effects/flash-notifications.effects';
import { IntercomEffects } from './core/store/effects/intercom.effects';
import { ModalsEffects } from './core/store/effects/modals.effects';
import { ReportingEffects } from './core/store/effects/reporting.effects';
import { SubscriptionsEffects } from './core/store/effects/subscriptions.effects';
import { WizardEffects } from './core/store/effects/wizard.effects';
import { S3UploadEffects } from './core/store/effects/s3-upload.effects';
import { DailyPipelinesEffects } from './core/store/effects/dailyPipelines.effects';

import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';

import { AuthGuard } from './core/guards/auth.guard';
import { ApiUserGuard } from './core/guards/api-user.guard';
import { AnonymousGuard } from './core/guards/anonymouse.guard';
import { HttpClientModule } from '@angular/common/http';

import { AuthModule } from '@auth0/auth0-angular';
import { httpInterceptorProviders } from 'src/app/core/interceptors/index';
import { RefreshTokenComponent } from './modules/api-management/pages/refresh-token/refresh-token.component';

export const resourceProviderFactory  = (provider: ResourceService) => {
  return () => provider.loadedCachedData();
};

@NgModule({
  declarations: [
    AppComponent,
    RefreshTokenComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      authorizationParams: {
        audience: environment.auth0.audience,
        redirect_uri: window.location.origin
      },      
      httpInterceptor: {
        allowedList: [
          environment.openbridgeApiUris.authentication + '/auth/jwt'
        ]
      }
    }),
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CarouselModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    PopoverModule.forRoot(),
    ProgressbarModule.forRoot(),
    RatingModule.forRoot(),
    SortableModule.forRoot(),
    TabsModule.forRoot(),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    BsDatepickerModule.forRoot(),
    SharedModule,
    EffectsModule.forRoot([
      AccountEffects,
      AuthEffects,
      FlashNotificationsEffects,
      IdentitiesEffects,
      HistoryEffects,
      IntercomEffects,
      ModalsEffects,
      ReportingEffects,
      S3UploadEffects,
      SubscriptionsEffects,
      WizardEffects,
      DailyPipelinesEffects]),
    StoreModule.forRoot(reducers),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      routerState: RouterState.Minimal
    }),
    ...buildSpecificModules
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: resourceProviderFactory,
      deps: [ResourceService],
      multi: true
    },
    { provide: 'googleTagManagerId',  useValue: 'GTM-5P9PTPB'},
    // { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    AuthGuard,
    AnonymousGuard,
    ApiUserGuard,
    httpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}