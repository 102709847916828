export const responseSnowflakeSubscription = {
  data: {
    type: 'Subscription',
    id: '236',
    attributes: {
      price: 0,
      status: 'active',
      subscription_product_meta_attributes: [
        {
          data_id: 0,
          data_key: 'snowflakeAccount',
          data_value: 'account',
          product: 52
        },
        {
          data_id: 0,
          data_key: 'snowflakeDatabase',
          data_value: 'databaseName',
          product: 52
        },
        {
          data_id: 0,
          data_key: 'snowflakeWarehouse',
          data_value: 'warehouse',
          product: 52
        },
        {
          data_id: 0,
          data_key: 'snowflakeSchema',
          data_value: 'schema',
          product: 52
        },
        {
          data_id: 0,
          data_key: 'snowflakeUser',
          data_value: 'username',
          product: 52
        },
        {
          data_id: 0,
          data_key: 'snowflakePassword',
          data_value: 'snowflakePswd',
          product: 52
        }
      ],
      date_start: '2022-01-06T11:28:15',
      date_end: '2022-01-06T11:28:15',
      auto_renew: 1,
      created_at: '2022-01-06T11:28:18.411030',
      modified_at: '2022-01-06T11:28:19.209088',
      quantity: 1,
      stripe_subscription_id: '',
      name: 'snowflake-warehouse-success',
      rabbit_payload_successful: 0,
      primary_job_id: null,
      pipeline: null,
      invalid_subscription: 0,
      invalidated_at: null,
      notified_at: null,
      canonical_name: null,
      account_id: 464,
      product_id: 52,
      product_plan_id: null,
      remote_identity_id: null,
      storage_group_id: 169,
      user_id: 431,
      history_requested: 0,
      unique_hash: null
    },
    relationships: {
      account: {
        data: {
          type: 'Account',
          id: '464'
        }
      },
      product: {
        data: {
          type: 'Product',
          id: '52'
        }
      },
      product_plan: {
        data: null
      },
      remote_identity: {
        data: null
      },
      storage_group: {
        data: {
          type: 'StorageGroup',
          id: '169'
        }
      },
      user: {
        data: {
          type: 'User',
          id: '431'
        }
      }
    }
  },
  included: [
    {
      type: 'StorageGroup',
      id: '169',
      attributes: {
        product_id: 52,
        name: 'snowflake-warehouse-success',
        key_name: '93b1fc5c0e527c00-snowflake-warehouse-success'
      }
    }
  ]
};