export const responseFacebookCommonProfiles = {
  data: [
    {
      type: 'FacebookPages',
      id: '11384491516',
      attributes: {
        name: 'Monopoly',
        country_page_likes: 5711753,
        name_with_location_descriptor: 'Monopoly (Default)',
        engagement: {
          count: 10061100,
          social_sentence: '10M people like this.'
        },
        description: 'On March 19, 1935, Parker Brothers acquired the rights for the MONOPOLY game from Charles Darrow and soon began selling the board game in the United States. Within a year of the release of the MONOPOLY game, 35,000 copies of the board game were being made each week. \n\nOver the years, the MONOPOLY board and the rules of MONOPOLY have remained virtually unchanged. Today, the MONOPOLY game is known as the world’s favorite family game brand and the game has been played by more than one billion people in 114 countries around the world. Whether fans play face-to-face, on mobile, play MONOPOLY online or participate in casino or lottery gaming, there is a MONOPOLY game and experience to fit every lifestyle.\n\nIf you want to learn more about the rich and fun history of the Monopoly game, visit www.Monopoly.com.',
        about: 'The MONOPOLY game is the fast-dealing property trading game that has been played by over a billion people in 114 countries since 1935.'
      }
    },
    {
      type: 'FacebookPages',
      id: '49961932114',
      attributes: {
        name: 'Monopoly',
        country_page_likes: 595513,
        name_with_location_descriptor: 'Monopoly (MX)',
        engagement: {
          count: 10061100,
          social_sentence: '10M people like this.'
        },
        description: 'Monopoly es un juego divertido y adictivo que te hará pasar horas y horas de entretenimiento y sonrisas.',
        about: '¡Vive como Millonario!'
      }
    },
    {
      type: 'FacebookPages',
      id: '113264809700',
      attributes: {
        name: 'Popples',
        instagram_business_account: {
          id: '17841401783475992'
        },
        country_page_likes: 50706,
        name_with_location_descriptor: 'Popples',
        engagement: {
          count: 50706,
          social_sentence: '50K people like this.'
        },
        description: 'Adventure is always a pop, skip and jump away for these 5 best friends! Watch Bubbles, Sunny, Lulu, Yikes and Izzy in the brand new Netflix Original Series, Popples! Popples is a high-energy comedy series about an amazing species of creatures that can pop into and out of a ball. The story is driven by the optimistic comic enthusiasm of these BPPs (Best Popple Pals). Always wanting to help their friends, neighbors, and each other, their efforts tend to backfire in some hilarious way and they spend the rest of the episode trying to unwind the mayhem they have caused… but luckily they always manage to save the day in their own POP-tastic way!',
        about: 'The Official Popples Page! Sharing laughter, fun, and surprises!'
      }
    },
    {
      type: 'FacebookPages',
      id: '158498753170',
      attributes: {
        name: 'Nerf',
        instagram_business_account: {
          id: '17841400468487295'
        },
        country_page_likes: 1068447,
        name_with_location_descriptor: 'Nerf (Default)',
        engagement: {
          count: 2329033,
          social_sentence: '2.3M people like this.'
        },
        description: 'Official page for Nerf™ We"ve been pushing the limits of fun since 1969, and in our nation, IT"S NERF OR NOTHIN"! \n\n*Never modify any NERF blasters or other NERF products.Use only the darts, water, rounds and discs designed for specific NERF blasters.',
        about: 'Official page for Nerf™ We"ve been pushing the limits of fun since 1969, and in our nation, IT"S NERF OR NOTHIN"!'
      }
    },
    {
      type: 'FacebookPages',
      id: '281216627198',
      attributes: {
        name: 'Playskool',
        country_page_likes: 329061,
        name_with_location_descriptor: 'Playskool',
        engagement: {
          count: 329061,
          social_sentence: '329K people like this.'
        },
        about: 'Welcome to the official Facebook page for Playskool and some of your favorite characters, including Mr. Potato Head!'
      }
    },
    {
      type: 'FacebookPages',
      id: '311139662914',
      attributes: {
        name: 'Play-Doh',
        instagram_business_account: {
          id: '17841400756427179'
        },
        country_page_likes: 1134959,
        name_with_location_descriptor: 'Play-Doh (Default)',
        engagement: {
          count: 2168804,
          social_sentence: '2.1M people like this.'
        },
        about: 'Creatable, Colorful, Makeable Fun! \nFun to play with, not to eat.'
      }
    },
    {
      type: 'FacebookPages',
      id: '100606993456146',
      attributes: {
        name: 'Nerf',
        country_page_likes: 18338,
        name_with_location_descriptor: 'Nerf (CZ)',
        engagement: {
          count: 2329033,
          social_sentence: '2.3M people like this.'
        },
        description: 'Z malého oranžového pěnového balonku z roku 1969 se Nerf stal značkou s mnoha komponenty a součástkami. Ze dvora na velká fotbalová hřiště; Nerf se stal životním stylem, který lidem připomíná, že čas na legraci je třeba si najít vždy!',
        about: 'Oficiální kanál NERF. Posouváme hranice zábavy od roku 1969 a jak se u nás říká - NERF ANEBO NIC!   '
      }
    },
    {
      type: 'FacebookPages',
      id: '102420256481570',
      attributes: {
        name: 'Nerf',
        country_page_likes: 16249,
        name_with_location_descriptor: 'Nerf (TW)',
        engagement: {
          count: 2329189,
          social_sentence: '2.3M people like this.'
        },
        description: '從1969年創立的玩具射擊器品牌，有ELITE菁英系列、ULTRA極限系列、RIVAL決戰系列等適合各種年齡層 It"s NERF or nothin"\n千萬不要自行改裝拆卸任何Nerf射擊器與其他產品，建議使用專為Nerf使用安全設計的泡棉彈鏢、飛碟鏢、球鏢或水，以達到最佳表現。',
        about: '\n目前NERF尚無官方維修站,敬請見諒!'
      }
    },
    {
      type: 'FacebookPages',
      id: '104192712963945',
      attributes: {
        name: 'Monopoly 地產大亨',
        country_page_likes: 8735,
        name_with_location_descriptor: 'Monopoly 地產大亨',
        location: {
          zip: '10492'
        },
        engagement: {
          count: 8735,
          social_sentence: '8.7K people like this.'
        },
        about: '孩之寶為全球知名品牌玩具公司，擁有眾多知名品牌如地產大亨、層層疊、戰國風雲等益智遊戲。地產大亨為孩之寶（Hasbro）集團旗下目前全世界最有名的紙盤遊戲。'
      }
    },
    {
      type: 'FacebookPages',
      id: '104739719570938',
      attributes: {
        name: 'Monopoly',
        country_page_likes: 284750,
        name_with_location_descriptor: 'Monopoly (IT)',
        engagement: {
          count: 10060429,
          social_sentence: '10M people like this.'
        },
        about: 'Il Monopoly in tanti anni di storia è diventato un fenomeno planetario:da semplice gioco in scatola ad applicazione per IPhone tra le più scaricate, dalla versione per PC a quella a mazzo di carte\nSegui le novità e le news di Mr Monopoly'
      }
    },
    {
      type: 'FacebookPages',
      id: '108305686526383',
      attributes: {
        name: 'Transformers Singapore',
        country_page_likes: 10295,
        name_with_location_descriptor: 'Transformers Singapore (SG)',
        engagement: {
          count: 2303565,
          social_sentence: '2.3M people like this.'
        },
        about: 'The Official TRANSFORMERS Facebook Community (Singapore) : It’s More Than Meets The Eye\n'
      }
    },
    {
      type: 'FacebookPages',
      id: '108725949157221',
      attributes: {
        name: 'Hasbro Gaming',
        instagram_business_account: {
          id: '17841405631057030'
        },
        country_page_likes: 2428987,
        name_with_location_descriptor: 'Hasbro Gaming (Default)',
        engagement: {
          count: 3308388,
          social_sentence: '3.3M people like this.'
        },
        about: 'Hasbro Gaming brands include some of the world’s most beloved games such as MONOPOLY, TWISTER, OPERATION and THE GAME OF LIFE.'
      }
    },
    {
      type: 'FacebookPages',
      id: '109189360738865',
      attributes: {
        name: 'GI Joe',
        instagram_business_account: {
          id: '17841432521999739'
        },
        country_page_likes: 9637,
        name_with_location_descriptor: 'GI Joe',
        engagement: {
          count: 9637,
          social_sentence: '9.6K people like this.'
        },
        about: 'Wherever there is trouble, G.I. JOE is there!\n    \nINSTAGRAM: @GIJoe |\n       TWITTER: @GIJoeOfficial\n'
      }
    },
    {
      type: 'FacebookPages',
      id: '117803908292269',
      attributes: {
        name: 'Monopoly',
        country_page_likes: 134850,
        name_with_location_descriptor: 'Monopoly (PL)',
        engagement: {
          count: 10061100,
          social_sentence: '10M people like this.'
        },
        description: 'Monopoly to jedna z najpopularniejszych gier planszowych na świecie. Gra jest obecnie w sprzedaży w ponad 114 krajach na całym świecie, dostępna jest w 40 różnych wersjach językowych.\n\nW listopadzie 1973 roku w Nowym Yorku, po raz pierwszy zorganizowane zostały Światowe Mistrzostwa Gry w Monopoly. Od tego czasu, średnio co 4 lata organizowane są kolejne Mistrzostwa Monopoly. Ostatnie Światowe Mistrzostwa odbyły się w październiku 2009 roku w Las Vegas. '
      }
    },
    {
      type: 'FacebookPages',
      id: '118429394841173',
      attributes: {
        name: 'My Little Pony',
        instagram_business_account: {
          id: '17841401289410417'
        },
        country_page_likes: 817364,
        name_with_location_descriptor: 'My Little Pony (Default)',
        engagement: {
          count: 1328794,
          social_sentence: '1.3M people like this.'
        },
        about: 'Since 1983 the magical My Little Pony brand has brought fun, friendship & joy to millions of kids of all ages around the globe.'
      }
    },
    {
      type: 'FacebookPages',
      id: '121958587879153',
      attributes: {
        name: '變形金剛同樂會',
        country_page_likes: 14871,
        name_with_location_descriptor: '變形金剛同樂會',
        engagement: {
          count: 14871,
          social_sentence: '14K people like this.'
        },
        about: '變形金剛玩具(台灣官方)頁面，最新產品資訊及活動消息都在此'
      }
    },
    {
      type: 'FacebookPages',
      id: '125302161403441',
      attributes: {
        name: 'Nerf Singapore',
        country_page_likes: 12357,
        name_with_location_descriptor: 'Nerf Singapore (SG)',
        location: {
          city: 'Singapore',
          country: 'Singapore',
          latitude: 1.31696,
          longitude: 103.84371,
          state: 'Singapore',
          street: '101 Thomson Road',
          zip: '307591'
        },
        engagement: {
          count: 2329033,
          social_sentence: '2.3M people like this.'
        },
        description: 'Official Page of Nerf Singapore. \n\nOur products are available at the following retailers (in alphabetical order):\n\nBHG\nGifts Greetings (Selected stores) \nIsetan\nJohn Little\nKiddy Palace (Selected stores)\nLazada Singapore\nMetro\nOG\nPopular Bookstore (Selected stores)\nRobinsons\nTakashimaya\nToys”R”Us\n\nOfficial page for Nerf™ We"ve been pushing the limits of fun since 1969, and in our nation, IT"S NERF OR NOTHIN"!\n\n*Never modify any NERF blasters or other NERF products.Use only the darts, discs, rounds and water designed for specific NERF blasters.'
      }
    },
    {
      type: 'FacebookPages',
      id: '136581423019153',
      attributes: {
        name: 'Nerf',
        country_page_likes: 99627,
        name_with_location_descriptor: 'Nerf (FR)',
        engagement: {
          count: 2329033,
          social_sentence: '2.3M people like this.'
        },
        about: 'Page officielle de Nerf™. Nous repoussons les limites du fun depuis 2009, et chez nous C"EST NERF OU RIEN ! \n'
      }
    },
    {
      type: 'FacebookPages',
      id: '138843549467359',
      attributes: {
        name: 'Monopoly',
        instagram_business_account: {
          id: '17841425455579310'
        },
        country_page_likes: 387044,
        name_with_location_descriptor: 'Monopoly (DE, AT - German, CH - German)',
        engagement: {
          count: 10061771,
          social_sentence: '10M people like this.'
        },
        description: 'Das große Handeln um Mieten, Macht und Moneten. Wer hat als Erster seine Straßenzüge komplett und  wer hat die beste Strategie, um seine Mieteinnahmen in Hotels und Häuser zu investieren? Wenn Monopoly auf den Tisch kommt und Mama zum Immobilienhai wird, ist die ganze Familie gerne dabei!',
        about: 'Hier findest du immer wieder die aktuellsten News zu deinem Lieblingsspiel und kannst dich mit Monopoly-Fans aus aller Welt austauschen. '
      }
    },
    {
      type: 'FacebookPages',
      id: '143102116368953',
      attributes: {
        name: 'Hasbro Gaming - NO',
        country_page_likes: 4795,
        name_with_location_descriptor: 'Hasbro Gaming - NO (NO)',
        location: {
          latitude: 59.998986120604,
          longitude: 10.6787109375
        },
        engagement: {
          count: 3308388,
          social_sentence: '3.3M people like this.'
        }
      }
    },
    {
      type: 'FacebookPages',
      id: '146805978722938',
      attributes: {
        name: 'TRANSFORMERS',
        instagram_business_account: {
          id: '17841402166668199'
        },
        country_page_likes: 1048300,
        name_with_location_descriptor: 'TRANSFORMERS (Default)',
        engagement: {
          count: 2303565,
          social_sentence: '2.3M people like this.'
        },
        about: 'The Official TRANSFORMERS Facebook Community: It’s  #MORETHANMEETSTHEEYE'
      }
    },
    {
      type: 'FacebookPages',
      id: '148447961992639',
      attributes: {
        name: 'Play-Doh',
        country_page_likes: 13260,
        name_with_location_descriptor: 'Play-Doh (NL, BE - Dutch)',
        engagement: {
          count: 2168659,
          social_sentence: '2.1M people like this.'
        },
        about: 'Speel, leer, creëer en maak nieuwe kleuren met Play-Doh!\nLeuk om mee te spelen, niet om op te eten.\n\n'
      }
    },
    {
      type: 'FacebookPages',
      id: '150221385117051',
      attributes: {
        name: 'Monopoly',
        country_page_likes: 206361,
        name_with_location_descriptor: 'Monopoly (MY)',
        engagement: {
          count: 10060429,
          social_sentence: '10M people like this.'
        },
        description: 'On March 19, 1935, Parker Brothers acquired the rights for the MONOPOLY game from Charles Darrow and soon began selling the board game in the United States. Within a year of the release of the MONOPOLY game, 35,000 copies of the board game were being made each week. \n\nOver the years, the MONOPOLY board and the rules of MONOPOLY have remained virtually unchanged. Today, the MONOPOLY game is known as the world’s favorite family game brand and the game has been played by more than one billion people in 114 countries around the world. Whether fans play face-to-face, on mobile, play MONOPOLY online or participate in casino or lottery gaming, there is a MONOPOLY game and experience to fit every lifestyle.\n\nIf you want to learn more about the rich and fun history of the Monopoly game, visit www.Monopoly.com.',
        about: 'The MONOPOLY game is the fast-dealing property trading game that has been played by over a billion people in 114 countries since 1935.'
      }
    },
    {
      type: 'FacebookPages',
      id: '152452064782617',
      attributes: {
        name: 'Power Rangers',
        instagram_business_account: {
          id: '17841401680484139'
        },
        country_page_likes: 2797332,
        name_with_location_descriptor: 'Power Rangers',
        engagement: {
          count: 2797332,
          social_sentence: '2.7M people like this.'
        },
        description: 'The Power Rangers franchise launched in 1993 with the hit series, Mighty Morphin Power Rangers. Power Rangers is a global phenomenon with an iconic legacy that inspires and unites the multi-generational fans while embracing the values of community, teamwork and adventure.\n\nThe 28th season, Power Rangers Dino Fury, airs on Saturdays at 8/7AM central on Nickelodeon in the U.S. and on various broadcasters around the globe. Stream the 1st half of Dino Fury Season 1 on Netflix in the U.S., U.K., Australia, New Zealand, and Canada. The series delivers mega action, humor, excitement and entertainment, in addition to the core themes of friendship, teamwork, fitness, and helping others.\n\nShop: https://shop.powerrangers.com\nTwitter: https://twitter.com/powerrangers\nInstagram: http://instagram.com/powerrangers\nYouTube: https://www.youtube.com/PowerRangers ',
        about: 'It"s Morphin Time! Watch Power Rangers Dino Fury every Saturday at 8am / 7am central on Nickelodeon! \n\nStream the 1st half of Power Rangers Dino Fury on Netflix in the U.S., U.K., Australia, New Zealand, and Canada! \n'
      }
    },
    {
      type: 'FacebookPages',
      id: '153751211424310',
      attributes: {
        name: 'Monopoly',
        instagram_business_account: {
          id: '17841407114013990'
        },
        country_page_likes: 1129100,
        name_with_location_descriptor: 'Monopoly (PE, CO, CL, ...)',
        engagement: {
          count: 10060429,
          social_sentence: '10M people like this.'
        },
        description: 'La leyenda de MONOPOLY\nCharles B. Darrow era uno de los muchos norteamericanos desempleados durante la Gran depresión. La vida era deprimente, pero Darrow tuvo una idea: un juego que le permitiría a todos disfrutar un poco de la vida de las altas esferas. En 1934, llevó su juego a Parker Brother; sin embargo, su juego fue rechazado pues no cumplía con los estándares de la compañía. Ya que Darrow era un hombre que no se rendía al enfrentar el primer obstáculo, hizo un trato con una imprenta local y logró producir 5000 juegos, los cuales vendió a una tienda departamental en Filadelfia. A las personas les encantó el juego y la noticia de su éxito pronto llegó a Parker Brother. Negociaron un trato y como resultado, la fortuna de ambos surgió. A la fecha, MONOPOLY sigue siendo el juego de mesa número 1 en el mundo.',
        about: 'Monopoly'
      }
    }
  ],
  includes: {
    next: null
  }
};