import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { copyDetails } from 'src/app/core/functions/utility';

@Component({
  selector: 'app-modal-global-error',
  templateUrl: './modal-global-error.component.html'
})
export class ModalGlobalErrorComponent{
  error: any;
  copyMessage = copyDetails;

  constructor(
    private auth: AuthService
  ) {}

}
