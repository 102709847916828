export const responseAmazonSettlementReportsSpm = {
  links: {
    first: 'https://subscriptions.api.dev.openbridge.io/spm?page=1&subscription=993',
    last: 'https://subscriptions.api.dev.openbridge.io/spm?page=1&subscription=993',
    next: '',
    prev: ''
  },
  data: [
    {
      type: 'SubscriptionProductMeta',
      id: '1918',
      attributes: {
        data_id: 0,
        data_key: 'remote_identity_id',
        data_value: '135',
        created_at: '2022-05-12T10:50:28.866006',
        modified_at: '2022-05-12T10:50:28.866028',
        product: {
          id: 57,
          active: 1,
          name: 'Settlement Reports',
          summary: '',
          name_slug: 'settlement-reports',
          created_at: '2018-08-14T04:00:00',
          modified_at: '2018-08-14T04:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'settlement-reports',
          allow_as_trial: 1,
          is_storage_product: 0,
          initialize_job: 1,
          premium_product: 0,
          worker_name: 'sporders',
          product_group: 1,
          remote_identity_type: 17,
          product_category: 1,
          parent_product: null
        },
        subscription: {
          id: 993,
          price: 0,
          status: 'active',
          date_start: '2022-05-12T10:50:24',
          date_end: '2022-05-12T10:50:24',
          auto_renew: 1,
          created_at: '2022-05-12T10:50:28.851742',
          modified_at: '2022-05-12T10:50:28.851771',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'amazon-settlement-reports-test',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 57,
          product_plan: null,
          remote_identity: 135,
          storage_group: 110,
          user: 309
        }
      }
    }
  ],
  meta: {
    pagination: {
      page: 1,
      pages: 1,
      count: 1
    }
  }
};