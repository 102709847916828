<div class="modal-header">
  <h3 class="modal-title pull-left">
    <span id="modalTitle">
      {{ modalState.title }}
    </span>
    <span class="me-2 badge bg-info-soft ms-1 mt-n1">Loading</span>
  </h3>
</div>
<div class="modal-body text-muted">
  <div class="mb-4" id="modalMessage">
    {{ modalState.message }}
  </div>
  <div class="progress">
    <div class="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" aria-valuenow="0"
      aria-valuemin="0" aria-valuemax="100" [style]="widthStyle"></div>
  </div>
</div>