export const responseAmazonAttributionSpm = {
  links: {
    first: 'https://subscriptions.api.dev.openbridge.io/spm?page=1&subscription=727',
    last: 'https://subscriptions.api.dev.openbridge.io/spm?page=1&subscription=727',
    next: '',
    prev: ''
  },
  data: [
    {
      type: 'SubscriptionProductMeta',
      id: '1651',
      attributes: {
        data_id: 0,
        data_key: 'remote_identity_id',
        data_value: '112',
        created_at: '2022-05-06T11:45:49.490563',
        modified_at: '2022-05-06T11:45:49.490582',
        product: {
          id: 50,
          active: 1,
          name: 'Amazon Attribution',
          summary: '',
          name_slug: 'amazon-attribution',
          created_at: '2019-03-29T04:00:00',
          modified_at: '2019-03-29T04:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'amazon-attribution',
          allow_as_trial: 1,
          is_storage_product: 0,
          initialize_job: 1,
          premium_product: 0,
          worker_name: 'amzn_attribution',
          product_group: 1,
          remote_identity_type: 14,
          product_category: 1,
          parent_product: null
        },
        subscription: {
          id: 727,
          price: 0,
          status: 'active',
          date_start: '2022-05-06T11:45:46',
          date_end: '2022-05-06T11:45:46',
          auto_renew: 1,
          created_at: '2022-05-06T11:45:49.467569',
          modified_at: '2022-05-06T11:45:49.467584',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'amazon-attr-test',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 50,
          product_plan: null,
          remote_identity: 112,
          storage_group: 110,
          user: 309
        }
      }
    },
    {
      type: 'SubscriptionProductMeta',
      id: '1652',
      attributes: {
        data_id: 0,
        data_key: 'profile_id',
        data_value: '2401846814837028',
        created_at: '2022-05-06T11:45:49.501913',
        modified_at: '2022-05-06T11:45:49.501931',
        product: {
          id: 50,
          active: 1,
          name: 'Amazon Attribution',
          summary: '',
          name_slug: 'amazon-attribution',
          created_at: '2019-03-29T04:00:00',
          modified_at: '2019-03-29T04:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'amazon-attribution',
          allow_as_trial: 1,
          is_storage_product: 0,
          initialize_job: 1,
          premium_product: 0,
          worker_name: 'amzn_attribution',
          product_group: 1,
          remote_identity_type: 14,
          product_category: 1,
          parent_product: null
        },
        subscription: {
          id: 727,
          price: 0,
          status: 'active',
          date_start: '2022-05-06T11:45:46',
          date_end: '2022-05-06T11:45:46',
          auto_renew: 1,
          created_at: '2022-05-06T11:45:49.467569',
          modified_at: '2022-05-06T11:45:49.467584',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'amazon-attr-test',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 50,
          product_plan: null,
          remote_identity: 112,
          storage_group: 110,
          user: 309
        }
      }
    }
  ],
  meta: {
    pagination: {
      page: 1,
      pages: 1,
      count: 2
    }
  }
};