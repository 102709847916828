import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-site-update-refresh',
  templateUrl: './modal-site-update-refresh.component.html'
})
export class ModalSiteUpdateRefreshComponent implements OnInit {
  barWidth = '0px';
  intervalTimeInMilliSeconds = 250;
  waitBeforeReloadInSeconds = 10;
  waitBeforeReloadInMilliSeconds = 1000 * this.waitBeforeReloadInSeconds;
  counterMax = this.waitBeforeReloadInMilliSeconds / this.intervalTimeInMilliSeconds;
  counter = 0;
  refreshInterval = null;

  constructor() { }

  ngOnInit(): void {
    this.refreshInterval = setInterval(() => {
      if ((++this.counter) === this.counterMax) {
        clearInterval(this.refreshInterval);
        window.location.reload();
      }
      this.barWidth = ((100 / this.counterMax) * this.counter).toString() + '%';
    }, this.intervalTimeInMilliSeconds);
  }

}
