import { vendorsHashed } from './logos-hashed';

const baseLogoImagePath = '/assets/img/products/';

export const vendors = {
  amazon: baseLogoImagePath + vendorsHashed.amazon,
  amazonAdvertising: baseLogoImagePath + vendorsHashed.amazonAdvertising,
  amazonSellerCentral: baseLogoImagePath + vendorsHashed.amazonSellerCentral,
  amazonVendorCentral: baseLogoImagePath + vendorsHashed.amazonVendorCentral,
  awsAthena: baseLogoImagePath + vendorsHashed.awsAthena,
  airbyte: baseLogoImagePath + vendorsHashed.opensource,
  awsRedshift: baseLogoImagePath + vendorsHashed.awsRedshift,
  awsSpectrum: baseLogoImagePath + vendorsHashed.awsSpectrum,
  azureBlobStorage: baseLogoImagePath + vendorsHashed.azureBlobStorage,
  azureDataLake: baseLogoImagePath + vendorsHashed.azureDataLake,
  googleAnalytics360: baseLogoImagePath + vendorsHashed.googleAnalytics360,
  googleBigquery: baseLogoImagePath + vendorsHashed.googleBigquery,
  googleCampaignManager: baseLogoImagePath + vendorsHashed.googleCampaignManager,
  googleCloud: baseLogoImagePath + vendorsHashed.googleCloud,
  googleDisplayVideo360: baseLogoImagePath + vendorsHashed.googleDisplayVideo360,
  googleSearch360: baseLogoImagePath + vendorsHashed.googleSearch360,
  googleAds: baseLogoImagePath + vendorsHashed.googleAds,
  google: baseLogoImagePath + vendorsHashed.google,
  facebook: baseLogoImagePath + vendorsHashed.facebook,
  instagram: baseLogoImagePath + vendorsHashed.instagram,
  kinesis: baseLogoImagePath + vendorsHashed.kinesis,
  batch: baseLogoImagePath + vendorsHashed.batch,
  shopify: baseLogoImagePath + vendorsHashed.shopify,
  snowflake: baseLogoImagePath + vendorsHashed.snowflake,
  youtube: baseLogoImagePath + vendorsHashed.youtube,
  dataBricks: baseLogoImagePath + vendorsHashed.dataBricks,
  openbridgeMultiStorage: baseLogoImagePath + vendorsHashed.openbridgeMultiStorage
};