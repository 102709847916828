export const responseSharedRemoteIdentities = {
  links: {
    first: 'https://remote-identity.api.dev.openbridge.io/sri?page=1&page_size=100&sort=-created_at',
    last: 'https://remote-identity.api.dev.openbridge.io/sri?page=1&page_size=100&sort=-created_at',
    next: '',
    prev: ''
  },
  data: [
    {
      type: 'RemoteIdentity',
      id: '215',
      attributes: {
        name: 'google-identity-failure',
        created_at: '2022-01-24T17:37:09.724779',
        modified_at: '2022-01-24T17:37:09.724794',
        identity_hash: 'a113e0f055f4e16b3ed3c43f005f4a3c',
        remote_unique_id: 'f22bda528ae3f0d664d2153449b835c7ee29e0f4',
        account_id: 342,
        user_id: 309,
        notified_at: null,
        invalidate_manually: 0,
        invalid_identity: 0,
        invalidated_at: null,
        notification_counter: 0,
        region: 'MX'
      },
      relationships: {
        remote_identity_type: {
          data: {
            type: 'RemoteIdentityType',
            id: '1'
          }
        },
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        },
        trusted_identity: {
          data: null
        },
        remote_identity_type_application: {
          data: {
            type: 'RemoteIdentityTypeApplication',
            id: '22'
          }
        }
      }
    },
    {
      type: 'RemoteIdentity',
      id: '110',
      attributes: {
        name: 'bigquery-identity-success',
        created_at: '2021-12-01T15:20:53.403180',
        modified_at: '2021-12-01T15:20:53.403202',
        identity_hash: '1207c302-e6aa-40d0-a14f-4f6d14241f49',
        remote_unique_id: 'f22bda528ae3f0d664d2153450b835c7ee29e0f4',
        account_id: 410,
        user_id: 377,
        notified_at: null,
        invalidate_manually: 0,
        invalid_identity: 0,
        invalidated_at: null,
        notification_counter: 0,
        region: 'global'
      },
      relationships: {
        remote_identity_type: {
          data: {
            type: 'RemoteIdentityType',
            id: '12'
          }
        },
        account: {
          data: {
            type: 'Account',
            id: '410'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '377'
          }
        },
        trusted_identity: {
          data: null
        },
        remote_identity_type_application: {
          data: null
        }
      }
    },
    {
      type: 'RemoteIdentity',
      id: '22',
      attributes: {
        name: 'amazon-adv-dsp-identity-success',
        created_at: '2021-10-15T18:49:09.839933',
        modified_at: '2021-10-15T18:49:09.839946',
        identity_hash: '4cee22043dacd56cf8dbcf3eaed1ca44',
        remote_unique_id: 'amzn1.account.AH4XMYTXT4TG5BZDYCBJMMUMPDOQ',
        account_id: 342,
        user_id: 309,
        notified_at: null,
        invalidate_manually: 0,
        invalid_identity: 0,
        invalidated_at: null,
        notification_counter: 0,
        region: 'na'
      },
      relationships: {
        remote_identity_type: {
          data: {
            type: 'RemoteIdentityType',
            id: '14'
          }
        },
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        },
        trusted_identity: {
          data: null
        },
        remote_identity_type_application: {
          data: {
            type: 'RemoteIdentityTypeApplication',
            id: '16'
          }
        }
      }
    },
    {
      type: 'RemoteIdentity',
      id: '112',
      attributes: {
        name: 'amazon-attribution-identity-success',
        created_at: '2021-11-20T14:12:37.403180',
        modified_at: '2021-11-20T14:12:37.403180',
        identity_hash: '390883e60dc4627e0fb5900eba38c1f5',
        remote_unique_id: 'amzn1.account.AF4UMXCQOTHFNRWXEXYHXDVFKGVQ',
        account_id: 342,
        user_id: 309,
        notified_at: null,
        invalidate_manually: 0,
        invalid_identity: 0,
        invalidated_at: null,
        notification_counter: 0,
        region: 'na'
      },
      relationships: {
        remote_identity_type: {
          data: {
            type: 'RemoteIdentityType',
            id: '14'
          }
        },
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        },
        trusted_identity: {
          data: null
        },
        remote_identity_type_application: {
          data: {
            type: 'RemoteIdentityTypeApplication',
            id: '16'
          }
        }
      }
    },
    {
      type: 'RemoteIdentity',
      id: '134',
      attributes: {
        name: 'amazon-sp-identity-failure',
        created_at: '2022-01-24T16:52:34.037409',
        modified_at: '2022-01-24T16:52:34.037424',
        identity_hash: 'de98b6270e653ad701a908c34fc85186',
        remote_unique_id: 'amazon-sp-identity-failure',
        account_id: 342,
        user_id: 309,
        notified_at: null,
        invalidate_manually: 0,
        invalid_identity: 0,
        invalidated_at: null,
        notification_counter: 0,
        region: 'DE'
      },
      relationships: {
        remote_identity_type: {
          data: {
            type: 'RemoteIdentityType',
            id: '17'
          }
        },
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        },
        trusted_identity: {
          data: null
        },
        remote_identity_type_application: {
          data: {
            type: 'RemoteIdentityTypeApplication',
            id: '22'
          }
        }
      }
    },
    {
      type: 'RemoteIdentity',
      id: '85',
      attributes: {
        name: 'amazon-common-identity-failure',
        created_at: '2021-11-04T13:42:23.403577',
        modified_at: '2021-11-04T13:42:23.403589',
        identity_hash: 'f87d15deab503fbfce49c23c113bfdd3',
        remote_unique_id: 'amzn1.account.AELZF4TCFXPYZH2XGIRMIJSLDDWQ',
        account_id: 343,
        user_id: 310,
        notified_at: null,
        invalidate_manually: 0,
        invalid_identity: 0,
        invalidated_at: null,
        notification_counter: 0,
        region: 'na'
      },
      relationships: {
        remote_identity_type: {
          data: {
            type: 'RemoteIdentityType',
            id: '14'
          }
        },
        account: {
          data: {
            type: 'Account',
            id: '343'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '310'
          }
        },
        trusted_identity: {
          data: null
        },
        remote_identity_type_application: {
          data: {
            type: 'RemoteIdentityTypeApplication',
            id: '16'
          }
        }
      }
    },
    {
      type: 'RemoteIdentity',
      id: '178',
      attributes: {
        name: 'google-ads-identity-success',
        created_at: '2022-02-09T17:30:27.095221',
        modified_at: '2022-02-09T17:30:27.095235',
        identity_hash: 'd5d7b6d2f1785e20ebb8771b8c5749be',
        remote_unique_id: 'asdf9823uj23hydasdf9238',
        account_id: 342,
        user_id: 309,
        notified_at: null,
        invalidate_manually: 0,
        invalid_identity: 0,
        invalidated_at: null,
        notification_counter: 0,
        region: 'global'
      },
      relationships: {
        remote_identity_type: {
          data: {
            type: 'RemoteIdentityType',
            id: '8'
          }
        },
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        },
        trusted_identity: {
          data: null
        },
        remote_identity_type_application: {
          data: {
            type: 'RemoteIdentityTypeApplication',
            id: '11'
          }
        }
      }
    },
    {
      type: 'RemoteIdentity',
      id: '179',
      attributes: {
        name: 'google-ads-identity-failure',
        created_at: '2022-02-15T09:29:45.321546',
        modified_at: '2022-02-15T09:29:45.321560',
        identity_hash: 'b5587098369950248e114b1ae68ad05a',
        remote_unique_id: '101878852755606711695',
        account_id: 342,
        user_id: 309,
        notified_at: null,
        invalidate_manually: 0,
        invalid_identity: 0,
        invalidated_at: null,
        notification_counter: 0,
        region: 'global'
      },
      relationships: {
        remote_identity_type: {
          data: {
            type: 'RemoteIdentityType',
            id: '8'
          }
        },
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        },
        trusted_identity: {
          data: null
        },
        remote_identity_type_application: {
          data: {
            type: 'RemoteIdentityTypeApplication',
            id: '11'
          }
        }
      }
    },
    {
      type: 'RemoteIdentity',
      id: '202',
      attributes: {
        name: 'facebook-identity-success',
        created_at: '2014-06-11T14:48:02',
        modified_at: '2022-03-01T17:01:56',
        identity_hash: 'db63b3ff6a9715900e0a2675abd29bc0',
        remote_unique_id: '100002672085194',
        account_id: 564,
        user_id: 531,
        notified_at: '2020-05-26T00:40:02',
        invalidate_manually: 0,
        invalid_identity: 0,
        invalidated_at: '2020-06-14T00:05:01',
        notification_counter: 0,
        region: 'global'
      },
      relationships: {
        remote_identity_type: {
          data: {
            type: 'RemoteIdentityType',
            id: '2'
          }
        },
        account: {
          data: {
            type: 'Account',
            id: '564'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '531'
          }
        },
        trusted_identity: {
          data: null
        },
        remote_identity_type_application: {
          data: {
            type: 'RemoteIdentityTypeApplication',
            id: '8'
          }
        }
      }
    },
    {
      type: 'RemoteIdentity',
      id: '135',
      attributes: {
        name: 'amazon-sp-identity-success',
        created_at: '2022-01-24T17:37:09.724779',
        modified_at: '2022-01-24T17:37:09.724794',
        identity_hash: 'a113e0f055f4e16b3ed3c43f005f4a3c',
        remote_unique_id: 'amazon-sp-identity-success',
        account_id: 342,
        user_id: 309,
        notified_at: null,
        invalidate_manually: 0,
        invalid_identity: 0,
        invalidated_at: null,
        notification_counter: 0,
        region: 'MX'
      },
      relationships: {
        remote_identity_type: {
          data: {
            type: 'RemoteIdentityType',
            id: '17'
          }
        },
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        },
        trusted_identity: {
          data: null
        },
        remote_identity_type_application: {
          data: {
            type: 'RemoteIdentityTypeApplication',
            id: '22'
          }
        }
      }
    },
    {
      type: 'RemoteIdentity',
      id: '216',
      attributes: {
        name: 'google-identity-success',
        created_at: '2022-04-04T15:38:26.375775',
        modified_at: '2022-04-04T15:38:26.375789',
        identity_hash: 'bcc106194b911f9dbe38a627c298e780',
        remote_unique_id: '116165343250658957918',
        account_id: 342,
        user_id: 309,
        notified_at: null,
        invalidate_manually: 0,
        invalid_identity: 0,
        invalidated_at: null,
        notification_counter: 0,
        region: 'global'
      },
      relationships: {
        remote_identity_type: {
          data: {
            type: 'RemoteIdentityType',
            id: '1'
          }
        },
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        },
        trusted_identity: {
          data: null
        },
        remote_identity_type_application: {
          data: {
            type: 'RemoteIdentityTypeApplication',
            id: '1'
          }
        }
      }
    },
    {
      type: 'RemoteIdentity',
      id: '265',
      attributes: {
        name: 'facebook-identity-failure',
        created_at: '2022-04-15T12:55:54.461805',
        modified_at: '2022-04-15T12:55:54.461819',
        identity_hash: '7a9fc4e78f731e15c4107c60f42366b5',
        remote_unique_id: '526589612',
        account_id: 342,
        user_id: 309,
        notified_at: null,
        invalidate_manually: 0,
        invalid_identity: 0,
        invalidated_at: null,
        notification_counter: 0,
        region: 'global'
      },
      relationships: {
        remote_identity_type: {
          data: {
            type: 'RemoteIdentityType',
            id: '2'
          }
        },
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        },
        trusted_identity: {
          data: null
        },
        remote_identity_type_application: {
          data: {
            type: 'RemoteIdentityTypeApplication',
            id: '2'
          }
        }
      }
    }
  ],
  meta: {
    pagination: {
      page: 1,
      pages: 1,
      count: 20
    }
  }
};