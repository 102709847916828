export const responseBlobStorage = {
  data: {
    attributes: {
      accmapping_id: 954,
      account_id: 'test-1640091024160-000001',
      created_at: '2021-12-21 12:50:35.059033',
      error_code: null,
      message: '',
      modified_at: '2021-12-21 12:50:35.483009',
      path: '/ebs/ftpd/test-1640091024160-000001/storage_validation',
      status: 'ACTIVE',
      storage_type: 'azure_blob'
    },
    id: 958
  }
};