export const apiResponseCodesSubscription = {
  CONSOLE_EXCEPTION_CREATE_SUBSCRIPTION_ALREADY_EXISTS: 'Subscription already exists',
  CONSOLE_EXCEPTION_CREATE_SUBSCRIPTION_VALIDATION: 'Subscription parameters are missing or invalid',
  CONSOLE_EXCEPTION_UPDATE_SUBSCRIPTION_VALIDATION: 'Subscription parameters are missing or invalid',
  CONSOLE_EXCEPTION_CREATE_SPM_ALREADY_EXISTS: 'Subscription already exists',
  CONSOLE_EXCEPTION_CREATE_SPM_VALIDATION: 'Data for the subscription is missing or invalid',
  CONSOLE_EXCEPTION_UPDATE_SPM_VALIDATION: 'Data for the subscription is missing or invalid',
  CONSOLE_EXCEPTION_CREATE_SUBSCRIPTION_SPM_ALREADY_EXISTS: 'Subscription already exists',
  CONSOLE_EXCEPTION_CREATE_STORAGE_ACCMAPPING: 'An internal error creating your storage has occurred, please contact support if this continues.',
  CONSOLE_EXCEPTION_SUBSCRIPTION_NOT_FOUND: 'Subscription parameter could not be.',
  CONSOLE_EXCEPTION_SPM_NOT_FOUND: 'Subscription parameter could not be.'
};