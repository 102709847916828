import { trigger, style, transition, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Integration } from 'src/app/shared/models/integration.model';

@Component({
  selector: 'app-modal-third-party-identity',
  templateUrl: './modal-third-party-identity.component.html',
  styleUrls: [],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 }))
      ])
    ])
  ]
})

export class ModalThirdPartyIdentityComponent implements OnInit {
  formGroup: UntypedFormGroup
  modalState: any;
  externalIdentityLink: string;
  integration: Integration;
  onClose$: Subject<any> = new Subject<any>();
  isCopied = false;

  constructor(
    private bsModalRef: BsModalRef,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.formGroup = new UntypedFormGroup({
      externalIdentityLink: new UntypedFormControl(this.externalIdentityLink),
    });
  }

  onSubmit($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.copyURL(this.externalIdentityLink);
  }

  cancel(): void {
    this.onClose$.next(false);
    this.closeModalWithComponent();
    if (this.integration) {
      this.router.navigate([this.integration.routerLink], { queryParams: { stage: 'identity' } });
    }
  }

  private closeModalWithComponent(): void {
    if (this.bsModalRef !== null) {
      this.bsModalRef.hide();
    }
    this.bsModalRef = null;
  }

  copyURL(url: string): boolean {
    if (navigator.clipboard) {
      this.copyTextAnimation();
      navigator.clipboard.writeText(url);
    } else {
      console.info('Browser Not compatible');
    }
    return false;
  };


  copyTextAnimation() {
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, 2000); // Show "Copied!" message for 2 seconds
  }
}
