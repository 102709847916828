import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/oauth.actions';

// CHange "any" to whatever the user model will be in the future.
export interface OauthState {
  currentStage: string;
  previousStages: string[] | null;
  remoteIdentityAppId: number | null;
  extraParams: object | null;
}

//  Create the initial state for the authenticated user.
export const initialOAuthState: OauthState = {
  currentStage: 'selection',
  previousStages: [],
  remoteIdentityAppId: null,
  extraParams: null
};

export const reducer = createReducer(
  initialOAuthState,
  on(actions.oauthAppIdUpdate, (state, action) => {

    return {
      ...state,
      remoteIdentityAppId: action.config.remoteIdentityAppId,
    };
  }),
  on(actions.oauthNextPage, (state, action) => {

    const previousStages = [...state.previousStages];
    previousStages.push(state.currentStage);

    return {
      ...state,
      previousStages: previousStages,
      currentStage: action.stageName,
    };
  }),
  on(actions.oauthPreviousPage, (state) => {

    const previousStages = [...state.previousStages];
    const currentStage = previousStages.pop();

    return {
      ...state,
      previousStage: previousStages,
      currentStage: currentStage
    };
  }),
  on(actions.oauthUpdateExtraParams, (state, action) => {
    const extraParams = {
      ...state.extraParams,
      ...action.extraParams
    };

    return {
      ...state,
      extraParams: extraParams
    };
  })

);
