import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/s3-upload.actions';


export interface FileState {
  hashId: string;
  uploadType: 'csvValidator';
  file: File;
  total: number;
  loaded: number;
  status: string; //'initialized' | 'uploading' | 'verifying' | 'complete'
  s3PutUri?: string | null;
  verifyResponse?: any;
  progress: number;
  dropzoneRejected: boolean;
}

// CHange "any" to whatever the user model will be in the future.
export interface S3UploadFilesState {
  uploadedFileStates: FileState[]
}

//  Create the initial state for the authenticated user.
const initialS3FilesState: S3UploadFilesState = {
  uploadedFileStates: []
};

export const reducer = createReducer(
  initialS3FilesState,
  on(actions.initializeS3FileUpload, (state, payload) => {
    const uploadedFileStates = [
      ...state.uploadedFileStates,
      payload
    ];

    return {
      ...state,
      uploadedFileStates: uploadedFileStates
    };
  }),
  on(actions.rejectedS3FileUpload, (state, payload) => {
    const uploadedFileStates = [
      ...state.uploadedFileStates,
      payload
    ];

    return {
      ...state,
      uploadedFileStates: uploadedFileStates
    };
  }),
  on(actions.updateUploadStage, (state, payload) => {
    const fileStates = [...state.uploadedFileStates];
    const objIndex = fileStates.findIndex((obj => obj.hashId == payload.hashId));
    const stateObj: FileState = { ...fileStates[objIndex] }
    stateObj.status = payload.stage;
    fileStates[objIndex] = stateObj;

    return {
      uploadedFileStates: fileStates
    };
  }),
  on(actions.updateKeyS3FileUpload, (state, payload) => {


    const fileStates = [...state.uploadedFileStates];
    const objIndex = fileStates.findIndex((obj => obj.hashId == payload.hashId));
    const stateObj = { ...fileStates[objIndex] }
    stateObj.s3PutUri = payload.uri;
    fileStates[objIndex] = stateObj;

    return {
      uploadedFileStates: fileStates
    };

  }),
  on(actions.updateValidateS3FileUploadResponse, (state, payload) => {
    const fileStates = [...state.uploadedFileStates];
    const objIndex = fileStates.findIndex((obj => obj.hashId == payload.hashId));
    const stateObj = { ...fileStates[objIndex] }
    stateObj.verifyResponse = payload.response;
    fileStates[objIndex] = stateObj;

    return {
      uploadedFileStates: fileStates
    };
  }),
  on(actions.updateUploadProgress, (state, payload) => {
    const fileStates = [...state.uploadedFileStates];
    const objIndex = fileStates.findIndex((obj => obj.hashId == payload.hashId));
    const stateObj = { ...fileStates[objIndex] }
    stateObj.loaded = payload.loaded;
    stateObj.total = payload.total;
    fileStates[objIndex] = stateObj;

    return {
      uploadedFileStates: fileStates
    };
  }),
  on(actions.updateUploadProgressStatus, (state, payload) => {
    const fileStates = [...state.uploadedFileStates];
    const objIndex = fileStates.findIndex((obj => obj.hashId == payload.hashId));
    const stateObj = { ...fileStates[objIndex] }
    stateObj.progress = payload.progress;

    fileStates[objIndex] = stateObj;

    return {
      uploadedFileStates: fileStates
    };
  }),
  on(actions.removeS3FileUploadFromStore, (state, payload) => {
    const fileStates = [...state.uploadedFileStates];
    const newFileStates = fileStates.filter(function (entry) { return entry.hashId !== payload.hashId; });
    return {
      uploadedFileStates: newFileStates
    };
  })
);