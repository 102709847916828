import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { intercomBoot, intercomEventPost, intercomUpdate } from '../actions/intercom.actions';
import { IntercomService } from 'src/app/shared/services/intercom.service';


@Injectable()
export class IntercomEffects {

  intercomBoot$ = createEffect(() => this.actions$
    .pipe(
      ofType(intercomBoot),
      tap(async action => {
        this.intercomService.bootIntercom({
          email: action.email,
          email_verified: action.emailVerified,
          name: action.name,
          // Add any other user details you want to pass
          user_id: action.userId,
          openbridge_account_id: action.accountId
        });
      })
    ),
    { dispatch: false }
  );

  intercomUpdate$ = createEffect(() => this.actions$
    .pipe(
      ofType(intercomUpdate),
      tap(async action => {
        this.intercomService.updateIntercom({
          // Add any other user details you want to update
          subscribed_productIds_active: JSON.stringify(action.ActiveSubscribedProductIds),
          subscribed_productIds_inactive: JSON.stringify(action.InActiveSubscribedProductIds),
          subscribed_identities_valid: JSON.stringify(action.validIdentitiesIds),
          subscribed_identities_inValid: JSON.stringify(action.inValidIdentitiesIds),

        });
      })
    ),
    { dispatch: false }
  );

  intercomEventPost$ = createEffect(() => this.actions$
    .pipe(
      ofType(intercomEventPost),
      tap(async action => {

        const payload = { ...action };
        delete payload['type'];

        this.intercomService.triggerIntercomEvent(action.eventName, action.metaData);

      })
    ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private intercomService: IntercomService
  ) { }

}





