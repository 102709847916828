export const responseDataLakeSpm = {
  links: {
    first: 'https://subscriptions.api.dev.openbridge.io/spm?page=1&subscription=163',
    last: 'https://subscriptions.api.dev.openbridge.io/spm?page=1&subscription=163',
    next: '',
    prev: ''
  },
  data: [
    {
      type: 'SubscriptionProductMeta',
      id: '775',
      attributes: {
        data_id: 0,
        data_key: 'azureContainer',
        data_value: 'tests',
        created_at: '2021-12-09T16:38:18.500676',
        modified_at: '2021-12-09T16:38:18.500695',
        product: {
          id: 47,
          active: 1,
          name: 'Azure Data Lake',
          summary: '',
          name_slug: 'azure-data-lake',
          created_at: '2020-08-10T04:00:00',
          modified_at: '2020-08-10T04:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'azure-data-lake',
          allow_as_trial: 1,
          is_storage_product: 1,
          initialize_job: 1,
          premium_product: 0,
          worker_name: null,
          product_group: 18,
          remote_identity_type: 16,
          product_category: 1,
          parent_product: 44
        },
        subscription: {
          id: 163,
          price: 0,
          status: 'active',
          date_start: '2021-12-09T16:38:14',
          date_end: '2021-12-09T16:38:14',
          auto_renew: 1,
          created_at: '2021-12-09T16:38:18.496725',
          modified_at: '2021-12-09T16:38:19.497518',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'my datalake test',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 47,
          product_plan: null,
          remote_identity: null,
          storage_group: 108,
          user: 309
        }
      }
    },
    {
      type: 'SubscriptionProductMeta',
      id: '776',
      attributes: {
        data_id: 0,
        data_key: 'azureConnectionString',
        data_value: 'DefaultEndpointsProtocol=https;AccountName=datalaketest;AccountKey=xyz;EndpointSuffix=xyz',
        created_at: '2021-12-09T16:38:18.505518',
        modified_at: '2021-12-09T16:38:18.505538',
        product: {
          id: 47,
          active: 1,
          name: 'Azure Data Lake',
          summary: '',
          name_slug: 'azure-data-lake',
          created_at: '2020-08-10T04:00:00',
          modified_at: '2020-08-10T04:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'azure-data-lake',
          allow_as_trial: 1,
          is_storage_product: 1,
          initialize_job: 1,
          premium_product: 0,
          worker_name: null,
          product_group: 18,
          remote_identity_type: 16,
          product_category: 1,
          parent_product: 44
        },
        subscription: {
          id: 163,
          price: 0,
          status: 'active',
          date_start: '2021-12-09T16:38:14',
          date_end: '2021-12-09T16:38:14',
          auto_renew: 1,
          created_at: '2021-12-09T16:38:18.496725',
          modified_at: '2021-12-09T16:38:19.497518',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'my datalake test',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 47,
          product_plan: null,
          remote_identity: null,
          storage_group: 108,
          user: 309
        }
      }
    }
  ],
  meta: {
    pagination: {
      page: 1,
      pages: 1,
      count: 2
    }
  }
};