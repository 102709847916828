export const responseAccount = {
  links: {
    first: 'https://account.api.dev.openbridge.io/account?page=1',
    last: 'https://account.api.dev.openbridge.io/account?page=1',
    next: '',
    prev: ''
  },
  data: [
    {
      type: 'Account',
      id: '342',
      attributes: {
        account_status: 1,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        company: '',
        created_at: '2021-10-15T14:25:13.037235',
        modified_at: '2022-10-06T12:21:04.851222',
        free_subscription_limit: 0,
        free_subscriptions: 0,
        is_branded: 0,
        stripe_subscription_id: 'sub_1JkrLPDJfvhvVHhnPxtAgegM',
        in_trial: 1,
        renews_at_period_end: 0,
        period_ends_at: '2021-10-29T19:25:12.990779',
        deactivate_at: '2021-11-08T20:25:12.990779',
        account_type_id: 13,
        country_id: null,
        primary_account_address_id: null,
        primary_account_card_id: null,
        extended_trial: 0,
        organization_id: 'org_h1bKkafTNIchp9xt',
        organization_allowed: true,
        owner: {
          id: 309,
          auth0_user_id: 'auth0|61698f49ff86f6006a397d15'
        }
      }
    }
  ],
  meta: {
    pagination: {
      page: 1,
      pages: 1,
      count: 1
    }
  }
};