import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
// tslint:disable-next-line: max-line-length
import { modalMessageStorageTestAccMapping, modalMessageStorageTestComplete, modalMessageStorageTestInitialize, modalMessageStorageTestPolling, modalMessageStorageTestSubmission, modalTitleStorageTest } from 'src/app/shared/constants/modal-titles-and-messages';
import {
  AccMappingAmazonAthena, AccMappingAzureBlob, AccMappingAzureDataLake, AccMappingBigquery,
  AccMappingDataBricks,
  AccMappingRedshift, AccMappingSnowflake, AccMappingSpectrum, AccMappingTypes
} from 'src/app/shared/models/accmapping.interface';
import { ConfigAwsAthena, ConfigAwsSpectrum, ConfigAzureBlobStorage, ConfigAzureDataLake, ConfigDataBricks, ConfigSnowflake } from 'src/app/shared/models/product-configuration.types';
import { environment } from 'src/environments/environment';
import { modalsClose, modalsOpen, modalsUpdate } from '../store/actions/modals.action';
import { AppState } from '../store/reducers';
import { AccmappingService } from './accmapping.service';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class ApiStoragesService {

  storageTestState$: Subject<{ state: string, payload: any }> = new Subject<{ state: string, payload: any }>();

  constructor(
    private accMapping: AccmappingService,
    private httpClient: HttpClient,
    private store$: Store<AppState>,
    private accountService: AccountService

  ) { }

  async testAthenaDestination(config: ConfigAwsAthena): Promise<any> {

    this.sendInitializationMessage();
    const storageKey = this.accMapping.generateStorageKey(await this.accountService.getAccountId(), true);

    // // Create the account mapping configuration.
    const mappingConfig: AccMappingAmazonAthena = {
      data: {
        attributes: {
          is_temp: true,
          athena_access_key_id: config.awsAccessKey,
          athena_bucket: config.s3Bucket,
          athena_database: config.awsDatabaseName,
          athena_region: config.awsRegion,
          athena_secret_access_key: config.awsSecretKey,
          path: this.accMapping.generateAccmappingPath(storageKey),
          region: 'us-east-1',
          s3_bucket: environment.accMapping.bucketKey,
          storage: 'athena'
        }
      }
    };

    return await this.startTest(mappingConfig);

  }

  async testAzureBlobDestination(config: ConfigAzureBlobStorage): Promise<any> {

    this.sendInitializationMessage();
    const storageKey = this.accMapping.generateStorageKey(await this.accountService.getAccountId(), true);

    const mappingConfig: AccMappingAzureBlob = {
      data: {
        attributes: {
          is_temp: true,
          container: config.storageContainer,
          connection_string: config.connectionString,
          path: this.accMapping.generateAccmappingPath(storageKey),
          region: 'us-east-1',
          s3_bucket: environment.accMapping.bucketKey,
          storage: 'azure_blob',
        }
      }
    };

    return await this.startTest(mappingConfig);

  }

  async testAzureLakeDestination(config: ConfigAzureDataLake): Promise<any> {

    this.sendInitializationMessage();
    const storageKey = this.accMapping.generateStorageKey(await this.accountService.getAccountId(), true);

    const mappingConfig: AccMappingAzureDataLake = {
      data: {
        attributes: {
          is_temp: true,
          container: config.storageContainer,
          connection_string: config.connectionString,
          path: this.accMapping.generateAccmappingPath(storageKey),
          region: 'us-east-1',
          s3_bucket: environment.accMapping.bucketKey,
          storage: 'azure_datalake',
        }
      }
    };

    return await this.startTest(mappingConfig);

  }

  //  Need to figure out what to do with big query.
  async testBigQueryDestination(config: any): Promise<any> {
    this.sendInitializationMessage();

    const storageKey = this.accMapping.generateStorageKey(await this.accountService.getAccountId(), true);

    const mappingConfig: AccMappingBigquery = {
      data: {
        attributes: {
          is_temp: true,
          bigquery_dataset_id: config.datasetId,
          bigquery_project_id: config.projectId,
          bigquery_service_account: config.serviceAccountJson,
          path: this.accMapping.generateAccmappingPath(storageKey),
          region: 'us-east-1',
          s3_bucket: environment.accMapping.bucketKey,
          storage: 'bigquery',
        }
      }
    };

    return await this.startTest(mappingConfig);
  }

  async testRedshiftDestination(config: any): Promise<any> {

    this.sendInitializationMessage();

    const storageKey = this.accMapping.generateStorageKey(await this.accountService.getAccountId(), true);

    // Create the account mapping configuration.
    const mappingConfig: AccMappingRedshift = {
      data: {
        attributes: {
          is_temp: true,
          database: config.dbname,
          host: config.host,
          password: config.password,
          path: this.accMapping.generateAccmappingPath(storageKey),
          port: config.port,
          region: 'us-east-1',
          storage: 'redshift',
          s3_bucket: environment.accMapping.bucketKey,
          user: config.username
        }
      }
    };

    return await this.startTest(mappingConfig);
  }

  async testSnowflakeDestination(config: ConfigSnowflake): Promise<any> {
    this.sendInitializationMessage();
    const storageKey = this.accMapping.generateStorageKey(await this.accountService.getAccountId(), true);

    const mappingConfig: AccMappingSnowflake = {
      data: {
        attributes: {
          is_temp: true,
          path: this.accMapping.generateAccmappingPath(storageKey),
          region: 'us-east-1',
          s3_bucket: environment.accMapping.bucketKey,
          snowflake_account: config.account,
          snowflake_database: config.databaseName,
          snowflake_schema: config.schema,
          snowflake_password: config.password,
          snowflake_user: config.username,
          snowflake_warehouse: config.warehouse,
          snowflake_use_clustering: config.useClustering,
          storage: 'snowflake',
          templates: 'https://s3.amazonaws.com/ob_internal/templates/default.json'
        }
      }
    };

    return await this.startTest(mappingConfig);
  }

  async testSpectrumDestination(config: ConfigAwsSpectrum): Promise<any> {
    this.sendInitializationMessage();

    const storageKey = this.accMapping.generateStorageKey(await this.accountService.getAccountId(), true);

    const mappingConfig: AccMappingSpectrum = {
      data: {
        attributes: {
          is_temp: true,
          database: config.dbname,
          host: config.host,
          user: config.username,
          password: config.password,
          path: this.accMapping.generateAccmappingPath(storageKey),
          port: +config.port,
          spectrum_bucket: config.awsBucket,
          spectrum_database: config.spectrumDb,
          spectrum_iam_role: config.awsIamRoleArn,
          spectrum_region: config.awsRegion,
          spectrum_schema: config.spectrumSchema,
          region: 'us-east-1',
          s3_bucket: environment.accMapping.bucketKey,
          storage: 'spectrum',
        }
      }
    };

    return await this.startTest(mappingConfig);
  }

  async testDataBricksDestination(config: ConfigDataBricks): Promise<any> {

    this.sendInitializationMessage();
    const storageKey = this.accMapping.generateStorageKey(await this.accountService.getAccountId(), true);

    const mappingConfig: AccMappingDataBricks = {
      data: {
        attributes: {
          path: this.accMapping.generateAccmappingPath(storageKey),
          region: 'us-east-1',
          s3_bucket: environment.accMapping.bucketKey,
          storage: 'databricks_external',
          is_temp: true,
          databricks_server_hostname: config.dataBricksServerHostname,
          databricks_http_path: config.dataBricksHttpPath,
          databricks_access_token: config.dataBricksAccessToken,
          databricks_schema: config.dataBricksSchema,
          databricks_catalog: config.dataBricksCatalog,
          databricks_storage_format: config.storageFormat,
          databricks_credentials_name: config.credentialsName,
          databricks_bucket_uri: config.bucketUri,
          databricks_path_prefix: config.pathPrefix || '',
          databricks_adls_connection_string: ((config.connectionString === '') ? null : config.connectionString),
          databricks_adls_container: config.containerName,
          databricks_use_clustering: config.useClustering,
          databricks_use_partitioning: config.usePartitioning
        }
      }
    };
    return await this.startTest(mappingConfig);
  }

  private sendInitializationMessage(): void {
    this.store$.dispatch(modalsOpen({
      modalType: 'progress',
      title: modalTitleStorageTest,
      message: modalMessageStorageTestInitialize,
      progress: 0
    }));
  }

  private async startTest(mappingConfig: AccMappingTypes): Promise<any> {
    const delay = ms => new Promise(res => setTimeout(res, ms));

    try {

      this.store$.dispatch(modalsUpdate({
        modalType: 'progress',
        title: modalTitleStorageTest,
        message: modalMessageStorageTestAccMapping,
        progress: 15
      }));

      // this.storageTestState$.next({ state: 'storage-test-store-map', payload: null });

      const accMappingCreateResponse = await this.accMapping.createAccountMapping(mappingConfig, true);

      const accMappingDirPath = accMappingCreateResponse.path;

      const storageKey = this.getStorageKeyFromPath(accMappingDirPath);

      if (environment.enableMock) {
        await delay(2000);
      }

      this.store$.dispatch(modalsUpdate({
        modalType: 'progress',
        title: modalTitleStorageTest,
        message: modalMessageStorageTestSubmission,
        progress: 55
      }));

      // this.storageTestState$.next({ state: 'storage-test-submitted', payload: null });

      const storageTestId = this.submitPathForTest(accMappingDirPath, mappingConfig.data.attributes.storage, storageKey);

      this.store$.dispatch(modalsUpdate({
        modalType: 'progress',
        title: modalTitleStorageTest,
        message: modalMessageStorageTestPolling,
        progress: 75
      }));


      // this.storageTestState$.next({ state: 'storage-test-start-poll', payload: null });

      if (environment.enableMock) {
        await delay(2000);
      }

      const validationResponse = await this.pollValidationResponse((await storageTestId));
      this.store$.dispatch(modalsUpdate({
        modalType: 'progress',
        title: modalTitleStorageTest,
        message: modalMessageStorageTestComplete,
        progress: 100
      }));

      await delay(1000);

      this.store$.dispatch(modalsClose());

      // this.storageTestState$.next({ state: 'storage-test-complete', payload: validationResponse });

      return validationResponse;

    }
    catch (error) {
      this.store$.dispatch(modalsUpdate({
        modalType: 'progress',
        title: modalTitleStorageTest,
        message: modalMessageStorageTestComplete,
        progress: 100
      }));

      await delay(1000);

      this.store$.dispatch(modalsClose());

      // this.storageTestState$.next({ state: 'storage-test-complete', payload: error });
    }
  }

  private async submitPathForTest(accMappingDirPath: string, storageType: string, storageKey: string): Promise<any> {

    const payload = {
      data: {
        attributes: {
          storage_type: storageType,
          path: accMappingDirPath + '/storage_validation',
          account_id: storageKey
        }
      }
    };

    const storagesPostUri = environment.openbridgeApiUris.service + '/service/storages/' + environment.openbridgeApiUris.partialIdentifier + '/v1/storages';

    const postResponse = await this.httpClient.post(storagesPostUri, payload, { observe: 'response' }).toPromise();

    return postResponse.body['data']['id'];

  }

  private async pollValidationResponse(storageTestId: string): Promise<any> {

    const delay = ms => new Promise(res => setTimeout(res, ms));
    let pollCount = 0;
    let postResponse = null;
    let validationResponse = false;
    let status = '';

    try {
      const storagesPostUri = environment.openbridgeApiUris.service + '/service/storages/' +
        environment.openbridgeApiUris.partialIdentifier + '/v1/storages/' + storageTestId;

      while (!validationResponse) {
        postResponse = await this.httpClient.get(storagesPostUri, { observe: 'response' }).toPromise();

        // Simplify the status.
        status = postResponse.body.data.attributes.status;

        if (status === 'ACTIVE' || status === 'FAILED') {
          validationResponse = true;
        }

        if (pollCount++ === environment.storageValidation.numberOfPollsBeforeFailure) {
          throw Error('Unable to resolve test with test id: ' + storageTestId);
        }

        // If we didn't pass wait 2 seconds.
        if (!validationResponse) {
          await delay(environment.storageValidation.timeBetweenPollsInMilliseconds);
        }
      }
    }
    catch {
      postResponse = { message: 'Polling timed out before testing could resolve.' };
    }

    return postResponse;
  }

  private getPathFromURI(path: string): any {
    const uriPathParts = path.split('/');
    return decodeURIComponent(uriPathParts[(uriPathParts.length - 1)]);
  }

  private getStorageKeyFromPath(path: string): any {
    const uriPathParts = path.split('/');
    return uriPathParts[(uriPathParts.length - 1)];
  }

}
