export const responseSubscriptions = {
  links: {
    first: 'https://subscriptions.api.dev.openbridge.io/sub?page=1&page_size=100&sort=-created_at',
    last: 'https://subscriptions.api.dev.openbridge.io/sub?page=2&page_size=100&sort=-created_at',
    next: 'https://subscriptions.api.dev.openbridge.io/sub?page=2&page_size=100&sort=-created_at',
    prev: ''
  },
  data: [
    {
      type: 'Subscription',
      id: '124',
      attributes: {
        price: 0,
        status: 'cancelled',
        date_start: '2021-10-23T03:17:27',
        date_end: '2021-10-23T03:17:27',
        auto_renew: 1,
        created_at: '2021-10-22T22:17:30.554002',
        modified_at: '2022-01-04T08:47:53.435597',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'snowflake',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 52,
        product_plan_id: null,
        remote_identity_id: null,
        storage_group_id: 88,
        user_id: 309,
        history_requested: 0,
        unique_hash: null
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '52'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: null
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '88'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '629',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-05-03T10:53:26',
        date_end: '2022-05-03T10:53:26',
        auto_renew: 1,
        created_at: '2022-05-03T10:53:30.410510',
        modified_at: '2022-05-03T10:53:30.410524',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'amazon-adv-test',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 40,
        product_plan_id: null,
        remote_identity_id: 22,
        storage_group_id: 110,
        user_id: 309,
        history_requested: 0,
        unique_hash: null
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '40'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '22'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '110'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '727',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-05-06T11:45:46',
        date_end: '2022-05-06T11:45:46',
        auto_renew: 1,
        created_at: '2022-05-06T11:45:49.467569',
        modified_at: '2022-05-06T11:45:49.467584',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'amazon-attr-test',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 50,
        product_plan_id: null,
        remote_identity_id: 112,
        storage_group_id: 110,
        user_id: 309,
        history_requested: 0,
        unique_hash: null
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '50'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '112'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '110'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '760',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-05-09T05:01:57',
        date_end: '2022-05-09T05:01:57',
        auto_renew: 1,
        created_at: '2022-05-09T05:02:01.220837',
        modified_at: '2022-05-09T05:02:01.220852',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'google-ads-test',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 63,
        product_plan_id: null,
        remote_identity_id: 178,
        storage_group_id: 110,
        user_id: 309,
        history_requested: 0,
        unique_hash: null
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '63'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '178'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '110'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '761',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-05-09T05:27:33',
        date_end: '2022-05-09T05:27:33',
        auto_renew: 1,
        created_at: '2022-05-09T05:27:37.600805',
        modified_at: '2022-05-09T05:27:37.600821',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'amazon-dsp-test',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 54,
        product_plan_id: null,
        remote_identity_id: 22,
        storage_group_id: 110,
        user_id: 309,
        history_requested: 0,
        unique_hash: null
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '54'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '22'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '110'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '762',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-05-09T06:12:24',
        date_end: '2022-05-09T06:12:24',
        auto_renew: 1,
        created_at: '2022-05-09T06:12:27.398465',
        modified_at: '2022-05-09T06:12:27.398479',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'amazon-fees-test',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 62,
        product_plan_id: null,
        remote_identity_id: 135,
        storage_group_id: 110,
        user_id: 309,
        history_requested: 0,
        unique_hash: null
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '62'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '135'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '110'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '763',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-05-09T06:26:40',
        date_end: '2022-05-09T06:26:40',
        auto_renew: 1,
        created_at: '2022-05-09T06:26:44.453419',
        modified_at: '2022-05-09T06:26:44.453432',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'amazon-finance-realtime',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 55,
        product_plan_id: null,
        remote_identity_id: 135,
        storage_group_id: 110,
        user_id: 309,
        history_requested: 0,
        unique_hash: null
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '55'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '135'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '110'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '793',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-05-09T10:09:26',
        date_end: '2022-05-09T10:09:26',
        auto_renew: 1,
        created_at: '2022-05-09T10:09:30.424261',
        modified_at: '2022-05-09T10:09:30.424277',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'amazon-fulfillment',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 58,
        product_plan_id: null,
        remote_identity_id: 135,
        storage_group_id: 110,
        user_id: 309,
        history_requested: 0,
        unique_hash: null
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '58'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '135'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '110'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '794',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-05-09T10:49:24',
        date_end: '2022-05-09T10:49:24',
        auto_renew: 1,
        created_at: '2022-05-09T10:49:27.750029',
        modified_at: '2022-05-09T10:49:27.750043',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'amazon-inbound-fulfillment-test',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 56,
        product_plan_id: null,
        remote_identity_id: 135,
        storage_group_id: 110,
        user_id: 309,
        history_requested: 0,
        unique_hash: null
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '56'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '135'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '110'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '795',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-05-09T11:00:11',
        date_end: '2022-05-09T11:00:11',
        auto_renew: 1,
        created_at: '2022-05-09T11:00:15.513635',
        modified_at: '2022-05-09T11:00:15.513649',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'instagram-insights-test',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 34,
        product_plan_id: null,
        remote_identity_id: 202,
        storage_group_id: 110,
        user_id: 309,
        history_requested: 0,
        unique_hash: null
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '34'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '202'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '110'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '859',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-05-11T10:33:12',
        date_end: '2022-05-11T10:33:12',
        auto_renew: 1,
        created_at: '2022-05-11T10:33:15.745693',
        modified_at: '2022-05-11T10:33:15.745706',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'amazon-inventory-test',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 60,
        product_plan_id: null,
        remote_identity_id: 135,
        storage_group_id: 110,
        user_id: 309,
        history_requested: 0,
        unique_hash: null
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '60'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '135'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '110'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '892',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-05-11T12:43:09',
        date_end: '2022-05-11T12:43:09',
        auto_renew: 1,
        created_at: '2022-05-11T12:43:13.150386',
        modified_at: '2022-05-11T12:43:13.150402',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'amazon-inventory-realtime-test',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 59,
        product_plan_id: null,
        remote_identity_id: 135,
        storage_group_id: 110,
        user_id: 309,
        history_requested: 0,
        unique_hash: null
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '59'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '135'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '110'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '893',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-05-11T12:58:32',
        date_end: '2022-05-11T12:58:32',
        auto_renew: 1,
        created_at: '2022-05-11T12:58:36.645134',
        modified_at: '2022-05-11T12:58:36.645148',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'facebook-marketing-test',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 11,
        product_plan_id: null,
        remote_identity_id: 202,
        storage_group_id: 110,
        user_id: 309,
        history_requested: 0,
        unique_hash: null
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '11'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '202'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '110'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '958',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-05-12T08:00:14',
        date_end: '2022-05-12T08:00:14',
        auto_renew: 1,
        created_at: '2022-05-12T08:00:18.741680',
        modified_at: '2022-05-12T08:00:18.741694',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'amazon-orders-test',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 53,
        product_plan_id: null,
        remote_identity_id: 135,
        storage_group_id: 110,
        user_id: 309,
        history_requested: 0,
        unique_hash: null
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '53'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '135'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '110'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '991',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-05-12T10:27:16',
        date_end: '2022-05-12T10:27:16',
        auto_renew: 1,
        created_at: '2022-05-12T10:27:19.521720',
        modified_at: '2022-05-12T10:27:19.521733',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'facebook-page-insights-test',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 2,
        product_plan_id: null,
        remote_identity_id: 202,
        storage_group_id: 110,
        user_id: 309,
        history_requested: 0,
        unique_hash: null
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '2'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '202'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '110'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '992',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-05-12T10:37:20',
        date_end: '2022-05-12T10:37:20',
        auto_renew: 1,
        created_at: '2022-05-12T10:37:24.037379',
        modified_at: '2022-05-12T10:37:24.037394',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'amazon-sales-test',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 61,
        product_plan_id: null,
        remote_identity_id: 135,
        storage_group_id: 110,
        user_id: 309,
        history_requested: 0,
        unique_hash: null
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '61'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '135'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '110'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '993',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-05-12T10:50:24',
        date_end: '2022-05-12T10:50:24',
        auto_renew: 1,
        created_at: '2022-05-12T10:50:28.851742',
        modified_at: '2022-05-12T10:50:28.851771',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'amazon-settlement-reports-test',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 57,
        product_plan_id: null,
        remote_identity_id: 135,
        storage_group_id: 110,
        user_id: 309,
        history_requested: 0,
        unique_hash: null
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '57'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '135'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '110'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '994',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-05-12T11:01:08',
        date_end: '2022-05-12T11:01:08',
        auto_renew: 1,
        created_at: '2022-05-12T11:01:12.632461',
        modified_at: '2022-05-12T11:01:12.632479',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'instagram-stories-test',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 38,
        product_plan_id: null,
        remote_identity_id: 202,
        storage_group_id: 110,
        user_id: 309,
        history_requested: 0,
        unique_hash: null
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '38'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '202'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '110'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '276',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-01-24T13:33:28',
        date_end: '2022-01-24T13:33:28',
        auto_renew: 1,
        created_at: '2022-01-24T13:33:31.316183',
        modified_at: '2022-01-24T13:33:31.981685',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'snowflake-development',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 52,
        product_plan_id: null,
        remote_identity_id: null,
        storage_group_id: 200,
        user_id: 309,
        history_requested: 0,
        unique_hash: null
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '52'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: null
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '200'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '277',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-01-24T13:33:28',
        date_end: '2022-01-24T13:33:28',
        auto_renew: 1,
        created_at: '2022-01-24T13:33:31.316183',
        modified_at: '2022-01-24T13:33:31.981685',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'databricks-development',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 68,
        product_plan_id: null,
        remote_identity_id: null,
        storage_group_id: 171,
        user_id: 309,
        history_requested: 0,
        unique_hash: null
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '68'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: null
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '171'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '138',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2021-11-01T08:07:17',
        date_end: '2021-11-01T08:07:17',
        auto_renew: 1,
        created_at: '2021-11-01T08:07:20.518985',
        modified_at: '2021-11-10T08:02:46.433260',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'bigquery',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 37,
        product_plan_id: null,
        remote_identity_id: 77,
        storage_group_id: 102,
        user_id: 309,
        history_requested: 0,
        unique_hash: null
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '37'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '77'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '102'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '165',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2021-12-10T07:24:24',
        date_end: '2021-12-10T07:24:24',
        auto_renew: 1,
        created_at: '2021-12-10T07:24:27.132305',
        modified_at: '2021-12-10T08:20:55.649222',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'aws-athena-test',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 31,
        product_plan_id: null,
        remote_identity_id: null,
        storage_group_id: 110,
        user_id: 309,
        history_requested: 0,
        unique_hash: null
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '31'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: null
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '110'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '162',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2021-12-09T16:13:22',
        date_end: '2021-12-09T16:13:22',
        auto_renew: 1,
        created_at: '2021-12-09T16:13:29.603127',
        modified_at: '2021-12-09T16:13:30.550700',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'azure blob storage',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 46,
        product_plan_id: null,
        remote_identity_id: null,
        storage_group_id: 107,
        user_id: 309,
        history_requested: 0,
        unique_hash: null
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '46'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: null
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '107'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '163',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2021-12-09T16:38:14',
        date_end: '2021-12-09T16:38:14',
        auto_renew: 1,
        created_at: '2021-12-09T16:38:18.496725',
        modified_at: '2021-12-09T16:38:19.497518',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'my datalake test',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 47,
        product_plan_id: null,
        remote_identity_id: null,
        storage_group_id: 108,
        user_id: 309,
        history_requested: 0,
        unique_hash: null
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '47'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: null
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '108'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    }
  ],
  included: [
    {
      type: 'StorageGroup',
      id: '102',
      attributes: {
        product_id: 37,
        name: 'bigquery',
        key_name: 'b08046b8463056b7-bigquery'
      }
    },
    {
      type: 'StorageGroup',
      id: '105',
      attributes: {
        product_id: 31,
        name: 'my-blizzard',
        key_name: 'adb53e2033d2c9fc-my-blizzard'
      }
    },
    {
      type: 'StorageGroup',
      id: '106',
      attributes: {
        product_id: 37,
        name: 'bigquery razor storage',
        key_name: 'bc9b374472b1e7a4-bigquery-razor-storage'
      }
    },
    {
      type: 'StorageGroup',
      id: '107',
      attributes: {
        product_id: 46,
        name: 'azure blob storage',
        key_name: '985317bf25546027-azure-blob-storage'
      }
    },
    {
      type: 'StorageGroup',
      id: '108',
      attributes: {
        product_id: 47,
        name: 'my datalake test',
        key_name: 'afad95d7a191146f-my-datalake-test'
      }
    },
    {
      type: 'StorageGroup',
      id: '110',
      attributes: {
        product_id: 31,
        name: 'aws-athena-test',
        key_name: '85e4f28214fd8834-aws-athena-test'
      }
    },
    {
      type: 'StorageGroup',
      id: '111',
      attributes: {
        product_id: 31,
        name: 'athena-test-destination',
        key_name: '8eae8c21fb87de50-athena-test-destination'
      }
    },
    {
      type: 'StorageGroup',
      id: '199',
      attributes: {
        product_id: 46,
        name: 'kishan-blob-storage-e2e-test',
        key_name: 'a3bde46f0ca9e0e0-kishan-blob-storage-e2e-test'
      }
    },
    {
      type: 'StorageGroup',
      id: '200',
      attributes: {
        product_id: 52,
        name: 'snowflake-development',
        key_name: 'bce6bd86207a5549-snowflake-development'
      }
    },
    {
      type: 'StorageGroup',
      id: '201',
      attributes: {
        product_id: 46,
        name: 'kishan-e2e-destination-test',
        key_name: '95e60f84a4af4bf4-kishan-e2e-destination-test'
      }
    },
    {
      type: 'StorageGroup',
      id: '202',
      attributes: {
        product_id: 46,
        name: 'kishan-e2e-destination ',
        key_name: '925f945d719d1088-kishan-e2e-destination-'
      }
    },
    {
      type: 'StorageGroup',
      id: '203',
      attributes: {
        product_id: 47,
        name: 'kishan-test',
        key_name: 'a8bab8f7d20ac672-kishan-test'
      }
    },
    {
      type: 'StorageGroup',
      id: '204',
      attributes: {
        product_id: 31,
        name: 'kishan-athena-test',
        key_name: 'b45b1b4756b41827-kishan-athena-test'
      }
    },
    {
      type: 'StorageGroup',
      id: '232',
      attributes: {
        product_id: 31,
        name: 'aws athena e2e test',
        key_name: 'b9f8badf84172323-aws-athena-e2e-test'
      }
    },
    {
      type: 'StorageGroup',
      id: '240',
      attributes: {
        product_id: 46,
        name: 'blob-mock-1',
        key_name: 'bf610ddae125451e-blob-mock-1'
      }
    },
    {
      type: 'StorageGroup',
      id: '244',
      attributes: {
        product_id: 31,
        name: 'reeeeee take 3',
        key_name: 'a3e4e6c56a682319-reeeeee-take-3'
      }
    },
    {
      type: 'StorageGroup',
      id: '245',
      attributes: {
        product_id: 37,
        name: 'asdfasdfads',
        key_name: '984e7c5f47fea2a0-asdfasdfads'
      }
    },
    {
      type: 'StorageGroup',
      id: '266',
      attributes: {
        product_id: 37,
        name: 'my-bigquery-data',
        key_name: '84e65948c5fe52f6-my-bigquery-data'
      }
    },
    {
      type: 'StorageGroup',
      id: '88',
      attributes: {
        product_id: 52,
        name: 'snowflake',
        key_name: 'snowflake-342'
      }
    }
  ],
  meta: {
    pagination: {
      page: 1,
      pages: 1,
      count: 23
    }
  }
};