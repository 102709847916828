import { environment } from '../environments/environment';

export const requestURLs = [
    {
        name: 'deltaURL',
        value: environment.openbridgeApiUris.account + '/deltas/:id'
    },
    {
        name: 'tokenURL',
        value: 'https://' + environment.auth0.domain + '/oauth/token'
    },
    {
        name: 'jwtURL',
        value: environment.openbridgeApiUris.authentication + '/auth/jwt'
    },
    {
        name: 'accountURL',
        value: environment.openbridgeApiUris.account
    },
    {
        name: 'userURL',
        value: environment.openbridgeApiUris.user
    },
    {
        name: 'baseIdentitiesURL',
        value: environment.openbridgeApiUris.identities + '/ri'
    },
    {
        name: 'sharedIdentitiesURL',
        value: environment.openbridgeApiUris.identities + '/sri',
    },
    {
        name: 'serviceApiEndpoint',
        value: environment.openbridgeApiUris.service + '/service/',
    },
    {
        name: 'encryptURI',
        value: environment.openbridgeApiUris.service + '/service/encrypt/encrypt',
    },
    {
        name: 'accMappingEncryptionURI',
        value: environment.openbridgeApiUris.service + '/service/encrypt/accmapping_enc',
    },
    {
        name: 'accMappingURI',
        value: environment.openbridgeApiUris.service + '/service/accmapping/' + environment.openbridgeApiUris.partialIdentifier + '/v2/accmapping/:string',
    },
    {
        name: 'pipelineURL',
        value: environment.openbridgeApiUris.pipelineCount + '/count',
    },
    {
        name: 'storagesURI',
        value: environment.openbridgeApiUris.service + '/service/storages/' + environment.openbridgeApiUris.partialIdentifier + '/v1/storages',
    },
    {
        name: 'stripeCustomer',
        value: environment.openbridgeApiUris.service + '/service/stripe/customer/:id',
    },
    {
        name: 'subscriptionURI',
        value: environment.openbridgeApiUris.subscription + '/sub',
    },
    {
        name: 'bigQueryAccountValidationURI',
        value: environment.openbridgeApiUris.service + '/service/bq/validate-acct/:encryptionString',
    },
    {
        name: 'remoteIdentityMetaEndpoint',
        value: environment.openbridgeApiUris.identities + '/rim',
    },
    {
        name: 'subscriptionSpm',
        value: environment.openbridgeApiUris.subscription + '/spm',
    },
    {
        name: 'userUpdateURL',
        value: environment.openbridgeApiUris.service + '/service/auth0/user/:id',
    },
    {
        name: 'passwordChangeURL',
        value: 'https://' + environment.auth0.domain + '/dbconnections/change_password'
    },
    {
        name: 'historyApiURL',
        value: environment.openbridgeApiUris.service + '/service/history/dev/v1/history/:id',
    },
    {
        name: 'customerPortalSession',
        value: environment.openbridgeApiUris.service + '/service/stripe/customer-portal-session',
    },
    {
        name: 'stripeCheckoutSessionURI',
        value: environment.openbridgeApiUris.service + '/service/stripe/checkout-session',
    },
    {
        name: 'ngrxState',
        value: environment.openbridgeApiUris.state,
    },
    {
        name: 'initialOAuthURI',
        value: environment.openbridgeApiUris.oauth + '/initialize',
    },
    {
        name: 'healthCheckURL',
        value: environment.openbridgeApiUris.service + '/service/healthchecks/dev/healthchecks/account/:id',
    }
];