import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/history.actions';

interface HistoryParams {
  productId: number;
  max_request_time: number;
}

// Change "any" to whatever the identity model will be in the future.
export interface HistoryState {
  productHistoryParams: HistoryParams[]
}

//  Create the initial state for account identities.
const initialHistoryState: HistoryState = {
  productHistoryParams: []
};

export const reducer = createReducer(
  initialHistoryState,
  on(actions.historyParamsUpdate, (state, payload) => {
    const stateData = {
      ...state,
      productHistoryParams: payload.params
    };
    return stateData;
  })

);