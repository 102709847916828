export const responseSnowflakeStorage = {
  data: {
    attributes: {
      accmapping_id: 1008,
      account_id: 'test-1641468440915-000001',
      created_at: '2022-01-06 11:27:32.531832',
      error_code: null,
      message: '',
      modified_at: '2022-01-06 11:27:32.818646',
      path: '/ebs/ftpd/test-1641468440915-000001/storage_validation',
      status: 'ACTIVE',
      storage_type: 'snowflake'
    },
    id: 1014
  }
};