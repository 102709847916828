export const responseSnowflakeSpm = {
  links: {
    first: 'https://subscriptions.api.dev.openbridge.io/spm?page=1&subscription=276',
    last: 'https://subscriptions.api.dev.openbridge.io/spm?page=1&subscription=276',
    next: '',
    prev: ''
  },
  data: [
    {
      type: 'SubscriptionProductMeta',
      id: '1037',
      attributes: {
        data_id: 0,
        data_key: 'snowflakeAccount',
        data_value: 'yu04945.us-east-2.aws',
        created_at: '2022-01-24T13:33:31.323885',
        modified_at: '2022-05-05T05:53:10.334922',
        product: {
          id: 52,
          active: 1,
          name: 'Snowflake',
          summary: '',
          name_slug: 'snowflake',
          created_at: '2021-03-12T05:00:00',
          modified_at: '2021-03-12T05:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'snowflake',
          allow_as_trial: 1,
          is_storage_product: 1,
          initialize_job: 0,
          premium_product: 0,
          worker_name: null,
          product_group: 4,
          remote_identity_type: null,
          product_category: 6,
          parent_product: null
        },
        subscription: {
          id: 276,
          price: 0,
          status: 'active',
          date_start: '2022-05-05T05:53:06',
          date_end: '2022-05-05T05:53:06',
          auto_renew: 1,
          created_at: '2022-01-24T13:33:31.316183',
          modified_at: '2022-05-05T05:53:10.326500',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'snowflake-development',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 52,
          product_plan: null,
          remote_identity: null,
          storage_group: 200,
          user: 309
        }
      }
    },
    {
      type: 'SubscriptionProductMeta',
      id: '1038',
      attributes: {
        data_id: 0,
        data_key: 'snowflakeDatabase',
        data_value: 'OPENBRIDGE_DATABASE',
        created_at: '2022-01-24T13:33:31.330632',
        modified_at: '2022-05-05T05:53:10.343717',
        product: {
          id: 52,
          active: 1,
          name: 'Snowflake',
          summary: '',
          name_slug: 'snowflake',
          created_at: '2021-03-12T05:00:00',
          modified_at: '2021-03-12T05:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'snowflake',
          allow_as_trial: 1,
          is_storage_product: 1,
          initialize_job: 0,
          premium_product: 0,
          worker_name: null,
          product_group: 4,
          remote_identity_type: null,
          product_category: 6,
          parent_product: null
        },
        subscription: {
          id: 276,
          price: 0,
          status: 'active',
          date_start: '2022-05-05T05:53:06',
          date_end: '2022-05-05T05:53:06',
          auto_renew: 1,
          created_at: '2022-01-24T13:33:31.316183',
          modified_at: '2022-05-05T05:53:10.326500',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'snowflake-development',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 52,
          product_plan: null,
          remote_identity: null,
          storage_group: 200,
          user: 309
        }
      }
    },
    {
      type: 'SubscriptionProductMeta',
      id: '1039',
      attributes: {
        data_id: 0,
        data_key: 'snowflakeWarehouse',
        data_value: 'OPENBRIDGE_WAREHOUSE',
        created_at: '2022-01-24T13:33:31.336874',
        modified_at: '2022-05-05T05:53:10.351050',
        product: {
          id: 52,
          active: 1,
          name: 'Snowflake',
          summary: '',
          name_slug: 'snowflake',
          created_at: '2021-03-12T05:00:00',
          modified_at: '2021-03-12T05:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'snowflake',
          allow_as_trial: 1,
          is_storage_product: 1,
          initialize_job: 0,
          premium_product: 0,
          worker_name: null,
          product_group: 4,
          remote_identity_type: null,
          product_category: 6,
          parent_product: null
        },
        subscription: {
          id: 276,
          price: 0,
          status: 'active',
          date_start: '2022-05-05T05:53:06',
          date_end: '2022-05-05T05:53:06',
          auto_renew: 1,
          created_at: '2022-01-24T13:33:31.316183',
          modified_at: '2022-05-05T05:53:10.326500',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'snowflake-development',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 52,
          product_plan: null,
          remote_identity: null,
          storage_group: 200,
          user: 309
        }
      }
    },
    {
      type: 'SubscriptionProductMeta',
      id: '1040',
      attributes: {
        data_id: 0,
        data_key: 'snowflakeSchema',
        data_value: 'OPENBRIDGE_SCHEMA',
        created_at: '2022-01-24T13:33:31.342774',
        modified_at: '2022-05-05T05:53:10.359963',
        product: {
          id: 52,
          active: 1,
          name: 'Snowflake',
          summary: '',
          name_slug: 'snowflake',
          created_at: '2021-03-12T05:00:00',
          modified_at: '2021-03-12T05:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'snowflake',
          allow_as_trial: 1,
          is_storage_product: 1,
          initialize_job: 0,
          premium_product: 0,
          worker_name: null,
          product_group: 4,
          remote_identity_type: null,
          product_category: 6,
          parent_product: null
        },
        subscription: {
          id: 276,
          price: 0,
          status: 'active',
          date_start: '2022-05-05T05:53:06',
          date_end: '2022-05-05T05:53:06',
          auto_renew: 1,
          created_at: '2022-01-24T13:33:31.316183',
          modified_at: '2022-05-05T05:53:10.326500',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'snowflake-development',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 52,
          product_plan: null,
          remote_identity: null,
          storage_group: 200,
          user: 309
        }
      }
    },
    {
      type: 'SubscriptionProductMeta',
      id: '1041',
      attributes: {
        data_id: 0,
        data_key: 'snowflakeUser',
        data_value: 'OPENBRIDGE_USER',
        created_at: '2022-01-24T13:33:31.348988',
        modified_at: '2022-05-05T05:53:10.367475',
        product: {
          id: 52,
          active: 1,
          name: 'Snowflake',
          summary: '',
          name_slug: 'snowflake',
          created_at: '2021-03-12T05:00:00',
          modified_at: '2021-03-12T05:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'snowflake',
          allow_as_trial: 1,
          is_storage_product: 1,
          initialize_job: 0,
          premium_product: 0,
          worker_name: null,
          product_group: 4,
          remote_identity_type: null,
          product_category: 6,
          parent_product: null
        },
        subscription: {
          id: 276,
          price: 0,
          status: 'active',
          date_start: '2022-05-05T05:53:06',
          date_end: '2022-05-05T05:53:06',
          auto_renew: 1,
          created_at: '2022-01-24T13:33:31.316183',
          modified_at: '2022-05-05T05:53:10.326500',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'snowflake-development',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 52,
          product_plan: null,
          remote_identity: null,
          storage_group: 200,
          user: 309
        }
      }
    },
    {
      type: 'SubscriptionProductMeta',
      id: '1042',
      attributes: {
        data_id: 0,
        data_key: 'snowflakePassword',
        data_value: 'snowflakePswd',
        created_at: '2022-01-24T13:33:31.355512',
        modified_at: '2022-05-05T05:53:10.374971',
        product: {
          id: 52,
          active: 1,
          name: 'Snowflake',
          summary: '',
          name_slug: 'snowflake',
          created_at: '2021-03-12T05:00:00',
          modified_at: '2021-03-12T05:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'snowflake',
          allow_as_trial: 1,
          is_storage_product: 1,
          initialize_job: 0,
          premium_product: 0,
          worker_name: null,
          product_group: 4,
          remote_identity_type: null,
          product_category: 6,
          parent_product: null
        },
        subscription: {
          id: 276,
          price: 0,
          status: 'active',
          date_start: '2022-05-05T05:53:06',
          date_end: '2022-05-05T05:53:06',
          auto_renew: 1,
          created_at: '2022-01-24T13:33:31.316183',
          modified_at: '2022-05-05T05:53:10.326500',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'snowflake-development',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 52,
          product_plan: null,
          remote_identity: null,
          storage_group: 200,
          user: 309
        }
      }
    }
  ],
  meta: {
    pagination: {
      page: 1,
      pages: 1,
      count: 6
    }
  }
};