import { createAction, props } from '@ngrx/store';

interface ModalCreate {
  modalType: string;
  title: string;
  message: string;
  progress: number;
}

interface ModalUpdate {
  modalType: string;
  title: string;
  message: string;
  progress: number;
}

export const modalsOpen = createAction('[Modals] Open',
  props<ModalCreate>()
);

export const modalsUpdate = createAction('[Modals] Update',
  props<ModalUpdate>()
);

export const modalsClose = createAction('[Modals] Close');


