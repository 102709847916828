export const responseAthenaAccountMappingEncryption = {
  data: {
    attributes: {
      is_temp: true,
      athena_access_key_id: {
        encrypt: 'kms',
        value: 'AQICAHjIV9pasxFCwcl96XQ9T7SI448IVp1d2JwsMZkDhJARcwGvlU2FprNPMXD4yvtPy4l/AAAAcjBwBgkqhkiG9w0BBwagYzBhAgEAMFwGCSqGSIb3DQEHATAeBglghkgBZQMEAS4wEQQMxvZ3CGdHxZfYWtHMAgEQgC+dhvnGEBf5qHyUq/Bfl4e8j5OdYfPv8qyX25LCLEyscuVHN0MbJ0G55SiJKmjO3g=='
      },
      athena_bucket: 'netimp-openrbridge-athena',
      athena_database: '21',
      athena_region: 'us-east-1',
      athena_secret_access_key: {
        encrypt: 'kms',
        value: 'AQICAHjIV9pasxFCwcl96XQ9T7SI448IVp1d2JwsMZkDhJARcwH9dZA6hvHwePNzvkNOqO29AAAAhzCBhAYJKoZIhvcNAQcGoHcwdQIBADBwBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDHYvlgl6wAM6OVYfaQIBEIBDEaY2DfZib4oGbcvG8z/pRfy2HXSVGqa0fG1elDef1Tny71S7F///KdjR+yuXUbdDeRlfECmlXLM8ENED+++iUpJewA=='
      },
      path: '/ebs/ftpd/test-1639554999685-000001',
      region: 'us-east-1',
      s3_bucket: 'ob-zeroadmin-stage',
      storage: 'athena'
    }
  }
};