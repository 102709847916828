import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '../reducers';
import { Injectable } from '@angular/core';
import { startReportGeneration, initializeReportGengeration } from '../actions/reporting.actions';
import { take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { flashNotificationsClear, flashNotificationsFormValidationError } from '../actions/flash-notifications.actions';
import { PipelineParamsReports } from '../reducers/reporting.reducer';

@Injectable()
export class ReportingEffects {

  startReportGeneration$ = createEffect(() => this.actions$
    .pipe(
      ofType(startReportGeneration),
      tap(async action => {

        // Clear flash notifications
        this.store$.dispatch(flashNotificationsClear());

        const reportType = action.reportType;
        switch(reportType) {
          case 'pipeline-params':

            let subscriptions = [];

            this.store$.select('subscriptions').pipe(take(1)).subscribe(response => {
              subscriptions = response.subscriptions;
            });

            const reportDefinition: PipelineParamsReports = {
              type: 'pipeline-params',
              stage: 'initialized',
              completionPercentage: 0,
              subscriptions: subscriptions,
              reportData: null
            }

            this.store$.dispatch(initializeReportGengeration({definition: reportDefinition}))

            break;
          default:
            this.store$.dispatch(flashNotificationsFormValidationError({ message: 'Invalid report requested' }));
        }

      })
    ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
  ) { }

}