import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/core/store/reducers/index';
import { select, Store } from '@ngrx/store';
import { authenticated } from 'src/app/core/store/selectors/auth.selector';
import { tap } from 'rxjs/operators';

@Injectable()
export class AnonymousGuard  {

constructor(
  protected store$: Store<AppState>,
  private router: Router
) { }

canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
  return this.store$
    .pipe(
      select(authenticated),
      tap(isAuthenticated => {
        if (isAuthenticated) {
          this.router.navigateByUrl('/home');
        }
      })
    );
  }
}