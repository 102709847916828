import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/auth.actions';

// CHange "any" to whatever the user model will be in the future.
export interface AuthState {
  profile: any;
  currentAccount: any;
  token: string;
  jwt: string;
  expiresAt: number;
}

//  Create the initial state for the authenticated user.
const initialAuthState: AuthState = {
  profile: null,
  currentAccount: null,
  token: null,
  jwt: null,
  expiresAt: null
};

export const reducer = createReducer(
  initialAuthState,
  on(actions.authUserLogin, (state, action) => {
    return {
      ...state,
      profile: action.profile,
      jwt: action.token,
      expiresAt: action.expiresAt
    };
  }),
  on(actions.authAccountToggle, (state, action) => {
    return {
      ...state,
      currentAccount: action.currentAccount
    };
  }),
  on(actions.authUpdateToken, (state, action) => {
    return {
      ...state,
      jwt: action.token,
      expiresAt: action.expiresAt
    };
  }),
  on(actions.authUserLogout, (state) => {
    return initialAuthState;
  }),
);
