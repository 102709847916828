export const responseSpMarketplaces = {
  data: [
    {
      id: 'A1AM78C64UM0Y8',
      countryCode: 'MX',
      name: 'Amazon.com.mx',
      defaultCurrencyCode: 'MXN',
      defaultLanguageCode: 'es_MX',
      domainName: 'www.amazon.com.mx'
    },
    {
      id: 'A1MQXOICRS2Z7M',
      countryCode: 'CA',
      name: 'SI CA Prod Marketplace',
      defaultCurrencyCode: 'CAD',
      defaultLanguageCode: 'en_CA',
      domainName: 'siprod.stores.amazon.ca'
    },
    {
      id: 'A2EUQ1WTGCTBG2',
      countryCode: 'CA',
      name: 'Amazon.ca',
      defaultCurrencyCode: 'CAD',
      defaultLanguageCode: 'en_CA',
      domainName: 'www.amazon.ca'
    },
    {
      id: 'A2Q3Y263D00KWC',
      countryCode: 'BR',
      name: 'Amazon.com.br',
      defaultCurrencyCode: 'BRL',
      defaultLanguageCode: 'pt_BR',
      domainName: 'www.amazon.com.br'
    },
    {
      id: 'A2ZV50J4W1RKNI',
      countryCode: 'US',
      name: 'Non-Amazon',
      defaultCurrencyCode: 'USD',
      defaultLanguageCode: 'en_US',
      domainName: 'sim1.stores.amazon.com'
    },
    {
      id: 'A3H6HPSLHAK3XG',
      countryCode: 'MX',
      name: 'Non-Amazon',
      defaultCurrencyCode: 'MXN',
      defaultLanguageCode: 'es_MX',
      domainName: 'sidevo.stores.amazon.mx'
    },
    {
      id: 'AHRY1CZE9ZY4H',
      countryCode: 'US',
      name: 'Amazon.com Invoicing Shadow Marketplace',
      defaultCurrencyCode: 'USD',
      defaultLanguageCode: 'en_US',
      domainName: 'invoicing-shadow-marketplace.amazon.com'
    },
    {
      id: 'ATVPDKIKX0DER',
      countryCode: 'US',
      name: 'Amazon.com',
      defaultCurrencyCode: 'USD',
      defaultLanguageCode: 'en_US',
      domainName: 'www.amazon.com'
    }
  ]
};

// export const responseSpMarketplaces2 = {
//   data: [
//     {
//       id: 'A1AM78C64UM0Y8',
//       countryCode: 'MX',
//       name: 'Amazon.com.mx',
//       defaultCurrencyCode: 'MXN',
//       defaultLanguageCode: 'es_MX',
//       domainName: 'www.amazon.com.mx'
//     },
//     {
//       id: 'A1KAVWRNOS92EQ',
//       countryCode: 'US',
//       name: 'AWSMP',
//       defaultCurrencyCode: 'USD',
//       defaultLanguageCode: 'en_US',
//       domainName: 'awsmp-prod.amazon.com'
//     },
//     {
//       id: 'A2EUQ1WTGCTBG2',
//       countryCode: 'CA',
//       name: 'Amazon.ca',
//       defaultCurrencyCode: 'CAD',
//       defaultLanguageCode: 'en_CA',
//       domainName: 'www.amazon.ca'
//     },
//     {
//       id: 'AR4NJE6OZQ46P',
//       countryCode: 'US',
//       name: 'AmazonEC2Marketplace',
//       defaultCurrencyCode: 'USD',
//       defaultLanguageCode: 'en_US',
//       domainName: 'AmazonEC2Marketplace.stores.amazon.com'
//     },
//     {
//       id: 'ATVPDKIKX0DER',
//       countryCode: 'US',
//       name: 'Amazon.com',
//       defaultCurrencyCode: 'USD',
//       defaultLanguageCode: 'en_US',
//       domainName: 'www.amazon.com'
//     }
//   ]
// };

// export const responseSpMarketplaces3 = {
//   data: [
//     {
//       id: 'A1AM78C64UM0Y8',
//       countryCode: 'MX',
//       name: 'Amazon.com.mx',
//       defaultCurrencyCode: 'MXN',
//       defaultLanguageCode: 'es_MX',
//       domainName: 'www.amazon.com.mx'
//     },
//     {
//       id: 'A1MQXOICRS2Z7M',
//       countryCode: 'CA',
//       name: 'SI CA Prod Marketplace',
//       defaultCurrencyCode: 'CAD',
//       defaultLanguageCode: 'en_CA',
//       domainName: 'siprod.stores.amazon.ca'
//     },
//     {
//       id: 'A2EUQ1WTGCTBG2',
//       countryCode: 'CA',
//       name: 'Amazon.ca',
//       defaultCurrencyCode: 'CAD',
//       defaultLanguageCode: 'en_CA',
//       domainName: 'www.amazon.ca'
//     },
//     {
//       id: 'A2Q3Y263D00KWC',
//       countryCode: 'BR',
//       name: 'Amazon.com.br',
//       defaultCurrencyCode: 'BRL',
//       defaultLanguageCode: 'pt_BR',
//       domainName: 'www.amazon.com.br'
//     },
//     {
//       id: 'A2ZV50J4W1RKNI',
//       countryCode: 'US',
//       name: 'Non-Amazon',
//       defaultCurrencyCode: 'USD',
//       defaultLanguageCode: 'en_US',
//       domainName: 'sim1.stores.amazon.com'
//     },
//     {
//       id: 'A3H6HPSLHAK3XG',
//       countryCode: 'MX',
//       name: 'Non-Amazon',
//       defaultCurrencyCode: 'MXN',
//       defaultLanguageCode: 'es_MX',
//       domainName: 'sidevo.stores.amazon.mx'
//     },
//     {
//       id: 'AHRY1CZE9ZY4H',
//       countryCode: 'US',
//       name: 'Amazon.com Invoicing Shadow Marketplace',
//       defaultCurrencyCode: 'USD',
//       defaultLanguageCode: 'en_US',
//       domainName: 'invoicing-shadow-marketplace.amazon.com'
//     },
//     {
//       id: 'ATVPDKIKX0DER',
//       countryCode: 'US',
//       name: 'Amazon.com',
//       defaultCurrencyCode: 'USD',
//       defaultLanguageCode: 'en_US',
//       domainName: 'www.amazon.com'
//     }
//   ]
// };