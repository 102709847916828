export const responseDatabricksSpm = {
  links: {
    first: 'https://subscriptions.api.dev.openbridge.io/spm?page=1&subscription=277',
    last: 'https://subscriptions.api.dev.openbridge.io/spm?page=1&subscription=277',
    next: '',
    prev: ''
  },
  data: [
    {
      type: 'SubscriptionProductMeta',
      id: '1043',
      attributes: {
        data_id: 0,
        data_key: 'databricks_server_hostname',
        data_value: 'hostname',
        created_at: '2022-01-24T13:33:31.323885',
        modified_at: '2022-05-05T05:53:10.334922',
        product: {
          id: 68,
          active: 1,
          name: 'Databricks',
          summary: '',
          name_slug: 'databricks',
          created_at: '2021-03-12T05:00:00',
          modified_at: '2021-03-12T05:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'databricks',
          allow_as_trial: 1,
          is_storage_product: 1,
          initialize_job: 0,
          premium_product: 0,
          worker_name: null,
          product_group: 4,
          remote_identity_type: null,
          product_category: 6,
          parent_product: null
        },
        subscription: {
          id: 277,
          price: 0,
          status: 'active',
          date_start: '2022-05-05T05:53:06',
          date_end: '2022-05-05T05:53:06',
          auto_renew: 1,
          created_at: '2022-01-24T13:33:31.316183',
          modified_at: '2022-05-05T05:53:10.326500',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'databricks-development',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 68,
          product_plan: null,
          remote_identity: null,
          storage_group: 171,
          user: 309
        }
      }
    },
    {
      type: 'SubscriptionProductMeta',
      id: '1044',
      attributes: {
        data_id: 0,
        data_key: 'databricks_http_path',
        data_value: 'httppath',
        created_at: '2022-01-24T13:33:31.330632',
        modified_at: '2022-05-05T05:53:10.343717',
        product: {
          id: 68,
          active: 1,
          name: 'Databricks',
          summary: '',
          name_slug: 'databricks',
          created_at: '2021-03-12T05:00:00',
          modified_at: '2021-03-12T05:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'databricks',
          allow_as_trial: 1,
          is_storage_product: 1,
          initialize_job: 0,
          premium_product: 0,
          worker_name: null,
          product_group: 4,
          remote_identity_type: null,
          product_category: 6,
          parent_product: null
        },
        subscription: {
          id: 277,
          price: 0,
          status: 'active',
          date_start: '2022-05-05T05:53:06',
          date_end: '2022-05-05T05:53:06',
          auto_renew: 1,
          created_at: '2022-01-24T13:33:31.316183',
          modified_at: '2022-05-05T05:53:10.326500',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'databricks-development',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 68,
          product_plan: null,
          remote_identity: null,
          storage_group: 171,
          user: 309
        }
      }
    },
    {
      type: 'SubscriptionProductMeta',
      id: '1045',
      attributes: {
        data_id: 0,
        data_key: 'databricks_access_token',
        data_value: 'databricks1access1token',
        created_at: '2022-01-24T13:33:31.336874',
        modified_at: '2022-05-05T05:53:10.351050',
        product: {
          id: 68,
          active: 1,
          name: 'Databricks',
          summary: '',
          name_slug: 'databricks',
          created_at: '2021-03-12T05:00:00',
          modified_at: '2021-03-12T05:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'databricks',
          allow_as_trial: 1,
          is_storage_product: 1,
          initialize_job: 0,
          premium_product: 0,
          worker_name: null,
          product_group: 4,
          remote_identity_type: null,
          product_category: 6,
          parent_product: null
        },
        subscription: {
          id: 277,
          price: 0,
          status: 'active',
          date_start: '2022-05-05T05:53:06',
          date_end: '2022-05-05T05:53:06',
          auto_renew: 1,
          created_at: '2022-01-24T13:33:31.316183',
          modified_at: '2022-05-05T05:53:10.326500',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'databricks-development',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 68,
          product_plan: null,
          remote_identity: null,
          storage_group: 171,
          user: 309
        }
      }
    },
    {
      type: 'SubscriptionProductMeta',
      id: '1043',
      attributes: {
        data_id: 0,
        data_key: 'databricks_schema',
        data_value: 'schema',
        created_at: '2022-01-24T13:33:31.323885',
        modified_at: '2022-05-05T05:53:10.334922',
        product: {
          id: 68,
          active: 1,
          name: 'Databricks',
          summary: '',
          name_slug: 'databricks',
          created_at: '2021-03-12T05:00:00',
          modified_at: '2021-03-12T05:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'databricks',
          allow_as_trial: 1,
          is_storage_product: 1,
          initialize_job: 0,
          premium_product: 0,
          worker_name: null,
          product_group: 4,
          remote_identity_type: null,
          product_category: 6,
          parent_product: null
        },
        subscription: {
          id: 277,
          price: 0,
          status: 'active',
          date_start: '2022-05-05T05:53:06',
          date_end: '2022-05-05T05:53:06',
          auto_renew: 1,
          created_at: '2022-01-24T13:33:31.316183',
          modified_at: '2022-05-05T05:53:10.326500',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'databricks-development',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 68,
          product_plan: null,
          remote_identity: null,
          storage_group: 171,
          user: 309
        }
      }
    },
    {
      type: 'SubscriptionProductMeta',
      id: '1043',
      attributes: {
        data_id: 0,
        data_key: 'databricks_catalog',
        data_value: 'catalog',
        created_at: '2022-01-24T13:33:31.323885',
        modified_at: '2022-05-05T05:53:10.334922',
        product: {
          id: 68,
          active: 1,
          name: 'Databricks',
          summary: '',
          name_slug: 'databricks',
          created_at: '2021-03-12T05:00:00',
          modified_at: '2021-03-12T05:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'databricks',
          allow_as_trial: 1,
          is_storage_product: 1,
          initialize_job: 0,
          premium_product: 0,
          worker_name: null,
          product_group: 4,
          remote_identity_type: null,
          product_category: 6,
          parent_product: null
        },
        subscription: {
          id: 277,
          price: 0,
          status: 'active',
          date_start: '2022-05-05T05:53:06',
          date_end: '2022-05-05T05:53:06',
          auto_renew: 1,
          created_at: '2022-01-24T13:33:31.316183',
          modified_at: '2022-05-05T05:53:10.326500',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'databricks-development',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 68,
          product_plan: null,
          remote_identity: null,
          storage_group: 171,
          user: 309
        }
      }
    },
  ],
  meta: {
    pagination: {
      page: 1,
      pages: 1,
      count: 6
    }
  }
};