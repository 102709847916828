import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/flash-notifications.actions';
import { FlashNotification } from 'src/app/shared/models/flash-notification.model';

// export interface checklistValue

// CHange "any" to whatever the user model will be in the future.
export interface FlashNotificationState {
  notifications: FlashNotification[] | null;
}

//  Create the initial state for the authenticated user.
const initialFlashNotificationState: FlashNotificationState = {
  notifications: []
};

export const reducer = createReducer(
  initialFlashNotificationState,
  on(actions.flashNotificationsClear, (state, payload) => {
    return {
      notifications: [] = []
    };
  }),
  on(actions.flashNotificationsFormValidationError, (state, payload) => {
    const error = new FlashNotification('error', payload.message);
    return {
      notifications: [
        ...state.notifications,
        error
      ]
    };
  }),
  on(actions.flashNotificationsFormValidationErrorWithDetails, (state, payload) => {
    const error = new FlashNotification('error', payload.message, payload.details);
    return {
      notifications: [
        ...state.notifications,
        error
      ]
    };
  }),
  on(actions.flashNotificationsFormValidationSuccess, (state, payload) => {
    const success = new FlashNotification('success', payload.message);
    return {
      notifications: [
        ...state.notifications,
        success
      ]
    };
  }),

);