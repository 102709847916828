<div class="modal-header">
  <h3 class="modal-title pull-left">Openbridge Site Updating <span class="me-2 badge bg-info-soft ms-1 mt-n1">Updating</span></h3>
</div>
<div class="modal-body text-muted">
  <div class="mb-4">
    Openbridge has pushed an update to this application.  We will refresh the site in {{ waitBeforeReloadInSeconds }} seconds.
  </div>
  <div class="progress">
    <div class="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" aria-valuenow="0"
      aria-valuemin="0" aria-valuemax="100"  [ngStyle]="{'width': barWidth}"></div>
  </div>
</div>