import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/reporting.actions';

export interface ReportingState {
    reportStates: any[];
}

//  Create the initial state for account identities.
const initialReportingState: ReportingState = {
  reportStates: []
};

export const reducer = createReducer(
  initialReportingState,
  on(actions.startReportGeneration, (state, payload) => {
    return state;
  }),
  on(actions.initializeReportGengeration, (state, payload) => {
    const reportStates = [
      ...state.reportStates,
      payload.definition
    ]

    const stateData = {
      ...state,
      reportStates: reportStates
    };

    return stateData;
  }),
  on(actions.updateReportStage, (state, payload) => {
    const reportStates = state.reportStates.map((state) => ({
      ...state,
    }));

    const keys = Object.keys(payload);

    if(keys.includes('stage')) {
      reportStates[payload.index].stage=payload.stage;
    }

    if(keys.includes('percentage')) {
      reportStates[payload.index].completionPercentage = payload.percentage;
    }

    if(keys.includes('reportData')) {
      reportStates[payload.index].reportData = payload.reportData;
    }

    const stateData = {
      ...state,
      reportStates: reportStates
    };
    return stateData;
  })
);

export const ReportRequestStages: string[] = [
  'initialized',
  'processing',
  'complete'
];

export interface ReportRequest {
  type: string;
  stage: string;
  completionPercentage: number;
  reportData?: string | null
  errorMessage?: string | null;
}

export interface PipelineParamsReports extends ReportRequest {
  subscriptions: any[]
}