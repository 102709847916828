import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DateTimeService } from './date-time.service';

@Injectable({
  providedIn: 'root'
})
export class PipelineCountService {

  baseApiUrl: string = null;

  constructor(
    private http: HttpClient,
    private dateTimeService: DateTimeService
  ) {
    this.baseApiUrl = environment.openbridgeApiUris.pipelineCount;
  }

  async getAll(): Promise<any> {
    try {
      const getResponse = await this.http.get(this.baseApiUrl + '/count', { observe: 'response' }).toPromise();
      return getResponse;
    }
    catch (error) {
      throw error;
    }
  }

  async getByUTCDate(utcDateString: string): Promise<any> {
    try {
      const getResponse = await this.http.get(this.baseApiUrl + '/count?date=' + utcDateString, { observe: 'response' }).toPromise();
      return getResponse;
    }
    catch (error) {
      throw error;
    }
  }

  transformResponse(value: any): any {
    const transformedData = {
      labels: value.date,
      data: value.count
    };

    return transformedData;
  }

  getDateRangeArray(s, e, format: string): any {
    for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
      a.push(this.dateTimeService.format(new Date(d), format));
    }
    return a;
  }

}
