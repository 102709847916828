import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/modals.action';

// CHange "any" to whatever the user model will be in the future.
export interface ModalsState {
  title: string | null;
  message: string | null;
  progress: number | null;
  type: string | null;
}

//  Create the initial state for the authenticated user.
const initialModalState: ModalsState = {
  title: null,
  message: null,
  progress: 0,
  type: null
};

export const reducer = createReducer(
  initialModalState,
  on(actions.modalsOpen, (state, action) => {
    return {
      ...state,
      title: action.title,
      message: action.message,
      progress: action.progress,
      type: action.type
    };
  }),
  on(actions.modalsUpdate, (state, action) => {
    return {
      ...state,
      title: action.title,
      message: action.message,
      progress: action.progress
    };
  }),
  on(actions.modalsClose, (state, action) => {
    return initialModalState;
  })
);
