export const vendorsHashed = {
  amazon: '2d0d4809e6bdb6f4db3e547f27b1873c.svg',
  amazonAdvertising: '2d0d4809e6bdb6f4db3e547f27b1873c.svg',
  amazonSellerCentral: '2d0d4809e6bdb6f4db3e547f27b1873c.svg',
  amazonVendorCentral: '2d0d4809e6bdb6f4db3e547f27b1873c.svg',
  awsAthena: 'b2b102a76b7ad3f01419bf7c97528b8a.svg',
  awsRedshift: 'd24391102c70936bb08e087973564d75.svg',
  awsSpectrum: 'cb3eb83bd17e794ecef8b6ff8ac111af.svg',
  azureBlobStorage: '552858a07908956d29d9b3713686185b.svg',
  azureDataLake: '6f91bc8c7d488caba354ebf75df4bc67.svg',
  batch: 'd265aed699f7409ac0ec6fe07ee9cb11.svg',
  dataBricks: '7c789645bd884324eac1641ff0e55b62.svg',
  googleAnalytics360: '278e53c5ede173b2e674b8a7b8e9a31f.svg',
  googleBigquery: '07e0d87738a9c65bf743e036fec42c86.svg',
  googleCampaignManager: '6d7e955cf54cc9bc16c1746aeb8f226c.svg',
  googleCloud: 'da618bd528eb9075f5257be09e68c9bc.svg',
  googleDisplayVideo360: '3f4b977af0aeb38bfab286edc7cb5a08.svg',
  googleSearch360: '93ae0a5ef8a6e277254f705d68da5087.svg',
  googleAds: '20dbe5c498222ff7f33e9314b19b7f92.svg',
  google: 'c822c1b63853ed273b89687ac505f9fa.svg',
  facebook: '26cae7718c32180a7a0f8e19d6d40a59.svg',
  instagram: 'ffe8560492ef96f860b965341d0c9698.svg',
  kinesis: 'd172f15a95c6dec778ba931c14ff15c1.svg',
  openbridgeMultiStorage: '1136ef888da37a67d53f58d292d209e9.svg',
  opensource: '6bac264dd02bb09fd66b0c4b557cb548.svg',
  shopify: '329a01fddb5a552265170b02c579c85f.svg',
  snowflake: '08c8c3a0b5d92627f24fed878afd8325.svg',
  youtube: 'ba9bf05693b9fa202d922dd43a08f281.svg',
};