import { Integration } from '../../models/integration.model';
import { vendors } from '../logos';
import {
  productIdAmzVendorBrandAnalyticsReports,
  productIdAmzVendorsRetailAnalytics, productIdAmzVendorsRetailProcurmentOrder, productIdAmzVendorRealtimeAnalytics
} from '../product-ids';

export const integrationAmzVendorsRetailAnalytics: Integration = {
  productId: productIdAmzVendorsRetailAnalytics,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Vendor Retail Analytics',
  badge: 'none',
  type: 'source',
  sortOrder: 1200,
  tagLine: 'Search terms for products that are winning the most clicks and conversions.',
  routerLink: '/wizards/amazon-vendor-retail-analytics',
  logoPath: vendors.amazonSellerCentral,
  frequency: 'Daily',
  standardIntegration: false,
  premiumIntegration: true,
  opensourceIntegration: false,
  remoteIdentityTypeId: 18,
  tableWeight: 0.7,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'amazon.com/selling-partner',
    uri: 'https://developer-docs.amazon.com/sp-api/docs/report-type-values#vendor-retail-analytics-reports'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: {
    upstreamSource: "Amazon Vendor Central",
    upstreamSourceProduct: "Amazon Vendor Central",
    workerName: "sp_reports_vendor_retail",
    pathPrefix: "selling-partner/vendor-retail",
    queryKey: "amazon-vendor-central"
  }
};

export const integrationAmzVendorsRetailProcurmentOrder: Integration = {
  productId: productIdAmzVendorsRetailProcurmentOrder,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Vendor Retail Procurement',
  badge: 'none',
  type: 'source',
  sortOrder: 1200,
  tagLine: 'Deliver data for Vendors to help manage their retail business operations.',
  routerLink: '/wizards/amazon-vendor-retail-procurement',
  logoPath: vendors.amazonSellerCentral,
  frequency: 'Daily',
  standardIntegration: false,
  premiumIntegration: true,
  opensourceIntegration: false,
  remoteIdentityTypeId: 18,
  tableWeight: 0.1,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'amazon.com/selling-partner',
    uri: 'https://developer-docs.amazon.com/sp-api/docs/vendor-retail-procurement-apis-v1-use-case-guide'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: {
    upstreamSource: "Amazon Vendor Central",
    upstreamSourceProduct: "Amazon Vendor Central",
    workerName: "sp_vendor_procurement",
    pathPrefix: "selling-partner/vendor-procurement",
    queryKey: "amazon-vendor-central"
  }
};

export const integrationAmzVendorsBrandAnalyticsReports: Integration = {
  productId: productIdAmzVendorBrandAnalyticsReports,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Vendor Brand Analytics Reports',
  badge: 'none',
  type: 'source',
  sortOrder: 1200,
  tagLine: 'Search terms for products that are winning the most clicks and conversions.',
  routerLink: '/wizards/amazon-vendor-brand-analytics-reports',
  logoPath: vendors.amazonSellerCentral,
  frequency: 'Daily',
  standardIntegration: false,
  premiumIntegration: true,
  opensourceIntegration: false,
  remoteIdentityTypeId: 18,
  tableWeight: 0.65,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'amazon.com/selling-partner',
    uri: 'https://developer-docs.amazon.com/sp-api/docs/report-type-values#brand-analytics-reports'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: {
    upstreamSource: "Amazon Seller Central",
    upstreamSourceProduct: "Amazon Seller Central",
    workerName: "sp_reports_vendor_brand",
    pathPrefix: "selling-partner/reports-brand",
    queryKey: "amazon-seller-central"
  }
};

export const integrationAmzVendorRealtimeAnalytics: Integration = {
  productId: productIdAmzVendorRealtimeAnalytics,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Vendor Rapid Retail Analytics',
  badge: 'none',
  type: 'source',
  sortOrder: 1200,
  tagLine: 'Unlock near real-time Vendor sales, traffic, and inventory analytics',
  routerLink: '/wizards/amazon-vendor-real-time-analytics',
  logoPath: vendors.amazonSellerCentral,
  frequency: 'Hourly',
  standardIntegration: false,
  premiumIntegration: true,
  opensourceIntegration: false,
  remoteIdentityTypeId: 18,
  tableWeight: 0.2,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'amazon.com/selling-partner',
    uri: 'https://developer-docs.amazon.com/sp-api/docs/report-type-values'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: [
    'US',
    'CA',
    'MX',
    'UK',
    'DE',
    'FR',
    'IT',
    'ES',
    'NL',
    'PL',
    'SE',
    'EG',
    'TR',
    'SA',
    'AE',
    'IN',
    'SG',
    'JP',
    'AU',
    'BE',
    'BR'
  ],
  gptKnowledge: {
    upstreamSource: "Amazon Vendor Central",
    upstreamSourceProduct: "Amazon Vendor Central",
    workerName: "sp_reports_vendor_rt",
    pathPrefix: "selling-partner/vendor-rt",
    queryKey: "amazon-vendor-central"
  }
};