export const responseBigQueryStorage = {
  data: {
    attributes: {
      accmapping_id: 966,
      account_id: 'test-1640951289162-000001',
      created_at: '2021-12-31 11:48:18.696048',
      error_code: null,
      message: '',
      modified_at: '2021-12-31 11:48:18.960592',
      path: '/ebs/ftpd/test-1640951289162-000001/storage_validation',
      status: 'ACTIVE',
      storage_type: 'bigquery'
    },
    id: 972
  }
};