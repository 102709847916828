export const responseGoogleCampaignManagerProfiles = {
  data: [
    {
      id: '5905858',
      attributes: {
        kind: 'dfareporting#userProfile',
        username: 'analyticsrequests',
        accountId: '1300423',
        accountName: 'Hasbro Global CM',
        etag: '\'aTjgKplpiRgRgzTOVD5_GUdUcps\''
      }
    },
    {
      id: '5285654',
      attributes: {
        kind: 'dfareporting#userProfile',
        username: 'analyticsrequests@34503',
        accountId: '34503',
        accountName: 'OMD: Hasbro GLOBAL - DCM',
        etag: '\'AGL-DKgsaY5Jrd3LL0Xe5jCzQEY\''
      }
    },
    {
      id: '7210352',
      attributes: {
        kind: 'dfareporting#userProfile',
        username: 'analyticsrequests_Hasbro',
        accountId: '1300423',
        accountName: 'Hasbro Global CM',
        subAccountId: '2858198',
        subAccountName: 'US Subaccount',
        etag: '\'tcCFcNha39PNNu4Sp-G5LMu0w00\''
      }
    },
    {
      id: '7222388',
      attributes: {
        kind: 'dfareporting#userProfile',
        username: 'analyticsrequests_Hasbro_Global',
        accountId: '1300423',
        accountName: 'Hasbro Global CM',
        etag: '\'7mVbs02BnQfG-rmbWsS2V8Z6OVY\''
      }
    }
  ]
};