export const responseBigQueryRemoteIdentityMeta = {
  links: {
    first: 'https://remote-identity.api.dev.openbridge.io/rim?page=1&remote_identity=110&remoteidentitytypemetakey=10',
    last: 'https://remote-identity.api.dev.openbridge.io/rim?page=1&remote_identity=110&remoteidentitytypemetakey=10',
    next: '',
    prev: ''
  },
  data: [
    {
      type: 'RemoteIdentityMeta',
      id: '107',
      attributes: {
        remote_identity_id: 110,
        remote_identity_type_meta_key_id: 10,
        meta_value: 'meta value encrypted string',
        meta_format: 'ENCRYPTED_STRING',
        created_at: '2021-12-01T15:20:53.414969',
        modified_at: '2021-12-01T15:20:53.414990'
      },
      relationships: {
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '110'
          }
        },
        remote_identity_type_meta_key: {
          data: {
            type: 'RemoteIdentityTypeMetaKey',
            id: '10'
          }
        }
      }
    }
  ],
  meta: {
    pagination: {
      page: 1,
      pages: 1,
      count: 1
    }
  }
};