import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '../store/reducers';
import { first, mergeMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { openbridgeSupportUserRole } from '../store/selectors/auth.selector';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  userToken: string = null;
  userTokenSubscription$: any;

  constructor(
    private store$: Store<AppState>
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return this.store$.select('auth').pipe(
      first(),
      mergeMap(auth => {

        // Get the request URL.
        const url = new URL(req.url);
        let jwtToken = null;
        let headers = {};

        if(this.shouldIncludeAuthorizationHeader(req.url)) {
          jwtToken = auth.jwt;
          const contentType = req.headers.get('content-type');
      
          if (jwtToken !== null) {
            headers['Authorization'] = 'Bearer ' + jwtToken;

            if(req.method === 'POST' || req.method === 'PUT' ) {
              headers['content-type'] = 'application/json';
              if(contentType === 'multipart/form-data') {
                headers['content-type'] = undefined;
              }
            }
          }
        }

        const proxy = this.proxyHeaderValueFOrUri(req.url);
        if(proxy !== null) {
          headers['X-Resource-Proxy'] = proxy;
        }

        return next.handle(req.clone({ setHeaders: headers }));
      }),
    );
  }

  private shouldIncludeAuthorizationHeader(url: any): boolean {

    // Check if it is a service call for found org, this will work for now.
    const targetUrl = new URL(url);
    const re = new RegExp(/^.*\/service\/org\/name.*$/);
    const foundOrgName = (re.exec(targetUrl.href) !== null) ? true : false;

    if (
        !environment.httpInterceptor.tokenExcludeDomains.includes(url.origin)
      &&
        url.pathname !== '/last-updated.json'
      &&
        !foundOrgName
      &&
        url !== (environment.openbridgeApiUris.authentication + '/auth/jwt')
      &&
        url !== (environment.s3BucketUris.csvValidator)
      &&
        url !== (environment.s3BucketUris.airbyteConfig)
      ) {
      return true;
    }
    return false;
  }

  private proxyHeaderValueFOrUri(url: any): string | null {
    if(url === (environment.openbridgeApiUris.authentication + '/auth/jwt')) {

      const proxy = localStorage.getItem('proxy');

      if(proxy !== null) {
        return proxy;
      }
    }

    return null;
  }
}
