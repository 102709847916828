export const responseInstagramInsightsSpm = {
  links: {
    first: 'https://subscriptions.api.dev.openbridge.io/spm?page=1&subscription=795',
    last: 'https://subscriptions.api.dev.openbridge.io/spm?page=1&subscription=795',
    next: '',
    prev: ''
  },
  data: [
    {
      type: 'SubscriptionProductMeta',
      id: '1719',
      attributes: {
        data_id: 0,
        data_key: 'remote_identity_id',
        data_value: '202',
        created_at: '2022-05-09T11:00:15.529641',
        modified_at: '2022-05-09T11:04:57.098117',
        product: {
          id: 34,
          active: 1,
          name: 'Instagram Business IQ',
          summary: '',
          name_slug: 'instagram-business-iq',
          created_at: '2018-01-17T05:00:00',
          modified_at: '2018-01-17T05:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'instagram-business-iq',
          allow_as_trial: 1,
          is_storage_product: 0,
          initialize_job: 1,
          premium_product: 0,
          worker_name: 'instabiz',
          product_group: 9,
          remote_identity_type: 2,
          product_category: 1,
          parent_product: null
        },
        subscription: {
          id: 795,
          price: 0,
          status: 'active',
          date_start: '2022-05-09T11:04:52',
          date_end: '2022-05-09T11:04:52',
          auto_renew: 1,
          created_at: '2022-05-09T11:00:15.513635',
          modified_at: '2022-05-09T11:04:57.084161',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'instagram-insights-test',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 34,
          product_plan: null,
          remote_identity: 202,
          storage_group: 110,
          user: 309
        }
      }
    },
    {
      type: 'SubscriptionProductMeta',
      id: '1720',
      attributes: {
        data_id: 0,
        data_key: 'fb_account_id',
        data_value: '109189360738865',
        created_at: '2022-05-09T11:00:15.536245',
        modified_at: '2022-05-09T11:04:57.109193',
        product: {
          id: 34,
          active: 1,
          name: 'Instagram Business IQ',
          summary: '',
          name_slug: 'instagram-business-iq',
          created_at: '2018-01-17T05:00:00',
          modified_at: '2018-01-17T05:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'instagram-business-iq',
          allow_as_trial: 1,
          is_storage_product: 0,
          initialize_job: 1,
          premium_product: 0,
          worker_name: 'instabiz',
          product_group: 9,
          remote_identity_type: 2,
          product_category: 1,
          parent_product: null
        },
        subscription: {
          id: 795,
          price: 0,
          status: 'active',
          date_start: '2022-05-09T11:04:52',
          date_end: '2022-05-09T11:04:52',
          auto_renew: 1,
          created_at: '2022-05-09T11:00:15.513635',
          modified_at: '2022-05-09T11:04:57.084161',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'instagram-insights-test',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 34,
          product_plan: null,
          remote_identity: 202,
          storage_group: 110,
          user: 309
        }
      }
    },
    {
      type: 'SubscriptionProductMeta',
      id: '1721',
      attributes: {
        data_id: 0,
        data_key: 'account_id',
        data_value: '17841432521999739',
        created_at: '2022-05-09T11:00:15.544710',
        modified_at: '2022-05-09T11:04:57.118508',
        product: {
          id: 34,
          active: 1,
          name: 'Instagram Business IQ',
          summary: '',
          name_slug: 'instagram-business-iq',
          created_at: '2018-01-17T05:00:00',
          modified_at: '2018-01-17T05:00:00',
          sort_order: 0,
          launch_playbook: 0,
          url_name_slug: 'instagram-business-iq',
          allow_as_trial: 1,
          is_storage_product: 0,
          initialize_job: 1,
          premium_product: 0,
          worker_name: 'instabiz',
          product_group: 9,
          remote_identity_type: 2,
          product_category: 1,
          parent_product: null
        },
        subscription: {
          id: 795,
          price: 0,
          status: 'active',
          date_start: '2022-05-09T11:04:52',
          date_end: '2022-05-09T11:04:52',
          auto_renew: 1,
          created_at: '2022-05-09T11:00:15.513635',
          modified_at: '2022-05-09T11:04:57.084161',
          quantity: 1,
          stripe_subscription_id: '',
          name: 'instagram-insights-test',
          rabbit_payload_successful: 0,
          primary_job_id: null,
          pipeline: null,
          invalid_subscription: 0,
          invalidated_at: null,
          notified_at: null,
          canonical_name: null,
          history_requested: 0,
          unique_hash: null,
          account: 342,
          product: 34,
          product_plan: null,
          remote_identity: 202,
          storage_group: 110,
          user: 309
        }
      }
    }
  ],
  meta: {
    pagination: {
      page: 1,
      pages: 1,
      count: 3
    }
  }
};