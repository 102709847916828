import * as dayjs from 'dayjs';
import { requestURLs } from './constant';
import { responseSubscriptions } from './data/response-subscriptions';

export function findRequestURL(name: string): string {
    const response = requestURLs.filter(value => {
        if (value.name === name) {
            return value;
        }
    });

    return response[0].value;
}

export function findSubscriptionFromSubscriptionId(subscriptionId): any {
    for (const sub of responseSubscriptions.data) {
        if (sub.id === subscriptionId) {
            sub.attributes.status = 'invalid';
            return sub;
        }
    }
}

export function findSubscriptionFromSubscriptionName(subscriptionName): boolean {

    const activeSubscriptions = responseSubscriptions.data.filter(value => {
        return value.attributes.status !== 'invalid';
    });

    const result = activeSubscriptions.filter(value => {
        return value.attributes.name === subscriptionName;
    });

    if (result.length === 1) {
        return true;
    }
    return false;
}

export function subtractDateFromToday(difference: number): any {
    const today = new Date();
    const newDate = today.setDate(today.getDate() - difference);
    return dayjs(newDate).format('YYYY-MM-DD');
}

export function addDaysToTimestampDate(): any {
    // const x = Math.floor((Math.random() * 30) + 1);
    const today = new Date();
    const nextDate = new Date();
    const result = nextDate.setDate(today.getDate() + 30);
    return Math.floor(result / 1000);
}

// export function addDaysToTimestampDate(date, days): any {
//     const nextDate = new Date();
//     const result = nextDate.setDate(date.getDate() + days);
//     return Math.floor(result / 1000);
// }

// how to use
// const today = new Date();
// const nextDate = this.addDaysToTimestampDate(today, 30);
// console.info('nextDate', nextDate);
