import { response } from 'msw';
import { Md5 } from 'ts-md5/dist/md5';

const isJsonString = (value: string) => {
  try {
      JSON.parse(value);
  } catch (e) {
      return false;
  }
  return true;
};

const isBase64String = (value: string) => {
  try {
    const stringVal = window.atob(value);
  } catch (e) {
      return false;
  }
  return true;
};

export const setCache = (type: string, key: string, value: any) => {

  const stringifiedValue = JSON.stringify(value);

  if (type === 'local') {
    localStorage.setItem(key, stringifiedValue);
  }
  else {
    sessionStorage.setItem(key, stringifiedValue);
  }
};

export const getCache = (type: string, key: string) => {
  let responseValue: any;

  if (type === 'local') {
    responseValue = localStorage.getItem(key);
  }
  else {
    responseValue = sessionStorage.getItem(key);
  }

  if (responseValue === null || typeof responseValue === 'undefined') {
    return null;
  }

  return responseValue;

};

export const removeCache = (type: string, key: string) => {
  if (type === 'local') {
    localStorage.removeItem(key);
  }
  else {
    sessionStorage.removeItem(key);
  }
};

export const generateKeyFromObject = (params: object) => {
  const paramString = JSON.stringify(params);
  return Md5.hashStr(paramString);
};
