
export const fileInstandDownload = (fileName: string, data: any, mimeType: string ) => {
  const blob = new Blob([data], { type: mimeType });
  const link = document.createElement("a");
  if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  }  
}