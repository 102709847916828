import { Injectable } from '@angular/core';
import { identityTypes } from '../constants/identity-types';


@Injectable({
  providedIn: 'root'
})
export class IdentityTypeService {

  constructor() { }

  findIdentityTypeFromId(remoteIdentityTypeId: number): any {
    let result: any = null;

    result = identityTypes.filter((identityType) => {
      return identityType.remoteIdentityTypeId === remoteIdentityTypeId;
    });
    if (result.length === 1) {
      return result[0];
    }
    return false;
  }


  findIdentityTypeFromSlug(remoteIdentitySlug: string): any {
    let result: any = null;

    result = identityTypes.filter((identityType) => {
      return identityType.urlIdentifier === remoteIdentitySlug;
    });

    if (result.length === 1) {
      return result[0];
    }
    return false;
  }
}
