export const responseAthenaStorage = {
  data: {
    attributes: {
      accmapping_id: 935,
      account_id: 'test-1639574272084-000001',
      created_at: '2021-12-15 13:18:02.715607',
      error_code: null,
      message: '',
      modified_at: '2021-12-15 13:18:02.999661',
      path: '/ebs/ftpd/test-1639574272084-000001/storage_validation',
      status: 'ACTIVE',
      storage_type: 'athena'
    },
    id: 941
  }
};