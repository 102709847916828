export const responseGoogleSearchAds360Profiles = {
  data: [
    {
      id: '20300000000000421:21300000000004550',
      attributes: {
        agency: 'OMD France',
        agencyId: '20300000000000421',
        advertiser: 'Hasbro',
        advertiserId: '21300000000004550'
      }
    },
    {
      id: '20300000000000626:21700000001023208',
      attributes: {
        agency: 'OMD Spain',
        agencyId: '20300000000000626',
        advertiser: 'HASBRO GLOBAL',
        advertiserId: '21700000001023208'
      }
    }
  ]
};