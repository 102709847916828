import { Component, OnInit } from '@angular/core';
import { AppState, AuthService } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-modal-general-error',
  templateUrl: './modal-general-error.component.html'
})
export class ModalGeneralErrorComponent implements OnInit {

  modalState: any;
  modalStateSubscription: any;

  constructor(
    private auth: AuthService,
    private store$: Store<AppState>,
  ) { }

  ngOnInit(): void {
    this.modalStateSubscription = this.store$.select('modals').subscribe(response => {
      this.modalState = response;
    });
  }

  async signout(): Promise<void> {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    await delay(500);
    localStorage.removeItem('proxy');
    this.auth.logout();
    window.location.href = '/';
  }

}
