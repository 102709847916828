export const responseAmazonAttributionBrands = {
  data: [
    {
      id: 'ADMHG83G049CC',
      type: 'AmazonAdvertisingProfileBrand',
      attributes: {
        profile_id: '2401846814837028',
        brand_entity_id: 'ENTITY3NGZFX6LHDOCJ',
        brand_registry_name: 'AQUAFIT'
      }
    },
    {
      id: 'A2RM6OC3TFB2MN',
      type: 'AmazonAdvertisingProfileBrand',
      attributes: {
        profile_id: '2401846814837028',
        brand_entity_id: 'ENTITY27ED2H1Y6P8LG',
        brand_registry_name: 'fullstar'
      }
    },
    {
      id: 'A36R76U7LAMUV7',
      type: 'AmazonAdvertisingProfileBrand',
      attributes: {
        profile_id: '2401846814837028',
        brand_entity_id: 'ENTITY8QMNHT9G32R8',
        brand_registry_name: 'FITPULSE'
      }
    },
    {
      id: 'A2RM6OC3TFB2MN',
      type: 'AmazonAdvertisingProfileBrand',
      attributes: {
        profile_id: '3612007981467236',
        brand_entity_id: 'ENTITY3H02FHGNYY88T',
        brand_registry_name: 'fullstar'
      }
    }
  ]
};

export const responseAmazonAttributionBrands2 = {
  data: [
    {
      id: 'ADMHG83G049CC',
      type: 'AmazonAdvertisingProfileBrand',
      attributes: {
        profile_id: '801268442976258',
        brand_entity_id: 'ENTITY39ZTMYJF2NEO8',
        brand_registry_name: 'AQUAFIT'
      }
    },
    {
      id: 'A2RM6OC3TFB2MN',
      type: 'AmazonAdvertisingProfileBrand',
      attributes: {
        profile_id: '801268442976258',
        brand_entity_id: 'ENTITY3DWI1FV89FD88',
        brand_registry_name: 'fullstar'
      }
    },
    {
      id: 'A36R76U7LAMUV7',
      type: 'AmazonAdvertisingProfileBrand',
      attributes: {
        profile_id: '801268442976258',
        brand_entity_id: 'ENTITYBJDO4C2T7H9M',
        brand_registry_name: 'FITPULSE'
      }
    }
  ]
};