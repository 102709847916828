import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/identities.actions';
import { setCache } from 'src/app/core/functions/cache';

// Change "any" to whatever the identity model will be in the future.
export interface IdentitiesState {
  cacheKey: string;
  identities: any[] | null;
}

//  Create the initial state for account identities.
const initialIdentitiesState: IdentitiesState = {
  cacheKey: '',
  identities: []
};

export const reducer = createReducer(
  initialIdentitiesState,
  on(actions.identitySetCacheKey, (state, payload) => {
    const stateData = {
      ...state,
      cacheKey: payload.cacheKey
    };
    return stateData;
  }),  on(actions.identitiesLoad, (state, payload) => {
    const stateData = {
      ...state,
      identities: payload.identities
    };
    setCache('local', stateData.cacheKey, stateData);
    return stateData;
  }),
  on(actions.identityCreate, (state, payload) => {
    const stateData = {
      ...state,
      identities: [
        ...state.identities,
        payload
      ]
    };
    setCache('local', stateData.cacheKey, stateData);
    return stateData;
  }),
  on(actions.identityDelete, (state, payload) => {
    const identities = [...state.identities];
    const index = identities.findIndex((index) => {
      return index.id === payload.remoteIdentityId;
    })

    const spliceIdentity = identities.splice(index, 1);
    
    const stateData = {
      ...state,
      identities: identities
    };

    setCache('local', stateData.cacheKey, stateData);
    return stateData;
  })
);
