import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { identitiesGet, identitiesLoad, identityDelete } from '../actions/identities.actions';
import { IdentityService } from '../../services/identity.service';
import { CacheService } from '../../services/cache.service';
import { subscriptionsGet } from '../actions/subscriptions.actions';
import { AppService } from 'src/app/app.service';
import { modalsUpdate } from '../actions/modals.action';
import { intercomUpdate } from '../actions/intercom.actions';


@Injectable()
export class IdentitiesEffects {

  identitiesGet$ = createEffect(() => this.actions$
    .pipe(
      ofType(identitiesGet),
      tap(async action => {
        try {

          this.store$.dispatch(modalsUpdate({
            modalType: 'progress',
            title: 'Account',
            message: 'Retrieving identities...',
            progress: 65
          }));

          const effectSelf = this; // because, we need "this" somewhere else.
          let identities = this.cacheService.getCache('local', action.identityCacheKey);
          identities = JSON.parse(identities);

          if (identities == null) {
            console.info('Requesting identities data from openbridge API');

            const identityList = [];
            const identityResponses = await this.identityService.getAll();
            for (const response of identityResponses) {
              const data = response['data'];
              for (const identity of data) {
                const formatted = effectSelf.identityService.transformResponse(identity);
                if (formatted) {
                  identityList.push(formatted);
                }
              }
            }
            identities = { identities: identityList };
          }

          const identititesKeys = Object.keys(identities);
          if (!identititesKeys.includes('identities')) {
            identities['identities'] = [];
          }
          // update identities in intercom
          const allIdentities = identities.identities;
          const validIdentities = allIdentities.filter(data => data.invalidIdentity === 0);
          const inValidIdentities = allIdentities.filter(data => data.invalidIdentity === 1)

          const validIdentitiesIdMap = validIdentities.map(data => (data.id));
          const validIdentitiesUniqueIds = this.removeDuplicateIds(validIdentitiesIdMap);

          const inValidIdentitiesIdMap = inValidIdentities.map(data => (data.id));
          const inValidIdentitiesUniqueIds = this.removeDuplicateIds(inValidIdentitiesIdMap);

          this.store$.dispatch(intercomUpdate({
            validIdentitiesIds: validIdentitiesUniqueIds,
            inValidIdentitiesIds: inValidIdentitiesUniqueIds
          }));

          this.store$.dispatch(identitiesLoad(identities));

          // Subscriptions need to be requested after identities load.
          this.store$.dispatch(subscriptionsGet(
            {subscriptionCacheKey: action.subscriptionCacheKey }
          ));
        }
        catch (error) {
          this.appService.closeLoadingModal();
          throw error;
        }
      })
    ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private identityService: IdentityService,
    private store$: Store<AppState>,
    private cacheService: CacheService,
    private appService: AppService
  ) { }

  private removeDuplicateIds(data): any {
    return data.filter((value, index) => data.indexOf(value) === index);
  }
}