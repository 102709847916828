import { Injectable } from '@angular/core';
import { setCache, getCache, generateKeyFromObject, removeCache } from 'src/app/core/functions/cache';
import { environment } from 'src/environments/environment';
import { DateTimeService } from './date-time.service';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  constructor(
    private dateService: DateTimeService
  ) { }

  setCache(type: string, key: string, value: any): void {
    setCache(type, key, value);
  }

  getCache(type: string, key: string): any {
    return getCache(type, key);
  }

  setExpierableCache(key: string, value: string, expiresAt: number): void {
    const payload = {
      expiresAt: expiresAt,
      value: value
    };

    this.setCache('local', key, JSON.stringify(value));

  }

  getExpireableCache(key: string): any {
    const cachedItem = JSON.parse(this.getCache('local', key));

    if(!cachedItem.expiresAt) {
      return null
    }

    // add check for expireation

    return cachedItem.value;

  }

  removeCache(type: string, key: string): any {
    return removeCache(type, key);
  }

  generateKeyFromObject(params: object): string {
    return generateKeyFromObject(params);
  }

  forceCacheDeletion(lastLoginAtCacheKey: string): boolean {
    const forceInvalidationAt = environment.cache.forceInvalidationAt;
    const lastLoginAt = this.getCache('local', lastLoginAtCacheKey);

    if (lastLoginAt !== null && lastLoginAt <= forceInvalidationAt) {
      return true;
    }
    return false;
  }

  clearCache(stripeCustomerId: string): void {
    this.clearIdentityCache(stripeCustomerId);
    this.clearSubscriptionCache(stripeCustomerId);
    window.location.reload();
  }

  clearIdentityCache(stripeCustomerId: string): void {
    console.info('Removing cache for ' + stripeCustomerId + environment.cache.identitiesKey);
    this.removeCache('local', stripeCustomerId + environment.cache.identitiesKey);
  }

  clearSubscriptionCache(stripeCustomerId: string): void {
    console.info('Removing cache for ' + stripeCustomerId + environment.cache.subscriptionsKey);
    this.removeCache('local', stripeCustomerId + environment.cache.subscriptionsKey);
  }

  getLastModifiedEpochForKey(cacheKey: string, ): number {
    const cachedInfo = JSON.parse(this.getCache('local', cacheKey));

    let records: [] = [];
    let lastModified: number = 0;

    if (cachedInfo) {
      const cachedInfoKeys = Object.keys(cachedInfo);

      if (cachedInfoKeys.includes('identities')) {
        records = cachedInfo.identities;
      }
      if (cachedInfoKeys.includes('subscriptions')) {
        records = cachedInfo.subscriptions;
      }

      if (records.length > 0) {
        lastModified = this.findLastModifiedEpoch(records);
      }
    }

    if (lastModified !== null && lastModified !== 0) {
      lastModified += 100;
    }

    return lastModified;
  }

  findLastModifiedEpoch(objectArray: any[]): number {
    const objectArrayMap  = [...objectArray.map(e => {
      const dateSplit  = e.modifiedAt.split('.');
      const dateVal = dateSplit[0] + '+00:00';
      const epoch = this.dateService.convertTimeStampToEpoch(dateVal);
      return epoch;
    })];
    const maxTime = Math.max(...objectArrayMap);
    return maxTime;
  }

}
