export const responseGoogleCampaignManagerSubscription = {
  data: {
    type: 'Subscription',
    attributes: {
      account: '342',
      user: '309',
      product: 63,
      name: 'google-campaign-manager-success',
      status: 'active',
      subscription_product_meta_attributes: [
        {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: '178',
          product: 40
        },
        {
          data_id: 0,
          data_key: 'dfa_profile_id',
          data_value: '4156813945',
          product: 40
        },
        {
          data_id: 0,
          data_key: 'dfa_report_id',
          data_value: 9665639356,
          product: 40
        }
      ],
      quantity: 1,
      price: 0,
      auto_renew: 1,
      date_start: '2022-03-10 12:11:54',
      date_end: '2022-03-10 12:11:54',
      invalid_subscription: 0,
      rabbit_payload_successful: 0,
      stripe_subscription_id: '',
      storage_group: 88,
      remote_identity: '178',
      unique_hash: null
    }
  }
};