export const copyDetails = (input: any): boolean => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(JSON.stringify(input));
  } else {
    console.info('Browser Not compatible');
    // alert('Browser Not compatible');
  }
  return false;
}

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export const findObjectByKeyValue = <T>( array: T[], key: keyof T, value: any): T | undefined => {
  return array.find(obj => obj[key] === value);
}

export const isIterable = (obj: any): obj is Iterable<any> => {
  return typeof obj[Symbol.iterator] === 'function';
}