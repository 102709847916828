import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/account.actions';

// CHange "any" to whatever the user model will be in the future.
export interface AccountState {
  account: any | null;
  accountOwnerAuth0Id: any | null;
  dailyPipelines: any | null;
  organizationAllowed: boolean;
  organizationId: string | null;
}

//  Create the initial state for the authenticated user.
const initialAccountState: AccountState = {
  account: null,
  accountOwnerAuth0Id: null,
  dailyPipelines: null,
  organizationAllowed: false,
  organizationId: null
};

export const reducer = createReducer(
  initialAccountState,
  on(actions.dailyPipelinesLoad, (state, payload) => {
    return {
      ...state,
      dailyPipelines: payload.dailyPipelines
    };
  }),
  on(actions.accountUpdate, (state, payload) => {
    return {
      ...state,
      account: payload.account,
      accountOwnerAuth0Id: payload.accountOwnerAuth0Id,
      organizationAllowed: payload.organizationAllowed,
      organizationId: payload.organizationId,
    };
  })
);
