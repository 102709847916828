export const responseRedshiftAccountMappingEncryption = {
  data: {
    attributes: {
      is_temp: true,
      database: 'kishan_redshift',
      host: 'kishan07',
      password: {
        encrypt: 'kms',
        value: 'encrypted data'
      },
      path: ' / ebs / ftpd / test - 1641558714206 - 000001',
      port: '5439',
      region: 'us - east - 1',
      storage: 'redshift',
      user: {
        encrypt: 'kms',
        value: 'encrypted data'
      }
    }
  }
};