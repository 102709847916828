export const responseBigQueryDataSet = {
  data: [
    {
      type: 'GoogleBigQueryProfileDataset',
      id: 'openbridge-bigquery:james_app_test_dataset',
      attributes: {
        dataset_id: 'james_app_test_dataset',
        project_id: 'openbridge-bigquery',
        location: 'US',
        friendly_name: null,
        labels: {}
      }
    },
    {
      type: 'GoogleBigQueryProfileDataset',
      id: 'openbridge-bigquery:mytestingset',
      attributes: {
        dataset_id: 'mytestingset',
        project_id: 'openbridge-bigquery',
        location: 'US',
        friendly_name: null,
        labels: {}
      }
    },
    {
      type: 'GoogleBigQueryProfileDataset',
      id: 'openbridge-bigquery:stats_test_dataset',
      attributes: {
        dataset_id: 'stats_test_dataset',
        project_id: 'openbridge-bigquery',
        location: 'US',
        friendly_name: null,
        labels: {}
      }
    }
  ]
};