export const responseBigQueryAccountMappingEncryption = {
  data: {
    attributes: {
      is_temp: true,
      bigquery_dataset_id: 'james_app_test_dataset',
      bigquery_project_id: 'openbridge-bigquery',
      bigquery_service_account: {
        encrypt: 'kms',
        value: 'encrypted string'
      },
      path: '/ebs/ftpd/test-1640951289162-000001',
      region: 'us-east-1',
      s3_bucket: 'ob-zeroadmin-stage',
      storage: 'bigquery'
    }
  }
};