export const responseGoogleAnalytics360Projects = {
  data: [
    {
      id: 'data-transfers-241317',
      attributes: {
        kind: 'bigquery#project',
        numericId: '211709892159',
        projectReference: {
          projectId: 'data-transfers-241317'
        },
        friendlyName: 'Data Transfers'
      }
    },
    {
      id: 'easybake-treats',
      attributes: {
        kind: 'bigquery#project',
        numericId: '868426299981',
        projectReference: {
          projectId: 'easybake-treats'
        },
        friendlyName: 'EasyBake Treats'
      }
    },
    {
      id: 'lexical-tide-139915',
      attributes: {
        kind: 'bigquery#project',
        numericId: '549251220203',
        projectReference: {
          projectId: 'lexical-tide-139915'
        },
        friendlyName: 'Google360'
      }
    },
    {
      id: 'hasbro-casing-139217',
      attributes: {
        kind: 'bigquery#project',
        numericId: '417380182647',
        projectReference: {
          projectId: 'hasbro-casing-139217'
        },
        friendlyName: 'Hasbro Google Analytics 360'
      }
    },
    {
      id: 'intense-digit-205817',
      attributes: {
        kind: 'bigquery#project',
        numericId: '756889466972',
        projectReference: {
          projectId: 'intense-digit-205817'
        },
        friendlyName: 'My Project'
      }
    },
    {
      id: 'videoanalysismetrics',
      attributes: {
        kind: 'bigquery#project',
        numericId: '13554223901',
        projectReference: {
          projectId: 'videoanalysismetrics'
        },
        friendlyName: 'VideoAnalysisMetrics'
      }
    }
  ]
};