import { Injectable, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/core/store/reducers';
import { selectSubscriptions } from 'src/app/core/store/selectors/subscriptions.selector';
import { Md5 } from 'ts-md5/dist/md5';
import { Integration } from '../models/integration.model';

@Injectable({
  providedIn: 'root'
})
export class ObjectHashingService implements OnInit {
  subscriptionsSelector$: Observable<any> = this.store$.pipe(select(selectSubscriptions));
  subscriptions: any[];

  constructor(
    protected store$: Store<AppState>,
  ) {
    this.subscriptionsSelector$.subscribe(subscriptions => {
      this.subscriptions = subscriptions;
    });
  }

  ngOnInit(): void {
  }


  hashesAreUnique(pipeline: any): boolean {
    for (const subscription of this.subscriptions) {
      if (pipeline.data.attributes.product === subscription.productId &&
          subscription.status !== 'invalid' && subscription.id === 1256) {

        const found = JSON.parse(subscription.uniqueHash).some((r: string) => pipeline.data.attributes.unique_hash.includes(r));

        if (found) {
          return false;
        }
      }
    }
    return true;
  }

  createHashArrayForIntegrationWithSpmData(integration: Integration, spmData: any): any {

    let hashArray = null;

    if(!integration?.duplicationHashScheme?.type) {
      throw Error('Unknown duplication schema hash type');
    }

    switch (integration.duplicationHashScheme.type) {
      case 'multihash':
        hashArray = this.createMultiHashFromSpm(spmData, integration.duplicationHashScheme.multiHashkey);
        break;
      default:
        hashArray = this.createHashFromSpm(spmData);
    }

    return hashArray;

  }

  createHashFromSpm(spmData: any): any {
    const hashableObject = {};
    for (const data of spmData) {
      hashableObject[data.data_key] = data.data_value;
    }

    return [this.hashObject(hashableObject)];
  }

  createMultiHashFromSpm(spmData: any, multiHashkey: string): any {

    const hashableObject = {};
    let multiHashKeyValue = null;
    let hashedObjects = [];

    for (const data of spmData) {
      if (multiHashkey === data.data_key) {
        multiHashKeyValue = data.data_value;
      }
      else {
        hashableObject[data.data_key] = data.data_value;
      }
    }

    const multiHashKeyValueArray = JSON.parse(multiHashKeyValue);

    for (const value of multiHashKeyValueArray) {
      const cloned = {
        ... hashableObject,
      };
      cloned[multiHashkey] = value;
      const sortedClone = this.sortObjectByKeys(cloned);

      hashedObjects = [
        ...hashedObjects,
        this.hashObject(sortedClone)
      ];
    }

    return hashedObjects;
  }

  sortObjectByKeys(data: any): any {
    const keys = Object.keys(data).sort();
    const newData = {};
    for (const key of keys) {
      newData[key] = data[key];
    }
    return newData;
  }

  hashObject(keyValuePairs: any): string {
    const stringifiedKeyValuePairs = JSON.stringify(this.sortObject(keyValuePairs));
    return Md5.hashStr(stringifiedKeyValuePairs);
  }

  private sortObject(keyValuePairs: any): any {
    return Object.keys(keyValuePairs).sort().reduce((result, key) => {
      result[key] = keyValuePairs[key];
      return result;
    }, {});
  }

}
