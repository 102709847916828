import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WizardState } from '../reducers/wizard.reducer';

export const wizardCurrentStage = createSelector(
  state => state['wizard'],
  (wizard) => wizard.currentStage
);

export const selectWizardState = createFeatureSelector<WizardState>('wizard');

// export const wizardCurrentCache = createSelector(
//   state => state['wizard'],
//   (wizard) => wizard.currentStage
// );

export const wizardCurrentCacheSelector = createSelector(
  selectWizardState,
  (wizardState: WizardState) => wizardState.requestCaches
);
