export const responseDataLakeSubscription = {
  data: {
    type: 'Subscription',
    id: '222',
    attributes: {
      price: 0,
      status: 'active',
      subscription_product_meta_attributes: [
        {
          data_id: 0,
          data_key: 'azureContainer',
          data_value: 'datalake',
          product: 47
        },
        {
          data_id: 0,
          data_key: 'azureConnectionString',
          data_value: 'DefaultEndpointsProtocol=https;AccountName=datalaketest;AccountKey=xyz;EndpointSuffix=xyz',
          product: 47
        }
      ],
      date_start: '2021-12-21T11:38:01',
      date_end: '2021-12-21T11:38:01',
      auto_renew: 1,
      created_at: '2021-12-21T11:38:05.367575',
      modified_at: '2021-12-21T11:38:07.188947',
      quantity: 1,
      stripe_subscription_id: '',
      name: 'azure-data-lake-success',
      rabbit_payload_successful: 0,
      primary_job_id: null,
      pipeline: null,
      invalid_subscription: 0,
      invalidated_at: null,
      notified_at: null,
      canonical_name: null,
      account_id: 431,
      product_id: 47,
      product_plan_id: null,
      remote_identity_id: null,
      storage_group_id: 143,
      user_id: 398,
      history_requested: 0,
      unique_hash: null
    },
    relationships: {
      account: {
        data: {
          type: 'Account',
          id: '431'
        }
      },
      product: {
        data: {
          type: 'Product',
          id: '47'
        }
      },
      product_plan: {
        data: null
      },
      remote_identity: {
        data: null
      },
      storage_group: {
        data: {
          type: 'StorageGroup',
          id: '143'
        }
      },
      user: {
        data: {
          type: 'User',
          id: '398'
        }
      }
    }
  },
  included: [
    {
      type: 'StorageGroup',
      id: '143',
      attributes: {
        product_id: 47,
        name: 'azure-data-lake-success',
        key_name: 'a404e8db3047b06b-azure-data-lake-success'
      }
    }
  ]
};